import React from "react";
import { getDefaultColor, getHoverColor, getActiveColor, getDisabledColor } from "./text.styles.utils";
import { Container } from "./text.styles";
import { TContent, Content } from "../../button.types";

interface Props {
  /** Тип контента */
  content: TContent;
  /** Иконка */
  text: string | null;
}

const Text = (props: Props) => {
  if (!props.text) return null;

  switch (props.content) {
    case Content.Text:
    case Content.IconText:
    case Content.TextIcon:
    case Content.TextCaption:
      return <Container>{props.text}</Container>;
    default:
      return null;
  }
};

Text.Markup = { Container, getDefaultColor, getHoverColor, getActiveColor, getDisabledColor };

export { Text };
