import { useEffect, useRef, useState } from "react";

export const useElementComputedStyles = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);
  const [styles, setStyles] = useState<CSSStyleDeclaration | undefined>();

  useEffect(() => {
    if (ref.current) {
      setStyles(window.getComputedStyle(ref.current));
    }
  }, []);

  return { styles, ref };
};
