import React, { ReactNode, useCallback, useRef, useState } from "react";
import { AutoSizer, List, ListRowRenderer } from "react-virtualized";
import { SkeletonCard } from "components/ui/skeleton-card";
import { stopActions } from "store/actions";
import { useDispatch } from "react-redux";
import { Loader } from "components/ui/loader";
import { RenderedRows } from "react-virtualized/dist/es/List";
import { useFetch } from "../../../../components/common/pagination/useFetch";
import * as Markup from "./pagination-card-list.styles";
import "./pagination-card-list.css";


const DEFAULT_GAP = 8;
const DEFAULT_CARD_HEIGHT = 46;

type Props<T, P, R extends { totalCount: number }> = {
  itemRender: (item: T) => ReactNode;
  options: P;
  params: { pageNum?: number; pageSize?: number };
  fetcher: (args?: P) => Promise<R>;
  setItems: (response: R, params?: { pageNum?: number; pageSize?: number }) => void;
  items: T[];
  cardHeight?: number;
  scrollTo?: number;
  totalCount: number;
};


export const PaginationCardList = <T, P, R extends { totalCount: number }>(props: Props<T, P, R>) => {
  const { fetcher, options, itemRender, setItems, params, items, scrollTo, cardHeight = DEFAULT_CARD_HEIGHT, totalCount } = props;
  const scroll = useRef(scrollTo);
  const [page, setPage] = useState(params.pageNum || 1);
  const [hasNext, setHasNext] = useState(false);
  const list = useRef<List>(null);
  const pageSize = params.pageSize || 200;
  const dispatch = useDispatch();

  const callback = useCallback((response: R, pageParams?: Props<T, P, R>["params"]) => {
    setItems(response, pageParams);
    setPage(pageParams?.pageNum || 1);
    dispatch(stopActions.setCurrentPage(pageParams?.pageNum || 1));
    setHasNext((pageParams?.pageNum || 1) * pageSize < response.totalCount || false);
  }, [setItems, pageSize]);

  const {
    isLoading,
    fetchMore,
  } = useFetch({ fetcher, options, callback, initialParams: params });

  const onThreshold = useCallback(() => {
    fetchMore({ pageNum: page + 1, pageSize });
  }, [fetchMore, page, pageSize]);

  const rowRenderer: ListRowRenderer = useCallback(({ index, style, key }) => {
    const item = items[index];
    let content;
    const updatedStyles = {
      ...style,
      height: (Number(style.height)) - DEFAULT_GAP,
      top: ((Number(style.top) || 0) + DEFAULT_GAP / 2),
    };
    if (!hasNext || index < items.length - 1) {
      content = itemRender(item);
    } else {
      content = <div className="spinner-container"><Loader /></div>;
    }

    return <div style={updatedStyles} key={key} >
      {content}
    </div>;
  }, [items, hasNext, itemRender]);



  const handleScroll = useCallback((rows: RenderedRows) => {
    const { overscanStopIndex } = rows;
    if (overscanStopIndex >= items.length - 1 && !isLoading && hasNext) {
      onThreshold();
      scroll.current = undefined;
    }
  }, [items.length, isLoading, hasNext, onThreshold]);

  return (
    <Markup.ListWrapper>
      {
        <AutoSizer>
          {({ width, height }) =>
            <List
              ref={list}
              width={width}
              height={height}
              rowRenderer={rowRenderer}
              rowCount={items.length}
              rowHeight={cardHeight + DEFAULT_GAP}
              scrollToAlignment={"center"}
              overscanRowCount={10}
              noRowsRenderer={() => totalCount !== -1 ? <Markup.NoDataLabel><Markup.Text>Нет данных</Markup.Text></Markup.NoDataLabel> : <SkeletonCard count={15} />}
              className={"pagination-card-list"}
              onRowsRendered={handleScroll}
              scrollToIndex={scroll.current}
            />
          }
        </AutoSizer>
      }

    </Markup.ListWrapper>
  );
};
