import { KeyZoneResponse } from "api";
import { shallowEqual, useSelector } from "react-redux";
import { KeyZoneFilters } from "store/reducers/key-zone";
import { RootState } from "store/types";


export const useKeyZoneSubstringSearchSelector = () => useSelector((state: RootState) => state.keyZone.keyZoneSearchSubstring);
export const useKeyZoneItemsSelector = (filter: KeyZoneFilters): { items: KeyZoneResponse[], totalCount: number } => useSelector((state: RootState) => {
  const keyZones = state.keyZone[filter];
  const { keyZoneSearchSubstring } = state.keyZone;
  if (!keyZones) return { totalCount: keyZoneSearchSubstring.length > 0 ? 0 : -1, items: [] };
  const { totalCount = 0, items } = keyZones;
  return {
    items: items
      .reduce((acc, page) => [...acc, ...(page ?? [])], [])
      .slice(0, totalCount),
    totalCount,
  };
}, shallowEqual);

export const useKeyZoneActiveFilter = () => useSelector((state: RootState) => state.keyZone.keyZoneActiveFilter);
// export const useKeyZoneFiltersSelector = () => useSelector((state: RootState) => state.keyZone.keyZoneFilters);
