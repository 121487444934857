import { getVehicleClassByType, getVehicleType, VehicleTypeCaptionMap } from "models";
import { getTopologyByMode } from "models/route.utils";
import { useGetRouteItemsByVersion } from "../../route-details-panel.data";
import { labels } from "../../../../../shared/constants";
import { routeTypeChipLabel } from "../../new-route-panel/mocks";

export const useGetRouteInfoTabData = () => {
  const { routeDetails } = useGetRouteItemsByVersion();

  const infoCards: { title: string, text: string }[] = [
    {
      title: "Тип транспорта",
      text: VehicleTypeCaptionMap[getVehicleType(routeDetails?.transportType)] || labels.noValue,
    },
    {
      title: "Тип маршрута",
      text: routeDetails?.kind ? routeTypeChipLabel.filter(el => routeDetails?.kind.toString() === el.id)[0].label : labels.noValue,
    },
    {
      title: "Геометрия маршрута",
      text: getTopologyByMode(routeDetails?.topology) || labels.noValue,
    },
    {
      title: "Перевозчик",
      text: routeDetails?.contractor?.name || labels.noValue,
    },
    {
      title: "Парки",
      text: routeDetails?.depots.map(el=> el.name).join(", ") || labels.noValue,
    },
    {
      title: "Начало действия маршрута",
      text: routeDetails?.startDate || labels.noValue,
    },
    {
      title: "Окончание действия маршрута",
      text: routeDetails?.endDate || labels.noValue,
    },
    {
      title: "Класс вместимости ТС",
      text: routeDetails?.capacityType ? routeDetails?.capacityType.split(",").map(el=> getVehicleClassByType(el)).join(", ") : labels.noValue,
    },
    {
      title: "Средняя протяженность маршрута",
      text: routeDetails?.averageLength ? `${routeDetails.averageLength.toFixed(1)} км` : labels.noValue,
    },
  ];

  return { infoCards };
};
