import { useCallback, useState, useRef, useEffect } from "react";
import { FormValidatorHook, ValidatorError } from "./validator.types";

export const useFormValidator: FormValidatorHook = (form, scheme) => {
  const [errors, setErrors] = useState<Record<keyof typeof scheme, ValidatorError>>({} as Record<keyof typeof scheme, ValidatorError>);
  const [hasError, setHasError] = useState<boolean>(false);
  const schemeRef = useRef(scheme);

  useEffect(() => {
    schemeRef.current = scheme;
  }, [scheme]);

  const validate = useCallback(() => {
    const resultErrors = {} as Record<keyof typeof scheme, ValidatorError>;
    let isError = false;

    Object.keys(schemeRef.current).forEach((key: keyof typeof scheme) => {
      const value = form[key];
      const validator = schemeRef.current[key];

      if (validator) {
        const { hasError: currentHasError, error } = validator(value);

        if (currentHasError) {
          resultErrors[key] = error;
          isError = currentHasError;
        } else {
          resultErrors[key] = null;
        }
      }
    });

    setErrors(resultErrors);
    setHasError(isError);

    return { hasError: isError, errors: resultErrors };
  }, [form]);

  return { hasError, errors, validate };
};
