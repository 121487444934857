import React from "react";
import { SeparatedInlineList } from "components/common";
import styled from "styled-components/macro";

export type StopStationCardCaptionProps = {
  address: string;
  id?: string;
  routeList: string[];
};

const CaptionBlock = styled.span`
  display: flex;
  flex-flow: column nowrap;
  min-height: 1rem;
`;

const AddressBlock = styled.span``;

const RouteListBlock = styled.span``;

export const StopStationCardCaption = ({ address, routeList, id }: StopStationCardCaptionProps) => (
  <CaptionBlock>
    <SeparatedInlineList dots={false} items={[`№ ${id}`, address]} />
    {/* <AddressBlock>{}</AddressBlock>
    <AddressBlock>{}</AddressBlock> */}
    <RouteListBlock>
      <SeparatedInlineList items={routeList} />
    </RouteListBlock>
  </CaptionBlock>
);
