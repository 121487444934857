import { Action } from "redux";
import { CommonActionTypes } from "store/actions/common-actions/common-actions.types";
import { TYPES } from "../../actions";

export interface IInitialCommonState {
  applicationOnLoaded: boolean;
  filterStatus: boolean;
  flyToPoint: { lat: number, lng: number } | [][];
}

const initialState = {
  applicationOnLoaded: false,
  filterStatus: false,
  flyToPoint: [],
};

export const commonReducer = (state: IInitialCommonState = initialState, action: Action & { payload: any }) => {
  const { payload } = action;

  switch (action.type) {
    case TYPES.COMMON.SET_APPLICATION_ONLOADED_STATUS:
      return {
        ...state,
        applicationOnLoaded: payload,
      };

    case CommonActionTypes.FLY_TO_POINT:
      return {
        ...state,
        flyToPoint: payload,
      };

    case CommonActionTypes.FILTER_STATUS:
      return {
        ...state,
        filterStatus: payload,
      };

    default:
      return state;
  }
};
