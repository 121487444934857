import { SearchField } from "components";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { contractorActions, depotActions, driverActions, keyZoneActions, routeActions, stopActions, vehicleActions } from "store/actions";
import * as Markup from "./list-panel-header-search.styles";

export type ListPanelHeaderSearchProps = {
  onCancelClick: React.MouseEventHandler<HTMLButtonElement>;
  requestPath?: string;
};

const ListPanelHeaderSearchComponent = (props: ListPanelHeaderSearchProps) => {
  const { onCancelClick, requestPath } = props;
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  // const { stopActiveFilter } = useSelector((state: RootState) => ({
  //   stopActiveFilter: state.stop.stopActiveFilter,
  // }));

  const handleChange = useCallback(
    ({ value }: any) => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
      setSearchValue(value);
      timer.current = setTimeout(() => {
        switch (requestPath) {
          case "StopStation":
            dispatch(stopActions.setSearchSubstring(value));
            break;
          case "Vehicle":
            dispatch(
              vehicleActions.setSearchSubstring(value),
            );
            break;
          case "Route":
            dispatch(
              routeActions.setSearchSubstring(value),
            );
            break;
          case "Driver":
            dispatch(
              driverActions.setSearchSubstring(value),
            );
            break;
          case "Depot":
            dispatch(
              depotActions.setSearchSubstring(value),
            );
            break;
          case "Contractor":
            dispatch(
              contractorActions.setSearchSubstring(value),
            );
            break;
          case "KeyZone":
            dispatch(
              keyZoneActions.setSearchSubstring(value),
            );
            break;
          default:
            break;
        }
        timer.current = null;
      }, 700);

    },
    [setSearchValue, dispatch, requestPath],
  );

  const handleClear = useCallback(
    () => {
      setSearchValue("");
      switch (requestPath) {
        case "StopStation":
          dispatch(stopActions.setSearchSubstring(""));
          break;
        case "Vehicle":
          dispatch(vehicleActions.setSearchSubstring(""));
          break;
        case "Route":
          dispatch(
            routeActions.setSearchSubstring(""),
          );
          break;
        case "Driver":
          dispatch(
            driverActions.setSearchSubstring(""),
          );
          break;
        case "Depot":
          dispatch(
            depotActions.setSearchSubstring(""),
          );
          break;
        case "Contractor":
          dispatch(
            contractorActions.setSearchSubstring(""),
          );
          break;
        case "KeyZone":
          dispatch(
            keyZoneActions.setSearchSubstring(""),
          );
          break;

        default:
          break;
      }
    },
    [dispatch, requestPath],
  );

  useEffect(() => {
    setSearchValue("");
    return () => {
      handleClear();
    };
  }, []);

  return (
    <Markup.Container>
      <SearchField value={searchValue} onChange={handleChange} onClear={handleClear} />
      <Markup.TextButton onClick={onCancelClick}>Отменить</Markup.TextButton>
    </Markup.Container>
  );
};

export const ListPanelHeaderSearch = memo(ListPanelHeaderSearchComponent);
