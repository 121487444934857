export const calcLeftLetter = (maxLength?: number, value?: string) => {
  if (typeof maxLength !== "number") return "";

  const numSymbol = maxLength && maxLength - (value ?? "").length;

  if (numSymbol && numSymbol % 10 === 1 && numSymbol !== 11) {
    return `Остался ${numSymbol} символ`;
  }

  if (
    (numSymbol && numSymbol % 10 === 2 && numSymbol !== 12) ||
    (numSymbol && numSymbol % 10 === 3 && numSymbol !== 13) ||
    (numSymbol && numSymbol % 10 === 4 && numSymbol !== 14)
  ) {
    return `Осталось ${numSymbol} символа`;
  }

  return `Осталось ${numSymbol} символов`;
};
