import { RootState } from "store/types";
import { shallowEqual, useSelector } from "react-redux";
import { RouteFilters } from "store/reducers/route";
import { RouteVersion } from "../../../api";

export const useRouteFilterSelector = () => useSelector((state: RootState) => state.route.routeActiveFilter);
export const useRouteItemsSelector = (filter: RouteFilters) => useSelector((state: RootState) => {
  const routes = state.route[filter];
  const { routeSearchSubstring } = state.route;
  if (!routes) return { totalCount: routeSearchSubstring.length > 0 ? 0 : -1, items: [] };
  const { totalCount } = routes;

  return { totalCount, items: routes?.items.reduce((acc, page) => [...acc, ...page], []).slice(0, totalCount) as RouteVersion[] ?? [] };
}, shallowEqual);
export const useRouteSearchSubstringSelector = () => useSelector((state: RootState) => state.route.routeSearchSubstring);
export const useRouteActiveFilter = () => useSelector((state: RootState) => state.route.routeActiveFilter);
export const useRouteFiltersSelector = () => useSelector((state: RootState) => state.route.routeFilters);
export const useActiveRouteSelector = () => useSelector((state: RootState) => state.route.activeRouteId);
