/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useCallback, useEffect, useState } from "react";
import { toastError, toastSuccess } from "components";
import { ErrorHandler } from "shared/helpers/error-handler";
import { useNavigateToPreviousRoute } from "shared/hooks/use-navigate-to-previous-route";
import { useDispatch } from "react-redux";
import { useStopCurrentPage } from "store/selectors/stop-selectors";
import { useRouter } from "react-router5";
import { keyZoneActions } from "store/actions";
import { PAGE_ROUTES } from "shared/definitions";
import { Geometry } from "geojson";
import { createKeyZoneRequest, editKeyZoneRequest, getKeyZoneRequest, getKeyZoneVersionRequest, KeyZoneVersionDetails } from "api";
import { useKeyZoneActiveFilter } from "store/selectors/keyzone-selectors";
import { useGetKeyZoneItemsByVersion } from "../keyzone-details-panel.data";

export type NewKeyZoneForm = {
  name: string,
  startDate: string | null,
  endDate: string | null,
  geometry: Geometry,
  description: string,
};

export type EditKeyZoneForm = {
  keyZoneId: number;
  versionId?: number;
} & NewKeyZoneForm;

const initialForm: NewKeyZoneForm = {
  name: "",
  startDate: null,
  endDate: null,
  geometry: {
    type: "Polygon",
    coordinates: [],
  },
  description: "",
};

const detailsToForm = (details: KeyZoneVersionDetails): NewKeyZoneForm => {
  const { name, startDate, endDate, geometry, description, ...rest } = details || {};

  return {
    name: name || "",
    startDate: startDate || null,
    endDate: endDate || null,
    geometry: geometry || initialForm.geometry,
    description: description || "",
    ...rest,
  };
};


export const useNewKeyZoneLogic = (isNew: boolean, setValue?: (name: keyof NewKeyZoneForm, value: NewKeyZoneForm[keyof NewKeyZoneForm]) => void) => {
  const { keyZoneDetails: item } = useGetKeyZoneItemsByVersion();
  const router = useRouter();
  const [form, setForm] = useState(isNew ? initialForm : detailsToForm(item));
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();
  const activeFilter = useKeyZoneActiveFilter();
  const currentPage = useStopCurrentPage();

  useEffect(() => {
    setForm(initialForm);
    !isNew ?? setStartDate(item.startDate ? item.startDate : undefined);
    !isNew ?? setEndDate(item.endDate ? item.endDate : undefined);
    return () => {
      setForm(initialForm);
    };

  }, []);

  const handleExecQuery = useCallback(async (verId: number) => {
    const payload = await getKeyZoneVersionRequest(verId.toString());
    dispatch(keyZoneActions.getKeyZoneByVersionId(verId.toString(), payload));
    const response = await getKeyZoneRequest({ pageNum: 1, pageSize: 200 });
    dispatch(keyZoneActions.setKeyZones({
      data: response,
      status: activeFilter,
      pageNum: 1,
    }));
    router.navigate(PAGE_ROUTES.KEY_ZONE, { id: verId });
  }, [dispatch, activeFilter, currentPage, router]);


  const clearUp = useCallback(() => {
    (Object.keys(initialForm) as Array<keyof typeof initialForm>).map(el => setValue && setValue(`${el}`, initialForm[`${el}`]));
    setEndDate(undefined);
    setStartDate(undefined);
  }, [setValue]);

  const createKeyZone = useCallback((values: NewKeyZoneForm) => {
    setIsLoading(true);
    createKeyZoneRequest(values).then((res) => {
      setIsLoading(false);
      toastSuccess({ title: "Успешно", message: `Добавлен ${values.name}` });
      res.versionId ? handleExecQuery(res.versionId) : router.navigate(PAGE_ROUTES.KEY_ZONES);
      // navigateToPrevPage();
    })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));

  }, [handleExecQuery]);


  const editKeyZone = useCallback(async (values: EditKeyZoneForm) => {
    setIsLoading(true);
    // eslint-disable-next-line no-param-reassign
    delete values.versionId;
    await createKeyZoneRequest({ ...values, keyZoneId: item.keyZoneId })
      .then((res: any) => {
        setIsLoading(false);
        toastSuccess({ title: "Успешно", message: `${isNew ? "Добавлен" : "Обновлен"} ${values.name}` });
        res.versionId ? handleExecQuery(res.versionId) : router.navigate(PAGE_ROUTES.KEY_ZONES);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));

  }, [handleExecQuery, isNew, item]);

  const editVersionKeyZone = useCallback(async (values: EditKeyZoneForm) => {
    setIsLoading(true);
    // eslint-disable-next-line no-param-reassign
    await editKeyZoneRequest({ ...values, keyZoneId: item.keyZoneId })
      .then((res: any) => {
        setIsLoading(false);
        toastSuccess({ title: "Успешно", message: `${isNew ? "Добавлен" : "Обновлен"} ${values.name}` });
        item.versionId ? handleExecQuery(item.versionId) : router.navigate(PAGE_ROUTES.KEY_ZONES);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));

  }, [handleExecQuery, isNew, item]);


  return {
    form,
    clearUp,
    editVersionKeyZone,
    createKeyZone,
    editKeyZone,
    startDate,
    endDate,
    setForm,
    isLoading,
  };
};
