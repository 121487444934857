import styled from "styled-components/macro";
import { withAccentColor } from "shared/utils";

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
`;

export const TextButton = styled.button`
  margin-left: 10px;
  border: none;
  outline: none;
  padding: 0;
  color: ${withAccentColor("blue", "basic")};
  background: transparent;
  &:hover {
    cursor: pointer;
  }
`;
