import React, { useCallback, useEffect } from "react";
import * as api from "services";
import { setSourceAction } from "store/actions";
import { useDispatch } from "react-redux";
import { ContractorTypeResponse, DefaultSelectProps } from "../select-with-source.types";
import { SelectField } from "../../select-field";
import { useSourceLoader } from "../use-source-loader";

export const ContractorSelectField = (props: DefaultSelectProps & { date?: string }) => {
  const { date = new Date().toLocaleDateString("sv-SE", { year: "numeric", month: "2-digit", day: "2-digit" }) } = props;
  const dispatch = useDispatch();
  const fetcher = useCallback(() => api.get<{ items: ContractorTypeResponse[] }>("Contractor/version", { pageSize: 100, isActive: true, Date: date }), [date]);
  const mapper = useCallback((data: { items: ContractorTypeResponse[] }) => {
    const { items } = data;
    return items
      .map(item => ({ value: item.Id, label: item.shortName }))
      .filter(({ label }) => label);
  }, []);

  useEffect(()=>{
    dispatch(setSourceAction("contractor", undefined));
  }, [date]);

  const { options, isLoading } = useSourceLoader("contractor", fetcher, mapper);

  return <SelectField options={isLoading ? [] : options} { ...props } />;
};
