import { TabValues } from "../../../features";
import { IInitialContractorState } from "./contractor.types";

export const initialState: IInitialContractorState = {
  contractorByContractorId: {},
  contractorByVersionId: {},
  contractorActiveFilter: TabValues.ACTIVE,
  currentContractorStation: {},
  contractorSearchString: null,
  contractorFilters: {
    ContractorTypeIds: undefined,
    VehicleTransportTypes: undefined,
    VehicleCapacityTypes: undefined,
  },
  [TabValues.ACTIVE]: null,
  [TabValues.INACTIVE]: null,
  selectedContractor: "",
  isCardLoading: false,
};
