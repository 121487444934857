import { css } from "styled-components";
import { withNeutralColor } from "../../../shared/utils";

export const loadingCss = css`
  &,
  &:disabled {
    box-shadow: none;
    border-color: ${withNeutralColor("l2")};
    color: ${withNeutralColor("d4")};
    user-select: none;
  }
`;
