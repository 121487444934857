import React, { useCallback, useEffect, useState } from "react";
import { FilterWindow, Tab } from "components/ui";
import { commonActions, vehicleActions } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useRoute, useRouter } from "react-router5";
import { RootState } from "store/types";
import { PAGE_ROUTES } from "shared/definitions/routes";
import { VehicleFilterContent } from "features/filters-windows/vehicle-filter-content";
import { ListPanel, ListPanelFilterTabs, ListPanelHeader, ListPanelHeaderWrapper } from "../components";
import { TabValues } from "../list-panels.constants";
import { VehicleCardListPagination } from "../../card-lists/vehicle-card-list/vehicle-card-list-pagination";
import { useSelectedVehicleSelector } from "../../../store/selectors/vehicle-selectors";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

export const VehicleListPanel = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { showDetails } = useLayoutDesignState();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { route: { params: { id: routeId } = {} } } = useRoute();
  const vehicleActiveFilter = useSelector((state: RootState) => state.vehicle.vehicleActiveFilter);
  const activeId = useSelectedVehicleSelector();

  const { filterStatus } = useSelector((state: RootState) => ({
    filterStatus: state.common.filterStatus,
  }));
  const toggle = useCallback(() => {
    dispatch(commonActions.setFilterStatus(!filterStatus));
  }, [filterStatus]);

  useEffect(() => {
    if (!routeId && activeId) {
      router.navigate(PAGE_ROUTES.VEHICLE, { id: activeId });
    }
  }, [activeId, routeId, router]);

  const handleItemClick = useCallback(
    (id: string | number) => {
      dispatch(vehicleActions.setSelectedVehicle(String(id)));
      router.navigate(PAGE_ROUTES.VEHICLE, { id });
    },
    [router, dispatch]);

  const handleAddClick = useCallback(() => {
    router.navigate(PAGE_ROUTES.VEHICLE, { id: "new" });
    // showDetails();
  }, [router, showDetails]);

  const handleChangeFilter = useCallback((filter: string) => {
    dispatch(vehicleActions.setActiveFilter(filter as TabValues));
  }, [dispatch]);

  return (
    <ListPanel
      headerSlot={
        <ListPanelHeaderWrapper>
          {/* <FilterWindow headerText="Транспортные средства"  isShown={showFilter} hide={toggle}  modalContent={<VehicleFilterContent hide={toggle} />}/> */}
          <ListPanelHeader requestPath="Vehicle"  title="Транспортные средства" onAddClick={handleAddClick} onFilterClick={toggle} />
        </ListPanelHeaderWrapper>
      }
      filterSlot={
        <ListPanelFilterTabs activeFilterTab={vehicleActiveFilter} onChange={handleChangeFilter}>
          <Tab value={TabValues.ACTIVE}>Активные</Tab>
          {/*
          <Tab value={TabValues.ONLINE} isDisabled>На связи</Tab>
          <Tab value={TabValues.OFFLINE} isDisabled>Нет связи</Tab>
          */}
          <Tab value={TabValues.INACTIVE}>Неактивные</Tab>
        </ListPanelFilterTabs>
      }
      listSlot={<VehicleCardListPagination activeId={activeId} onClick={handleItemClick} />}
    />
  );
};
