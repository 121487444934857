import { getKeyZoneRequest, GetKeyZoneRequestOptions, KeyZoneResponse, KeyZoneVersionResponse } from "api";
import { KeyZoneCard } from "features/cards/keyzone-card";
import { TabValues } from "features/list-panels";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLayoutDesignState } from "shared/hooks/use-layout-design-state";
import keyZoneActions from "store/actions/keyzone-actions/keyzone.actions";
import { useKeyZoneActiveFilter, useKeyZoneItemsSelector, useKeyZoneSubstringSearchSelector } from "store/selectors/keyzone-selectors";
import { RootState } from "store/types";
import { PaginationCardList } from "../components/pagination-card-list";

const DEFAULT_PAGE_SIZE = 50;

export type KeyZoneCardListProps = {
  activeId?: string;
  onClick?: (id: string) => void;
};

const KeyZoneCardListComponent = (props: KeyZoneCardListProps) => {
  const { activeId, onClick } = props;
  const [pageNum] = useState(1);
  const activeFilter = useKeyZoneActiveFilter();
  const { items, totalCount } = useKeyZoneItemsSelector(activeFilter);
  const { keyZoneMapActiveItem } = useSelector((state: RootState) => ({ keyZoneMapActiveItem: state.keyZone.keyZoneMapActiveItem }));
  const dispatch = useDispatch();
  const firstItem = items[0];
  const activeIndex = useMemo(() => items.findIndex((item) => `${item.versionId}` === String(activeId)), [items, activeId]);
  const searchSubString = useKeyZoneSubstringSearchSelector();

  useEffect(() => {
    if (!activeId && firstItem && onClick) {
      const stopId = `${firstItem.versionId}`;
      onClick(stopId);
    }
  }, [activeId, firstItem]);

  useEffect(() => {
    if (onClick && keyZoneMapActiveItem) {
      const stopId = `${keyZoneMapActiveItem}`;
      onClick(stopId);
    }
  }, [keyZoneMapActiveItem]);

  const handleClick = useCallback((id: string) => {
    if (onClick) {
      onClick(id);
    }
  }, [onClick]);

  


  const params: GetKeyZoneRequestOptions = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
    isActive: activeFilter === TabValues.ACTIVE,
    nameSearch: searchSubString || undefined,
  }), [pageNum, activeFilter, searchSubString]);

  const options: GetKeyZoneRequestOptions = useMemo(() => ({
    isActive: activeFilter === TabValues.ACTIVE,
  }), [activeFilter]);

  const itemRender = useCallback((item:  KeyZoneResponse) => {
    const { name, versionId } = item;

    return <KeyZoneCard
      id={versionId}
      name = {name || ""}
      isActual={activeFilter === TabValues.ACTIVE}
      isActive={activeId === String(versionId)}
      onClick={handleClick}
    />;
  }, [activeId, handleClick, activeFilter]);

  const setItems = useCallback((response: KeyZoneVersionResponse, pageParams?: GetKeyZoneRequestOptions) => {
    dispatch(keyZoneActions.setKeyZones({
      data: response,
      status: activeFilter,
      pageNum: pageParams?.pageNum || -1,
    }));
  }, [dispatch, activeFilter]);

  return (
    <PaginationCardList
      fetcher={getKeyZoneRequest}
      items={items}
      itemRender={itemRender}
      options={options}
      params={params}
      setItems={setItems}
      totalCount={totalCount}
      scrollTo={activeIndex}
    />
  );
};
export const KeyZoneCardListPagination = memo(KeyZoneCardListComponent);
