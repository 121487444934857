import React, { createRef, useCallback, useEffect, useRef } from "react";
import { XClose16Icon, Search24GreyIcon } from "assets/icons";
import {
  SearchFieldChangeHandlerSign,
  SearchFieldApplyHandlerSign,
  SearchFieldClearHandlerSign,
  SearchFieldChangeEvent,
  SearchFieldKeyDownEvent,
} from "./search-field.types";
import * as Markup from "./search-field.styles";

const ENTER_KEY = "Enter";

export type SearchFieldProps = {
  value: string;
  className?: string;
  onChange?: SearchFieldChangeHandlerSign;
  onApply?: SearchFieldApplyHandlerSign;
  onClear?: SearchFieldClearHandlerSign;
  disabled?: boolean;
};

export const SearchField = (props: SearchFieldProps) => {
  const { value, disabled, className, onChange, onClear, onApply } = props;

  const handleChange = useCallback(
    (event: SearchFieldChangeEvent) => {
      onChange?.({ value: event.target.value, event });
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (event: SearchFieldKeyDownEvent) => {
      if (event.key !== ENTER_KEY) return;
      onApply?.({ value, event });
    },
    [value, onApply],
  );

  const hasInputValue = value !== "";

  const emailInput = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return (
    <Markup.Container className={className}  hasInputValue={hasInputValue}>
      <Search24GreyIcon />
      <Markup.Input type="text" ref={emailInput} disabled={disabled} value={value} onChange={handleChange} onKeyDown={handleKeyDown} />
      {hasInputValue && !disabled && (
        <Markup.ClearButton onClick={onClear}>
          <XClose16Icon />
        </Markup.ClearButton>
      )}
    </Markup.Container>
  );
};
