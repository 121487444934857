import styled, { keyframes } from "styled-components";
import { ReactComponent as Loader24Icon } from "./loader24.svg";

const signOpacity = keyframes`
{
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
`;

type LoaderProps = {
  color?: string;
  size?: string;
};

export const Loader = styled(Loader24Icon)<LoaderProps>`
  width: ${({ size }) => size || "24px"};
  height: ${({ size }) => size || "24px"};

  & > path {
    animation: ${signOpacity} 800ms linear infinite;
    fill: ${(props) => props.color || "#84919A"};
    :nth-child(1) {
      animation-delay: -800ms;
    }
    :nth-child(2) {
      animation-delay: -700ms;
    }
    :nth-child(3) {
      animation-delay: -600ms;
    }
    :nth-child(4) {
      animation-delay: -500ms;
    }
    :nth-child(5) {
      animation-delay: -400ms;
    }
    :nth-child(6) {
      animation-delay: -300ms;
    }
    :nth-child(7) {
      animation-delay: -200ms;
    }
    :nth-child(8) {
      animation-delay: -100ms;
    }
  }
`;
