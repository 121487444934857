import { StopFilters } from "store/reducers/stop/stop.types";
import { StopDetailsResponse, StopVersionResponse } from "../../../api";

type Action<T extends StopActionTypes, P = never> = { type: T; payload: P };

export enum StopActionTypes {
  SET_STOPS = "SET_STOPS",
  UPDATE_STOPS_FILES = "UPDATE_STOPS_FILES",
  SET_ACTIVE_STOP = "SET_ACTIVE_STOP",
  SET_INACTIVE_STOP = "SET_INACTIVE_STOP",
  SET_STOP_BY_STOP_ID = "SET_STOP_BY_STOP_ID",
  SET_STOP_BY_VERSION_ID = "SET_STOP_BY_VERSION_ID",
  SET_STOP_ACTIVE_FILTER = "SET_STOP_ACTIVE_FILTER",
  SET_STOP_SEARCH_STRING = "SET_STOP_SEARCH_STRING",
  SET_STOP_FILTERS = "SET_STOP_FILTERS",
  SET_STOP_ACTIVE_ITEM = "SET_STOP_ACTIVE_ITEM",
  SET_NEW_STOP_STATION_COORDS = "SET_NEW_STOP_STATION_COORDS",
  SET_NEW_STOP_STATION_DATA = "SET_NEW_STOP_STATION_DATA",
  SET_ISLOADING = "SET_ISLOADING",
  SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
}

export type SetStopsAction = Action<
StopActionTypes.SET_STOPS,
{ status: StopFilters; data: StopVersionResponse; pageNum: number }
>;
export type SetStopSearchStringAction = Action<StopActionTypes.SET_STOP_SEARCH_STRING, string>;
export type SetStopActiveFilterAction = Action<StopActionTypes.SET_STOP_ACTIVE_FILTER, StopFilters>;
export type SetStopFiltersAction = Action<StopActionTypes.SET_STOP_FILTERS, {
  BalanceHolder?: string | null,
  RouteIds?: string | null,
}>;
export type SetStopByVersionIdAction = Action<
StopActionTypes.SET_STOP_BY_VERSION_ID,
{ versionId: string; data: StopDetailsResponse }
>;
export type SetStopActiveItemAction = Action<StopActionTypes.SET_STOP_ACTIVE_ITEM, string>;
export type SetStopNewCoordinates = Action<StopActionTypes.SET_NEW_STOP_STATION_COORDS, { lng: number; lat: number }>;
export type SetStopNewData = Action<StopActionTypes.SET_NEW_STOP_STATION_DATA, { [key: string]: any }>;
export type SetCurrentPage = Action<StopActionTypes.SET_CURRENT_PAGE, number>;
export type UpdateStopsFilesAction = Action<StopActionTypes.UPDATE_STOPS_FILES, { stopId: string; data: Partial<StopDetailsResponse> }>;

export type StopsAction =
  | SetStopsAction
  | SetStopFiltersAction
  | SetStopSearchStringAction
  | SetStopActiveFilterAction
  | SetStopByVersionIdAction
  | SetStopNewCoordinates
  | SetStopNewData
  | SetCurrentPage
  | UpdateStopsFilesAction
  | SetStopActiveItemAction;
