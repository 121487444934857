import React, { useCallback, useMemo, useState } from "react";
import { FormField } from "features/detail-panels/components";
import { TestButton } from "components";
import { labels } from "shared/constants";
import { ContractorMultiSelectField } from "components/common/select-with-source";
import { Content } from "@megapolis/uikit/button/button.types";
import { Kind, Priority } from "components/ui/buttontest/button.types";
import { useDispatch } from "react-redux";
import { Slider, Toggle } from "@megapolis/uikit";
import { vehicleActions } from "store/actions";
import { useVehicleFiltersSelector } from "store/selectors/vehicle-selectors";
import { VehicleAnotherFilters } from "store/reducers/vehicle";
import { CapacityTypesFilter, VehicleTypesFilter } from "features/filter-element";
import * as Markup from "./vehicle-filter-content.style";
import { RouteFilterElement } from "../route-filter-element";

export const VehicleFilterContent = (props: { hide: () => void }) => {

  const initialFIlter = useMemo(() => ({
    ContractorIds: undefined,
    RouteIds: undefined,
    TransportTypes: undefined,
    VehicleBrands: undefined,
    VehicleMaxAge: undefined,
    VehicleClimateSystem: undefined,
    VehicleLowFloor: undefined,
    CapacityTypes: undefined,
    RouteFilterElements: undefined,
  }), []);
  const dispatch = useDispatch();
  const filters = useVehicleFiltersSelector();
  const [filter, setFilter] = useState<VehicleAnotherFilters>(filters);

  const handleSubmit = useCallback(() => { dispatch(vehicleActions.setFilters(filter)); props.hide(); }, [dispatch, filter, props]);
  const handleReset = useCallback(() => { dispatch(vehicleActions.setFilters(initialFIlter)); setFilter(initialFIlter); }, [dispatch, initialFIlter]);



  const updateValue = useCallback(<P extends keyof VehicleAnotherFilters>(name: P, value: VehicleAnotherFilters[P] | null) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  }, []);


  return (


    <><Markup.Container>
      <FormField>
        <VehicleTypesFilter TransportTypes={filter.TransportTypes || ""} field={"TransportTypes"} updateValue={updateValue} />
      </FormField>
      <FormField>
        <ContractorMultiSelectField
          value={filter.ContractorIds ? filter.ContractorIds?.split(",") : null}
          onChange={el => {
            const result = el.map(({ value }) => value);
            updateValue("ContractorIds", result.toString());
          }}
          label={labels.contractor}
          cyData="route-contractor"
          errorText={undefined}
          hasError={false}
        />
      </FormField>
      <FormField>
        <RouteFilterElement arr={filter.RouteFilterElements || []} update={(arr) => updateValue("RouteFilterElements", arr)} />
      </FormField>
      <FormField>
        <CapacityTypesFilter CapacityTypesFilter={filter.CapacityTypes || ""} field={"CapacityTypes"} updateValue={updateValue} />
      </FormField>
      <FormField>
        <Markup.LabelContainer>{"Климатическая установка"}</Markup.LabelContainer>
        <Toggle value={filter.VehicleClimateSystem || false} onChange={(e) => {
          updateValue("VehicleClimateSystem", e);
        }} />
      </FormField>
      <FormField>
        <Markup.LabelContainer>{"Низкий пол"}</Markup.LabelContainer>
        <Toggle value={filter.VehicleLowFloor || false} onChange={(e) => {
          updateValue("VehicleLowFloor", e);
        }} />
      </FormField>
      <FormField>
        <Markup.LabelContainer>{"Возраст ТС"}</Markup.LabelContainer>
        <Slider onChange={(e) => {
          if (e === 0) {
            updateValue("VehicleMaxAge", undefined);
          } else {
            updateValue("VehicleMaxAge", e);
          }
        }} min={1} max={50} value={filter.VehicleMaxAge || undefined}></Slider>
      </FormField>
    </Markup.Container>
      <Markup.Footer>
        <TestButton content={Content.Text} priority={Priority.Tertiary} text="Применить фильтр" onClick={handleSubmit} cyData="Filter" />
        <TestButton kind={Kind.Danger} content={Content.Text} priority={Priority.Tertiary} text="Сброс" onClick={handleReset} cyData="Filter" />
      </Markup.Footer>
    </>);
};
