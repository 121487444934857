import styled from "styled-components/macro";
import { prop } from "styled-tools";

type OverflowType = "auto" | "hidden" | "scroll" | "visible";

export type BoxProps = {
  display?: string;
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
  grow?: string;
  shrink?: string;
  basis?: string;
  flex?: string;
  alignItems?: string;
  justifyContent?: string;
  justifySelf?: string;
  alignSelf?: string;
  justifyItems?: string;
  alignContent?: string;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  overflow?: OverflowType;
  overflowX?: OverflowType;
  overflowY?: OverflowType;
  border?: string;
  borderTop?: string;
  borderLeft?: string;
  borderBottom?: string;
  borderRight?: string;
  borderRadius?: string;
  boxShadow?: string;
};

export const Box = styled.div<BoxProps>`
  display: ${prop("display", "flex")};
  flex-direction: ${prop("direction")};
  flex-wrap: ${prop("wrap")};
  flex-grow: ${prop("grow")};
  flex-shrink: ${prop("shrink")};
  flex-basis: ${prop("basis")};
  flex: ${prop("flex")};
  align-items: ${prop("alignItems")};
  justify-content: ${prop("justifyContent")};
  justify-self: ${prop("justifySelf")};
  align-self: ${prop("alignSelf")};
  justify-items: ${prop("justifyItems")};
  align-content: ${prop("alignContent")};
  margin: ${prop("margin")};
  padding: ${prop("padding")};
  width: ${prop("width", "100%")};
  height: ${prop("height", "auto")};
  max-width: ${prop("maxWidth")};
  max-height: ${prop("maxHeight")};
  min-width: ${prop("minWidth")};
  min-height: ${prop("minHeight")};
  overflow: ${prop("overflow")};
  overflow-x: ${prop("overflowX")};
  overflow-y: ${prop("overflowY")};
  border: ${prop("border")};
  box-shadow: ${prop("boxShadow")};
  border-top: ${prop("borderTop")};
  border-left: ${prop("borderLeft")};
  border-bottom: ${prop("borderBottom")};
  border-right: ${prop("borderRight")};
  border-radius: ${prop("borderRadius")};
`;
