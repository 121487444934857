import styled from "styled-components";

export const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const NoDataLabel = styled.div`
  width: 100%;
  padding: 10px 0;
  background-color: #dbdbdb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;

export const Text = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;
