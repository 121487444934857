import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  min-width: 180px;

  .react-datepicker-popper {
    width: 100%;
  }
`;

export const PopupContainer = styled.div<{ zIndex: number }>`
  position: relative;
  z-index: ${(p) => p.zIndex};
`;

export const Popup = styled.div(
  ({ theme: { datePicker, colors, spacings, borderRadiuses } }) => css`
    background: ${colors.stackBackground.modal};
    width: 100%;
    box-shadow: ${datePicker.popup.boxShadow};
    margin-top: ${spacings[3].str};
    border-radius: ${borderRadiuses[2].str};
    padding: 16px;
    position: absolute !important;

    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
    }

    .react-datepicker__day-names {
      position: relative;
      margin-bottom: 8px;

      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        height: 1px;
        background: ${colors.content.quinary};
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      flex-basis: 15%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .react-datepicker__day-name {
      text-transform: capitalize;
    }

    .react-datepicker__day-name {
      color: ${datePicker.dayName.color};
    }

    .react-datepicker__day {
      cursor: pointer;
      color: ${datePicker.day.default.color};
      background: ${datePicker.day.default.background};

      &--outside-month {
        color: ${datePicker.day.outside.color};
      }

      &--today {
        color: ${datePicker.day.today.color};
      }

      &--in-selecting-range,
      &--in-range {
        background: ${datePicker.day.hover.background};
      }

      &--selected,
      &--selecting-range-start,
      &--range-start,
      &--range-end,
      &--in-selecting-range:hover {
        background: ${datePicker.day.active.background};
        color: ${datePicker.day.active.color};
        box-shadow: none;
        outline: none;
      }

      &--selected {
        border-radius: ${borderRadiuses[1].str};
      }

      &--selecting-range-start,
      &--range-start {
        border-top-left-radius: ${borderRadiuses[1].str};
        border-bottom-left-radius: ${borderRadiuses[1].str};
      }

      &--range-end,
      &--in-selecting-range:hover {
        border-top-right-radius: ${borderRadiuses[1].str};
        border-bottom-right-radius: ${borderRadiuses[1].str};
      }

      &--disabled,
      &--disabled:hover {
        color: ${datePicker.day.disabled.color};
        text-decoration: line-through;
        cursor: default;
      }
    }
  `,
);

export const Header = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  margin: 0 -8px;
`;

const arrowCommonCSS = css(
  ({ theme: { datePicker } }) => `
  appearance: none;
  background: none;
  margin: 0;
  padding: 3px;
  border: none;
  font-family: inherit;
  width: 32px;
  height: 32px;
  cursor: pointer;
  fill: ${datePicker.arrow.default};
  stroke: ${datePicker.arrow.default};

  &:hover {
    fill: ${datePicker.arrow.hover};
    stroke: ${datePicker.arrow.hover};
  }

  &:disabled {
    fill: ${datePicker.arrow.disabled};
    stroke: ${datePicker.arrow.disabled};
    cursor: default;
  }
`,
);

export const Prev = styled.button`
  ${arrowCommonCSS};
  order: 1;
`;

export const Next = styled.button`
  ${arrowCommonCSS};
  order: 3;
`;

export const Title = styled.span<{ onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void }>`
  order: 2;
  flex-grow: 1;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

export const MonthSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    height: 1px;
    background: ${({ theme }) => theme.colors.content.quinary};
  }
`;

export const Month = styled.button(
  ({ theme: { datePicker } }) => css`
    appearance: none;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    outline: none;
    flex-basis: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    border-radius: 4px;
    text-transform: capitalize;
    cursor: pointer;
    font-family: inherit;
    color: ${datePicker.day.default.color};
    background: ${datePicker.day.default.background};

    &[aria-selected="true"] {
      color: ${datePicker.day.active.color};
      background: ${datePicker.day.active.background};
    }

    &:disabled {
      color: ${datePicker.day.disabled.color};
      background: ${datePicker.day.default.background};
      cursor: default;
      text-decoration: line-through;
    }
  `,
);
