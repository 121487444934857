import React, { useMemo } from "react";
import { InfoCard, BlockHeader } from "components/common";
import styled from "styled-components/macro";
import { UploadArea } from "components";
import { useGetDepotItemsByVersion } from "features/detail-panels/depot-details-panel/depot-details-panel.data";
import { v4 } from "uuid";
import { labels } from "shared/constants";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Header = styled.span`
  font-weight: 600;
`;

// export const GeneralInfo = () => (
//   <Container>
//     <BlockHeader left={<Header>Общие сведения информация</Header>} />
//     <InfoCards>
//       <InfoCard title="Название">Филиал Юго-Западный</InfoCard>
//       <InfoCard title="Перевозчик">ГУП «Мосгортранс» • АльфаГрант</InfoCard>
//       <InfoCard title="Адрес">Новопетровский пр., 11Б, Москва, 125130</InfoCard>
//       <InfoCard title="Типы транспорта">Автобус • Трамвай • Электробус</InfoCard>
//     </InfoCards>
//   </Container>
// );


export const GeneralInfo = () => {
  const { depotDetails } = useGetDepotItemsByVersion();

  const infoCards = useMemo(() => [
    {
      title: "Название",
      text: depotDetails?.name || labels.noValue,
    },
    {
      title: "Перевозчик",
      text: depotDetails?.contractor.shortName || labels.noValue,
    },
    {
      title: "Адрес",
      text: "Новопетровский пр., 11Б, Москва, 125130" || labels.noValue,
    },
    {
      title: "Типы транспорта",
      text: "Автобус • Трамвай • Электробус",
    },
  ], [depotDetails]);



  return (<Container>
    <BlockHeader left={<Header>Общие сведения информация</Header>} />
    <InfoCards>
      {infoCards.map((card: { title: string; text: string }) => (
        <InfoCard key={v4()} title={card.title}>
          {card.text}
        </InfoCard>
      ))}
    </InfoCards>
  </Container>
  );
};

