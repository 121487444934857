import { RouteActionType, RoutesAction } from "store/actions/route-actions/route-actions.types";
import { findIndexInArrayOfItemArray, updateItemInArrayByIndex } from "shared/utils/array";
import { IInitialRouteState } from "./route.types";
import { initialState } from "./route.constants";

export const routeReducer = (state: IInitialRouteState = initialState, action: RoutesAction) => {
  switch (action.type) {

    case RouteActionType.ADD_ROUTES: {
      const { payload } = action;
      const prevData = state[payload.status]?.items ?? [];
      prevData[payload.data.pageIndex || 0] = payload.data.items;

      return { ...state, [payload.status]: { ...payload.data, items: prevData } };
    }

    case RouteActionType.SET_ROUTE_ACTIVE_FILTER: {
      const { payload } = action;

      return { ...state, routeActiveFilter: payload };
    }

    case RouteActionType.SET_FILTERS: {
      const { payload } = action;

      return { ...state, routeFilters: payload };
    }

    case RouteActionType.SET_NEW_ROUTE_FORM: {
      const { payload } = action;

      return { ...state, newRouteForm: payload };
    }

    case RouteActionType.SET_ROUTE_BY_VERSION_ID: {
      const { payload } = action;

      return {
        ...state,
        routeByVersionId: {
          ...state.routeByVersionId,
          [payload.versionId]: payload.data,
        },
      };
    }

    case RouteActionType.UPDATE_ROUTE_BY_VERSION: {
      const { payload } = action;
      const { versionId } = payload;
      const currentItemsKey = state.routeActiveFilter;
      const details = state.routeByVersionId[versionId] ?? {};
      const updatedDetails = { ...details, ...payload.data };

      const items = state[currentItemsKey]?.items ?? [];
      const [page, index] = findIndexInArrayOfItemArray(items, (item) => item.versionId === Number(versionId));
      items[page] = updateItemInArrayByIndex(items[page], index, updatedDetails);
      const updatedItems = { ...state[currentItemsKey], items };

      return {
        ...state,
        [currentItemsKey]: updatedItems,
        routeByVersionId: {
          ...state.routeByVersionId,
          [versionId]: updatedDetails,
        },
      };
    }

    case RouteActionType.DELETE_ROUTE_BY_VERSION: {
      const { payload } = action;
      const { versionId } = payload;

      const newRouteByVersionId = { ...state.routeByVersionId };
      delete newRouteByVersionId[versionId];

      return {
        ...state,
        routeByVersionId: { ...newRouteByVersionId },
      };
    }

    case RouteActionType.SET_ROUTE_SEARCH_SUBSTRING: {
      const { payload } = action;

      return { ...state, routeSearchSubstring: payload };
    }

    case RouteActionType.SET_ACTIVE_ROUTE_ID: {
      const { payload } = action;

      return { ...state, activeRouteId: payload };
    }

    case RouteActionType.SET_ROUTE_ISLOADING: {
      const { payload } = action;

      return { ...state, routeIsLoading: payload };
    }

    case RouteActionType.SET_SELECTED_ROUTE_TRIPS: {
      const { payload } = action;

      return { ...state, selectedRouteTrips: payload };
    }

    case RouteActionType.SET_NEW_ROUTE_TRIPS: {
      const { payload } = action;

      return { ...state, newRouteTrips: payload };
    }

    case RouteActionType.SET_EDIT_ROUTE_TRIPS: {
      const { payload } = action;

      return { ...state, editRouteTrips: payload };
    }

    case RouteActionType.UPDATE_ROUTES_FILES: {
      const { payload } = action;
      const { routeId, data } = payload;
      const details = Object.values(state.routeByVersionId);
      const detailsById = details.filter(stop => stop.routeId.toString() === routeId);
      const updated = detailsById.reduce(
        (accumulator, route) => ({ ...accumulator, [route.versionId!.toString()]: { ...route, files: data.files } }),
        {},
      );
     
      return {
        ...state,
        routeByVersionId: {
          ...state.routeByVersionId,
          ...updated,
        },
      };
    }

    case RouteActionType.DELETE_NEW_ROUTE_TRIP: {
      const { payload } = action;
      return { ...state, newRouteTrips: state.newRouteTrips.filter((list: { type: string; direction: string }) => JSON.stringify({ type: list.type, direction: list.direction }) !== JSON.stringify({ type: payload.type, direction: payload.direction })) };
    }

    default:
      return state;
  }
};
