// import { withNeutralColor } from "shared/utils";
// import styled from "styled-components/macro";
// import { switchProp } from "styled-tools";

// type IconWrapperProps = {
//   isActive: boolean;
// };

// export type DriverCardIconProps = {
//   isActual: boolean;
// };

// const IconWrapper = styled.span<IconWrapperProps>`
//   & > svg,
//   path:first-child {
//     fill: ${switchProp("isActive", { false: withNeutralColor("l2") })};
//   }
//   & > svg {
//     border-radius: 3px;
//   }
// `;

// export const AvatarWrapper = styled.span`
//   width: 32px;
//   height: 32px;
//   border-radius: 50%;
// `;

// export const DriverCardIcon = ({ isActual }: DriverCardIconProps) => (

//   <TestAvatar32/>
// );


import React from "react";
import { BusstIcon, TestAvatar32 } from "assets/icons";
import styled from "styled-components/macro";
import { withNeutralColor } from "shared/utils";
import { switchProp } from "styled-tools";

export type DriverCardIconProps = {
  isActual: boolean;
};

type IconWrapperProps = {
  isActive: boolean;
};

const IconWrapper = styled.span<IconWrapperProps>`
  & > svg,
  path:first-child {
    fill: ${switchProp("isActive", { false: withNeutralColor("l2") })};
  }
  & > svg {
    border-radius: 3px;
  }
`;

export const DriverCardIcon = ({ isActual }: DriverCardIconProps) => (
  <IconWrapper isActive={isActual}>
    <TestAvatar32/>
  </IconWrapper>
);

