type Action<A extends CommonActionTypes, P> = { type: A, payload: P };

export enum CommonActionTypes {
  FLY_TO_POINT = "FLY_TO_POINT",
  FILTER_STATUS = "FILTER_STATUS",
}

export type SetFlyToAction = Action<CommonActionTypes.FLY_TO_POINT, { lat: number, lng: number }>;
export type SetFilterStatusAction = Action<CommonActionTypes.FILTER_STATUS, { status: boolean }>;

export type ContractorAction =
  | SetFilterStatusAction
  | SetFlyToAction;
