import React from "react";
import { InfoCard, BlockHeader, UploadArea } from "components";
import { v4 } from "uuid";
import styled from "styled-components/macro";
import { useGetContractorInfoTabData } from "../contractor-details-info-tab.data";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Header = styled.span`
  font-weight: 600;
`;

export const GeneralInfo = () => {
  const { infoCards } = useGetContractorInfoTabData();

  return (<Container>
    <BlockHeader left={<Header>Общие сведения информация</Header>} />
    <InfoCards>
      {infoCards.map((card: { title: string; text: string }) => (
        <InfoCard key={v4()} title={card.title}>
          <div dangerouslySetInnerHTML={{ __html: card.text }}></div>
        </InfoCard>
      ))}
    </InfoCards>
  </Container>
  );
};

