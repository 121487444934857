import { Contractor } from "./contractor";

export type Depot = {
  /** Идентификатор */
  depotId: number;
  /** Идентификатор версии */
  versionId?: number;
  /** Наименование парка */
  name: string;
  /** Перевозчик */
  contractor: Contractor;
};

export type DepotRequestItem = {
  /** Массив транспорта */
  items: DepotVersion[];
  /** Номер страницы */
  pageIndex: number;
  /** Размер страниц */
  pageSize: number;
  /** Общее число */
  totalCount: number;
};

export type DepotVersion = {
  depotId: number;
  versionId: number;
  name: string;
  startDate: string | null;
  endDate: string | null;
  contractor: Contractor;
  files?: {
    id: string,
    name: string,
    sizeInBytes: number,
    mimeType: string,
  }[],
};

export const InitialDepotItem: DepotRequestItem = {
  items: [],
  pageIndex: 1,
  pageSize: 50,
  totalCount: 0,
 };
