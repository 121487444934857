import styled from "styled-components/macro";
import { Tabs } from "components/ui";
import { withContentColor } from "../../../../../../../shared/utils/styled-helpers";

interface Props {
  show: boolean
}

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 85%;
`;

export const TripCard = styled.div`
  margin: 8px 0;
  display: table;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
`;

export const TabsHeader = styled.span`
  padding: 0 8px;
  color: ${withContentColor("secondary")};
  line-height: 24px;
  font-size: 11px;
  font-weight: 600;
`;

export const DeleteBubble = styled.span<Props>`
  position: absolute;
  top: -6px;
  right: 0px;
  border-radius: 18%;
  height: 16px;
  background: #e35252;
  color: white;
  visibility: ${props => props.show ? "visible" : "hidden"};
`;

export const EditBubble = styled.span<Props>`
  position: absolute;
  top: -6px;
  right: 31px;
  border-radius: 18%;
  height: 16px;
  background: #67b0ff;
  color: white;
  visibility: ${props => props.show ? "visible" : "hidden"};
`;

export const ContainerBubble = styled.div`
  text-decoration: none;
  position: relative;
  display: inline-block;

`;

export const DirectionsBlock = styled.div`
  margin: 8px 0;
`;

export const DirectionsTabs = styled(Tabs)`
  &::after {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`;

export const CardWrapper = styled.div`
  padding: 4px 0;
  width: 100%;
  flex-grow: 1;
`;

export const TripCards = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;
`;

export const TripHeader = styled.div<{ isEdit?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => props.isEdit ? "0.5fr 2fr 1fr 1fr 1fr" : "2fr 1fr 1fr" };
  width: 100%;
`;

export const TripHeaderItem = styled.div`
  margin: 8px 12px;
`;


