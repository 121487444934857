/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from "react";
import { Card, Divider } from "components";
import { Stepper, StartIndicator, MidIndicator, EndIndicator, Step } from "components/ui/stepper";
import { Checkbox } from "megapolis-uikit-latest";
import { KeyZoneDetails } from "models";
import { CardWrapper, DirectionsBlock, StepWrapper, TripCard, TripCards, TripHeader, TripHeaderItem, Wrapper } from "../direction/trip-directions.styles";
import { CheckBoxWrapper, Container } from "./keyzone-stepper.style";



const StepContent = ({ title, caption }: { title?: string; caption?: string }) => (
  <CardWrapper>
    <Card title={title} caption={caption} />
  </CardWrapper>
);

export const StepWithCaption = ({
  indicator,
  title,
  caption,
  children,
}: {
  showCaption?: boolean;
  indicator: React.ReactNode;
  time?: string;
  title?: string;
  caption?: string;
  children?: React.ReactNode;
}) => (
  <Wrapper>
    <StepWrapper>
      <Step indicator={indicator}>{children || <StepContent title={title} caption={caption} />}</Step>
    </StepWrapper>
  </Wrapper>
);

export const KeyZoneStepper = (props: { keyZones: KeyZoneDetails[], isEdit?: boolean, onKeyZoneSelect?: (tripStops: KeyZoneDetails[]) => void, onKeyZoneClick?: (keyZone: KeyZoneDetails) => void, }) => {
  const { keyZones, isEdit = false, onKeyZoneSelect, onKeyZoneClick } = props;
  const [currentKeyZones, setCurrentKeyZones] = useState<KeyZoneDetails[]>(keyZones || []);
  const [activeIdx, setActiveIdx] = useState<number | null>(null);

  useEffect(() => {
    if (keyZones && keyZones.length > 0) {
      const arr: KeyZoneDetails[] = keyZones;
      setCurrentKeyZones(arr);
    }
    return function cleanup() {
      setCurrentKeyZones([]);
    };

  }, [keyZones]);

  const handleCheck = useCallback((elm: KeyZoneDetails, idx: number) => {
    const ar = currentKeyZones.map((e, i) => {
      if (i === idx) {
        return { ...e, active: !elm.active };
      }
      return e;
    });
    setCurrentKeyZones(ar as KeyZoneDetails[]);
    let filtredKeyZones = ar;
    filtredKeyZones = filtredKeyZones.map((zone, i) => ({ ...zone, order: i + 1 }));
    onKeyZoneSelect && onKeyZoneSelect(filtredKeyZones);
  }, [currentKeyZones, onKeyZoneSelect]);

  const indicatorType = useCallback((idx: number, last: number) => {
    switch (idx) {
      case idx = 0:
        return <StartIndicator pointColor="blue" />;
      case idx = last:
        return <EndIndicator pointColor="red" />;
      default:
        return <MidIndicator />;
    }
  }, []);

  if (keyZones && keyZones?.length === 0) return (<></>);

  return (
    <>
      <TripHeader>
        <TripHeaderItem>
          Наименование
        </TripHeaderItem>
      </TripHeader>
      <Divider></Divider>
      <Stepper>
        {currentKeyZones.length > 0 &&
          currentKeyZones.map((elm, idx) => (isEdit ?
            <Container isActive={idx === activeIdx} key={idx} onClick={() => {
              setActiveIdx(idx);
              if (onKeyZoneClick) {
                onKeyZoneClick(elm);
              }
            }}>
              <TripHeader isEdit key={idx}>
                <CheckBoxWrapper>
                  <Checkbox.Component
                    onClick={() => handleCheck(elm, idx)}
                    value={elm.active || false}
                  />
                </CheckBoxWrapper>
                <StepWithCaption
                  indicator={indicatorType(idx, keyZones?.length - 1)}
                >
                  <TripCard>{elm.name}</TripCard>
                </StepWithCaption>
              </TripHeader>
            </Container>
            : <StepWithCaption
              key={idx + 1}
              indicator={indicatorType(idx, keyZones?.length - 1)}
            >
              <TripCard>{elm.name}</TripCard>
            </StepWithCaption>

          ))
        }
      </Stepper>
    </>
  );

};




