export const driver = {
  editDriver: "Редактирование водителя",
  createDriver: "Создание водителя",
  driverName: "Имя",
  driverNumber: "Номер остановки по реестру",
  driverSurname: "Фамилия",
  driverMiddlename: "Отчество",
  birthDate: "Дата рождения",
  driverLisence: "Категория прав",
  personNumber: "Табельный номер",
  lastMedDate: "Дата последнего медосмотра",
} as const;