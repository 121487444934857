import { XClose24BlueIcon } from "assets/icons";
import { IconButton } from "components/common";
import { ContractorFilterContent, DepotFilterContent, DriverFilterContent, RouteFilterContent, StopStationFilterContent, VehicleFilterContent } from "features";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoute } from "react-router5";
import { commonActions } from "store/actions";
import { RootState } from "store/types";
import * as Markup from "./filter-component.style";

export type SelectProps = {
  isShown: boolean;
  hide: () => void;
  modalContent: JSX.Element;
  headerText: string;
};

export const Filter = () => {
  const containerRef = useRef(null);

  const {
    route: {
      name,
      params: { id },
    },
  } = useRoute();
  const { filterStatus } = useSelector((state: RootState) => ({
    filterStatus: state.common.filterStatus,
  }));
  const dispatch = useDispatch();
  const toggle = useCallback(() => {
    dispatch(commonActions.setFilterStatus(!filterStatus));
  }, [filterStatus]);

  useEffect(() => () => {
    dispatch(commonActions.setFilterStatus(false));
  }, []);

  const innerFilter = useCallback(() => {
    switch (name) {
      case "route" || "routes":
        return <RouteFilterContent hide={toggle} />;
      case "stop-station" || "stop-stations":
        return <StopStationFilterContent hide={toggle} />;
      case "vehicle" || "vehicles":
        return <VehicleFilterContent hide={toggle} />;
      case "driver" || "drivers":
        return <DriverFilterContent hide={toggle} />;
      case "depot" || "depots":
        return <DepotFilterContent hide={toggle} />;
      case "contractor" || "contractors":
        return <ContractorFilterContent hide={toggle} />;
      default:
        return <></>;
    }
  }, [name]);


  return (
    <Markup.Container ref={containerRef}>
      {/* <Markup.StyledModal> */}
      <Markup.Header>
        <Markup.Heading>{"Фильтр"}</Markup.Heading>
        <Markup.ButtonsList>
          <IconButton content="icon" icon={<XClose24BlueIcon />} onClick={toggle} />
        </Markup.ButtonsList>
      </Markup.Header>
      <Markup.Content>{innerFilter()}</Markup.Content>
      {/* </Markup.StyledModal> */}
    </Markup.Container>
  );
};

export const FilterComponent = React.memo(Filter);

