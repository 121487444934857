import { TabValues } from "../../../features";
import { IInitialRouteState } from "./route.types";

export const initialState: IInitialRouteState = {
  routeByRouteId: {},
  routeByVersionId: {},
  routeActiveFilter: TabValues.ALL,
  currentRoute: {},
  newRouteForm: undefined,
  newRouteCoords: undefined,
  newRouteData: undefined,
  [TabValues.ALL]: null,
  [TabValues.PLANNED]: null,
  [TabValues.ACTIVE]: null,
  [TabValues.ARCHIVED]: null,
  routeSearchSubstring: "",
  activeRouteId: "",
  selectedRouteTrips: [],
  routeFilters: {
    ContractorIds: "",
    RouteKindIds: "",
    TransportTypes: "",
    CapacityTypes: undefined,
  },
  newRouteTrips: [],
  editRouteTrips: [],
  routeIsLoading: false,
};
