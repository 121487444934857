import React from "react";
import * as Markup from "./info-card.styles";

export type InfoCardProps = {
  /** Основной контент карточки */
  children: React.ReactNode;
  /** Иконка слева от основного контента */
  leftIcon?: React.ReactNode;
  /** Заголовок */
  title?: React.ReactNode;
};

export const InfoCard = (props: InfoCardProps) => {
  const { title, leftIcon, children } = props;
  return (
    <Markup.Container>
      {title && <Markup.Title>{title}</Markup.Title>}
      <Markup.ContentWrapper>
        {leftIcon && <Markup.LeftIcon>{leftIcon}</Markup.LeftIcon>}
        <Markup.Content>{children}</Markup.Content>
      </Markup.ContentWrapper>
    </Markup.Container>
  );
};
