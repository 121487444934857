import React, { memo } from "react";
import * as Markup from "./stack-header.styles";

export type StackHeaderProps = {
  /** Основной контент заголовка */
  children: React.ReactNode;
  /** Контент до основного контента */
  before?: React.ReactNode;
  /** Контент слева от основного контента */
  left?: React.ReactNode;
  /** Контент справа от основного контента */
  right?: React.ReactNode;
};

const StackHeaderComponent = (props: StackHeaderProps) => {
  const { children, before, left, right } = props;

  return (
    <Markup.Container>
      {before && <Markup.Before>{before}</Markup.Before>}
      <Markup.ContentWrapper>
        {left && <Markup.Left>{left}</Markup.Left>}
        <Markup.Content>{children}</Markup.Content>
        {right && <Markup.Right>{right}</Markup.Right>}
      </Markup.ContentWrapper>
    </Markup.Container>
  );
};

export const StackHeader = memo(StackHeaderComponent);
