import React from "react";
import ReactDatePicker from "react-datepicker";
import { ru } from "date-fns/locale";
import { MaskedInput } from "@megapolis/uikit";
import { ReactComponent as Calendar24Icon } from "./calendar-24.svg";
import { useDatePickerProps } from "./date-picker.hooks";
import * as Markup from "./date-picker.styles";
import { dateMask } from "./date-picker.consts";
import type { DatePickerProps } from "./date-picker.types";

const DatePickerComponent = (props: DatePickerProps) => {
  const {
    value,
    onChange,
    /* cspell:disable-next-line */
    placeholder = "ДД/ММ/ГГГГ",
    minDate,
    maxDate,
    excludeDates,
    hasError,
    isIconHidden,
    zIndex,
    customInput,
    isOpen,
    ...inputProps
  } = props;

  const datePickerProps = useDatePickerProps(props);

  return (
    <Markup.Wrapper>
      <ReactDatePicker
        disabledKeyboardNavigation
        dateFormat="dd/MM/yyyy"
        locale={ru}
        selected={value}
        placeholderText={placeholder}
        disabled={inputProps.isDisabled}
        minDate={minDate}
        maxDate={maxDate}
        open={isOpen}
        excludeDates={excludeDates}
        ariaInvalid={hasError ? "true" : undefined}
        onChange={onChange}
        customInput={
          customInput ?? (
            <MaskedInput
              hasError={hasError}
              after={!isIconHidden && <Calendar24Icon />}
              mask={dateMask}
              {...inputProps}
            />
          )
        }
        {...datePickerProps}
      />
    </Markup.Wrapper>
  );
};

export const DatePicker = React.memo(DatePickerComponent);
