export const route = {
    routeName: "Название маршрута",
    routes: "Маршруты",
    routeNumber: "Номер маршрута",
    trabsportType: "Тип трансторта",
    routeType: "Тип маршрута",
    attributes: "Атрибуты трассы",
    geometry: "Геометрия трассы",
    editRoute: "Редактирование маршрута",
    createRoute: "Создание маршрута",
    copyRoute: "Копирование маршрута",
    depot: "",
    trips: "Трассы",
    startDateRoute: "Дата начала действия маршрута",
    endDateRoute: "Дата окончания действия маршрута",
} as const;