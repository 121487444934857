import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContractorCard, TabValues } from "features";
import { RootState } from "store/types";
import { PaginationCardList } from "../components/pagination-card-list";
import { useContractorActiveFilter, useContractorItemsSelector, useContractorsFiltersSelector } from "../../../store/selectors/contractor-selectors";
import { ContractorResponse, ContractorsVersionResponse, getContractorRequest } from "../../../api";
import { contractorActions } from "../../../store/actions";

export type ContractorCardListProps = {
  activeId?: ID;
  onClick?: (id: string) => void;
};

const DEFAULT_PAGE_SIZE = 50;

const ContractorCardListComponent = (props: ContractorCardListProps) => {
  const { activeId, onClick } = props;
  const dispatch = useDispatch();
  const { ContractorTypeIds, VehicleTransportTypes, VehicleCapacityTypes } = useContractorsFiltersSelector();
  const activeFilter = useContractorActiveFilter();
  const { items, totalCount } = useContractorItemsSelector(activeFilter);
  const [pageNum] = useState(1);
  const { searchSubString } = useSelector((state: RootState) => ({
    searchSubString: state.contractor.contractorSearchString,
  }));
  const firstItem = items[0];
  const activeIndex = useMemo(() => items.findIndex((item) => String(item.versionId) === String(activeId)), [items, activeId]);


  useEffect(() => {
    if (!activeId && firstItem?.versionId && onClick) {
      onClick(String(firstItem.versionId));
    }
    if (activeId && onClick) {
      onClick(String(activeId));
    }
  }, [activeId, firstItem?.versionId, onClick]);

  const handleClick = useCallback((id: ID) => {
    if (onClick) {
      onClick(String(id));
      // showDetails();
    }
  }, [onClick]);

  const options = useMemo(() => ({
    isActive: activeFilter === TabValues.ACTIVE,
  }), [activeFilter]);

  const params = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
    SearchSubstring: searchSubString || undefined,
    ContractorTypeIds,
    VehicleTransportTypes,
    VehicleCapacityTypes,
  }), [ContractorTypeIds, VehicleCapacityTypes, VehicleTransportTypes, pageNum, searchSubString]);

  const setItems = useCallback((response: ContractorsVersionResponse) => {
    dispatch(contractorActions.setContractors(activeFilter, response));
  }, [activeFilter, dispatch]);

  const itemRenderer = useCallback((item:  ContractorResponse) => {
    const { shortName, versionId } = item;
    return (
      <ContractorCard
        id={versionId}
        shortName={shortName}
        isActive={String(activeId) === String(versionId)}
        onClick={handleClick}
      />
    );
  }, [activeId, handleClick]);

  return (
    <PaginationCardList
      items={items}
      params={params}
      options={options}
      itemRender={itemRenderer}
      fetcher={getContractorRequest}
      totalCount={totalCount}
      setItems={setItems}
      scrollTo={activeIndex}
    />
  );
};

// <CardList.Item key={item.id}>
//   <ContractorCard {...item} isActive={activeId === item.id.toString()} onClick={onClick} />
// </CardList.Item>

export const ContractorPaginationCardList = memo(ContractorCardListComponent);
