import { Depot } from "./depot";

type WithId<T extends Record<string, unknown>> = { id: string | number } & T;

export enum VehicleTypes {
  Bus = "Bus",
  Trolleybus = "Trolleybus",
  Electrobus = "Electrobus",
  Tram = "Tram",
}

export enum VehicleClasses {
  ОМК = "ОМК",
  МК = "МК",
  СК = "СК",
  БК = "БК",
  ОБК = "ОБК",
}

/** Данные о ТС */
export type VehicleData = {
  /** Наименование ТС */
  transportType: string;
  /** Тип маршрута */
  routeType: string;
  /** Перевозчик */
  contractorName: string;
};

export type Vehicle = {
  /** Идентификатор */
  id: string;
  /** Государственный регистрационный номер */
  registrationNumber: string;
  /** Перевозчик */
  contractor: string;
  /** Тип ТС */
  type: VehicleTypes;
  /** Признак актуальности ТС */
  isActual: boolean;
};

export type VehicleRequestItem = {
  /** Массив транспорта */
  items: VehicleDetails[];
  /** Номер страницы */
  pageIndex: number;
  /** Размер страниц */
  pageSize: number;
  /** Общее число */
  totalCount: number;
};

export type VehicleDetails = {
  id: string;
  versionId: number;
  /** id оборудования */
  tmId: number;
  plate: string;
  transportType: string;
  commissioningDate?: string;
  manufactureDate?: string;
  brand: string;
  model: string;
  contractor: WithId<{ name: string }>;
  startDate: string | null;
  endDate?: string | null;
  diagnosticCard?: WithId<{ activeFrom: string }>;
  capacityType?: string;
  depot?: Depot;
  vin?: string;
  files?: {
    id: string,
    name: string,
    sizeInBytes: number,
    mimeType: string,
  }[],
  features?: {
    lowFloor: boolean,
    climateSystem: boolean,
  }
};
