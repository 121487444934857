import createRouter, { Options } from "router5";
import browserPlugin from "router5-plugin-browser";
import listenersPlugin from "router5-plugin-listeners";
import { PAGE_ROUTES } from "./shared/definitions";

const localRoutes = [
  {
    name: PAGE_ROUTES.MAIN,
    path: "/",
  },
  {
    name: PAGE_ROUTES.ROUTES,
    path: "/routes",
  },
  {
    name: PAGE_ROUTES.ROUTE,
    path: "/route/:id",
  },
  {
    name: PAGE_ROUTES.STOP_STATION,
    path: "/stop-station/:id",
  },
  {
    name: PAGE_ROUTES.STOP_STATIONS,
    path: "/stop-stations",
  },
  {
    name: PAGE_ROUTES.VEHICLE,
    path: "/vehicle/:id",
  },
  {
    name: PAGE_ROUTES.VEHICLES,
    path: "/vehicles",
  },
  {
    name: PAGE_ROUTES.CONTRACTORS,
    path: "/contractors",
  },
  {
    name: PAGE_ROUTES.CONTRACTOR,
    path: "/contractors/:id",
  },
  {
    name: PAGE_ROUTES.DEPOTS,
    path: "/depots",
  },
  {
    name: PAGE_ROUTES.DEPOT,
    path: "/depot/:id",
  },
  {
    name: PAGE_ROUTES.DRIVER,
    path: "/drivers/:id",
  },
  {
    name: PAGE_ROUTES.DRIVERS,
    path: "/drivers",
  },
  {
    name: PAGE_ROUTES.KEY_ZONE,
    path: "/key-zones/:id",
  },
  {
    name: PAGE_ROUTES.KEY_ZONES,
    path: "/key-zones",
  },
  {
    name: PAGE_ROUTES.SCHEDULE,
    path: "/schedules/:id",
  },
  {
    name: PAGE_ROUTES.SCHEDULES,
    path: "/schedules",
  },
  {
    name: PAGE_ROUTES.UI,
    path: "/ui",
  },
  {
    name: PAGE_ROUTES.NOT_FOUND,
    path: `/${PAGE_ROUTES.NOT_FOUND}`,
  },
];

export const routes = localRoutes;

const params: Options | any = {
  defaultRoute: "NOT_FOUND",
  defaultParams: {},
  queryParamsMode: "loose",
  strictTrailingSlash: true,
};

const router = createRouter(routes, params);

router.usePlugin(
  browserPlugin({
    base: "",
    useHash: false,
    hashPrefix: "",
    mergeState: true,
    preserveHash: false,
    forceDeactivate: true,
  }),
);

router.usePlugin(listenersPlugin());

router.start();

export default router;
