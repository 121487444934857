import { Input as UIInput } from "@megapolis/uikit";

// export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
//   hasError?: boolean;
// };
//
// const InputComponent = (props: InputProps, ref?: ForwardedRef<HTMLInputElement>) => {
//   const { hasError = false, ...restProps } = props;
//   return <Markup.Input ref={ref} hasError={hasError} {...restProps} />;
// };
//
// const InputWithRef = forwardRef(InputComponent);

export const Input = UIInput;
