import { SourceAction, SourceActionType } from "../../actions";
import { SourceState } from "./source.types";
import { initialState } from "./source.constants";

export const sourceReducer = (state: SourceState = initialState, action: SourceAction ) => {
  switch (action.type) {
    case SourceActionType.setSource: {
      const { id, options } = action.payload;
      return { ...state, [id]: options };
    }
    default: {
      return state;
    }
  }
};
