import { SearchField, SearchFieldChangeHandlerParams } from "@megapolis/uikit";
import { Priority, Kind } from "@megapolis/uikit/button/button.types";
import { getDepotRequest, GetDepotRequestOptions } from "api";
import { Loader, Tab, TestButton } from "components";
import { DepotCard } from "features/cards";
import { DetailsPanel, DetailsPanelTabs } from "features/detail-panels/components";
import { Content } from "megapolis-uikit-latest/button/button.types";
import { DepotVersion } from "models";
import React, { useState, useMemo, useEffect, useCallback, memo, useRef } from "react";
import { AutoSizer, List, ListRowRenderer } from "react-virtualized";
import { RenderedRows } from "react-virtualized/dist/es/List";
import { Footer } from "./depot-filter-element.style";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_GAP = 8;
const DEFAULT_CARD_HEIGHT = 46;
const maxListheight = DEFAULT_PAGE_SIZE * DEFAULT_CARD_HEIGHT;

export type DepotCardListPaginationProps = {
  activeId?: string;
  onClick?: (id: string) => void;
  onSubmit?: (arr: DepotVersion[]) => void;
  filterItems?: DepotVersion[]
};

const SearchDepotContentComponent = (props: DepotCardListPaginationProps) => {
  const { activeId, onSubmit, filterItems = [] } = props;
  const [items, setItems] = useState<DepotVersion[]>([]);
  const [selected, setSelected] = useState<DepotVersion[]>(filterItems);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [activeTab, setActiveTab] = useState<"ALL" | "SELECTED">("ALL");
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const timer: { current: NodeJS.Timeout | null } = useRef(null);

  const options: GetDepotRequestOptions = useMemo(() => ({
    pageNum: currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    isActive: true,
    SearchSubstring: searchField || undefined,
  }), [searchField, currentPage]);

  const fetchReposByPage = useCallback(async (newPage: number) => {
    setLoading(true);
    try {
      const response = await getDepotRequest({ ...options, pageNum: newPage });
      setItems((arr: any) => arr.concat(response.items));
      setCurrentPage(newPage + 1);
      setTotalCount(response?.totalCount || 1);
      setHasNext((response.pageIndex || 1) * DEFAULT_PAGE_SIZE < response.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [options]);

  useEffect(() => {
    setItems([]);
    fetchReposByPage(1);
  }, []);

  const fetchReposBySearchString = useCallback(async (search: string) => {
    setLoading(true);
    try {
      const response = await getDepotRequest({ ...options, SearchSubstring: search, pageNum: 1 });
      setItems((arr: any) => arr.concat(response.items));
      setCurrentPage(1);
      setTotalCount(response?.totalCount || 1);
      setHasNext((response.pageIndex || 1) * DEFAULT_PAGE_SIZE < response.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const handleClickItem = useCallback((item: DepotVersion) => {
    setSelected((arr) => {
      const indx = arr.findIndex((value) => value.depotId === item.depotId);
      if (indx === -1) {
        return arr.concat(item);
      }
      return arr;
    });


  }, [selected]);

  const handleChange = useCallback(
    ({ value }: SearchFieldChangeHandlerParams) => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
      setSearchField(value);
      timer.current = setTimeout(() => {
        if (value !== "") {
          setItems([]);
          fetchReposBySearchString(value);
        } else {
          setItems([]);
          fetchReposByPage(1);
        }

        timer.current = null;
      }, 700);

    },
    [selected],
  );

  const handleScroll = useCallback((rows: RenderedRows) => {
    const { overscanStopIndex } = rows;
    if (overscanStopIndex >= items.length - 1 && !loading && hasNext) {
      fetchReposByPage(currentPage);
    }
  }, [items.length, hasNext, loading, currentPage]);

  const itemRender = useCallback((item: DepotVersion & { depotId: number }) => {
    const { versionId, name, depotId, ...rest } = item;
    console.log(versionId);
    return (
      <DepotCard
        {...rest}
        name={name}
        key={versionId}
        id={versionId}
        isActive={String(activeId) === String(versionId)}
        onClick={() => handleClickItem(item)}
      />
    );
  }, [handleClickItem, activeId]);

  const rowRenderer: ListRowRenderer = useCallback(({ index, style, key }) => {
    const item = items[index];
    let content;
    const updatedStyles = {
      ...style,
      height: (Number(style.height)) - DEFAULT_GAP,
      top: ((Number(style.top) || 0) + DEFAULT_GAP / 2),
    };
    if (!hasNext || index < items.length - 1) {
      if (item) {
        content = itemRender(item);
      }
    } else {
      content = <div className="spinner-container"><Loader /></div>;
    }

    return <div style={updatedStyles} key={key} >
      {content}
    </div>;
  }, [items, hasNext, itemRender]);


  const rowRendererSelected: ListRowRenderer = useCallback(({ index, style, key }) => {
    const item = selected[index];
    let content;
    const updatedStyles = {
      ...style,
      height: (Number(style.height)) - DEFAULT_GAP,
      top: ((Number(style.top) || 0) + DEFAULT_GAP / 2),
    };
    if (!hasNext || index < items.length - 1) {
      if (item) {
        content = itemRender(item);
      }
    } else {
      content = <div className="spinner-container"><Loader /></div>;
    }

    return <div style={updatedStyles} key={key} >
      {content}
    </div>;
  }, [selected, hasNext, itemRender]);


  return (
    <>
      <DetailsPanel
        headerSlot={<SearchField value={searchField} onChange={handleChange} onClear={() => handleChange({ value: "" } as SearchFieldChangeHandlerParams)} />}
        tabsSlot={<DetailsPanelTabs value={activeTab} onChange={(filter: string) => setActiveTab(filter as "ALL" | "SELECTED")}>
          <Tab value={"ALL"}>Все</Tab>
          <Tab value={"SELECTED"}>Выбранные</Tab>
        </DetailsPanelTabs>}>
        {activeTab === "ALL" && <div style={{ width: "100%", height: maxListheight }}>
          <AutoSizer>
            {({ width }) => (
              <List
                height={maxListheight}
                onRowsRendered={handleScroll}
                ref={undefined}
                rowCount={totalCount}
                rowHeight={DEFAULT_CARD_HEIGHT + DEFAULT_GAP}
                rowRenderer={rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        </div>}
        {activeTab === "SELECTED" && <div style={{ width: "100%", height: maxListheight }}>
          <AutoSizer>
            {({ width }) => (
              <List
                height={maxListheight}
                ref={undefined}
                rowCount={selected.length}
                rowHeight={DEFAULT_CARD_HEIGHT + DEFAULT_GAP}
                rowRenderer={rowRendererSelected}
                width={width}
              />
            )}
          </AutoSizer>
        </div>}
      </DetailsPanel>
      <Footer>
        <TestButton content={Content.Text} priority={Priority.Tertiary} text="Готово" onClick={() => {
          if (onSubmit) {
            onSubmit(selected);
            setSelected([]);
          }
        }} cyData="Filter" />
        <TestButton kind={Kind.Danger} content={Content.Text} priority={Priority.Tertiary} text="Сброс" onClick={() => setSelected([])} cyData="Filter" />
      </Footer>
    </>


  );
};

export const SearchDepotContent = memo(SearchDepotContentComponent);
