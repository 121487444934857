/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { reduxPlugin, router5Middleware } from "redux-router5";
import router from "../router";
import reducers from "./reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
// eslint-disable-next-line import/no-mutable-exports
let store: any;
// eslint-disable-next-line react-hooks/rules-of-hooks
export const getStore = (): void => {

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const enhancer = composeEnhancers(
    applyMiddleware(thunk, router5Middleware(router)),
  );
  store = createStore(reducers, enhancer);


  // store = createStore(reducers, applyMiddleware(thunk, router5Middleware(router)));
  router.usePlugin(reduxPlugin(store.dispatch));
};

getStore();

export default store;
