import React, { memo } from "react";
import styled from "styled-components/macro";
import { ReactComponent as Search24IconSvg } from "./search-24.svg";

type IconProps = {
  color?: string;
};

const Icon = styled(Search24IconSvg)<IconProps>`
  & > path {
    stroke: ${(props) => props.color};
    fill: transparent;
  }
`;

const Search24IconComponent = ({ color }: IconProps) => <Icon color={color} />;

export const Search24Icon = memo(Search24IconComponent);
