// import { Button as UIButton } from "@megapolis/uikit";

// export const Button = UIButton;


import React, { InputHTMLAttributes, memo } from "react";
import { Button as TextButton, ButtonProps } from "@megapolis/uikit";

export type TextButtonProps = InputHTMLAttributes<HTMLButtonElement> & ButtonProps;

const ButtonComponent = (props: TextButtonProps) =>{
  const { ...buttonProps } = props;

  return (
    <TextButton {...buttonProps} ></TextButton>
  );

} ;
export const Button = memo(ButtonComponent);