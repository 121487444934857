import React, { ForwardedRef, forwardRef, memo } from "react";
import styled from "styled-components/macro";
import { MaskedInput, MaskedInputProps } from "../masked-input";
import { TextFieldBase, TextFieldBaseProps } from "./text-field-base";
import { loadingCss } from "./common.styles";

export type MaskedTextFieldProps = MaskedInputProps & Omit<TextFieldBaseProps, "children">;

const WrappedInput = styled(MaskedInput)<{ isLoading: boolean }>`
  ${({ isLoading }) => isLoading && loadingCss}
`;

const MaskedTextFieldComponent = (props: MaskedTextFieldProps, ref?: ForwardedRef<HTMLInputElement>) => {
  const {
    hasError,
    disabled,
    className,
    caption,
    errorText,
    label,
    isLoading = false,
    isRequired,
    ...inputProps
  } = props;

  return (
    <TextFieldBase
      className={className}
      errorText={errorText}
      caption={caption}
      label={label}
      hasError={hasError}
      isLoading={isLoading}
      isRequired={isRequired}>
      <WrappedInput
        ref={ref}
        hasError={hasError}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        {...inputProps}
      />
    </TextFieldBase>
  );
};

const MaskedTextFieldWithRef = forwardRef(MaskedTextFieldComponent);

export const MaskedTextField = memo(MaskedTextFieldWithRef);
