/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getColorPointLine } from "../layers/utils/scheme";

type IPaint = {
  id: string;
  lineName: string;
  color: string;
  isOpenPopupColor: boolean;
  coordinates: [number, number][];
  isEdited: boolean;
  isGraphRoute: boolean;
  isLoading: boolean;
  isError: boolean;
  isVisible: boolean;
  isSelected: boolean;
};

const initialStatePaint: IPaint[] = [
  {
    id: "line_0",
    lineName: "Линия 1",
    color: getColorPointLine(1),
    isVisible: true,
    isOpenPopupColor: false,
    isSelected: true,
    isGraphRoute: false,
    coordinates: [],
    isEdited: true,
    isLoading: false,
    isError: false,
  },
];

export const PaintBuildingSlice = createSlice({
  name: "RouteBuilding",
  initialState: initialStatePaint,
  reducers: {
    addLine(state: any, { payload }: PayloadAction) {
      const data = { ...initialStatePaint[0] };
      data.id = `line_${state.length}`;
      data.lineName = `Линия ${state.length + 1}`;
      data.color = getColorPointLine(state.length + 1);

      return [data, ...state.map((el: any) => ({ ...el, isSelected: false, isEdited: false }))];
    },

    toggleSelectedColor(state: any, { payload }: PayloadAction<{ id: string; color: string }>) {
      state.forEach((list: { id: any; isOpenPopupColor: boolean; color: any; }) => {
        if (list.id === payload.id) {
          list.isOpenPopupColor = false;
          list.color = payload.color;
        }
      });
    },

    setRoute: (state: any, { payload }: PayloadAction<any>) => {
      state.routes = [...state.routes, payload];
    },

    isOpenPopupColor(state: any, { payload }: PayloadAction<string>) {
      state.forEach((list: { id: any; isOpenPopupColor: boolean; }) => {
        if (list.id === payload) list.isOpenPopupColor = list.id === payload;
      });
    },

    renameLineName(state: any, { payload }: PayloadAction<{ id: string; name: string }>) {
      state.forEach((list: { id: any; lineName: any; }) => {
        if (list.id === payload.id) {
          list.lineName = payload.name;
        }
      });
    },

    toggleIsGraphRoute(state: any, { payload }: PayloadAction<string>) {
      state.forEach((list: { id: any; isGraphRoute: boolean; }) => {
        if (list.id === payload) {
          list.isGraphRoute = true;
        }
      });
    },

    removeLine(state: any, { payload }: PayloadAction<string>) {
      return state.filter((list: { id: any; }) => list.id !== payload);
    },

    setIsLoading(state: any, { payload }: PayloadAction<{ id: string; type: boolean }>) {
      state.forEach((list: { id: any; isLoading: any; }) => {
        if (list.id === payload.id) {
          list.isLoading = payload.type;
        }
      });
    },
    errorShow(state: any, { payload }: PayloadAction<string>) {
      state.forEach((list: { id: any; isError: boolean; }) => {
        if (list.id === payload) {
          list.isError = true;
        }
      });
    },
    toggleVisible(state: any, { payload }: PayloadAction<string>) {
      state.forEach((list: { id: any; isVisible: boolean; }) => {
        if (list.id === payload) {
          list.isVisible = !list.isVisible;
        }
      });
    },
    toggleSelectedList(state: any, { payload }: PayloadAction<string>) {
      state.map((list: { isSelected: boolean; id: any; }) => (list.isSelected = list.id === payload));
    },

    removeAll(state: any, { payload }: PayloadAction) {
      return [];
    },
    changeCoordinates(state: any, { payload }: PayloadAction<[number, number]>) {
      state.forEach((list: { isSelected: any; isEdited: any; coordinates: any[]; }) => {
        if (list.isSelected && list.isEdited) {
          list.coordinates = [...list.coordinates, payload];
        }
      });
    },
    finishDrawIsEdited(state: any, { payload }: PayloadAction<string>) {
      state.forEach((list: { id: any; isEdited: boolean; }) => {
        if (list.id === payload) {
          list.isEdited = false;
        }
      });
    },
    continueDrawIsEdited(state: any, { payload }: PayloadAction<string>) {
      state.forEach((list: { id: any; isEdited: boolean; }) => {
        if (list.id === payload) {
          list.isEdited = true;
        }
      });
    },
  },
});

const initialState: { activeSidebar: SidebarEnum | undefined; } = {
  activeSidebar: undefined,
};

export enum SidebarEnum {
  PaintBuilding,
  RouteBuilding,
  LocationInformation,
  BaseMap,
  Layers,
}

export const mapControlsSlice = createSlice({
  name: "mapControls",
  initialState,
  reducers: {
    setActiveSidebar(state, { payload }: PayloadAction<SidebarEnum | undefined>) {
      state.activeSidebar = payload;
    },
  },
});
