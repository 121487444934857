import { KeyZoneVersionDetails, KeyZoneVersionResponse } from "api";
import { KeyZoneFilters } from "store/reducers/key-zone";

type Action<A extends KeyZoneActionType, P> = { type: A, payload: P };

export enum KeyZoneActionType {
  SET_KEY_ZONES = "SET_KEY_ZONES",
  SET_KEY_ZONE_ACTIVE_FILTER = "SET_KEY_ZONE_ACTIVE_FILTER",
  SET_ACTIVE_KEY_ZONE_ID = "SET_ACTIVE_KEY_ZONE_ID",
  SET_KEY_ZONE_BY_VERSION_ID = "SET_KEY_ZONE_BY_VERSION_ID",
  SET_KEY_ZONE_SEARCH_SUBSTRING = "SET_KEY_ZONE_SEARCH_SUBSTRING",
  SET_KEY_ZONE_ACTIVE_ITEM = "SET_KEY_ZONE_ACTIVE_ITEM",
  SET_KEY_ZONE_MAP_ACTIVE_ITEM = "SET_KEY_ZONE_MAP_ACTIVE_ITEM",
  SET_KEY_ZONE_FILTERS = "SET_KEY_ZONE_FILTERS",
  UPDATE_KEY_ZONE_BY_VERSION = "UPDATE_KEY_ZONE_BY_VERSION",
}

export type SetKeyZonesAction = Action<
KeyZoneActionType.SET_KEY_ZONES,
{ status: KeyZoneFilters; data: KeyZoneVersionResponse; pageNum: number }
>;
export type SetKeyZoneFilters = Action<KeyZoneActionType.SET_KEY_ZONE_FILTERS, {
  ContractorIds?: string | null,
  RouteIds?: string | null,
  DepotIds?: string | null,
}>;
export type SetKeyZoneActiveFilterAction = Action<KeyZoneActionType.SET_KEY_ZONE_ACTIVE_FILTER, KeyZoneFilters>;
export type SetActiveKeyZoneAction = Action<KeyZoneActionType.SET_ACTIVE_KEY_ZONE_ID, string>;
export type SetKeyZoneSearchSubstring = Action<KeyZoneActionType.SET_KEY_ZONE_SEARCH_SUBSTRING, string>;
export type SetKeyZoneByVersionIdAction = Action<KeyZoneActionType.SET_KEY_ZONE_BY_VERSION_ID, { versionId: ID, data: KeyZoneVersionDetails }>;
export type SetKeyZoneActiveItemAction = Action<KeyZoneActionType.SET_KEY_ZONE_ACTIVE_ITEM, string>;
export type SetKeyZoneMapActiveItemAction = Action<KeyZoneActionType.SET_KEY_ZONE_MAP_ACTIVE_ITEM, string>;
// export type UpdateKeyZoneByVersionIdAction = Action<KeyZoneActionType.UPDATE_KEY_ZONE_BY_VERSION, { versionId: string, data: Partial<KeyZoneDetails> }>;

export type KeyZonesAction =
  | SetKeyZoneActiveFilterAction
  | SetKeyZoneActiveItemAction
  | SetKeyZoneFilters
  | SetKeyZoneSearchSubstring
  | SetActiveKeyZoneAction
  | SetKeyZoneMapActiveItemAction
  | SetKeyZoneByVersionIdAction
  | SetKeyZonesAction;
