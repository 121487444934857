import React from "react";
import styled from "styled-components/macro";
import { Divider } from "components/common";
import { DriverTabListPagination } from "features/detail-tabs";
import { Drivers } from "./sections";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
`;

export const DepotDetailsDriversTab = (props: { VehicleIds?: string, DepotIds?: string, DriverIds?: string, ContractorIds?: string, RouteIds?: string }) => {
  const { DepotIds = "", VehicleIds = "", DriverIds = "", ContractorIds = "", RouteIds = "" } = props;
  return (
    <Container>
      <DriverTabListPagination VehicleIds={VehicleIds} DepotIds={DepotIds} DriverIds={DriverIds} ContractorIds={ContractorIds} RouteIds={RouteIds} />
      <Divider margin="8px 0" />
    </Container>);
};
