import React, { memo, useCallback } from "react";
import { Card } from "components/ui";
import { KeyZoneVersionDetails } from "api";
import * as Markup from "./keyzone-card.styles";

export type KeyZoneCardProps = {
  /** Идентификатор */
  id: number;
  /** ФИО */
  name: string;
  /** Фотография водителя */
  avatar?: React.ReactNode;
  /** Признак работоспособности(актуальности) ОП */
  isActual?: boolean;
  /** Признак активности картчоки */
  isActive?: boolean;
  /** Обработчик нажатия на карточку */
  onClick?: (id: string) => void;
};

const KeyZoneCardComponent = (props: KeyZoneCardProps) => {
  const { id, name, isActive = false, onClick, isActual = true } = props;
  const fullName = name;

  const handleClick = useCallback(() => onClick?.(id.toString()), [onClick, id]);

  return (
    <Card
      title={fullName}
      caption= ""
      icon={<Markup.KeyZoneCardIcon isActual={isActual} />}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};

export const KeyZoneCard = memo(KeyZoneCardComponent);
