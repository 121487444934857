import styled from "styled-components/macro";

export const Container = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const LeftBlock = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-self: flex-start;
  flex-grow: 1;
  margin: 8px 0;
  line-height: 16px;
`;

export const RightBlock = styled.span`
  display: flex;
  flex-flow: row nowrap;
`;
