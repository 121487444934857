import React, { useCallback, useMemo, useState } from "react";
import { Content } from "@megapolis/uikit/button/button.types";
import { BusstIcon, EditBlue32Icon } from "assets/icons";
import { useRouter } from "react-router5";
import { Button } from "components";
import { PAGE_ROUTES } from "shared/definitions/routes";
import { useRouteParams } from "shared/hooks";
import styled from "styled-components";
import { VersionViewer } from "components/ui/version-card";
import { VersionViewerContent } from "features/version-view";
import { useDispatch } from "react-redux";
import { stopActions } from "store/actions";
import { getStopStationVersionsDates } from "api";
import { getScheduleTypesCaptionMapShort } from "models/schedule";
import { formatDateToString } from "../../../../../shared/utils/date";
import { labels } from "../../../../../shared/constants";
import { useGetScheduleItemsByVersion } from "../schedule-details.data";
import { DetailsPanelHeader } from "../../../components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const ScheduleIcon = styled.div<{ active: boolean }>`
/* Marker */


width: 8px;
height: 8px;

/* Semantic-Success-Default-[Light] */

background: ${props => props.active ? "#1E7735" : "#69737B"};
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const ScheduleDetailsPanelHeader = () => {
  const { id } = useRouteParams();
  const { item } = useGetScheduleItemsByVersion();
  const router = useRouter();
  const { id: currentPathId } = useRouteParams();
  const dispatch = useDispatch();

  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => {
    setIsShown(!isShown);
  };

  const handleEditClick = useCallback(
    () => {
      // router.navigate(PAGE_ROUTES.STOP_STATION, { id: `${id}_edit` });
    },
    [router, id],
  );
  
  const listItems = useMemo(
    () => {
      const line = item?.daysOfWeek.map((el)=>getScheduleTypesCaptionMapShort(el)).join(",");
      if (item?.holiday) {
        return `${line}; праздничные дни`;
      }
      return line;
    },
    [item],
  );

  return (
    <DetailsPanelHeader
      title={item?.status === "Active" ? "Активное" : "Неактивное" || labels.noValue}
      caption={<>{listItems}</>}
      icon={<ScheduleIcon active={item?.status === "Active"} />}
      rightAction={
        <Button onClick={handleEditClick} icon={<EditBlue32Icon />} content={Content.Icon} isDisabled={true} priority="quaternary" />
      }
    />
  );
};
