import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IdNameType, SelectSourceHook } from "./select-with-source.types";
import { Option } from "../select/select.types";
import { useSourceSelector } from "../../../store/selectors";
import { setSourceAction } from "../../../store/actions";

const defaultMapper = (data: IdNameType[]): Option[] =>
  data.map(item => ({ value: item.id, label: item.name }));

export const useSourceLoader: SelectSourceHook = (sourceId, fetcher, mapper) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const options = useSourceSelector(sourceId);

  const setOptions = useCallback((value: Option[]) => {
    dispatch(setSourceAction(sourceId, value));
  }, [sourceId, dispatch]);

  useEffect(() => {
    if (!options) {
      setIsLoading(true);
      fetcher()
        .then(resp => mapper ? mapper(resp) : defaultMapper(resp as unknown as IdNameType[]))
        .then(data => setOptions(data))
        .finally(() => setIsLoading(false));
    }
  }, [options, fetcher, mapper, setOptions]);

  return { options: options || [], isLoading };
};
