import styled from "styled-components/macro";
import { Indicator } from "../indicator";

export const Container = styled(Indicator)`
  justify-content: flex-start;
`;

export const PlankWrapper = styled.div`
  display: flex;
  align-items: center;
  height: calc(50% - 8px + 0.3em);
`;
