import React, { ChangeEventHandler, useCallback, useState, useMemo, useEffect, useRef } from "react";
import { ArrowDown16BlueIcon } from "assets/icons";
import { useClickOutside } from "./select.utils";
import { Option } from "./select.types";
import { SelectOption } from "./select-option";
import * as Markup from "./select.styles";
import { TextFieldBase } from "../../ui/text-field/text-field-base";

export type SelectProps = {
  options: Option[];
  value?: Option | null;
  onChange?: (option: Option) => void;
  hasError?: boolean;
  errorText?: string;
  cyData?: string;
};

/** Временный компонент, позже будет переработан и вынесен в UIKit */
export const Select = (props: SelectProps) => {
  const { options, onChange, value, errorText, hasError = false, cyData } = props;
  const containerRef = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [inputValue, setInputValue] = useState(() => value?.label ?? "");

  const handleOpenMenu = useCallback(() => {
    setMenuVisible(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuVisible(false);
  }, []);

  useClickOutside({ ref: containerRef, callback: handleCloseMenu });

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleClick = useCallback(
    (option: Option) => {
      onChange?.(option);
      handleCloseMenu();
    },
    [onChange, handleCloseMenu],
  );

  useEffect(() => {
    setInputValue(value?.label ?? "");
  }, [value]);

  const filteredOptions = useMemo(
    () =>
      options.reduce<ReturnType<typeof SelectOption>[]>((acc, option) => {
        if (!inputValue.length || option.label.includes(inputValue) || (value && String(option.value) === String(value.value))) {
          return [
            ...acc,
            <SelectOption
              key={`${option.label}_${option.value}`}
              {...option}
              onClick={handleClick}
              isActive={value?.value === option.value}
            />,
          ];
        }
        return acc;
      }, []),
    [options, handleClick, inputValue, value],
  );

  return (
    <Markup.Container ref={containerRef}>
      <Markup.Controls>
        <TextFieldBase hasError={hasError} errorText={errorText}>
          <Markup.WrappedInput data-cypress={cyData} value={inputValue} onChange={handleInputChange} onFocus={handleOpenMenu} hasError={hasError} />
          <Markup.IconBlock>
            <ArrowDown16BlueIcon style={{ transform: menuVisible ? "rotate(180deg)" : "rotate(0deg)" }}/>
          </Markup.IconBlock>
        </TextFieldBase>
      </Markup.Controls>
      {menuVisible && (
        <Markup.SelectOptions>
          {filteredOptions.length > 0 ? filteredOptions : <SelectOption label="Нет подходящих опций" value="" />}
        </Markup.SelectOptions>
      )}
    </Markup.Container>
  );
};
