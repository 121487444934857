/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { Card, Divider, IconButton } from "components";
import { ArrowDown16BlueIcon } from "assets/icons";
import { Stepper, StartIndicator, MidIndicator, EndIndicator, Step } from "components/ui/stepper";
import { StopTime, TripStop } from "api";
import { getTripByMode } from "models/route.utils";
import { Checkbox } from "@megapolis/uikit";
import ScheduleTable from "features/table/table-container";
import { CardWrapper, DirectionsBlock, StepWrapper, TripCard, TripCards, TripHeader, TripHeaderItem, Wrapper } from "./schedule-details-info-tab.styles";


const StepContent = ({ title, caption }: { title?: string; caption?: string }) => (
  <CardWrapper>
    <Card title={title} caption={caption} />
  </CardWrapper>
);

export const StepWithCaption = ({
  indicator,
  title,
  caption,
  children,
}: {
  showCaption?: boolean;
  indicator: React.ReactNode;
  time?: string;
  title?: string;
  caption?: string;
  children?: React.ReactNode;
}) => (
  <Wrapper>
    <StepWrapper>
      <Step indicator={indicator}>{children || <StepContent title={title} caption={caption} />}</Step>
    </StepWrapper>
  </Wrapper>
);

type CustomStopTime = StopTime & { kind?: string, order?: number };

const kindSet = (arr: CustomStopTime[]): CustomStopTime[] => {
  arr.forEach((element: any, index: number, oldArr: { length: number; }) => {
    switch (index) {
      case 0:
        element.kind = "First";
        element.order = 1;
        break;
      case oldArr.length - 1:
        element.kind = "Last";
        element.order = oldArr.length;
        break;

      default:
        element.kind = "Middle";
        element.order = index + 1;
        break;
    }
  });
  return arr;

};

const indicatorType = (kind: string | undefined) => {
  switch (kind) {
    case "First":
      return <StartIndicator pointColor="blue" />;
    case "Last":
      return <EndIndicator pointColor="red" />;
    default:
      return <MidIndicator />;
  }
};

export const ScheduleStopTimesModal = (props: { tripStops: StopTime & { kind?: string, order?: number }[] }) => {
  const { tripStops } = props;
  const [currentTrips, setCurrentTrips] = useState<CustomStopTime[]>(kindSet(tripStops as any));
  // const dispatch = useDispatch()

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Остановочный пункт",
            accessor: "stopName",
            Cell: (cell: any) => (<><StepWithCaption indicator={indicatorType(cell.row.original.kind)} title={cell.value} /></>),
          },
          {
            Header: "Тип",
            accessor: "stopMode",
            Cell: (cell: any) => (<>{cell.value}</>),
          },
          {
            Header: "Прибытие",
            accessor: "arrivalTime",
            Cell: (cell: any) => (<>{cell.value}</>),
          },
          {
            Header: "Стоянка",
            accessor: "stopDuration",
            Cell: (cell: any) => (<>{cell.value}</>),
          },
          {
            Header: "Отправление",
            accessor: "departureTime",
            Cell: (cell: any) => (<>{cell.value}</>),
          },
          
        ],
      },
    ], []);

  if (tripStops.length === 0) return (<></>);

  return (
    <>
      <ScheduleTable columns={columns} data={currentTrips} ></ScheduleTable>
    </>
  );

};

