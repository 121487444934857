import React from "react";
import * as utils from "./textarea.utils";
import { Props } from "./textarea.types";

export const useGetParams = (props: Props) => {
  const { onChange, maxLength, value } = props;

  const handleChange = React.useCallback(
    (event: React.BaseSyntheticEvent<InputEvent, HTMLTextAreaElement, HTMLTextAreaElement>) => {
      event.preventDefault();

      const next = event.target.value;

      if (typeof maxLength === "number" && next.length > maxLength) {
        onChange(`${next.substring(0, maxLength)}`);
        return;
      }

      onChange(next);
    },
    [onChange, maxLength],
  );

  const content = React.useMemo(() => utils.calcLeftLetter(maxLength, value), [maxLength, value]);

  return { handleChange, content };
};
