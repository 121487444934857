import React, { memo } from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
`;

export type IndicatorProps = {
  children: React.ReactNode;
  className?: string;
};

const IndicatorComponent = (props: IndicatorProps) => {
  const { children, className } = props;
  return <Container className={className}>{children}</Container>;
};

export const Indicator = memo(IndicatorComponent);
