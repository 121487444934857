import React, { memo } from "react";
import * as Markup from "./block-header.styles";

export type BlockHeaderProps = {
  /** Левый контент заголовка */
  left: React.ReactNode;
  /** Правый контент заголовка */
  right?: React.ReactNode;
  /** Клик по заголовку */
  onClick?: React.MouseEventHandler<HTMLElement>;
};

const BlockHeaderComponent = (props: BlockHeaderProps) => {
  const { left, right, onClick } = props;
  return (
    <Markup.Container onClick={onClick}>
      <Markup.LeftBlock>{left}</Markup.LeftBlock>
      {right && <Markup.RightBlock>{right}</Markup.RightBlock>}
    </Markup.Container>
  );
};

export const BlockHeader = memo(BlockHeaderComponent);
