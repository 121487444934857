import styled, { css } from "styled-components";
import { Props } from "./textarea.types";

const commonCss = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  position: relative;
`;

export const Container = styled.span(
  ({ theme: { borderRadiuses } }) => css`
    position: relative;
    width: 100%;
    border-radius: ${borderRadiuses[2].str};
  `,
);

export const Textarea = styled.textarea<Omit<Props, "onChange">>(
  ({ theme: { textarea, borderRadiuses, typography }, ...props }) => css`
    width: 100%;
    margin: 0;
    padding: 8px;
    flex-basis: 100%;
    position: relative;
    background-color: ${textarea.default.backgroundColor};
    min-height: ${typeof props.minHeight === "number" && props.minHeight > 0 ? `${props.minHeight}px` : "unset"};
    border: 1px solid ${textarea.default.borderColor};
    border-radius: ${borderRadiuses[2].str};
    box-shadow: ${props.hasError ? `0 0 0 3px ${textarea.error.boxShadowColor}` : "none"};
    resize: ${!props.isResize && "none"};
    caret-color: ${props.hasError ? `${textarea.error.caretColor}` : `${textarea.default.caretColor}`};
    color: ${textarea.default.color};
    font-size: ${typography.fontSize.str};
    line-height: ${typography.lineHeight.str};
    outline: none;

    &::placeholder {
      color: ${textarea.default.placeholderColor};
      font-family: inherit;
      opacity: 1;
    }

    &:hover {
      cursor: text;
      background-color: ${textarea.hover.backgroundColor};
    }

    &:focus {
      border-color: ${textarea.focus.borderColor};
      box-shadow: ${!props.hasError && `0 0 0 3px ${textarea.focus.boxShadowColor}`};
    }

    &:disabled {
      cursor: default;
      color: ${textarea.disabled.color};
      user-select: none;
    }
  `,
);

export const Before = styled.span`
  ${commonCss};
`;

export const After = styled.span(
  ({ theme: { textarea, typography } }) => css`
    ${commonCss};
    padding-top: 5px;
    left: 8px;
    color: ${textarea.default.placeholderColor};
    font-family: inherit;
    font-size: ${typography.fontSize.str};
    line-height: ${typography.lineHeight.str};
    opacity: 0.9;
  `,
);
