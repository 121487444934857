import React, { memo, useCallback } from "react";
import { Card } from "components/ui";
import { SeparatedInlineList } from "components/common";
import { Driver, DriverDepot } from "api/requests/driver/types";
import { TestAvatar32 } from "assets/icons";
import * as Markup from "./driver-card.styles";

export type DriverCardProps = {
  /** Идентификатор */
  id: string;
  /** ФИО */
  fullName: string;
  /** Перевозчик */
  contractor: string;
  /** Список маршрутов водителя */
  routeList: string[];
  /** Фотография водителя */
  avatar: React.ReactNode;
  /** Признак работоспособности(актуальности) ОП */
  isActual?: boolean;
  /** Признак активности картчоки */
  isActive?: boolean;
  /** Обработчик нажатия на карточку */
  onClick?: (id: string) => void;
};

const DriverCardComponent = (props: Driver & { isActive: boolean; isActual?: boolean; onClick: any;  depot: DriverDepot; }) => {
  const { versionId, id, firstName, middleName, lastName, isActive = false, onClick, depot, isActual = true } = props;
  const avatar = <TestAvatar32 />;
  const fullName = `${lastName} ${firstName} ${middleName}`;

  const handleClick = useCallback(() => onClick?.(id), [id, onClick]);

  return (
    <Card
      title={fullName}
      caption={depot?.name || depot?.contractor ? <SeparatedInlineList items={[depot?.name, depot?.contractor ? depot?.contractor.shortName : "" ]} /> : <></>}
      icon={<Markup.DriverCardIcon isActual={isActual} />}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};

export const DriverCard = memo(DriverCardComponent);
