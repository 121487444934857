import { Dispatch } from "redux";
import {
  ContractorGetRequestOptions,
  ContractorResponse,
  ContractorsVersionResponse,
  getContractorRequest,
  getContractorVersionRequest,
} from "api";
import { labels } from "shared/constants";
import { ContractorFilters, ContractorsFilter } from "store/reducers/contractor/contractor.types";
import { TYPES } from "../types";
import {
  ContractorActionTypes,
  SetContractorByVersionIdAction,
  SetContractorFilterAction,
  SetContractorsAction, SetContractorSearchStringAction, SetFilters, SetIsContractorCardLoadingAction,
  SetSelectedContractorAction,
  UpdateContractorByVersionIdAction,
  UpdateContractorsFilesAction,
} from "./contractor-actions.types";
import { toastError } from "../../../components";


export const contractorActions = {
  getContractorRequest: (params: ContractorGetRequestOptions) => async (dispatch: Dispatch<any>) => {
    const payload = await getContractorRequest({ ...params, pageSize: 50 });
    dispatch({
      type: params.isActive ? TYPES.CONTRACTOR.SET_ACTIVE_CONTRACTOR : TYPES.CONTRACTOR.SET_INACTIVE_CONTRACTOR,
      payload,
    });
  },
  getContractorByVersionId: (versionId: string) => async (dispatch: Dispatch<SetContractorByVersionIdAction | SetIsContractorCardLoadingAction>) => {
    try {
      dispatch(contractorActions.setIsCardLoading(true));
      const payload = await getContractorVersionRequest(versionId);
      dispatch({
        type: ContractorActionTypes.SET_CONTRACTOR_BY_VERSION_ID,
        payload: {
          id: versionId,
          data: payload,
        },
      });
    } catch (error) {
      toastError(labels.mapNotLoad);
    } finally {
      dispatch(contractorActions.setIsCardLoading(false));
    }
  },

  setContractorByVersionId: (versionId: string, payload: ContractorResponse) => ({
    type: ContractorActionTypes.SET_CONTRACTOR_BY_VERSION_ID,
    payload: {
      id: versionId,
      data: payload,
    },
  }),


  updateContractorFiles: (contractorId: string, data: Partial<ContractorResponse>): UpdateContractorsFilesAction => ({
    type: ContractorActionTypes.UPDATE_CONTRACTORS_FILES,
    payload: { contractorId, data },
  }),

  setFilters: (payload: ContractorsFilter): SetFilters => ({
    type: ContractorActionTypes.SET_FILTERS,
    payload,
  }),

  setActiveFilter: (payload: ContractorFilters): SetContractorFilterAction => ({
    type: ContractorActionTypes.SET_CONTRACTOR_ACTIVE_FILTER,
    payload,
  }),

  updateContractorByVersion: (versionId: string, data: Partial<ContractorResponse>): UpdateContractorByVersionIdAction => ({
    type: ContractorActionTypes.UPDATE_CONTRACTOR_BY_VERSION,
    payload: { versionId, data },
  }),

  setContractors: (status: ContractorFilters, data: ContractorsVersionResponse): SetContractorsAction => ({
    type: ContractorActionTypes.SET_CONTRACTORS,
    payload: { status, data },
  }),

  setSelectedContractor: (payload: ID): SetSelectedContractorAction => ({
    type: ContractorActionTypes.SET_CONTRACTOR_ACTIVE_ITEM,
    payload,
  }),


  setSearchSubstring: (payload: string): SetContractorSearchStringAction => ({
    type: ContractorActionTypes.SET_CONTRACTOR_SEARCH_STRING,
    payload,
  }),

  setIsCardLoading: (payload: boolean): SetIsContractorCardLoadingAction => ({
    type: ContractorActionTypes.SET_IS_CONTRACTOR_CARD_LOADING,
    payload,
  }),
};

export default contractorActions;
