import React, { memo } from "react";
import { Plank } from "../plank";
import { Point } from "../point";
import { IndicatorPropsBase } from "../types";
import * as Markup from "./end-indicator.styles";

export type EndIndicatorProps = IndicatorPropsBase;

const EndIndicatorComponent = (props: EndIndicatorProps) => {
  const { pointColor, plankColor, className } = props;

  return (
    <Markup.Container className={className}>
      <Markup.PlankWrapper>
        <Plank color={plankColor} />
      </Markup.PlankWrapper>
      <Point color={pointColor} />
    </Markup.Container>
  );
};

export const EndIndicator = memo(EndIndicatorComponent);
