import styled from "styled-components/macro";

export const List = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;


export const ListItem = styled.li<{ dots: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-shrink: 0;
  &:not(:last-child)::after {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 4px;
    background-color: ${props => props.dots ? "currentColor" : "transparent"};
  }
`;
