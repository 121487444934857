import styled from "styled-components";
import { withNeutralColor } from "shared/utils";

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
  padding: 16px;
  button:first-child {
    width: 100%;
  }
`;

export const LabelButton = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-weight: 500;
  justify-content: space-between;
`;

export const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

export const LabelContainer = styled.span`
  margin-bottom: 8px;
`;

export const Label = styled.span`
  font-weight: 500;
  line-height: 16px;
  padding: 0 8px 4px 8px;
  width: 100%;
`;

export const Container = styled.div`
flex-grow: 1;
height: 100%;
padding: 8px 16px;
`;

export const MainBlock = styled.div`
  flex-grow: 1;
  height: 80%;
  padding: 8px 16px;
`;

export const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const NoDataLabel = styled.div`
  width: 100%;
  padding: 10px 0;
  background-color: #dbdbdb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;

export const Text = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;
