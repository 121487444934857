/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Content } from "@megapolis/uikit/button/button.types";
import { Button } from "components";
import { useRouter } from "react-router5";
import { EditBlue32Icon, TestAvatar32 } from "assets/icons";
import { PAGE_ROUTES } from "shared/definitions";
import { useRouteParams } from "shared/hooks";
import { VersionViewer } from "components/ui/version-card";
import styled from "styled-components";
import { VersionViewerContent } from "features/version-view";
import { driverActions, routeActions } from "store/actions";
import { useDispatch } from "react-redux";
import { labels } from "shared/constants";
import { DetailsPanelHeader } from "features/detail-panels/components";
import { getDriverVersionsDates } from "api/requests/driver/driver.requests";
import { useGetDriverItemsByVersion } from "../../driver-details-panel.data";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const DriverDetailsHeader = () => {
  const { id } = useRouteParams();
  const { driverDetails, versionId, fetchDriver } = useGetDriverItemsByVersion();
  const name = useMemo(()=>`${driverDetails?.lastName || "-"} ${driverDetails?.firstName || "-"} ${driverDetails?.middleName || ""}`, [driverDetails] );
  const router = useRouter();
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => {
    setIsShown(!isShown);
  };

  useEffect(() => {
    fetchDriver(versionId);
    return () => {
      setIsShown(false);
    };
  }, [versionId]);

  const handleEditClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.DRIVER, { id: `${id}_edit` });
    },
    [router, id],
  );

  const handleEditVersionClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.DRIVER, { id: `${id}_version` });
    },
    [router, id],
  );

  const handleItemClick = useCallback((versionId: any) => {
    dispatch(driverActions.setActiveDriver(versionId));
    router.navigate(PAGE_ROUTES.DRIVER, { id: versionId.toString() });
    setIsShown(!isShown);
  }, [dispatch, isShown, router]);

  const handleCreateClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.DRIVER, { id: "new" });
    },
    [router],
  );
  const versionCaption = useCallback((itm: any) => {
    let versionString = "";
    if (itm?.startDate) {
      versionString =  `Версия от ${itm?.startDate}`;
    } else if (!itm?.startDate && itm?.endDate) {
      versionString =  `Версия до ${itm?.endDate}`;
    } else {
      versionString =  "Версия бессрочная";
    }
    return versionString;
  }, []);

  return (
    <DetailsPanelHeader
      title={name || labels.noValue}
      caption={<Container onClick={toggle} ><span >{versionCaption(driverDetails)}</span><VersionViewer isShown={isShown} hide={toggle} modalContent={<VersionViewerContent currentVersion={driverDetails} id={driverDetails?.driverId} handleItemClick={handleItemClick} onEdit={handleEditVersionClick} fetchVersions={getDriverVersionsDates} />} headerText={"Версионность"} onCreateClick={handleCreateClick} /></Container>}
      icon={<TestAvatar32 />}
      rightAction={<>
        <Button content={Content.Icon} icon={<EditBlue32Icon />} onClick={handleEditClick} priority="quaternary" />
      </>

      }
    />
  );
};


