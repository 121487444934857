import mapboxgl, { Anchor, Expression } from "mapbox-gl";
import { MapLayerNames } from "../utils/consts/map.constants";


const TEXT_SIZE = 10;

const TEXT_FONT: string[] = ["MoscowSans Regular"];

const paintStops = (): mapboxgl.SymbolPaint => ({
  "icon-opacity": ["case", ["boolean", ["feature-state", "selected"], false], 0, 1],
});

const TEXT_OFFSET: Expression = [
  "interpolate",
  ["exponential", 0],
  ["zoom"],
  12,
  1.5,
  13.5,
  1.6,
  15,
  1.7,
  17,
  1.8,
  19,
  1.9,
];

const general: {
  "text-size": number;
  "text-font": string[];
  "text-radial-offset": Expression;
  "text-variable-anchor": Anchor[];
} = {
  "text-size": TEXT_SIZE,
  "text-font": TEXT_FONT,
  "text-radial-offset": TEXT_OFFSET,
  "text-variable-anchor": ["top"],
};

const layoutStops = (icon: string): mapboxgl.SymbolLayout => ({
  "icon-image": icon,
  "icon-size": 1,
  "icon-allow-overlap": true,
  "visibility": "none",
  ...{ ...general },
});

export const actualStopLayer = (sourceId: string): mapboxgl.AnyLayer  =>  ({
  id: MapLayerNames.ACTUAL_STOP_LAYER_ID,
  type: "symbol",
  source: sourceId,
  "source-layer": "stops",
  minzoom: 10,
  maxzoom: 24,
  layout: layoutStops("actualStopIcon"),
  paint: {
    "icon-opacity": ["case", ["boolean", ["feature-state", "selected"], false], 0, 1],
    "icon-opacity-transition": {
      duration: 1000,
    },
  },
});
  
export const actualStopSelectedLayer = (sourceId: string): mapboxgl.SymbolLayer => ({
  id: MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID,
  type: "symbol",
  source: sourceId,
  minzoom: 0,
  maxzoom: 24,
  layout: {
    "icon-image": "selectedStopIcon",
    "icon-allow-overlap": true,
    "icon-size": ["interpolate", ["exponential", 0], ["zoom"], 13.5, 0.7, 14.01, 1, 15, 1, 16.01, 1],
    "visibility": "none",
    "icon-anchor": "bottom",
  },
});

