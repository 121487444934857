import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider } from "components/common";
import { getRouteFile } from "api";
import { Loader, NoDataLabel, toastError, ViewFileArea } from "components";
import { DetailsBlock } from "features/detail-panels/components";
import { RouteDetails } from "models";
import { useGetRouteItemsByVersion } from "../../route-details-panel.data";
import * as Markup from "./route-details-info-tab.styles";
import { GeneralInfo } from "./sections";

export const RouteDetailsInfoTab = () => {

  const { routeDetails } = useGetRouteItemsByVersion();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);

  const fetchFiles = useCallback(async (controller: AbortController, route: RouteDetails) => {
    if (!route?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(route.files.map(async fileElem => {
        const payload = await getRouteFile(route.routeId, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);

  useEffect(() => {
    setFiles([]);
    const controller = new AbortController();
    fetchFiles(controller, routeDetails);
    return () => {
      setFiles([]);
      controller.abort();
    };
  }, [routeDetails, fetchFiles]);

  return (
    <Markup.Container>
      <GeneralInfo />
      <Divider margin="8px 0" />
      <DetailsBlock header={<Box>Файлы</Box>}>
        {
          isLoading && (<Box justifyContent={"center"}><Loader /></Box>)
        }
        {files.length > 0 ?
          (<Box direction="column" margin="-24px 0 0 0">
            <ViewFileArea
              filesInput={files}
              multiple={true} />
          </Box>) : (!isLoading && <NoDataLabel/>)
        }
      </DetailsBlock>
    </Markup.Container>
  );
};
