import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  convertNestedObjectToArray,
  FilesArrayType,
  FilesObjectType,
  UploadAreaProps,
} from "./view-file-area.utils";

export const useUploadAreaLogic = (props: UploadAreaProps) => {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FilesObjectType>({});
  const {
    handleChangeFiles,
    multiple = false,
    acceptOnlyImages = false,
    label = "Перетащите сюда файлы",
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    filesInput,
    ...otherProps
  } = props;



  const handleUploadButtonClick = useCallback(() => {
    fileInputField.current!.click();
  }, []);

  const addNewFiles = useCallback(
    (newFiles: FileList) => {
      for (const file of newFiles) {
        if (file.size <= maxFileSizeInBytes) {
          if (!multiple) {
            return { file };
          }
          files[file.name] = file;
        }
      }
      return { ...files };
    },
    [files, maxFileSizeInBytes, multiple],
  );




  const callHandleChangeFilesCallback = useCallback(
    (updatedFiles: FilesObjectType) => {
      const filesAsArray: FilesArrayType = convertNestedObjectToArray(updatedFiles);
      // handleChangeFiles(filesAsArray);
    },
    [handleChangeFiles],
  );

  const handleNewFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFiles = e.target.files;
      if (!!newFiles && newFiles.length) {
        const updatedFiles = addNewFiles(newFiles);
        setFiles(updatedFiles);
        callHandleChangeFilesCallback(updatedFiles);
      }
    },
    [addNewFiles, callHandleChangeFilesCallback],
  );

  const handleManualFileUpload = useCallback(
    (newFiles: File[]): void => {
      if (!!newFiles && newFiles.length) {
        const dt = new DataTransfer();
        newFiles.map(el => {
          dt.items.add(el);
          return el;
        });
        const updatedFiles = addNewFiles(dt.files);
        setFiles(updatedFiles);
        // callHandleChangeFilesCallback(updatedFiles);
      }
    },
    [addNewFiles, callHandleChangeFilesCallback],
  );


  useEffect(() => {
    if (props.filesInput) handleManualFileUpload(props.filesInput);
  }, [props.filesInput]);

  const handleFileRemove = useCallback(
    (key: string) => {
      delete files[key];
      setFiles({ ...files });
      callHandleChangeFilesCallback({ ...files });
    },
    [callHandleChangeFilesCallback, files],
  );

  return {
    files,
    label,
    multiple,
    otherProps,
    fileInputField,
    acceptOnlyImages,
    handleFileRemove,
    handleManualFileUpload,
    handleNewFileUpload,
    handleUploadButtonClick,
  };
};
