import styled from "styled-components/macro";
import { ifProp } from "styled-tools";
import { ArrowDown16BlueIcon } from "assets/icons";
import { withNeutralColor } from "shared/utils";
import { Input } from "components/ui";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const WrappedInput = styled(Input)`
  padding-right: 40px;
`;

export const Controls = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
`;

export const IconBlock = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
`;

export const ArrowIcon = styled(ArrowDown16BlueIcon)<{ rotated?: boolean }>`
  transform: ${props => props.rotated ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s ease;
`;

export const SelectOptions = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32), 0px 12px 24px rgba(91, 104, 113, 0.24);
  background-color: #fff;
  margin: 0;
  padding: 8px;
  list-style: none;
  overflow: auto;
  min-height: 100%;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  z-index: 1;
`;

export const SelectOption = styled.li<{ isActive: boolean }>`
  padding: 8px;
  line-height: 16px;
  background-color: ${ifProp("isActive", withNeutralColor("l4"))};
  border-radius: 12px;
  &:hover {
    background-color: ${withNeutralColor("l4")};
  }
`;

export const OptionButton = styled.button`
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

