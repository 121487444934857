import React, { memo, ReactNode } from "react";
import { Select, SelectProps } from "../select";
import * as Markup from "./select-field.styles";

export type SelectFieldProps = SelectProps & {
  label?: ReactNode;
  isRequired?: boolean;
  cyData?: string;
};

const createLabel = (label: ReactNode) => (typeof label === "string" ? <Markup.Label>{label}</Markup.Label> : label);

const SelectFieldComponent = (props: SelectFieldProps) => {
  const { label, isRequired = false, ...selectProps } = props;

  return (
    <Markup.Container>
      {label && <Markup.LabelContainer isRequired={isRequired}>{createLabel(label)}</Markup.LabelContainer>}
      <Select data-cypress='test' {...selectProps} />
    </Markup.Container>
  );
};

export const SelectField = memo(SelectFieldComponent);
