import React, { FC } from "react";
import * as Markup from "./text-tab.styles";

export interface TextTabProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
  active?: boolean;
  className?: string;
  isDisabled?: boolean;
}

export const TextTab: FC<TextTabProps> = ({ isDisabled, children, icon, active = false, onClick, className }) => (
  <Markup.Container className={className} active={active} onClick={isDisabled ? undefined : onClick} isDisabled={!!isDisabled}>
    {icon && <Markup.Icon>{icon}</Markup.Icon>}
    {children && <Markup.Content>{children}</Markup.Content>}
  </Markup.Container>
);
