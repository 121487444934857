import { useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { routeActions } from "store/actions";
import { useRouteParams } from "shared/hooks";
import { getRouteVersionRequest, Trip } from "api";

export const useGetRouteItemsByVersion = () => {
  const dispatch = useDispatch();
  const { id } = useRouteParams();
  const  pageId = typeof id !== "undefined" ? id : "";
  const versionId = useMemo(() => !pageId.toString().includes("_") ? pageId : pageId.split("_")[0], [pageId]);
  const stopId = "";
  const { routeByVersionId } = useSelector((state: RootState) => ({
    routeByVersionId: state.route.routeByVersionId ?? {},
    routeIsLoading: state.route.routeIsLoading,
  }), shallowEqual);

  // eslint-disable-next-line consistent-return
  const fetchRoute = useCallback(async (verId) => {
    if (!(verId in routeByVersionId)) {
      dispatch(routeActions.setIsLoading(true));
      try {
        const payload = await getRouteVersionRequest(verId, { includeInfo: true, includeTrips: true, includeTripStops: true, includeTripsKeyZones: true });
        dispatch(routeActions.getRouteByVersionId(verId, payload));
        return payload;
      } catch (error) {
        dispatch(routeActions.setIsLoading(false));
      } finally {
        dispatch(routeActions.setIsLoading(false));
      }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    } else {
      return routeByVersionId[verId];
    }
  }, [routeByVersionId, dispatch]);

  const deleteFromRouteItems = useCallback(()=>{
    if ((versionId in routeByVersionId)) {
      dispatch(routeActions.deleteRouteByVersionId(versionId));
    }
  }, [dispatch, routeByVersionId, versionId]);

  const addToEditRoute = useCallback((payload: Trip[] | [])=>{
    dispatch(routeActions.setEditRouteTrips(payload));
  }, [dispatch]);

  // useEffect(() => {
  //   if (!routeIsLoading) fetchRoute(versionId);
  // }, [fetchRoute, versionId]);

  return {
    routeDetails: routeByVersionId[versionId] ?? null,
    deleteFromRouteItems,
    addToEditRoute,
    fetchRoute,
    versionId,
    stopId,
  };
};