import { Geometry, Polygon } from "geojson";
import { KeyZoneVersionDetails } from "api";
import { useMap } from "components/common/main-map/map.context";
import mapboxgl from "mapbox-gl";
import { useCallback } from "react";



export const KeyZoneMap = () => {

  const { map } = useMap();


  const clearLayers = useCallback(() => {
    if (map?.getLayer("KeyZonePolygon")) {
      map?.removeLayer("KeyZonePolygon");
    }

    if (map?.getLayer("KeyZoneLine")) {
      map?.removeLayer("KeyZoneLine");
    }

    if (map?.getSource("KeyZoneSource")) {
      map?.removeSource("KeyZoneSource");
    }
  }, []);


  const createLayersForKeyZone = (geometry: KeyZoneVersionDetails["geometry"]) => {
    if (!map) return;
    const data = {
      type: "Feature",
      geometry: geometry as Geometry || {
        type: "Polygon",
        // These coordinates outline Maine.
        coordinates: [],
      } as Geometry,
      properties: {},
    };

    const prevSource = map?.getSource("KeyZoneSource");

    if (!prevSource) {
      map?.addSource("KeyZoneSource", {
        type: "geojson",
        data,
      } as mapboxgl.AnySourceData);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      prevSource?.setData(data);
    }

    if (!map?.getLayer("KeyZonePolygon")) {
      map?.addLayer({
        "id": "KeyZonePolygon",
        "type": "fill",
        "source": "KeyZoneSource", // reference the data source
        "layout": {},
        "paint": {
          "fill-color": "#5ACAFD", // blue color fill
          "fill-opacity": 0.7,
        },
      });
    }


    if (!map?.getLayer("KeyZoneLine")) {
      map?.addLayer({
        "id": "KeyZoneLine",
        "type": "line",
        "source": "KeyZoneSource",
        "layout": {},
        "paint": {
          "line-color": "#5ACAFD",
          "line-width": 0.4,
        },
      });
    }

    if (geometry) {
      const { coordinates } = geometry as Polygon;
      if (coordinates && coordinates[0]) {
        const bounds = new mapboxgl.LngLatBounds();
        coordinates[0].map((coord) => bounds.extend([coord[0], coord[1]]));
        map.fitBounds(bounds, {
          padding: 20,
        });

      }

    }


  };

  return {
    clearLayers,
    createLayersForKeyZone,
  };
};
