/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl, { VectorSourceImpl } from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { useRoute } from "react-router5";
import { addMarker } from "shared/helpers/add-marker";
import { TYPES } from "store/actions";
import { RootState } from "store/types";
import { PAGE_ROUTES } from "shared/definitions";
import { useActiveStopStation } from "shared/hooks/use-active-stop-station";
import { getStopRequest } from "api";
import { APP_ENV } from "app-env";
import { useTimer } from "./utils";
import { updateSelectedStopSource } from "../stop-layers-controller/stop.utils";
import { MapLayerNames } from "../utils/consts/map.constants";

export const getActualStops = async () => {
  try {
    return (await getStopRequest().then(({ items }) => items)) as any[];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error();
    throw e;
  }
};

export const useActualStop = (map?: mapboxgl.Map) => {
  const activeActualStop = useActiveStopStation()?.item ?? null;
  const { route: routeParams } = useRoute();
  const { path } = routeParams;
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  const [stopData, setStopData] = useState<any>();
  const [isBeenDrawnStops, setIsBeenDrawnStops] = useState<boolean>(false);
  const { removeTimer } = useTimer();
  const isNewStopStation = path.indexOf("/stop-station/new") > -1;
  const isNewDepot = path.indexOf("/depot/new") > -1;
  const isEditStopStation = path.indexOf("/stop-station/") > -1 && path.indexOf("_edit") > -1;
  const dispatch = useDispatch();
  const { searchSubstring } = useSelector((state: RootState) => ({
    searchSubstring: state.stop.searchSubstring,
  }));
  const { route: {
    name: pageRoute,
    params: { id },
  },
  } = useRoute();
  const { newStopStationCoords } = useSelector((state: RootState) => ({
    newStopStationCoords: state.stop.newStopStationCoords,
  }));

  const createDataPoint = useCallback(
    () => {
      if (!map) return;
      if (!activeActualStop?.lat && !activeActualStop?.lng) return;

      updateSelectedStopSource(map, { lng: activeActualStop.lng, lat: activeActualStop.lat });

      map.flyTo({
        center: [activeActualStop.lng, activeActualStop.lat],
        essential: true,
        zoom: 16,
      });
    }, [activeActualStop, map, updateSelectedStopSource]);

  useEffect(() => {
    if ([PAGE_ROUTES.STOP_STATION, PAGE_ROUTES.STOP_STATIONS].includes(pageRoute) && id) {
      const waiting = () => {
        if (!map?.isStyleLoaded()) {
          if (timer.current !== null) {
            clearTimeout(timer.current);
          }
          timer.current = setTimeout(waiting, 100);
        } else {
          createDataPoint();
          setIsBeenDrawnStops(true);
        }
      };
      waiting();

    }
  }, [createDataPoint, id, isBeenDrawnStops, map, pageRoute, removeTimer, stopData]);

  useEffect(() => {
    if (!map) return;

    map.on("click", (e) => {
      if (
        !(
          window.location.href.indexOf("/stop-station/new") > -1 ||
          (window.location.href.indexOf("/stop-station/") > -1 && window.location.href.indexOf("_edit") > -1)
        ) && !(
          window.location.href.indexOf("/contractors/new") > -1 ||
          (window.location.href.indexOf("/contractors/") > -1 && window.location.href.indexOf("_edit") > -1)
        )
        && !(
          window.location.href.indexOf("/depot/new") > -1 ||
          (window.location.href.indexOf("/depot/") > -1 && window.location.href.indexOf("_edit") > -1)
        )
      )
        return;
      const { lng, lat } = e?.lngLat;

      dispatch({
        type: TYPES.STOP.SET_NEW_STOP_STATION_DATA,
        payload: { address: "", street: "" },
      });

      addMarker({ lng, lat } as mapboxgl.LngLat, map);

      dispatch({
        type: TYPES.STOP.SET_NEW_STOP_STATION_COORDS,
        payload: { lng, lat },
      });

      fetch(`https://geocode-maps.yandex.ru/1.x/?geocode=${lat},${lng}&sco=latlong&format=json&spn=2.169799804687493,2.24626892361096253&ll=${lng},${lat}&rspn=1&results=1&apikey=f1d03e63-82b9-4586-8be2-5723669ef7eb`, {})
        // fetch(`https://api.roads.mos.ru/geocode/api/near?lon=${lng}&lat=${lat}`, {})
        .then((res) => res.json())
        .then(({ response }) => {
          if (response) {
            const partianl = response.GeoObjectCollection.featureMember;
            if (partianl.length > 0) {
              // const address = partianl[0].GeoObject.metaDataProperty.GeocoderMetaData.Address;
              const address = partianl[0].GeoObject.name;

              dispatch({
                type: TYPES.STOP.SET_NEW_STOP_STATION_DATA,
                payload: { address, street: address },
              });
            }
          }
        })
        .catch(() => { });
    });
  }, [isNewStopStation, isEditStopStation, dispatch, map]);

  useEffect(() => {
    if (!map) return;
    const source = map?.getSource(MapLayerNames.ACTUAL_STOP_SOURCE_ID) as VectorSourceImpl;
    if ( searchSubstring && searchSubstring?.length > 0) {
      source.setTiles([`${APP_ENV.REACT_APP_BASE_URL}/Stop/version/tile/{x}/{y}/{z}?isActive=true&SearchSubstring=${searchSubstring}`]);
    } else {
      source.setTiles([`${APP_ENV.REACT_APP_BASE_URL}/Stop/version/tile/{x}/{y}/{z}?isActive=true`]);
    }
    
    
  }, [searchSubstring]);

  // useFilterStops(map);
};
