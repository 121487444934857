import styled, { css } from "styled-components/macro";
import { withAccentColor } from "shared/utils";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const requiredLabelCss = css`
  &::after {
    content: "*";
    color: ${withAccentColor("red", "basic")};
  }
`;

export const LabelContainer = styled.span<{ isRequired: boolean }>`
  ${({ isRequired }) => isRequired && requiredLabelCss}
`;

export const Label = styled.span`
font-weight: 500;
line-height: 16px;
padding: 0 8px 4px 8px;
width: 100%;
`;

export const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0 0 -8px 0;
  padding: 0;
  width: 100%;
`;

export const Item = styled.li`
  margin-bottom: 8px;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;
