import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
`;

export const PlankWrapper = styled.div`
  height: 100%;
  min-height: 4px;
`;
