import React, { useCallback, memo } from "react";
import { Option } from "./select.types";
import * as Markup from "./select.styles";

export type SelectOptionProps = Option & {
  isActive?: boolean;
  onClick?: (option: Option) => void;
};

const SelectOptionComponent = (props: SelectOptionProps) => {
  const { onClick, isActive = false, ...option } = props;

  const handleClick = useCallback(() => {
    onClick?.(option);
  }, [onClick, option]);
  return (
    <Markup.SelectOption isActive={isActive}>
      <Markup.OptionButton onClick={handleClick}>{option.label}</Markup.OptionButton>
    </Markup.SelectOption>
  );
};

export const SelectOption = memo(SelectOptionComponent);
