/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: { isPaint: boolean, isDrawLine: boolean, isEditLine: boolean } = {
  isPaint: false,
  isDrawLine: false,
  isEditLine: false,
};

export const layersSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    toggleIsPaint(state, { payload }: PayloadAction<boolean>) {
      state.isPaint = payload;
    },

    setIsDrawLine(state, { payload }: PayloadAction<boolean>) {
      state.isDrawLine = payload;
    },
    setIsEditLine(state, { payload }: PayloadAction<boolean>) {
      state.isEditLine = payload;
    },
  },
});
