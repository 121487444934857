export const vehicle = {
  brand: "Марка",
  model: "Модель",
  tmId: " ID бортового оборудования",
  contractor: "Перевозчик",
  vehicleType: "Тип ТС",
  routeType: "Тип маршрута",
  vehicleClassType:  "Класс ТС",
  contractorType: "Тип перевозчика",
  onboardEquipmentId: "ID бортового оборудования",
  onboardEquipment: "Бортовое оборудование",
  depot: "Парк",
  depots: "Парки",
  maintenanceLastDate: "Последнее ТО",
  createVehicle: "Новое транспортное средство",
  editVehicle: "Редактирование транспортного средства",
  manufactureDate: "Дата производства",
  commissioningDate: "Дата ввода в эксплуатацию",
  startDateVehicle: "Дата начала действия транспорта",
  endDateVehicle: "Дата окончания действия транспорта",
  vin: "VIN",
  grz: "Государственный регистрационный знак",
} as const;
