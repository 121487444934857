import { Option } from "../../../components/common/select/select.types";
import { SetSourceAction, SetTabSourceAction, SourceActionType } from "./source-actions.types";

export const setSourceAction = (id: string, options: Option[] | undefined): SetSourceAction => ({
  type: SourceActionType.setSource,
  payload: { id, options },
});

export const setSourceTabAction = (id: string, items: any[]): SetTabSourceAction => ({
  type: SourceActionType.setTabSource,
  payload: { id, items },
});

