import React, { memo, useCallback } from "react";
import { Chip } from "components/ui";
import { ChipProps } from "@megapolis/uikit/chip/chip";
import * as Markup from "./chips.styles";

type ClickEvent = React.MouseEvent<HTMLButtonElement>;

export type ChipsItemProps = Omit<ChipProps, "onClick"> & {
  id: string;
  onClick?: (arg: { id: string; event: ClickEvent }) => void;
  label?: string;
  isActive?: boolean;
};

const ChipsItemComponent = (props: ChipsItemProps) => {
  const { id, onClick, label, isActive } = props;

  const handleClick = useCallback((event: ClickEvent) => onClick?.({ id, event }), [id, onClick]);

  return (
    <Markup.Item>
      <Chip {...props} label={label || ""} onClick={handleClick} isActive={isActive} />
    </Markup.Item>
  );
};

export const ChipsItem = memo(ChipsItemComponent);
