import React from "react";
import * as Markup from "./switch-icon.styles";

export interface SwitchIconProps {
  icon: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isActive?: boolean;
}

const SwitchIconComponent: React.FC<SwitchIconProps> = (props) => {
  const { isActive = false, icon, onClick } = props;
  return (
    <Markup.Wrapper isActive={isActive} onClick={onClick}>
      {icon}
    </Markup.Wrapper>
  );
};

export const SwitchIcon = React.memo(SwitchIconComponent);
