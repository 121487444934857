import { Tabs } from "@megapolis/uikit";
import { withNeutralColor } from "shared/utils";
import styled from "styled-components/macro";

interface Props {
  show: boolean
}

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
`;

export const TripsStepperWrapper = styled.div`
height: 65%;
    display: grid;
    grid-template-rows: 0.5fr 0.1fr 8.5fr;
`;

export const ButtonMapControl = styled.div`
    display: flex
`;

export const LabelButton = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-weight: 500;
  justify-content: space-between;
`;

export const DeleteBubble = styled.span<Props>`
  position: absolute;
  top: -6px;
  right: 0px;
  border-radius: 18%;
  height: 16px;
  background: #e35252;
  color: white;
  visibility: ${props => props.show ? "visible" : "hidden"};
`;

export const EditBubble = styled.span<Props>`
  position: absolute;
  top: -6px;
  right: 31px;
  border-radius: 18%;
  height: 16px;
  background: #67b0ff;
  color: white;
  visibility: ${props => props.show ? "visible" : "hidden"};
`;

export const ContainerBubble = styled.div`
  text-decoration: none;
  position: relative;
  display: inline-block;

`;

export const Label = styled.span`
  font-weight: 500;
  line-height: 16px;
  padding: 0 8px 4px 8px;
  width: 100%;
`;

export const Header = styled.span`
  padding: 16px 16px 0 24px;
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const Heading = styled.span`
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding-left: 8px;
`;

export const MainBlock = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 8px 16px;
  overflow: auto;
`;

export const SubBlock = styled.div.attrs((props: any) => ({ display: props.display }))`
  flex-grow: 1;
  display: ${props => props.display ? "block" : "none"};
`;

export const Icon = styled.div.attrs((props: any) => ({ transform: props.transform }))`
  flex-grow: 1;
  margin: ${props => props.transform ? "0 5px" : "1px 5px 0"};
  transform: ${props => props.transform ? "rotate(0deg)" : "rotate(180deg)"};
  :hover {
    cursor: pointer;
  }
`;

export const UploadAreaCaption = styled.span`
  display: block;
  color: ${withNeutralColor("l1")};
  padding: 4px 8px;
  margin-top: 8px;
`;

export const DirectionsBlock = styled.div`
  margin: 8px 0;
`;

export const DirectionsTabs = styled(Tabs)`
  &::after {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  border-top: 1px solid ${withNeutralColor("l2")};
  width: 100%;
  padding: 16px;
  button:first-child {
    margin-right: 8px;
  }
`;

export const BlockLabel = styled.span`
  font-weight: 500;
  padding-left: 8px;
`;

export const Block = styled.div`
  margin-top: 8px;
`;

export const Chips = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 8px 0;
  list-style-type: none;
  button {
    color: ${withNeutralColor("d2")};
    margin: 0 8px 8px 0;
  }
`;

export const UploadAreaLabel = styled.span`
  display: block;
  font-weight: 500;
  padding-left: 8px;
  margin-bottom: 8px;
`;
