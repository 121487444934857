import React, { useCallback, useMemo, useState } from "react";
import { FormField } from "features/detail-panels/components";
import { TestButton } from "components";
import { Content } from "@megapolis/uikit/button/button.types";
import { Kind, Priority } from "components/ui/buttontest/button.types";
import { useDispatch } from "react-redux";
import { contractorActions } from "store/actions";
import { ContractorsFilter } from "store/reducers/contractor";
import { useContractorsFiltersSelector } from "store/selectors/contractor-selectors";
import { CapacityTypesFilter, ContractorTypesFilter, VehicleTypesFilter } from "features/filter-element";
import * as Markup from "./contractors-filter-content.style";

export const ContractorFilterContent = (props: { hide: () => void }) => {

  const initialFIlter = useMemo(()=>({
    ContractorTypeIds: undefined,
    VehicleTransportTypes: undefined,
    VehicleCapacityTypes: undefined,
  }), []);
  const dispatch = useDispatch();
  const filters = useContractorsFiltersSelector();
  const [filter, setFilter] = useState<ContractorsFilter>(filters);

  const handleSubmit = useCallback(() => { dispatch(contractorActions.setFilters(filter)); props.hide(); }, [dispatch, filter, props]);

  const handleReset = useCallback(() => { dispatch(contractorActions.setFilters(initialFIlter)); setFilter(initialFIlter); }, [dispatch, initialFIlter]);

  const updateValue = useCallback(<P extends keyof ContractorsFilter>(name: P, value: ContractorsFilter[P] | null) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  }, []);

  return (


    <><Markup.Container>
      <FormField>
        <ContractorTypesFilter ContractorTypesFilter={filter.ContractorTypeIds || ""} field={"ContractorTypeIds"} updateValue={updateValue} />
      </FormField>
      <FormField>
        <VehicleTypesFilter TransportTypes={filter.VehicleTransportTypes || ""} field={"VehicleTransportTypes"} updateValue={updateValue}/>
      </FormField>
      <FormField>
        <CapacityTypesFilter CapacityTypesFilter={filter.VehicleCapacityTypes || ""} field={"VehicleCapacityTypes"} updateValue={updateValue}/>
      </FormField>
    </Markup.Container>
    <Markup.Footer>
      <TestButton content={Content.Text} priority={Priority.Tertiary} text="Применить фильтр" onClick={handleSubmit} cyData="Filter" />
      <TestButton kind={Kind.Danger} content={Content.Text} priority={Priority.Tertiary} text="Сброс" onClick={handleReset} cyData="Filter" />
    </Markup.Footer>
    </>);
};
