import { withProp } from "styled-tools";
import { AccentColorKeys, AccentColors, AccentKeys, ContentColors } from "@megapolis/uikit/theme/theme-config.types";

type ColorSelector = (colors: AccentColors) => string;
type NeutralsSelector = (neutrals: AccentKeys) => string;
type AccentSelector = (accents: AccentColorKeys, level: AccentKeys) => string;

export const withColors = (selector: ColorSelector) => withProp("theme.colors", selector);

export const withNeutralColors = (selector: NeutralsSelector) => withProp("theme.colors.gray", selector);

export const withAccentColors = (selector: AccentSelector) => withProp("theme.colors", selector);

export const withNeutralColor = (color: AccentKeys) =>
  withProp("theme.colors.gray", (neutrals) => neutrals[color]);

export const withAccentColor = (color: AccentColorKeys, level: AccentKeys) =>
  withProp("theme.colors", (colors: AccentColors) => colors[color][level]);

export const withPrimaryAccentColor = () =>
  withAccentColor("blue", "basic");

export const withContentColor = (content: keyof ContentColors) =>
  withProp("theme.colors.content", (colors: ContentColors) => colors[content]);
