import { useCallback, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { contractorActions } from "store/actions";
import { useRoute } from "react-router5";

export const useGetContractorItemsByVersion = () => {
  const dispatch = useDispatch();
  const { route } = useRoute();
  const { path } = route;
  // const { id } = useRouteParams();
  const id = path.substring(path.lastIndexOf("/") + 1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const  pageId = typeof id !== "undefined" ? id : "";
  const versionId = useMemo(() => !pageId.toString().includes("_") ? pageId : pageId.split("_")[0], [pageId]);
  const { contractorByVersionId } = useSelector((state: RootState) => ({
    contractorByVersionId: state.contractor.contractorByVersionId ?? {},
  }), shallowEqual);

  // eslint-disable-next-line consistent-return
  const fetchContractor = useCallback(async (verId) => {
    if (!(verId in contractorByVersionId)) {
      setIsLoading(true);
      try {
        const payload = dispatch(contractorActions.getContractorByVersionId(verId));
        return payload;
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    } else contractorByVersionId[verId];
  }, [contractorByVersionId, dispatch ]);


  return {
    contractorDetails: contractorByVersionId[versionId] ?? null,
    fetchContractor,
    versionId,
    isLoading,
  };
};
