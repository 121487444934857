import { withNeutralColor } from "shared/utils";
import styled, { css } from "styled-components/macro";

const commonCss = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Container = styled.span`
  ${commonCss};
  flex-grow: 1;
`;

export const Icon = styled.span`
  ${commonCss};
  margin-right: 8px;
`;

export const Content = styled.span`
  ${commonCss};
  flex-flow: column nowrap;
  align-items: flex-start;
  flex-grow: 1;
`;

export const Text = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Caption = styled.span`
  ${commonCss};
  color: ${withNeutralColor("d1")};
  line-height: 16px;
  font-size: 11px;
  font-weight: 500;
`;

export const RightControl = styled.span`
  margin-left: 16px;
  ${commonCss};
`;
