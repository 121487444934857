/* eslint-disable */
import React, { useCallback, useEffect } from "react";
import { Layout as LayoutElement, useIsOverlayMode, useIsStackMode, useLayout } from "megapolis-uikit-latest"
import { useSidebar } from "features";
import { MainMap } from "../../../components";
import { IProps } from "./assets/interfaces";
import * as Markup from "./main-wrapper.styles";
import { SkeletonDetailCard } from "components/ui/skeleton-detail-card";
import "./main-wrapper.css";
import { useMap } from "components/common/main-map/map.context";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, lightTheme } from "megapolis-uikit-latest/theme";
import { useRoute } from "react-router5";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/types";
import { FilterComponent } from "components/ui/filter-component";

export const MainWrapper: React.FC<IProps> = ({ children, detailComponent: DetailComponent, mainMapComponent: MainMapComponent }: IProps) => {
  
  const {
    route: {
      name,
      params: { id },
    },
  } = useRoute();
  const isDisplayMap = React.useMemo(() => {
    if (["schedule", "schedules"].indexOf(name) === -1) return false;
    return true;
  }, [name]);

  const { map, onResize } = useMap();

  const useCentralBlocks = () => {
    // const { page } = usePage();
    const isDisplayDetails = React.useMemo(() => id === undefined, [id]);
    const { filterStatus } = useSelector((state: RootState) => ({
      filterStatus: state.common.filterStatus,
    }), shallowEqual);
    let blocks = [
      {
        id: "main",
        content: children,
        minWidth: 400,
        defaultWidth: 400,
        maxWidth: 1500,
      },
      {
        id: "blank",
        content: <></>,
        minWidth: 419,
        defaultWidth: 500,
        maxWidth: 1500,
      }
    ];

    if (!isDisplayDetails) {
      
      blocks.splice(1,1)

      if (filterStatus) {
        blocks.unshift({
          id: "filter",
          content: <FilterComponent/>,
          minWidth: 300,
          defaultWidth: 300,
          maxWidth: 500,
        })
      } else {
        blocks.splice(1,1)
      }

      blocks.push({
        id: "main-details",
        content: DetailComponent ? <React.StrictMode><DetailComponent /></React.StrictMode> : <SkeletonDetailCard />,
        minWidth: 419,
        defaultWidth: 500,
        maxWidth: 1500,
      })
    }



    return {
      blocks,
    };
  };

  const useRight = () => {
    const {
      route: {
        name,
        params: { id },
      },
    } = useRoute();
    return ({
      id: "main-map",
      content: <MainMap />,
      minWidth: 1500,
      defaultWidth: 1500,
      maxWidth: 1500,
    })
  };

  // if (!applicationOnLoaded) return <></>;

  return (
    <Markup.Wrapper>
      <ThemeProvider theme={lightTheme as any}>
        <GlobalStyle />
          <LayoutElement
            leftBlock={useSidebar()}
            centralBlocks={useCentralBlocks()}
            rightBlock={!isDisplayMap ? useRight() : undefined}
            onResize={onResize}
          />
      </ThemeProvider>
    </Markup.Wrapper>
  );
};
