import React, { useMemo } from "react";
import { InfoCard, BlockHeader } from "components";
import { v4 } from "uuid";
import styled from "styled-components/macro";
import { useGetDriverItemsByVersion } from "features/detail-panels/driver-details-panel/driver-details-panel.data";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Header = styled.span`
  font-weight: 600;
`;

export const GeneralInfo = () => {
  const { driverDetails } = useGetDriverItemsByVersion();

  const infoCards = useMemo(() => [
    {
      title: "Фамилия Имя Отчество",
      text: `${driverDetails?.firstName || "-"} ${driverDetails?.middleName || ""} ${driverDetails?.lastName || ""}`,
    },
    {
      title: "Дата рождения",
      text: driverDetails?.birthDate || "-",
    },
    {
      title: "Категория прав",
      text: driverDetails?.licenseCategories || "-",
    },
    {
      title: "Табельный номер",
      text: driverDetails?.personnelNumber || "-",
    },
    {
      title: "Парк",
      text: `${driverDetails?.depot?.name || "нет"}`,
    },
    {
      title: "Перевозчик",
      text: `${driverDetails?.depot?.contractor?.shortName || "нет"}`,
    },
    {
      title: "Среднее время работы",
      text: "Не указано",
    },
  ], [driverDetails]);



  return (<Container>
    <BlockHeader left={<Header>Общие сведения информация</Header>} />
    <InfoCards>
      {infoCards.map((card: { title: string; text: string }) => (
        <InfoCard key={v4()} title={card.title}>
          {card.text}
        </InfoCard>
      ))}
    </InfoCards>
  </Container>
  );
};

