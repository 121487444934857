import { DriverVersionDetails, DriverVersionRequest, DriverVersionResponse } from "api/requests/driver/types";
import { TabValues } from "features";
import { DriverDetails } from "models";
import { DriverFilters } from "store/reducers/driver";

type Action<A extends DriverActionType, P> = { type: A, payload: P };

export enum DriverActionType {
  SET_DRIVERS = "SET_DRIVERS",
  UPDATE_DRIVERS_FILES = "UPDATE_DRIVERS_FILES",
  SET_DRIVER_ACTIVE_FILTER = "SET_DRIVER_ACTIVE_FILTER",
  SET_ACTIVE_DRIVER_ID = "SET_ACTIVE_DRIVER_ID",
  SET_DRIVER_BY_VERSION_ID = "SET_DRIVER_BY_VERSION_ID",
  SET_DRIVER_SEARCH_SUBSTRING = "SET_DRIVER_SEARCH_SUBSTRING",
  SET_DRIVER_FILTERS = "SET_DRIVER_FILTERS",
  UPDATE_DRIVER_BY_VERSION = "UPDATE_DRIVER_BY_VERSION",
}

export type SetDriversAction = Action<
DriverActionType.SET_DRIVERS,
{ status: DriverFilters; data: DriverVersionResponse; pageNum: number }
>;
export type SetDriverFilters = Action<DriverActionType.SET_DRIVER_FILTERS, {
  ContractorIds?: string | null,
  RouteIds?: string | null,
  DepotIds?: string | null,
}>;
export type SetDriverActiveFilterAction = Action<DriverActionType.SET_DRIVER_ACTIVE_FILTER, DriverFilters>;
export type SetActiveDriverAction = Action<DriverActionType.SET_ACTIVE_DRIVER_ID, string>;
export type SetDriverSearchSubstring = Action<DriverActionType.SET_DRIVER_SEARCH_SUBSTRING, string>;
export type SetDriverByVersionIdAction = Action<DriverActionType.SET_DRIVER_BY_VERSION_ID, { versionId: ID, data: DriverVersionDetails }>;
export type UpdateDriverByVersionIdAction = Action<DriverActionType.UPDATE_DRIVER_BY_VERSION, { versionId: string, data: Partial<DriverDetails> }>;
export type UpdateDriversFilesAction = Action<DriverActionType.UPDATE_DRIVERS_FILES, { driverId: string, data: Partial<DriverVersionRequest> }>;


export type DriversAction =
  | SetDriverActiveFilterAction
  | SetDriverFilters
  | SetDriverSearchSubstring
  | SetActiveDriverAction
  | SetDriverByVersionIdAction
  | UpdateDriverByVersionIdAction
  | UpdateDriversFilesAction
  | SetDriversAction;
