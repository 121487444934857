import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetRouteItemsByVersion } from "features/detail-panels/route-details-panel/route-details-panel.data";
import { Trip, TripStop } from "api";
import { Chips, ChipsItem, Tab } from "components";
import { selectedTripController } from "components/common/main-map/selected-route/selected-trip";
import { useMap } from "components/common/main-map/map.context";
import { DetailsPanelTabs } from "features/detail-panels/components";
import { KeyZoneDetails } from "models";
import * as Markup from "./trip-directions.styles";
import { TripsDirectionsMap } from "./trip-directions.map";
import { TripsStepper } from "../trip-stepper/trips-stepper";
import { KeyZoneStepper } from "../keyZone-stepper/keyzone-stepper";

export const TripsDirections = () => {
  const { map } = useMap();
  const forwardTrips: Array<Trip> = useMemo(() => [], []);
  const backwardTrips: Array<Trip> = useMemo(() => [], []);
  const [tripStops, setTripStops] = useState<TripStop[]>([]);
  const [keyZones, setKeyZones] = useState<KeyZoneDetails[]>([]);
  const [filter, setFilter] = useState("tripStops");
  const [selectedTrip, setSelectedTrip] = useState<number | null>(null);
  const { routeDetails } = useGetRouteItemsByVersion();
  const { clearLayers, createStopLayersForTrip, createLineLayersForTrip, createZoneLayersForTrip, setVisibilityTripStops, setVisibilityTripKeyZones } = TripsDirectionsMap();

  const handleClick = useCallback(({ id }) => {
    setSelectedTrip(+id);
    setFilter("tripStops");
  }, []);


  useEffect(() => {
    clearLayers();
    if (!routeDetails?.trips?.length) return;
    const getTrip = routeDetails?.trips.find(obj => obj.tripId === selectedTrip);
    if (getTrip) {
      setTripStops(getTrip.tripStops || []);
      setKeyZones(getTrip.tripKeyZones || []);
      selectedTripController(getTrip, map);
      createLineLayersForTrip();
      createStopLayersForTrip();
      // createZoneLayersForTrip();
    }
  }, [selectedTrip]);

  useEffect(() => {

    switch (filter) {
      case "tripStops":
        setVisibilityTripStops(true);
        setVisibilityTripKeyZones(false);
        createStopLayersForTrip();
        break;
      case "keyZones":
        setVisibilityTripKeyZones(true);
        setVisibilityTripStops(false);
        createZoneLayersForTrip();
        break;
      default:
        break;
    }
  }, [filter]);

  const directionSort = useCallback(() => {
    forwardTrips.length = 0;
    backwardTrips.length = 0;

    if (!routeDetails?.trips?.length) return;
    routeDetails?.trips.map((elm) => {
      switch (elm.direction) {
        case "Backward":
          backwardTrips.push(elm);
          break;
        case "Forward":
          forwardTrips.push(elm);
          break;
        default:
          break;
      }
      return elm;
    });
    forwardTrips.sort((a, b) => a.type.localeCompare(b.type));
    backwardTrips.sort((a, b) => a.type.localeCompare(b.type));
  }, [backwardTrips, forwardTrips, routeDetails?.trips]);

  useEffect(() => {
    directionSort();
    setSelectedTrip(forwardTrips[0]?.tripId || backwardTrips[0]?.tripId || null);
    return function cleanup() {
      clearLayers();
    };
  }, [backwardTrips, clearLayers, directionSort, forwardTrips]);





  if (!routeDetails?.trips) {
    return (<Markup.Container>
      Маршруты отсутствуют
    </Markup.Container>
    );
  }



  return (<Markup.Container>
    <Markup.DirectionsBlock>
      <Chips label="Прямое направление">
        {forwardTrips.sort((a, b) => a.type.localeCompare(b.type)).map((elm, idx) => (
          <ChipsItem
            key={idx}
            isActive={selectedTrip === elm.tripId}
            onClick={handleClick}
            label={elm.type}
            id={elm.tripId ? elm.tripId.toString() : "0"} />
        ))}
      </Chips>
    </Markup.DirectionsBlock>
    <Markup.DirectionsBlock>
      <Chips label="Обратное направление">
        {backwardTrips.sort((a, b) => a.type.localeCompare(b.type)).map((elm, idx) => (
          <ChipsItem
            key={idx}
            isActive={selectedTrip === elm.tripId}
            onClick={handleClick}
            label={elm.type}
            id={elm.tripId ? elm.tripId.toString() : "0"} />
        ))}
      </Chips>
    </Markup.DirectionsBlock>
    <DetailsPanelTabs value={filter} onChange={(el) => el && setFilter(el)}>
      <Tab value={"tripStops"}>Остановки</Tab>
      <Tab value={"keyZones"} isDisabled={keyZones.length <= 0}>Зоны</Tab>
    </DetailsPanelTabs>
    {filter === "tripStops" && <TripsStepper tripStops={tripStops} />}
    {filter === "keyZones" && <KeyZoneStepper keyZones={keyZones as any} />}
  </Markup.Container>
  );
};

