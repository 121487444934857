import React, { useCallback, useEffect, useState } from "react";
import router from "router";
import { PAGE_ROUTES } from "shared/definitions/routes";
import { ScheduleCardListPagination } from "features/card-lists";
import { Tab } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { RouteSelectFieldCustom } from "components/common/select-with-source/route-multi-select-field/route-select-field.custom";
import { ListPanel, ListPanelFilterTabs, ListPanelHeader, ListPanelHeaderWrapper } from "../components";
import { TabValues } from "../list-panels.constants";

export const ScheduleListPanel = () => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState("");
  const [routeId, setRouteId] = useState({ value: "", label: "" });
  const [filter, setFilter] = useState<string>(TabValues.ALL);

  const handleItemClick = useCallback((id: number | string) => {
    setActiveId(id.toString());
    router.navigate(PAGE_ROUTES.SCHEDULE, {
      id,
    });
  }, []);

  useEffect(()=>{
    if (routeId.value === "") router.navigate(PAGE_ROUTES.SCHEDULES);
    router.navigate(PAGE_ROUTES.SCHEDULES);
  }, [routeId.value]);

  const handleAddItem = useCallback(
    () =>
      router.navigate(PAGE_ROUTES.SCHEDULE, {
        id: "new",
      }),
    [],
  );


  return (
    <ListPanel
      headerSlot={
        <ListPanelHeaderWrapper>
          <ListPanelHeader title="Расписание" />
          <RouteSelectFieldCustom value={routeId} onChange={(el)=>{
            setRouteId(el);
          }} ></RouteSelectFieldCustom>
        </ListPanelHeaderWrapper>
      }
      filterSlot={
        <ListPanelFilterTabs activeFilterTab={filter} onChange={setFilter}>
          <Tab value={TabValues.ALL}>Все</Tab>
          <Tab value={TabValues.ACTIVE}>Активные</Tab>
          <Tab value={TabValues.INACTIVE}>Неактивные</Tab>
        </ListPanelFilterTabs>
      }
      listSlot={!routeId.value ? <></> : <ScheduleCardListPagination activeId={activeId} routeId={routeId.value} activeFilter={filter} onClick={handleItemClick}/>}
    />
  );
};
