import React, { memo } from "react";
import * as Markup from "./separated-inline-list.styles";

export type SeparatedInlineListProps = {
  /** Список разделяемых строк */
  items: string[];
  /** Кастомный className */
  className?: string;

  dots?: boolean;
};

export const SeparatedInlineListComponent = (props: SeparatedInlineListProps) => {
  const { items, className, dots = true } = props;

  return (
    <Markup.List className={className}>
      {items.map((item, index) => (
        item &&  <Markup.ListItem dots={dots} key={`${item}_${index}`} >
          {item}
        </Markup.ListItem>
      ))}
    </Markup.List>
  );
};

export const SeparatedInlineList = memo(SeparatedInlineListComponent);
