import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Box, Divider } from "components/common";
import { NoDataLabel, toastError, ViewFileArea } from "components/ui";
import { getDriverFile } from "api/requests/driver/driver.requests";
import { useGetDriverItemsByVersion } from "features/detail-panels/driver-details-panel/driver-details-panel.data";
import { DriverDetails } from "models";
import { Loader } from "megapolis-uikit-latest/loader";
import { DetailsBlock } from "../../../../components";
import { GeneralInfo } from "./sections/driver.general-info";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 8px;
`;

export const DriverDetailsInfoTab = () => {
  const { driverDetails } = useGetDriverItemsByVersion();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);

  const fetchFiles = useCallback(async (controller: AbortController, driver: DriverDetails) => {
    if (!driver?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(driver.files.map(async fileElem => {
        const payload = await getDriverFile(driver.driverId, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);


  useEffect(() => {
    setFiles([]);
    const controller = new AbortController();
    fetchFiles(controller, driverDetails);
    return () => {
      setFiles([]);
      controller.abort();
    };
  }, [driverDetails, fetchFiles]);
  
  return (<Box direction="column" padding="0 24px">
    <GeneralInfo/>
    <Divider margin="8px 0" />
    <DetailsBlock header={<Box>Файлы</Box>}>
      {
        isLoading && (<Box justifyContent={"center"}><Loader /></Box>)
      }
      {files.length > 0 ?
        (<Box direction="column" margin="-24px 0 0 0">
          <ViewFileArea
            filesInput={files}
            multiple={true} />
        </Box>) : (!isLoading && <NoDataLabel/>)
      }
    </DetailsBlock>
  </Box>
  );
};
