import styled, { css } from "styled-components/macro";
import { withAccentColor } from "shared/utils";
import { withCBColor, withContentColor } from "@megapolis/uikit/utils/styled-helpers";

export const Icon = styled.span`
    width: 15%;
    height: 100%;
    margin-right: 7px;
`;

const commonCss = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Container = styled.span`
  ${commonCss};
  white-space: nowrap;
  flex-grow: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 0 24px;
`;

export const Header = styled.span`
  ${commonCss};
  flex-flow: column nowrap;
  align-items: flex-start;
  flex-grow: 1;
`;

export const Text = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;


export const Content = styled.span`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  line-height: 16px;
  overflow: hidden;
  text-align: left;
  margin: 7px 0;
  white-space: nowrap;
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

export const SkeletonContainer = styled.div`
display: flex;
flex-flow: column nowrap;
line-height: 16px;
padding: 8px 0;
`;

export const ContentWrapper = styled.span`
  display: flex;
  flex-flow: row nowrap;
`;

export const Title = styled.span`
  display: block;
  color: ${withContentColor("primary")};
`;

export const Caption = styled.span`
  display: block;
  color: ${withContentColor("secondary")};
  * {
    font-size: 11px;
  }
`;
