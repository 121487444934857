import { RouteCardProps } from "features/cards";
import { v4 } from "uuid";

type MockItem = RouteCardProps;

const generateColor = () => {
  const rgb = [...Array(3)].map(() => Math.floor(Math.random() * 255));
  return `rgb(${rgb.join(",")})`;
};

export const mockItems: MockItem[] = [...Array(100)].map((_, index) => ({
  id: v4(),
  routeName: `Маршрут ${index + 1}`,
  routeNumber: `ККК ${index + 1}`,
  routeIconColor: generateColor(),
  vehicleData: {
    contractorName: `Перевозчик ${index}`,
    routeType: `Тип маршрута ${index}`,
    transportType: `Тип транспорта ${index}`,
  },
}));
