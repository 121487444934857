export const getStyleByDrawing = () => [
  // Слой для отрисови границ
  {
    id: "gl-draw-line-border",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#ffffff",
      "line-opacity": 1,
      "line-width": {
        stops: [
          [2, 6],
          [16, 12],
          [20, 20],
        ],
      },
    },
  },
  // Слой для отрисови пути
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#ee273f",
      "line-opacity": 1,
      "line-width": {
        stops: [
          [2, 4],
          [16, 8],
          [20, 16],
        ],
      },
    },
  },
  // Слой для отрисови точек
  {
    id: "gl-draw-vertex",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#ee273f",
      "circle-opacity": 1,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#fff",
    },
  },
  // Слой для отрисови активной точки
  {
    id: "gl-draw-vertex-active",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "true"],
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#000",
      "circle-opacity": 0.5,
      "circle-stroke-width": ["interpolate", ["exponential", 1.5], ["zoom"], 2, 3],
      "circle-stroke-color": "#fff",
    },
  },
  {
    id: "gl-draw-midpoints",
    type: "circle",
    filter: ["all", ["==", "meta", "midpoint"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#ee273f",
      "circle-opacity": 0.8,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#fff",
      "circle-stroke-opacity": 0.8,
    },
  },
];
