import React, { useCallback, useMemo, useState } from "react";
import { FormField } from "features/detail-panels/components";
import { TestButton } from "components";
import { Content } from "@megapolis/uikit/button/button.types";
import { Kind, Priority } from "components/ui/buttontest/button.types";
import { useDispatch } from "react-redux";
import { useStopFiltersSelector } from "store/selectors/stop-selectors";
import { stopActions } from "store/actions";
import { Toggle } from "@megapolis/uikit";
import { RouteVersion, StopFeatures } from "api";
import { PavilionTypesFilter } from "features/filter-element";
import * as Markup from "./stop-station-filter-content.style";
import { RouteFilterElement } from "../route-filter-element";

export const StopStationFilterContent = (props: { hide: () => void }) => {

  type Filter = {
    BalanceHolder?: string | undefined,
    RouteIds?: string | null,
    RouteFilterElements?: RouteVersion[],
    features: StopFeatures,
  };

  const initialFilter = useMemo<Filter>(() => ({
    BalanceHolder: undefined,
    RouteIds: null,
    RouteFilterElements: undefined,
    features: {
      alarmButton: false,
      forecastBoard: false,
      light: false,
      pedestrianPass: false,
      usbCharge: false,
      wasteBin: false,
      wheelChairBoarding: false,
      wiFi: false,
    },
  }), []);
  const dispatch = useDispatch();
  const filters = useStopFiltersSelector();
  const [filter, setFilter] = useState<Filter>(filters);
  const [featureFilter, setFeatureFilter] = useState<StopFeatures>(filters.features);
  const handleSubmit = useCallback(() => { dispatch(stopActions.setFilters(filter)); props.hide(); }, [dispatch, filter, props]);

  const handleReset = useCallback(() => { dispatch(stopActions.setFilters(initialFilter)); setFilter(initialFilter); setFeatureFilter(initialFilter.features); }, [dispatch, initialFilter]);

  const updateValue = useCallback(<P extends keyof Filter>(name: P, value: Filter[P] | null) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  }, []);

  const updateValueFeatures = useCallback(<P extends keyof StopFeatures>(name: P, value: StopFeatures[P] | null) => {
    setFeatureFilter(prev => ({ ...prev, [name]: value }));
  }, []);

  return (
    <>
      <Markup.Container>
        <FormField>
          <RouteFilterElement arr={filter.RouteFilterElements || []} update={(arr) => updateValue("RouteFilterElements", arr)} />
        </FormField>
        <FormField>
          <PavilionTypesFilter PavilionTypesFilter={filter.BalanceHolder} field={"BalanceHolder"} updateValue={updateValue}/>
        </FormField>
        <FormField>
          <Markup.Label>{"Состав элементов"}</Markup.Label>
          <Markup.InfoCards>

            <Toggle value={featureFilter.light || false} onChange={(e: any) => { updateValueFeatures("light", e); }}><Markup.LabelContainer>{"Освещение"}</Markup.LabelContainer> </Toggle>


            <Toggle value={featureFilter.pedestrianPass || false} onChange={(e: any) => { updateValueFeatures("pedestrianPass", e); }}><Markup.LabelContainer>{"Пешеходный переход"}</Markup.LabelContainer> </Toggle>


            <Toggle value={featureFilter.wasteBin || false} onChange={(e: any) => { updateValueFeatures("wasteBin", e); }}><Markup.LabelContainer>{"Урна"}</Markup.LabelContainer> </Toggle>


            <Toggle value={featureFilter.forecastBoard || false} onChange={(e: any) => { updateValueFeatures("forecastBoard", e); }}><Markup.LabelContainer>{"ТПП"}</Markup.LabelContainer> </Toggle>


            <Toggle value={featureFilter.alarmButton || false} onChange={(e: any) => { updateValueFeatures("alarmButton", e); }}><Markup.LabelContainer>{"Кнопка 112"}</Markup.LabelContainer> </Toggle>


            <Toggle value={featureFilter.wiFi || false} onChange={(e: any) => { updateValueFeatures("wiFi", e); }}><Markup.LabelContainer>{"WI-FI"}</Markup.LabelContainer> </Toggle>


            <Toggle value={featureFilter.usbCharge || false} onChange={(e: any) => { updateValueFeatures("usbCharge", e); }}><Markup.LabelContainer>{"USB-зарядка"}</Markup.LabelContainer> </Toggle>

          </Markup.InfoCards>
        </FormField>
      </Markup.Container>
      <Markup.Footer>
        <TestButton content={Content.Text} priority={Priority.Tertiary} text="Применить фильтр" onClick={handleSubmit} cyData="Filter" />
        <TestButton kind={Kind.Danger} content={Content.Text} priority={Priority.Tertiary} text="Сброс" onClick={handleReset} cyData="Filter" />
      </Markup.Footer>
    </>);
};