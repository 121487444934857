import React, { useMemo } from "react";
import { useRouteParams } from "shared/hooks";
import { KeyZoneDetails } from "./keyzone-details-panel";
import { NewKeyZoneStationPanel } from "./new-keyzone";

export const KeyZoneDetailsPanel = () => {
  const { id } = useRouteParams();
  const isNew = id.toString() === "new";
  const isEdit = id.toString().indexOf("edit") > -1;
  const isEditVersion = useMemo(() => id.toString().indexOf("_version") > -1, [id]);

  if (isEditVersion) return <NewKeyZoneStationPanel isNew={false} changeVersion={isEditVersion} />;
  if (isNew) return <NewKeyZoneStationPanel />;
  if (isEdit) return <NewKeyZoneStationPanel isNew={!isEdit}/>;
  return <KeyZoneDetails />;
};