import styled from "styled-components/macro";
import { ifProp } from "styled-tools";
import { ArrowDown16BlueIcon, UnionBlueIcon } from "assets/icons";

export const ArrowIcon = styled(ArrowDown16BlueIcon)<{ rotated: boolean }>`
  transform: ${props => props.rotated ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s ease;
`;

export const UnionIcon = styled(UnionBlueIcon)`
margin-right: 10px;
`;

export const VersionBar = styled.div`
display: flex;
justify-content: space-between;
`;

export const ControlsVersions = styled.div`
display: flex;
align-items: center;
justify-content: space-evenly;
align-content: space-between;
flex-wrap: nowrap;
flex-direction: row;
}
`;

export const ActualVersion = styled.span`
color: rgb(2, 123, 255);;
`;