import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { scheduleActions, stopActions } from "store/actions";
import { useRoute } from "react-router5";
import { PAGE_ROUTES } from "../../../../shared/definitions";

export const useGetScheduleItemsByVersion = () => {
  const dispatch = useDispatch();
  const {
    route: {
      name,
      params: { id },
    },
  } = useRoute();

  const { scheduleByVersionId } = useSelector((state: RootState) => ({
    scheduleByVersionId: state.schedule.scheduleByVersionId,
  }));

  useEffect(() => {
    if ([PAGE_ROUTES.SCHEDULE, PAGE_ROUTES.SCHEDULES].includes(name) && id) {
      if (!scheduleByVersionId) {
        dispatch(scheduleActions.getScheduleByVersionId(id));
      } else if (!scheduleByVersionId[id])
        dispatch(scheduleActions.getScheduleByVersionId(id));
    }
  }, [dispatch, name, scheduleByVersionId, id]);

  return {
    item: scheduleByVersionId?.[id],
    versionId: id,
  };
};
