import React, { memo, useCallback, useEffect, useState } from "react";
import { InfoCard, Box } from "components/common";
import ScheduleTable from "features/table/table-container";
import { getScheduleStopTimesRequest, ScheduleStopTimesResponce } from "api";
import { useRoute } from "react-router5";
import { Modal, useModal } from "features/modal";
import { useGetScheduleInfoCardData, useGetScheduleStopTimeData } from "./schedule-details-info-tab.data";
import { DetailsBlock } from "../../../components";
import { BLockHeader, ArrowIcon, ToggleContainer, InfoCards } from "./schedule-details-info-tab.styles";
import { ScheduleStopTimesModal } from "./schedule-details-info-tab.modal";

const Collapsible = (props: any) => {
  const [open, setOPen] = useState( props.isOpen || false);

  const toggle = () => {
    setOPen(!open);
  };

  return (<div style={{ marginTop: "1%" }}>
    <BLockHeader>
      <h6 onClick={toggle}>{props.label}<ArrowIcon rotated={true} /></h6>
      
    </BLockHeader>
    {open &&
        <ToggleContainer>{props.children}</ToggleContainer>
    }
  </div>

  );
};

const ScheduleDetailsInfo = () => {
  const { infoCards } = useGetScheduleInfoCardData();
  const [tabledata, setTableData] = useState<Exclude<ScheduleStopTimesResponce, "stopTimes">[]>([]);
  const { isShown, toggle } = useModal();
  const [selected, setSelected] = useState<ScheduleStopTimesResponce | undefined>(undefined);


  const onClick = useCallback((el: any) => {
    setSelected(el);
    toggle();
  }, [toggle]);

  const { columns } = useGetScheduleStopTimeData(onClick);
  const {
    route: {
      params: { id },
    },
  } = useRoute();

  useEffect(() => {
    const fetchData = async () => {
      const payload = await getScheduleStopTimesRequest({
        scheduleVersionId: id,
      });
      setTableData(payload);
    };
    // eslint-disable-next-line no-console
    fetchData().catch(console.error);

    return () => {
      setTableData([]);
    };
  }, [id]);



  return (
    <Box direction="column" padding="0 16px">
      <DetailsBlock padding="0 8px" header="Общие сведения">
        <InfoCards>
          {infoCards.map((card, idx) => (
            <InfoCard key={`key_${idx}`} title={card.title}>
              {card.text}
            </InfoCard>
          ))}
        </InfoCards>
      </DetailsBlock>
      {/* <Divider margin="8px 0" /> */}
      <DetailsBlock padding="0 8px" header="Рейсы">
        {tabledata[0] &&
          tabledata.map((data, idx)=>(<Collapsible key={`col_${idx}`} isOpen={idx === 0} label={`Выпуск ${data.launch}`} >
            <ScheduleTable columns={columns} data={[data]} ></ScheduleTable>
          </Collapsible>))
          
        }
      </DetailsBlock>
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText={selected ? `Рейс ${selected.sequenceNumber}` : ""}
        modalContent={<ScheduleStopTimesModal tripStops={selected ? selected.stopTimes as any : []} />}
      />
    </Box>
  );
};

export const ScheduleDetailsInfoTab = memo(ScheduleDetailsInfo);
