import React from "react";
import { BusstIcon } from "assets/icons";
import styled from "styled-components/macro";
import { withNeutralColor } from "shared/utils";
import { switchProp } from "styled-tools";

export type StopStationCardIconProps = {
  isActual: boolean;
};

type IconWrapperProps = {
  isActive: boolean;
};

const IconWrapper = styled.span<IconWrapperProps>`
  & > svg,
  path:first-child {
    fill: ${switchProp("isActive", { false: withNeutralColor("l2") })};
  }
  & > svg {
    border-radius: 3px;
  }
`;

export const StopStationCardIcon = ({ isActual }: StopStationCardIconProps) => (
  <IconWrapper isActive={isActual}>
    <BusstIcon />
  </IconWrapper>
);
