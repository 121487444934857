/* eslint-disable @typescript-eslint/no-shadow */
import { Card } from "components/ui";
import React, { memo, ReactNode } from "react";
import Select, { ActionMeta, MultiValue } from "react-select";
import { DetailRouteIcon } from "../detail-route-icon";
import { SelectProps } from "../select";
import * as Markup from "./alternative-multi-seelect.styles";

export type Option = {
  label: string;
  value: string;
};

export type AlternativeMultiSelectProps = {
  value?: Option[] | null | undefined;
  hasError?: boolean | undefined;
  errorText?: string | undefined;
  cyData?: string | undefined;
  isLoading?: boolean | undefined;
  label?: ReactNode;
  custom?: ({ value, label }: any) => JSX.Element,
  isRequired?: boolean | undefined;
  onChange: (option: readonly Option[], actionMeta?: ActionMeta<Option>) => void
  options: Option[]
};

const createLabel = (label: ReactNode) => (typeof label === "string" ? <Markup.Label>{label}</Markup.Label> : label);

const AlternativeMultiSelectComponent = (props: AlternativeMultiSelectProps & { placeholder?: string }) => {
  const { cyData, onChange, isRequired = false, custom, isLoading = false, label, options, value, placeholder = "Введите значение" } = props;
  return (
    <Markup.Container>
      {label && <Markup.LabelContainer>{createLabel(label)}</Markup.LabelContainer>}
      <Select
        data-cypress={cyData}
        isMulti
        menuPosition="fixed"
        // styles={{ menuPortal: base => ({ ...base, zIndex: 9999, position: "absolute" }) }}
        isClearable
        isSearchable
        isLoading={isLoading}
        value={value}
        placeholder={<div>{placeholder}</div>}
        formatOptionLabel={custom}
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onChange={onChange}
        options={options}
      />
    </Markup.Container>
  );
};

export const AlternativeMultiSelect = memo(AlternativeMultiSelectComponent);
