import { DepotActions, DepotActionTypes } from "store/actions/depot-actions/depot-actions.types";
import { IInitialDepotState } from "./depot.types";
import { initialState } from "./depot.constants";

export const depotReducer = (state: IInitialDepotState = initialState, action: DepotActions) => {
  switch (action.type) {

    case DepotActionTypes.SET_DEPOT: {
      const { status, pageNum, data } = action.payload;
      const { items, totalCount } = data;

      const itemsDraft = [...(state[status]?.items ?? [])];
      itemsDraft[pageNum] = items;

      return {
        ...state,
        [status]: { totalCount, items: itemsDraft },
      };
    }

    case DepotActionTypes.SET_FILTERS: {
      const { payload } = action;
      return { ...state, depotFilters: payload };
    }

    case DepotActionTypes.SET_DEPOT_BY_VERSION_ID: {
      const { payload } = action;

      return {
        ...state,
        depotByVersionId: {
          ...state.depotByVersionId,
          [payload.versionId]: payload.data,
        },
      };
    }

    case DepotActionTypes.SET_DEPOT_ACTIVE_FILTER: {
      const { payload } = action;

      return {
        ...state,
        depotActiveFilter: payload,
      };
    }

    case DepotActionTypes.UPDATE_DEPOTS_FILES: {
      const { payload } = action;
      const { depotId, data } = payload;
      const details = Object.values(state.depotByVersionId);
      const depotsById = details.filter(stop => stop.depotId.toString() === depotId);
      const updated = depotsById.reduce(
        (accumulator, depot) => ({ ...accumulator, [depot.versionId.toString()]: { ...depot, files: data.files } }),
        {},
      );
     
      return {
        ...state,
        depotByVersionId: {
          ...state.depotByVersionId,
          ...updated,
        },
      };
    }

    case DepotActionTypes.SET_DEPOT_SEARCH_STRING: {
      const { payload } = action;

      return {
        ...state,
        depotSearchSubstring: payload,
      };
    }

    case DepotActionTypes.SET_DEPOT_ACTIVE_ITEM: {
      const { payload } = action;
      return {
        ...state,
        activeDepotId: payload,
      };
    }

    default:
      return state;
  }
};
