import { TabValues } from "../../../features";
import { IInitialKeyZoneState } from "./key-zone.types";

export const initialState: IInitialKeyZoneState = {
  keyZoneByVersionId: {},
  keyZoneActiveFilter: TabValues.ACTIVE,
  keyZoneActiveItem: "",
  keyZoneSearchSubstring: "",
  keyZoneMapActiveItem: "",
  keyZoneFilters: {
    ContractorIds: null,
    RouteIds: null,
    DepotIds: null,
  },
  activKeyZoneId: "",
  [TabValues.ACTIVE]: null,
  [TabValues.INACTIVE]: null,
};
