import React from "react";
import { KeyZone32Icon, TestAvatar32 } from "assets/icons";
import styled from "styled-components/macro";
import { withNeutralColor } from "shared/utils";
import { switchProp } from "styled-tools";

export type KeyZoneCardIconProps = {
  isActual: boolean;
};

type IconWrapperProps = {
  isActive: boolean;
};

const IconWrapper = styled.span<IconWrapperProps>`
  & > svg,
  path:first-child {
    fill: ${switchProp("isActive", { false: withNeutralColor("l2") })};
  }
  & > svg {
    border-radius: 3px;
  }
`;

export const KeyZoneCardIcon = ({ isActual }: KeyZoneCardIconProps) => (
  <IconWrapper isActive={isActual}>
    <KeyZone32Icon/>
  </IconWrapper>
);

