import React, { useCallback, useEffect, useRef, useState } from "react";
import { PAGE_ROUTES } from "shared/definitions";
import { useRoute, useRouter } from "react-router5";
import { commonActions, stopActions } from "store/actions";
import { Tab } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { useMap } from "components/common/main-map";
import { MapLayerNames } from "components/common/main-map/utils/consts/map.constants";
import { MapUtilsForLayers } from "components/common/main-map/utils/map.utils";
import { ListPanel, ListPanelFilterTabs, ListPanelHeader, ListPanelHeaderWrapper } from "../components";
import { TabValues } from "../list-panels.constants";
import {
  StopStationCardListPagination,
} from "../../card-lists/stop-station-card-list/stop-station-card-list-pagination";
import { StopFilters } from "../../../store/reducers/stop";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

export const StopStationListPanel = () => {
  const dispatch = useDispatch();
  const { map } = useMap();
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  const router = useRouter();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { showDetails } = useLayoutDesignState();
  const { route: { params: { id: routeId } = {} } } = useRoute();
  const { stopActiveFilter: activeFilter, stopActiveItem } = useSelector((state: RootState) => ({
    stopActiveFilter: state.stop.stopActiveFilter,
    stopActiveItem: state.stop.stopActiveItem,
  }));
  const { setVisibility } = MapUtilsForLayers();

  const { filterStatus } = useSelector((state: RootState) => ({
    filterStatus: state.common.filterStatus,
  }));
  const toggle = useCallback(() => {
    dispatch(commonActions.setFilterStatus(!filterStatus));
  }, [filterStatus]);

  useEffect(() => {
    if (!routeId && stopActiveItem) {
      router.navigate(PAGE_ROUTES.STOP_STATION, { id: stopActiveItem });
    }

  }, [routeId, router, stopActiveItem]);

  useEffect(() => {
    const waiting = () => {
      if (map?.isStyleLoaded()) {
        if (map) setVisibility(map, true, MapLayerNames.ACTUAL_STOP_LAYER_ID);
        if (map) setVisibility(map, true, MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID);
      } else {
        if (timer.current !== null) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(waiting, 100);
      }
    };
    waiting();
    return () => {
      if (map?.isStyleLoaded()) {
        if (map) setVisibility(map, false, MapLayerNames.ACTUAL_STOP_LAYER_ID);
        if (map) setVisibility(map, false, MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID);
        if (timer.current !== null) {
          clearTimeout(timer.current);
        }
      }

    };

  }, [map]);

  const handleItemClick = useCallback(
    (id: string) => {
      dispatch(stopActions.setActiveItem(id));
      router.navigate(PAGE_ROUTES.STOP_STATION, { id });
    },
    [router, dispatch],
  );

  const handleAddClick = useCallback(() => {
    router.navigate(PAGE_ROUTES.STOP_STATION, { id: "new" });
    // showDetails();
  }, [router, showDetails]);

  const handleChangeFilter = useCallback((filter: string) => {
    dispatch(stopActions.setActiveFilter(filter as StopFilters));
  }, [dispatch]);

  return (
    <ListPanel
      headerSlot={
        <ListPanelHeaderWrapper>
          {/* <FilterWindow headerText="Остановки" isShown={showFilter} hide={toggle} modalContent={<StopStationFilterContent hide={toggle} />} /> */}
          <ListPanelHeader requestPath="StopStation" title="Остановки" onAddClick={handleAddClick} onFilterClick={toggle} />
        </ListPanelHeaderWrapper>
      }
      filterSlot={
        <ListPanelFilterTabs activeFilterTab={activeFilter} onChange={handleChangeFilter}>
          <Tab value={TabValues.ACTIVE}>Активные</Tab>
          <Tab value={TabValues.INACTIVE}>Неактивные</Tab>
        </ListPanelFilterTabs>
      }
      listSlot={
        <StopStationCardListPagination
          activeId={stopActiveItem}
          onClick={handleItemClick}
        />
      }
    />
  );
};
