import { SourceAction, SourceActionType } from "../../actions";
import { TabSourceState } from "./source.types";
import { initialState } from "./source.constants";

export const tabSourceReducer = (state: TabSourceState = initialState, action: SourceAction ) => {
  switch (action.type) {
    case SourceActionType.setTabSource: {
      const { id, items } = action.payload;
      return { ...state, [id]: items };
    }
    default: {
      return state;
    }
  }
};
