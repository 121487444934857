import React, { memo } from "react";
import { IconButton } from "components/common";
import { Filter24BlueIcon, Add24BlueIcon, Search24BlueIcon } from "assets/icons";
import * as Markup from "./list-panel-header-main.styles";

export type ListPanelHeaderProps = {
  title: string;
  onSearchClick?: React.MouseEventHandler<HTMLButtonElement>;
  onFilterClick?: () => void;
  onAddClick?: () => void;
};

const ListPanelHeaderMainComponent = (props: ListPanelHeaderProps) => {
  const { title, onSearchClick, onAddClick, onFilterClick } = props;

  return (
    <>
      <Markup.Heading>{title}</Markup.Heading>
      <Markup.ButtonsList>
        {
          onSearchClick && (<IconButton data-cypress='search' content="icon" icon={<Search24BlueIcon />} onClick={onSearchClick} />)
        }{
          onFilterClick && (<IconButton data-cypress='filter' content="icon" icon={<Filter24BlueIcon />} onClick={onFilterClick} />)
        }{
          onAddClick && (<IconButton data-cypress='add' content="icon" icon={<Add24BlueIcon />} onClick={onAddClick} />)
        }
      </Markup.ButtonsList>
    </>
  );
};

export const ListPanelHeaderMain = memo(ListPanelHeaderMainComponent);
