import React, { useState, useCallback, useEffect } from "react";
import { DriverDetails as DriverDetailsType } from "models";
import { RouteTabListPagination } from "features/detail-tabs";
import { DetailsPanel } from "../../components";
import { DriverDetailsInfoTab } from "./tab-pages";
import { TAB_VALUES } from "./driver-details.constants";
import { DriverDetailsHeader, DriverDetailsTabs } from "./components";
import { useGetDriverItemsByVersion } from "../driver-details-panel.data";

export const DriverDetails = () => {
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const { driverDetails = {} as DriverDetailsType, versionId } = useGetDriverItemsByVersion();

  useEffect(() => {
    setActiveTab(TAB_VALUES.info);
    return () => {
      setActiveTab(TAB_VALUES.info);
    };
  }, [versionId]);

  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  return (
    <DetailsPanel
      headerSlot={<DriverDetailsHeader />}
      tabsSlot={<DriverDetailsTabs value={activeTab} onChange={handleChangeTab} />}>
      {activeTab === TAB_VALUES.info && <DriverDetailsInfoTab />}
      {activeTab === TAB_VALUES.routes && <RouteTabListPagination DriverIds={driverDetails ? driverDetails.driverId.toString() : ""} />}
      {/* {activeTab === TAB_VALUES.routes && <DriverDetailsRoutesTab />} */}
    </DetailsPanel>
  );
};
