import { isInteger } from "lodash";
import { ValidatorCreator } from "./validator.types";


export const isString = (value: any): value is string => typeof value === "string";


export const isNumber = (value: any): value is number => typeof value === "number";


export const isDate = (value: any): value is Date => value instanceof Date;

export const isNotNullable = (value: any) => value !== null && value !== undefined;


export const isEmptyString = (value: string) => value.trim().length === 0;


export const isFilledValue = <T>(value: T) => isNotNullable(value) && (isNumber(value) || isString(value) && !isEmptyString(value));


export const isMatches = (value: string, test: string | RegExp): boolean => {
  if (test instanceof RegExp) {
    return test.test(value);
  }
  return value === test;
};


export const isGreaterDate = (first: Date, second: Date): boolean => first > second;


export const isNotEmptyString: ValidatorCreator = (error: string) => (value: string) => {
  const hasError = !isString(value) || isEmptyString(value);

  return { hasError, error };
};

export const isFromToTM: ValidatorCreator = (error: string) => (value: any) => {
  const x = parseInt(value, 10);
  const hasError = !(x >= 0 && x <= 2147483647);

  return { hasError, error };
};

export const isCorrectStartDate: ValidatorCreator<[test: string]> = (error, test) => (value: string) => {
  const date1 = (new Date(value));
  const date2 = (new Date(test));
  if (date1.getDay() && date2.getDay()) {
    const greatDate = date1 < date2;
    if (greatDate) {
      return { hasError: false, error: null };
    }
    return { hasError: true, error };
  }
  return { hasError: false, error: null };
};

export const isCorrectEndDate: ValidatorCreator<[test: string]> = (error, test) => (value: string) => {
  if (value) {
    const date1 = (new Date(value));
    const date2 = (new Date(test));
    if (date1.getDay() && date2.getDay()) {
      const greatDate = date1 > date2;
      if (greatDate) {
        return { hasError: false, error: null };
      }
      return { hasError: true, error };
    }
  }
  return { hasError: false, error: null };
};

export const isMatchesTo: ValidatorCreator<[test: string | RegExp, optional?: boolean]> = (error, test, optional = false) => (value: string) => {
  if (optional && !isFilledValue(value)) {
    return { hasError: false, error: null };
  }
  const hasError = !isMatches(value, test);
  return { hasError, error };
};

export const isRequired: ValidatorCreator = (error: string) => (value: any) => {
  const hasError = !isNotNullable(value) || (isString(value) && isEmptyString(value));

  return { hasError, error };
};

export const isRequiredRouteForm: ValidatorCreator = (error: string, value: any) => (test: any) => {
  const hasError = !isNotNullable(value) || (isString(value) && isEmptyString(value));

  return { hasError, error };
};

export const isRequiredRouteKeyZonesEnough: ValidatorCreator = (error: string, value: any) => (test: any) => {
  if (value.length > 0) {
    const hasError = value.length < 2;
    return { hasError, error };
  }


  return { hasError: false, error: null };
};