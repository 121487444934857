import { VehicleAnotherFilters, VehicleFilters } from "store/reducers/vehicle";
import { VehicleDetails, VehicleRequestItem } from "../../../models";
import { TabValues } from "../../../features";

export enum VehicleActionType {
  SET_VEHICLES = "SET_VEHICLES",
  SET_VEHICLE_SEARCH_SUBSTRING = "SET_VEHICLE_SEARCH_SUBSTRING",
  SET_ACTIVE_VEHICLE = "SET_ACTIVE_VEHICLE",
  SET_FILTERS = "SET_FILTERS",
  UPDATE_VEHICLES_FILES = "UPDATE_VEHICLES_FILES",
  SET_INACTIVE_VEHICLE = "SET_INACTIVE_VEHICLE",
  SET_VEHICLE_BY_VEHICLE_ID = "SET_VEHICLE_BY_VEHICLE_ID",
  SET_VEHICLE_BY_VERSION_ID = "SET_VEHICLE_BY_VERSION_ID",
  SET_VEHICLE_ACTIVE_FILTER = "SET_VEHICLE_ACTIVE_FILTER",
  SET_NEW_VEHICLE_STATION_COORDS = "SET_NEW_VEHICLE_STATION_COORDS",
  SET_NEW_VEHICLE_STATION_DATA = "SET_NEW_VEHICLE_STATION_DATA",
  UPDATE_VEHICLE_BY_VERSION = "UPDATE_VEHICLE_BY_VERSION",
  SET_SELECTED_VEHICLE = "SET_SELECTED_VEHICLE",
  SET_VEHICLE_ISLOADING = "SET_VEHICLE_ISLOADING",
}

type Action<A extends VehicleActionType, P> = { type: A, payload: P };

export type SetVehicleByVersionIdAction = Action<VehicleActionType.SET_VEHICLE_BY_VERSION_ID, { versionId: string, data: VehicleDetails }>;
export type SetActiveVehicleAction = Action<VehicleActionType.SET_ACTIVE_VEHICLE, VehicleRequestItem>;
export type SetInactiveVehicleAction = Action<VehicleActionType.SET_INACTIVE_VEHICLE, VehicleRequestItem>;
export type SetVehicleActiveFilter = Action<VehicleActionType.SET_VEHICLE_ACTIVE_FILTER, TabValues>;
export type SetFilters = Action<VehicleActionType.SET_FILTERS, VehicleAnotherFilters>;
export type UpdateVehicleByVersionIdAction = Action<VehicleActionType.UPDATE_VEHICLE_BY_VERSION, { versionId: string, data: Partial<VehicleDetails> }>;
export type SetVehiclesAction = Action<VehicleActionType.SET_VEHICLES, { status: VehicleFilters; data: VehicleRequestItem }>;
export type SetVehicleSearchSubstring = Action<VehicleActionType.SET_VEHICLE_SEARCH_SUBSTRING, string>;
export type SetSelectedVehicleAction = Action<VehicleActionType.SET_SELECTED_VEHICLE, string>;
export type SetIsLoadingAction = Action<VehicleActionType.SET_VEHICLE_ISLOADING, boolean>;
export type UpdateVehiclesFilesAction = Action<VehicleActionType.UPDATE_VEHICLES_FILES, { vehicleId: string; data: Partial<VehicleDetails> }>;

export type VehicleActions =
  | SetVehicleByVersionIdAction
  | SetFilters
  | UpdateVehiclesFilesAction
  | SetActiveVehicleAction
  | SetInactiveVehicleAction
  | SetVehicleActiveFilter
  | UpdateVehicleByVersionIdAction
  | SetVehiclesAction
  | SetVehicleSearchSubstring
  | SetIsLoadingAction
  | SetSelectedVehicleAction;
