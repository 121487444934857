import React, { ReactNode, useCallback } from "react";
import * as api from "services";
import { AlternativeMultiSelect } from "components/common/alternative-multi-select";
import { ActionMeta } from "react-select";
import { ContractorTypeResponse, DefaultSelectProps } from "../select-with-source.types";
import { useSourceLoader } from "../use-source-loader";

export type Option = {
  label: string;
  value: string;
};

type SelectReact = {
  value?: string[] | null | undefined;
  hasError?: boolean | undefined;
  errorText?: string | undefined;
  cyData?: string | undefined;
  label?: ReactNode;
  isRequired?: boolean | undefined;
  onChange: (option: readonly Option[], actionMeta?: ActionMeta<Option>) => void
};

export const ContractorMultiSelectField = (props: SelectReact) => {
  const fetcher = useCallback(() => api.get<{ items: ContractorTypeResponse[] }>("Contractor/version", { pageSize: 100, isActive: true }), []);
  const mapper = useCallback((data: { items: ContractorTypeResponse[] }) => {
    const { items } = data;
    return items
      .map(item => ({ value: item.Id, label: item.shortName }))
      .filter(({ label }) => label);
  }, []);

  const { options, isLoading } = useSourceLoader("contractor", fetcher, mapper);


  return <AlternativeMultiSelect placeholder="Выберите перевозчика" isLoading={isLoading} options={isLoading ? [] : options} value={isLoading ? [] : options.filter(element => props.value?.includes(element.value.toString()))} label={props.label}  onChange={props.onChange} />;
};
