import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useStopActiveFilter,
  useStopFiltersSelector,
  useStopItemsSelector,
  useStopSubstringSearchSelector,
} from "store/selectors/stop-selectors";
import { PaginationCardList } from "../components/pagination-card-list";
import { getStopRequest, StopGetRequestOptions, StopResponse, StopVersionResponse } from "../../../api";
import { StopStationCard } from "../../cards";
import { TabValues } from "../../list-panels";
import { stopActions } from "../../../store/actions";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

const DEFAULT_PAGE_SIZE = 200;

export type StopStationCardListProps = {
  activeId?: string;
  onClick?: (id: string) => void;
};

const StopStationCardListComponent = (props: StopStationCardListProps) => {
  const { activeId, onClick } = props;
  const [pageNum] = useState(1);
  const filters = useStopFiltersSelector();
  const activeFilter = useStopActiveFilter();
  const { items, totalCount } = useStopItemsSelector(activeFilter);
  const searchString = useStopSubstringSearchSelector();
  const { showDetails } = useLayoutDesignState();
  const dispatch = useDispatch();

  const firstItem = items[0];
  const activeIndex = useMemo(() => items.findIndex((item) => `${item.id}_${item.versionId}` === String(activeId)), [items, activeId]);

  useEffect(() => {
    if (!activeId && firstItem && onClick) {
      const stopId = `${firstItem.id}_${firstItem.versionId}`;
      onClick(stopId);
    }
  }, [activeId, firstItem, onClick]);

  const handleClick = useCallback((id: string) => {
    if (onClick) {
      onClick(id);
      // showDetails();
    }
  }, [onClick, showDetails]);

  const params: StopGetRequestOptions = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
  }), [pageNum]);

  const options: StopGetRequestOptions = useMemo(() => ({
    isActive: activeFilter === TabValues.ACTIVE,
    SearchSubstring: searchString,
    BalanceHolder: filters.BalanceHolder,
    RouteIds: filters.RouteFilterElements?.map(el => el.routeId).join(",") || "",
  }), [activeFilter, searchString, filters]);

  const itemRender = useCallback((item:  StopResponse) => {
    const { versionId, name, address, id } = item;
    const stopId = `${id}_${versionId}`;

    return <StopStationCard
      id={stopId}
      stationId={id.toString()}
      stationName={name}
      address={address}
      isActive={activeId === stopId}
      onClick={handleClick}
      routeList={[]}
      isActual={activeFilter === TabValues.ACTIVE}
    />;
  }, [activeId, handleClick, activeFilter]);

  const setItems = useCallback((response: StopVersionResponse, pageParams?: StopGetRequestOptions) => {
    dispatch(stopActions.setStops({
      data: response,
      status: activeFilter,
      pageNum: pageParams?.pageNum || -1,
    }));
  }, [dispatch, activeFilter]);

  return (
    <PaginationCardList
      fetcher={getStopRequest}
      items={items}
      itemRender={itemRender}
      options={options}
      params={params}
      setItems={setItems}
      totalCount={totalCount}
      scrollTo={activeIndex}
    />
  );
};
export const StopStationCardListPagination = memo(StopStationCardListComponent);
