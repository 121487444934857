/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useCallback, useEffect, useRef, useState } from "react";
import { toastError, toastSuccess } from "components";
import { ErrorHandler } from "shared/helpers/error-handler";
import { addDepotRequest, deleteFileRequest, DepotCreateRequestOptions, editDepotRequest, getDepotFile, getDepotRequest, getDepotVersionRequest } from "api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { DepotVersion } from "models";
import { useStopCurrentPage } from "store/selectors/stop-selectors";
import { depotActions } from "store/actions";
import { PAGE_ROUTES } from "shared/definitions";
import router from "router";
import { useFileLoadLogic } from "features/file-load-logic";
import { useGetDepotItemsByVersion } from "../depot-details-panel.data";

export type NewDepotForm = {
  name: string,
  address: string,
  angle: number,
  lat: number,
  lng: number,
  transportType: string;
  files: any[];
  contractor: {
    Id: number
    fullName?: string
    shortName: string
  };
  startDate: string | null,
  endDate: string | null,
};

export type SetValue = (name: keyof NewDepotForm, value: NewDepotForm[keyof NewDepotForm]) => void;

export type EditDepotForm = {
  versionId?: number,
  depotId: number,
} & NewDepotForm;

const initialForm: NewDepotForm = {
  name: "",
  address: "",
  angle: 3.33,
  lat: 0,
  lng: 0,
  transportType: "", // Тип транспорта
  contractor: { shortName: "", Id: 0 },
  startDate: null,
  endDate: null,
  files: [],
};

const detailsEditForm = (details: any, files: any): DepotCreateRequestOptions => ({ ...details, files });

const detailsToForm = (details: any): EditDepotForm => {
  const { name, address, angle, lat, lng, startDate, endDate, versionId, depotId, transportType, contractor, files } = details || {};

  return { name, address, angle, lat, lng, startDate, endDate: endDate || null, versionId, depotId, transportType, contractor, files };
};

const formToRequest = (details: NewDepotForm): any => {
  const { name, address, startDate, endDate, transportType, contractor } = details || {};

  return { DepotId: null, versionId: null, name, address, startDate, endDate: endDate || null, transportType, contractor: { Id: contractor.Id, shortName: contractor.shortName } };
};




export const useNewDepotLogic = (isNew: boolean, setValue?: SetValue) => {
  const { depotDetails } = useGetDepotItemsByVersion();
  const [form, setForm] = useState(isNew ? initialForm : detailsToForm(depotDetails));
  const { createMetaDataArr } = useFileLoadLogic();
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [arrFiles, setArrFiles] = useState<File[]>([]);
  const [arrDepotFiles, setArrDepotFiles] = useState<{
    id: string,
    name: string,
    sizeInBytes: number,
    mimeType: string,
  }[]>([]);
  const controllerRef = useRef<AbortController | null>();

  const dispatch = useDispatch();
  const currentPage = useStopCurrentPage();

  const { newStopStationCoords, newStopStationData, activeFilter } = useSelector((state: RootState) => ({
    newStopStationCoords: state.stop.newStopStationCoords,
    newStopStationData: state.stop.newStopStationData,
    activeFilter: state.depot.depotActiveFilter,
  }));

  const fetchFiles = useCallback(async (controller: AbortController, depot: DepotVersion) => {
    if (!depot?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(depot.files.map(async fileElem => {
        const payload = await getDepotFile(depot.depotId, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setArrDepotFiles(depot?.files);
    setArrFiles(fileArr);
  }, []);

  const handleFiles = useCallback(async (arrfiles: File[]) => {
    const compare = arrfiles?.filter(({ name: id1 }) => !arrDepotFiles.some(({ name: id2 }) => id2 === id1));
    if (compare.length > 0) {
      const fileArr = await createMetaDataArr(compare);
      setArrDepotFiles((arr) => fileArr.concat(arr || []));
    }
    setArrFiles(arrfiles);
    setValue && setValue("files", arrfiles);
  }, [setValue, arrDepotFiles]);

  const handleDeleteFiles = useCallback(async (arrfiles: string) => {
    if (!arrDepotFiles) return;
    let Uid;
    // eslint-disable-next-line array-callback-return, consistent-return
    const myArray = arrDepotFiles.filter((obj) => {
      if (obj.name !== arrfiles) {
        return obj;
      }
      Uid = obj.id;
    });
    setArrDepotFiles(myArray);
    try {
      await deleteFileRequest(Uid);
    } catch (e: any) {
      toastError({ title: "Ошибка", message: e });
    }
  }, [setValue, arrDepotFiles]);

  const handleClearFiles = useCallback(() => {
    if (arrDepotFiles && arrDepotFiles.length > 0) {
      try {
        Promise.all(arrDepotFiles.map(async fileElem => {
          try {
            await deleteFileRequest(fileElem.id);
          } catch (e: any) {
            toastError({ title: "Ошибка", message: e });
          }

        }));
      } catch (error: any) {
        toastError({ title: "Ошибка", message: error });
      }
    }
  }, [deleteFileRequest, arrDepotFiles]);

  useEffect(() => {
    if (newStopStationCoords === undefined) return;
    setValue && setValue("lat", newStopStationCoords.lat);
    setValue && setValue("lng", newStopStationCoords.lng);
    setForm((prevState: any) => ({
      ...prevState,
      lat: newStopStationCoords.lat,
      lng: newStopStationCoords.lng,
    }));
  }, [newStopStationCoords, setValue]);

  useEffect(() => {
    if (newStopStationData === undefined) return;
    setValue && setValue("address", newStopStationData.address);
    setForm((prevState: any) => ({
      ...prevState,
      address: newStopStationData.address,
      street: newStopStationData.street,
    }));
  }, [newStopStationData, setValue]);


  useEffect(() => {
    setForm(initialForm);
    if (depotDetails && !isNew) {
      setStartDate(depotDetails.startDate ? depotDetails.startDate : undefined);
      setEndDate(depotDetails.endDate ? depotDetails.endDate : undefined);
    }
    return () => {
      setForm(initialForm);
    };

  }, [depotDetails, isNew]);

  useEffect(() => {
    const controller = new AbortController();
    if (depotDetails && !isNew) {
      fetchFiles(controller, depotDetails);
    }
    return () => {
      controller.abort();
    };
  }, []);

  const clearUp = useCallback(() => {
    (Object.keys(initialForm) as Array<keyof typeof initialForm>).map(el => setValue && setValue(`${el}`, initialForm[`${el}`]));
    setStartDate(undefined);
    setEndDate(undefined);
  }, [setValue]);


  const handleExecQuery = useCallback(async (verId: number) => {
    try {
      const payload = await getDepotVersionRequest(verId.toString());
      dispatch(depotActions.getDepotByVersionId(verId.toString(), payload));
      dispatch(depotActions.updateDepotsFiles(payload.depotId.toString(), payload));
      const response = await getDepotRequest({ pageNum: currentPage, pageSize: 200 });
      dispatch(depotActions.setDepot({
        data: response,
        status: activeFilter,
        pageNum: currentPage,
      }));
      router.navigate(PAGE_ROUTES.DEPOT, { id: `${verId}` });
    } catch (error) {
      router.navigate(PAGE_ROUTES.DEPOTS);
    }
  }, [dispatch, activeFilter, currentPage]);

  const createDepot = useCallback(async (values: NewDepotForm) => {
    setIsLoading(true);
    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(values.files || []);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }
    addDepotRequest({ ...formToRequest(values), files: arrDepotFiles }).then((el) => {
      setIsLoading(false);
      toastSuccess({ title: "Успешно", message: `Добавлен ${values.name}` });
      handleExecQuery(el.versionId);
    })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));

  }, [createMetaDataArr, handleExecQuery, arrDepotFiles]);

  const editVersionDepot = useCallback(async (values: EditDepotForm) => {
    setIsLoading(true);
    // let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    // try {
    //   fileArr = await createMetaDataArr(values.files || []);
    // } catch (error) {
    //   toastError({ title: "Error", message: "Не удалось загрузить файлы" });
    //   setIsLoading(false);
    // }

    await editDepotRequest(detailsEditForm(values, arrDepotFiles)).then(() => {
      setIsLoading(false);
      toastSuccess({ title: "Успешно", message: `${isNew ? "Добавлен" : "Обновлен"} ${values.name}` });
      handleExecQuery(depotDetails.versionId);
    })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));
  }, [createMetaDataArr, isNew, handleExecQuery, depotDetails, arrDepotFiles]);


  const editDepot = useCallback(async (values: EditDepotForm) => {
    setIsLoading(true);
    // eslint-disable-next-line no-param-reassign
    delete values.versionId;
    

    // await editDepotRequest({ ...depotDetails, endDate: changeDay(depotDetails.startDate, values.startDate) }).catch((error: any) => toastError({ title: "Error", message: error.response.data.toString() }));
    await addDepotRequest({ ...values, files: arrDepotFiles }).then(({ versionId }) => {
      setIsLoading(false);
      toastSuccess({ title: "Успешно", message: `${isNew ? "Добавлен" : "Обновлен"} ${values.name}` });
      handleExecQuery(depotDetails.versionId);
    })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));

  }, [createMetaDataArr, isNew, handleExecQuery, arrDepotFiles]);




  return {
    form,
    clearUp,
    createDepot,
    editDepot,
    editVersionDepot,
    handleDeleteFiles,
    handleClearFiles,
    handleFiles,
    files: arrFiles,
    startDate,
    endDate,
    setForm,
    isLoading,
  };
};
