import { BlockHeader, Divider, InfoCard, StackHeader } from "components/common";
import { DetailsPanel, DetailsPanelHeader, DetailsPanelTabs } from "features/detail-panels/components";
import React, { PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Tab } from "..";
import * as Markup from "./skeleton-detail-card.styles";

export type SkeletonDetailCardProps = {
  /** количество скеллетонов */
  count?: number;

};

export const SkeletonDetailCard = (props: SkeletonDetailCardProps) => {
  const { count = 6 } = props;
  return <DetailsPanel
    headerSlot={<StackHeader>
      <Markup.Container>
        <Markup.Icon><Skeleton height={"2rem"} /></Markup.Icon>
        <Markup.Content>
          <Markup.Text><Skeleton style={{ width: "70%" }} /></Markup.Text>
          <Markup.Caption>
            <Skeleton style={{ width: "40%" }} />
          </Markup.Caption>
        </Markup.Content>
      </Markup.Container>
    </StackHeader>}
    tabsSlot={<Markup.Container></Markup.Container>}>
    <Divider margin="10px 0" />
    <Markup.ContentContainer>
      <Markup.InfoCards>
        {[...Array(count)].map((e, i) => <Markup.SkeletonContainer key={i}>
          <Markup.Title><Skeleton style={{ width: "40%" }} /></Markup.Title>
          <Markup.ContentWrapper>
            <Markup.Content><Skeleton style={{ width: "70%" }} /></Markup.Content>
          </Markup.ContentWrapper>
        </Markup.SkeletonContainer>)}
      </Markup.InfoCards>
    </Markup.ContentContainer>
    <Markup.ContentContainer>
      <Divider></Divider>
      <Skeleton height={"3rem"} />
    </Markup.ContentContainer>
  </DetailsPanel>;
};
