import React from "react";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";
import { ReactComponent as ArrowLeft24Icon } from "./arrow-left-24.svg";
import { ReactComponent as ArrowRight24Icon } from "./arrow-right-24.svg";
import * as Markup from "./date-picker.styles";

export interface DatePickerHeaderProps {
  date: Date;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  onMonthClick: () => void;
}

const DatePickerHeaderComponent = (props: DatePickerHeaderProps) => {
  const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, onMonthClick } = props;
  return (
    <Markup.Header>
      <Markup.Prev onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <ArrowLeft24Icon />
      </Markup.Prev>
      <Markup.Next onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <ArrowRight24Icon />
      </Markup.Next>
      <Markup.Title onClick={onMonthClick}>{format(date, "LLLL", { locale: ru })}</Markup.Title>
    </Markup.Header>
  );
};

export const DatePickerHeader = React.memo(DatePickerHeaderComponent);
