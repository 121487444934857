import React, { memo } from "react";
import { Tab } from "components/ui";
import { DetailsPanelTabs } from "../../components";
import { TAB_VALUES } from "../contractor-details-panel.constants";

export type TabsProps = {
  value: string;
  onChange: (val: string) => void;
};

const ContractorPanelTabsComponent = (props: TabsProps) => {
  const { value, onChange } = props;
  return (
    <DetailsPanelTabs value={value} onChange={onChange}>
      <Tab value={TAB_VALUES.info}>Справка</Tab>
      {/* <Tab value={TAB_VALUES.vehicles}>ТС</Tab>
      <Tab value={TAB_VALUES.drivers}>Водители</Tab> */}
      <Tab value={TAB_VALUES.route}>Маршруты</Tab>
      <Tab value={TAB_VALUES.depot}>Парки</Tab>
      <Tab value={TAB_VALUES.drivers}>Водители</Tab>
    </DetailsPanelTabs>
  );
};

export const ContractorPanelTabs = memo(ContractorPanelTabsComponent);
