import React, { memo } from "react";
import * as Markup from "./list-panel-filter-tabs.styles";

export type ListPanelFilterTabsProps = {
  activeFilterTab: string;
  onChange: (value: string) => void;
  children: React.ReactNode;
};

export const ListPanelFilterTabsComponent = (props: ListPanelFilterTabsProps) => {
  const { activeFilterTab, children, onChange } = props;

  return (
    <Markup.Container>
      <Markup.EnhancedTabs value={activeFilterTab} onChange={onChange}>
        {children}
      </Markup.EnhancedTabs>
    </Markup.Container>
  );
};

export const ListPanelFilterTabs = memo(ListPanelFilterTabsComponent);
