import React from "react";
import * as hooks from "./textarea.hooks";
import * as Markup from "./textarea.styles";
import { Props } from "./textarea.types";

const TextareaComponent = (props: Props, ref?: React.ForwardedRef<HTMLTextAreaElement>) => {
  const { handleChange, content } = hooks.useGetParams(props);

  return (
    <Markup.Container className={props.className} data-cypress={props["data-cypress"]}>
      <Markup.Textarea
        placeholder={props.placeholder}
        hasError={props.hasError}
        isResize={props.isResize}
        minHeight={props.minHeight}
        value={props.value}
        onChange={handleChange}
        ref={ref}
        disabled={props.isDisabled}
      />
      {props.maxLength && <Markup.After>{content}</Markup.After>}
    </Markup.Container>
  );
};

const TextareaWithRef = React.forwardRef(TextareaComponent);

export const Textarea = React.memo(TextareaWithRef);
