import { TabValues } from "../../../features";
import { IInitialScheduleState } from "./schedule.types";

export const initialState: IInitialScheduleState = {
  activeSchedule: {},
  inactiveSchedule: {},
  scheduleByVersionId: {},
  scheduleActiveFilter: TabValues.ACTIVE,
  scheduleActiveItem: "",
  searchSubstring: "",
  scheduleFilters: {
    BalanceHolder: null,
    RouteIds: null,
  },
  [TabValues.ALL]: null,
  [TabValues.ACTIVE]: null,
  [TabValues.INACTIVE]: null,
};
