export const TYPES = {
  COMMON: {
    SET_APPLICATION_ONLOADED_STATUS: "SET_APPLICATION_ONLOADED_STATUS",
    FLY_TO_POINT: "FLY_TO_POINT",

  },
  STOP: {
    SET_ACTIVE_STOP: "SET_ACTIVE_STOP",
    SET_INACTIVE_STOP: "SET_INACTIVE_STOP",
    SET_STOP_BY_STOP_ID: "SET_STOP_BY_STOP_ID",
    SET_STOP_BY_VERSION_ID: "SET_STOP_BY_VERSION_ID",
    SET_STOP_ACTIVE_FILTER: "SET_STOP_ACTIVE_FILTER",
    SET_STOP_SEARCH_STRING: "SET_STOP_SEARCH_STRING",
    SET_STOP_ACTIVE_ITEM: "SET_STOP_ACTIVE_ITEM",
    SET_NEW_STOP_STATION_COORDS: "SET_NEW_STOP_STATION_COORDS",
    SET_NEW_STOP_STATION_DATA: "SET_NEW_STOP_STATION_DATA",
  },
  CONTRACTOR: {
    SET_ACTIVE_CONTRACTOR: "SET_ACTIVE_CONTRACTOR",
    SET_INACTIVE_CONTRACTOR: "SET_INACTIVE_CONTRACTOR",
    SET_CONTRACTOR_BY_CONTRACTOR_ID: "SET_CONTRACTOR_BY_CONTRACTOR_ID",
    SET_CONTRACTOR_BY_VERSION_ID: "SET_CONTRACTOR_BY_VERSION_ID",
    SET_CONTRACTOR_ACTIVE_FILTER: "SET_CONTRACTOR_ACTIVE_FILTER",
    SET_CONTRACTOR_ACTIVE_ITEM: "SET_CONTRACTOR_ACTIVE_ITEM",
  },
  VEHICLE: {
    SET_ACTIVE_VEHICLE: "SET_ACTIVE_VEHICLE",
    SET_INACTIVE_VEHICLE: "SET_INACTIVE_VEHICLE",
    SET_VEHICLE_BY_VEHICLE_ID: "SET_VEHICLE_BY_VEHICLE_ID",
    SET_VEHICLE_BY_VERSION_ID: "SET_VEHICLE_BY_VERSION_ID",
    SET_VEHICLE_ACTIVE_FILTER: "SET_VEHICLE_ACTIVE_FILTER",
    SET_NEW_VEHICLE_STATION_COORDS: "SET_NEW_VEHICLE_STATION_COORDS",
    SET_NEW_VEHICLE_STATION_DATA: "SET_NEW_VEHICLE_STATION_DATA",
  },
  ROUTES: {
    SET_ALL_ROUTE: "SET_ALL_ROUTE",
    SET_ACTIVE_ROUTE: "SET_ACTIVE_ROUTE",
    SET_PLANNED_ROUTE: "SET_PLANNED_ROUTE",
    SET_ARCHIVED_ROUTE: "SET_ARCHIVED_ROUTE",
    SET_ROUTE_BY_ROUTE_ID: "SET_ROUTE_BY_ROUTE_ID",
    SET_ROUTE_BY_VERSION_ID: "SET_ROUTE_BY_VERSION_ID",
    SET_ROUTE_ACTIVE_FILTER: "SET_ROUTE_ACTIVE_FILTER",
    SET_NEW_ROUTE_STATION_COORDS: "SET_NEW_ROUTE_STATION_COORDS",
    SET_NEW_ROUTE_STATION_DATA: "SET_NEW_ROUTE_STATION_DATA",
  },
  DEPOT: {
    SET_ACTIVE_DEPOT: "SET_ACTIVE_DEPOT",
    SET_INACTIVE_DEPOT: "SET_INACTIVE_DEPOT",
    SET_DEPOT_BY_DEPOT_ID: "SET_DEPOT_BY_DEPOT_ID",
    SET_DEPOT_BY_VERSION_ID: "SET_DEPOT_BY_VERSION_ID",
    SET_DEPOT_ACTIVE_FILTER: "SET_DEPOT_ACTIVE_FILTER",
    SET_DEPOT_ACTIVE_ITEM: "SET_DEPOT_ACTIVE_ITEM",
  },
  SCHEDULE: {
    SET_SCHEDULE: "SET_SCHEDULE",
    SET_CURRENT_SCHEDULE: "SET_CURRENT_SCHEDULE",
  },
} as const;

export default TYPES;
