import React, { memo } from "react";
import { ButtonProps } from "@megapolis/uikit";
import * as Markup from "./icon-button.styles";

export type IconButtonProps = ButtonProps & {
  /** Иконка */
  icon: React.ReactNode;
  /** Кастомный className */
  className?: string;
  /** Обработчик нажатия */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const IconButtonComponent = (props: IconButtonProps) =>{
  const { icon, className, onClick, content, ...buttonProps } = props;

  return (
    <Markup.Button className={className} onClick={onClick}  {...buttonProps} >
      {icon}
    </Markup.Button>
  );

} ;
export const IconButton = memo(IconButtonComponent);
