import React from "react";
import { useTheme } from "styled-components";
import { Loader as ComponentLoader } from "../../../loader";
import { TPriority, Priority, TKind, Kind } from "../../button.types";

interface Props {
  /** Тип кнопки */
  priority?: TPriority;
  /** Стиль кнопки */
  kind?: TKind;
  /** Отключён */
  isDisabled?: boolean;
}

const useGetColor = (props: Props) => {
  const theme = useTheme();
  const { isDisabled, priority = Priority.Primary, kind = Kind.Info } = props;

  if (isDisabled) {
    return theme.colors.content.quarternary;
  }

  if (priority === Priority.Primary) {
    return theme.colors.white.basic;
  }

  switch (kind) {
    case Kind.Success:
      return theme.colors.green.basic;
    case Kind.Warning:
      return theme.colors.yellow.d2;
    case Kind.Danger:
      return theme.colors.red.basic;
    case Kind.Info:
    default:
      return theme.colors.blue.basic;
  }
};

export const Loader = (props: Props) => {
  const color = useGetColor(props);

  return <ComponentLoader size="24px" color={color} />;
};
