import { getVehicleCapacityTypesRequest } from "api";
import { Chips, ChipsItem } from "components/common";
import { VehicleClassTypeArrayString } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { labels } from "shared/constants";

export type FilterProps = {
  CapacityTypesFilter: string | undefined;
  updateValue: any;
  field: string
};

export const Filter = (props: FilterProps) => {

  const { CapacityTypesFilter, updateValue, field } = props;
  const [CapacityTypesChips, setCapacityTypesChips] = useState<{
    id: string;
    label: string;
  }[]>([]);
  const capacityAllExp = useMemo(()=> CapacityTypesFilter !== undefined ? CapacityTypesFilter?.length === CapacityTypesChips.map(e=>e.id).toString().length : false, [CapacityTypesFilter, CapacityTypesChips]);
  const [capacityTypeArray, setCapacityTypeArray] = useState<string[]>(CapacityTypesFilter ? CapacityTypesFilter.split(",") : []);
  const [capacityAll, setCapacityAll] = useState(capacityAllExp);

  const getCapacityTypes = useCallback(async () => {
    const capacityTypes = await getVehicleCapacityTypesRequest();
    const result = capacityTypes.map(item => ({ id: item.shortName, label: item.name }));
    setCapacityTypesChips(result);
    setCapacityAll(capacityAllExp);
  }, [capacityAllExp]);

  useEffect(() => {
    setCapacityAll(capacityAllExp);
  }, [capacityAllExp]);

  useEffect(() => {
    getCapacityTypes();
    return ()=> {
      setCapacityTypesChips([]);
      setCapacityTypeArray([]);
      setCapacityAll(false);
    };
  }, []);

  return (
    <Chips label={labels.vehicleClassType}>
      <ChipsItem isActive={capacityAll} onClick={() => {
        if (capacityAll) {
          updateValue(field, "");
          setCapacityTypeArray([]);
          setCapacityAll(false);
        } else {
          updateValue(field, CapacityTypesChips.map(e => e.id).toString());
          setCapacityTypeArray(CapacityTypesChips.map(e => e.id));
          setCapacityAll(true);
        }
      }} id={"all"} label={"Все"} />
      {CapacityTypesChips.map((chip) => (
        <ChipsItem
          key={chip.id}
          isActive={CapacityTypesFilter !== undefined ? CapacityTypesFilter?.split(",").findIndex(el => el === chip.id) !== -1 : false}
          {...chip}
          onClick={({ id }) => {
            const idx = capacityTypeArray.indexOf(id);
            if (idx === -1) {
              setCapacityTypeArray(oldArray => {
                const arr = [...oldArray, id];
                updateValue(field, arr.toString());
                return arr;

              });
            } else {
              setCapacityTypeArray(oldArray => {
                const filtered = oldArray.filter((element) => element !== id);
                updateValue(field, filtered.toString());
                return filtered;
              });
            }
          }}
        />
      ))}
    </Chips>
  );
};

export const CapacityTypesFilter = React.memo(Filter);