import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "react-router5";
import { PAGE_ROUTES } from "../../../../shared/definitions";
import { keyZoneActions } from "../../../../store/actions";
import { getKeyZoneVersionsDates } from "../../../../api";
import { getLastItem } from "../../../../shared/utils/array";
import { MapLayerNames } from "../utils/consts/map.constants";
import { useMap } from "../map.context";

export const useOnZoneClick = () => {
  const { map } = useMap();
  const router = useRouter();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onKeyZone = useCallback(async (e) => {
    if (!e.features) return;

    const keyZoneId = JSON.parse(e.features[0]?.properties?.keyZoneId);

    if (keyZoneId) {
      const response = await getKeyZoneVersionsDates(keyZoneId);
      const actualVersion = getLastItem(response);

      if (actualVersion) {
        const { versionId } = actualVersion;
        dispatch(keyZoneActions.setMapActiveItem(versionId?.toString() || ""));
        router.navigate(PAGE_ROUTES.KEY_ZONE, { id: versionId?.toString() });
      }
    }
  }, [dispatch, router]);

  useEffect(() => {
    if (map) {
      map.on("click", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, onKeyZone);
    }

    return () => {
      if (map) {
        map.off("click", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, onKeyZone);
      }
    };
  }, [map, onKeyZone]);
};
