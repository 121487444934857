import React, { memo, ReactNode } from "react";
import { Heading } from "components/ui";
import { BlockHeader, Box, BoxProps } from "components/common";

export type DetailsBlockProps = {
  children: ReactNode;
  className?: string;
  header?: ReactNode;
  headerControl?: ReactNode;
} & BoxProps;

const DetailsBlockComponent = (props: DetailsBlockProps) => {
  const { header, headerControl, children, className, ...boxProps } = props;

  return (
    <Box direction="column" className={className} {...boxProps}>
      <BlockHeader left={<Heading.H5>{header}</Heading.H5>} right={headerControl} />
      {children}
    </Box>
  );
};

export const DetailsBlock = memo(DetailsBlockComponent);
