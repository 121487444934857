import React, { useCallback, useState } from "react";
import { isEmptyObject } from "shared/utils";
import { SkeletonDetailCard } from "components/ui/skeleton-detail-card";
import { useOnStopClick } from "components/common/main-map/actual-stops/use-on-stop-click";
import { DetailsPanel } from "../../components";
import { TAB_VALUES } from "./schedule-details.constants";
import { ScheduleDetailsPanelHeader, ScheduleDetailsPanelTabs } from "./components";
import { StopDetailsRouteTab, ScheduleDetailsInfoTab } from "./tab-pages";
import { useGetScheduleItemsByVersion } from "./schedule-details.data";

export const ScheduleDetails = () => {
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const { item, versionId } = useGetScheduleItemsByVersion();

  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  // if (!stopId?.length || !versionId?.length || !item || isEmptyObject(item)) return <SkeletonDetailCard />;

  return (
    <DetailsPanel
      headerSlot={<ScheduleDetailsPanelHeader />}
      tabsSlot={<ScheduleDetailsPanelTabs value={activeTab} onChange={handleChangeTab} />}>
      {activeTab === TAB_VALUES.info && <ScheduleDetailsInfoTab />}
      {/* {activeTab === TAB_VALUES.route && <StopDetailsRouteTab StopIds={item.id ? item.id.toString() : undefined} />}
      {activeTab === TAB_VALUES.schedule && <></>} */}
    </DetailsPanel>
  );
};
