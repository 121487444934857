import { IInitialVehicleState } from "./vehicle.types";
import { initialState } from "./vehicle.constants";
import { VehicleActions, VehicleActionType } from "../../actions/vehicle-actions/vehicle-actions.types";
import { findIndexInArrayOfItemArray, updateItemInArrayByIndex } from "../../../shared/utils/array";

export const vehicleReducer = (state: IInitialVehicleState = initialState, action: VehicleActions) => {
  switch (action.type) {
    case VehicleActionType.SET_ACTIVE_VEHICLE: {
      const { payload } = action;
      return {
        ...state,
        activeVehicle: payload,
      };
    }
    case VehicleActionType.SET_INACTIVE_VEHICLE: {
      const { payload } = action;
      return {
        ...state,
        inactiveVehicle: payload,
      };
    }

    case VehicleActionType.SET_VEHICLE_BY_VERSION_ID: {
      const { payload } = action;
      return {
        ...state,
        vehicleByVersionId: {
          ...state.vehicleByVersionId,
          [payload.versionId]: payload.data,
        },
      };
    }
    case VehicleActionType.SET_VEHICLE_ACTIVE_FILTER: {
      const { payload } = action;
      return {
        ...state,
        vehicleActiveFilter: payload,
      };
    }
    case VehicleActionType.UPDATE_VEHICLE_BY_VERSION: {
      const { payload } = action;
      const { versionId } = payload;
      const currentItemsKey = state.vehicleActiveFilter;
      const details = state.vehicleByVersionId[versionId] ?? {};
      const updatedDetails = { ...details, ...payload.data };

      const items = state[currentItemsKey]?.items ?? [];
      const [page, index] = findIndexInArrayOfItemArray(items, (item) => item.versionId === Number(versionId));
      items[page] = updateItemInArrayByIndex(items[page], index, updatedDetails);
      const updatedItems = { ...state[currentItemsKey], items };

      return {
        ...state,
        [currentItemsKey]: updatedItems,
        vehicleByVersionId: {
          ...state.vehicleByVersionId,
          [versionId]: updatedDetails,
        },
      };
    }

    case VehicleActionType.SET_VEHICLES: {
      const { payload } = action;
      const prevData = state[payload.status]?.items ?? [];
      prevData[payload.data.pageIndex || 0] =  payload.data.items;

      return { ...state, [payload.status]: { ...payload.data, items: prevData } };
    }

    case VehicleActionType.SET_FILTERS: {
      const { payload } = action;

      return { ...state, vehicleFilters: payload };
    }

    case VehicleActionType.SET_VEHICLE_SEARCH_SUBSTRING: {
      const { payload } = action;

      return { ...state, vehicleSearchSubstring: payload };
    }

    case VehicleActionType.SET_SELECTED_VEHICLE: {
      const { payload } = action;

      return { ...state, selectedVehicle: payload };
    }

    case VehicleActionType.SET_VEHICLE_ISLOADING: {
      const { payload } = action;

      return { ...state, isLoading: payload };
    }

    case VehicleActionType.UPDATE_VEHICLES_FILES: {
      const { payload } = action;
      const { vehicleId, data } = payload;
      const details = Object.values(state.vehicleByVersionId);
      const vehiclesById = details.filter(stop => stop.id.toString() === vehicleId);
      const updated = vehiclesById.reduce(
        (accumulator, vehicle) => ({ ...accumulator, [vehicle.versionId.toString()]: { ...vehicle, files: data.files } }),
        {},
      );
     
      return {
        ...state,
        vehicleByVersionId: {
          ...state.vehicleByVersionId,
          ...updated,
        },
      };
    }

    default:
      return state;
  }
};
