import { Chips, ChipsItem } from "components/common";
import { routeTypeChipLabel } from "features/detail-panels/route-details-panel/mocks";
import React, { useEffect, useMemo, useState } from "react";
import { labels } from "shared/constants";

export type FilterProps = {
  RouteTypes: string | undefined | null;
  updateValue: any;
  field: string;
};

export const Filter = (props: FilterProps) => {

  const { RouteTypes, updateValue, field } = props;
   

  const [routeTypeArray, setRouteTypeArray] = useState<string[]>(RouteTypes ? RouteTypes.split(",") : []);
  const routeAllExp = useMemo(()=> RouteTypes !== undefined ? RouteTypes?.length === routeTypeChipLabel.map(e=>e.id).toString().length : false, [RouteTypes, routeTypeChipLabel]);
  const [routeAll, setRouteAll] = useState(routeAllExp);

  useEffect(() => {
    setRouteAll(routeAllExp);
  }, [routeAllExp]);

  return (
    <Chips label={labels.routeType}>
      <ChipsItem isActive={routeAll} onClick={() => {
        if (routeAll) {
          updateValue(field, "");
          setRouteTypeArray([]);
          setRouteAll(false);
        } else {
          updateValue(field, routeTypeChipLabel.map(e => e.id).toString());
          setRouteTypeArray(routeTypeChipLabel.map(e => e.id));
          setRouteAll(true);
        }

      }} id={"all"} label={"Все"} />
      {routeTypeChipLabel.map((chip) => (
        <ChipsItem
          key={chip.id}
          isActive={RouteTypes !== undefined ? RouteTypes?.split(",").findIndex(el => el === chip.id) !== -1 : false}
          {...chip}
          onClick={({ id }) => {
            const idx = routeTypeArray.indexOf(id);
            if (idx === -1) {
              setRouteTypeArray(oldArray => {
                const arr = [...oldArray, id];
                updateValue(field, arr.toString());
                return arr;

              });
            } else {
              setRouteTypeArray(oldArray => {
                const filtered = oldArray.filter((element) => element !== id);
                updateValue(field, filtered.toString());
                return filtered;
              });
            }
          }}
        />
      ))}
    </Chips>
  );
};

export const RouteTypesFilter = React.memo(Filter);