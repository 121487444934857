/* eslint-disable @typescript-eslint/ban-ts-comment */
import mapboxgl, { LngLatBoundsLike, LngLatLike } from "mapbox-gl";
import { createNewData, createNewDataTrip } from "./utils";

export const selectedRoute = "selectedRoute";
export const selectedRouteOutline = "selectedRouteOutline";
export const selectedRouteArrow = "selectedRouteArrow";

const directions: string[] = ["Forward", "Backward"];

export const selectedRouteLayers = [
  ...directions.map((item) => selectedRoute + item),
  ...directions.map((item) => selectedRouteOutline + item),
  selectedRouteArrow,
];

export type TripDirection = "Forward" | "Backward";
export type StopMode = "Technological" | "Boarding" | "Default" | "Alighting";
export type StopKind = "First" | "Last" | "Middle";

export type TripStopResponse = {
  stopId: number;
  isAlighting: boolean;
  isBoarding: boolean;
  kind: StopKind;
  mode: StopMode;
  orderNum: number;
};

export type Trip = {
  tripId: number;
  direction: TripDirection;
  length: number;
  stops: TripStopResponse[];
  longName: string;
  codeName: string;
  path?: any;
};
export type TransportType = "Bus" | "Electrobus" | "Tram" | "Trolleybus";
export interface IRoute {
  appearance?: any;
  contractor?: any;
  name: string;
  number: string
  routeId: number;
  startDate: string | null;
  transportType: TransportType;
  trips?: any;
  stops?: any;
  versionId: number
}

export const selectedRouteController = (
  route: IRoute,
  map: mapboxgl.Map,
) => {
  const { trips } = route;
  const backgroundColor = "#808080";
  const tripsData = trips?.filter((trip: any) => trip.type === "00");

  if (!tripsData?.length) return;
  const geometryForward = trips?.filter(
    // eslint-disable-next-line array-callback-return, consistent-return
    (tripData: Trip) => {
      if (tripData?.direction === "Forward") return { ...tripData.path };
    }).map((tripData: Trip) => tripData.path) || [{ geometry: [] }];

  const geometryBackward = trips?.filter(
    // eslint-disable-next-line array-callback-return, consistent-return
    (tripData: Trip) => {
      if (tripData?.direction === "Backward") return { ...tripData.path };
    },
  ).map((tripData: Trip) => tripData.path) || [{ geometry: [] }];



  const newData = createNewData(geometryForward, geometryBackward, route, false, backgroundColor);


  if (geometryForward[0] && geometryForward[0].coordinates) {
    const bounds = geometryForward[0].coordinates;
    map?.fitBounds([bounds[0] as LngLatLike, bounds[bounds.length - 1] as LngLatLike] as LngLatBoundsLike, { minZoom: 30 });
  }

  if (geometryBackward[0] && geometryBackward[0].coordinates) {
    const bounds = geometryBackward[0].coordinates;
    map?.fitBounds([bounds[0] as LngLatLike, bounds[bounds.length - 1] as LngLatLike] as LngLatBoundsLike, { minZoom: 30 });
  }

  const prevSource = map.getSource(selectedRoute);

  if (!prevSource) {
    map.addSource(selectedRoute, {
      type: "geojson",
      data: newData,
      generateId: true,
    } as mapboxgl.AnySourceData);
  } else {
    // @ts-ignore
    prevSource?.setData(newData);
  }

  if (!map.getLayer(selectedRoute)) {
    map.addLayer({
      "id": selectedRoute,
      "type": "line",
      "source": selectedRoute,
      "paint": {
        "line-color": "grey",
        // "line-opacity": 0.75,
        "line-width": 5,
      },
    });
    if (map.getLayer("route") && map.getLayer("pointTrip")) {
      if (map?.getLayer("selectedRoute")) {
        map?.moveLayer("selectedRoute", "route");
      }
      map.moveLayer("route", "pointTrip");
    }

  }
};

export const selectedTripsController = (
  trips: Trip[],
  map: mapboxgl.Map,
) => {
  const backgroundColor = "#808080";
  const tripsData = trips?.filter((trip: any) => trip.type === "00");

  if (!tripsData?.length) return;
  const  geometryForward = trips?.filter(
    // eslint-disable-next-line array-callback-return, consistent-return
    (tripData: Trip) => {
      if (tripData?.direction === "Forward") return { ...tripData.path };
    },
  ).map((tripData: Trip)=> tripData.path) || [{ geometry: [] }];

  const geometryBackward = trips?.filter(
    // eslint-disable-next-line array-callback-return, consistent-return
    (tripData: Trip) => {
      if (tripData?.direction === "Backward") return { ...tripData.path };
    },
  ).map((tripData: Trip)=> tripData.path) || [{ geometry: [] }];



  const newData = createNewDataTrip(geometryForward, geometryBackward, false, backgroundColor);


  // if (geometryForward[0] && geometryForward[0].coordinates) {
  //   // const coord = geometryForward[0].coordinates;
  //   const bounds = new mapboxgl.LngLatBounds();
  //   geometryForward[0].coordinates.map((coord: any) => bounds.extend([coord[0], coord[1]]));
  //   map?.fitBounds([bounds[0] as LngLatLike, bounds[bounds.length - 1] as LngLatLike] as LngLatBoundsLike, { minZoom: 30 });
  // }

  if (geometryForward[0] && geometryForward[0].coordinates) {
    const { coordinates } = geometryForward[0];
    const bounds = new mapboxgl.LngLatBounds();
    coordinates.map((coord: (mapboxgl.LngLatLike)[]) => bounds.extend([coord[0], coord[1]]));
    map.fitBounds(bounds, {
      padding: 40,
      minZoom: 35,
    });

  }

  if (geometryBackward[0] && geometryBackward[0].coordinates) {
    const { coordinates } = geometryBackward[0];
    const bounds = new mapboxgl.LngLatBounds();
    coordinates.map((coord: (mapboxgl.LngLatLike)[]) => bounds.extend([coord[0], coord[1]]));
    map.fitBounds(bounds, {
      padding: 40,
      minZoom: 35,
    });

  }

  // if (geometryBackward[0] && geometryBackward[0].coordinates) {
  //   const bounds = geometryBackward[0].coordinates;
  //   map?.fitBounds([bounds[0] as LngLatLike, bounds[bounds.length - 1] as LngLatLike] as LngLatBoundsLike, { minZoom: 30 });
  // }
  
  const prevSource = map.getSource(selectedRoute);

  if (!prevSource) {
    map.addSource(selectedRoute, {
      type: "geojson",
      data: newData,
      generateId: true,
    } as mapboxgl.AnySourceData);
  } else {
    // @ts-ignore
    prevSource?.setData(newData);
  }

  if (!map.getLayer(selectedRoute)) {
    map.addLayer({
      "id": selectedRoute,
      "type": "line",
      "source": selectedRoute,
      "paint": {
        "line-color": "grey",
        // "line-opacity": 0.75,
        "line-width": 5,
      },
    });
    if (map.getLayer("route") && map.getLayer("pointTrip") ) {
      if (map?.getLayer("selectedRoute")) {
        map?.moveLayer("selectedRoute", "route");
      }
      map.moveLayer("route", "pointTrip");
    }

  }
};
