export const getDateToBack = (date: Date): string => {
  function join(t: number | Date | undefined, a: any[], s: string) {
    function format(m: Intl.DateTimeFormatOptions | undefined) {
      const f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  const a = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];
  const s = join(date, a, "-");
  return s;
};

export const changeDay = (start: string | null, str: string | null, numOfDays = 1) => {
  if (!str) return null;
  if (!start) return null;
  if (str === start) return str;
  const dateMod = new Date(str);
  dateMod.setDate(dateMod.getDate() - numOfDays);
  return getDateToBack(dateMod);
};