import { Dispatch } from "redux";
import { getDepotRequest, GetDepotRequestOptions } from "api";
import { DepotVersion } from "models";
import { DepotFilter } from "store/reducers/depot/depot.constants";
import { TYPES } from "../types";
import { DepotActionTypes, SetActiveDepotIdAction, SetDepotByVersionIdAction, SetDepotsAction, SetDepotSearchStringAction, SetFilters, UpdateDepotsFilesAction } from "./depot-actions.types";

export const depotActions = {

  getDepotRequest: (params: GetDepotRequestOptions) => async (dispatch: Dispatch<any>) => {
    const payload = await getDepotRequest({ ...params, pageSize: 50 });
    dispatch({
      type: params.isActive ? TYPES.DEPOT.SET_ACTIVE_DEPOT : TYPES.DEPOT.SET_INACTIVE_DEPOT,
      payload,
    });
  },

  setDepot: (payload: SetDepotsAction["payload"]): SetDepotsAction => ({
    type: DepotActionTypes.SET_DEPOT,
    payload,
  }),


  getDepotByVersionId: (versionId: string, payload: DepotVersion) => async (dispatch: Dispatch<SetDepotByVersionIdAction>) => {
    dispatch({
      type: DepotActionTypes.SET_DEPOT_BY_VERSION_ID,
      payload: {
        versionId,
        data: payload,
      },
    });
  },

  updateDepotsFiles: (depotId: string, data: Partial<DepotVersion>): UpdateDepotsFilesAction => ({
    type: DepotActionTypes.UPDATE_DEPOTS_FILES,
    payload: { depotId, data },
  }),

  setSearchSubstring: (payload: string): SetDepotSearchStringAction => ({
    type: DepotActionTypes.SET_DEPOT_SEARCH_STRING,
    payload,
  }),

  setFilters: (payload: DepotFilter): SetFilters => ({
    type: DepotActionTypes.SET_FILTERS,
    payload,
  }),

  setActiveDepot: (payload: string): SetActiveDepotIdAction => ({
    type: DepotActionTypes.SET_DEPOT_ACTIVE_ITEM,
    payload,
  }),
  
  setActiveFilter: (payload: string) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: TYPES.DEPOT.SET_DEPOT_ACTIVE_FILTER,
      payload,
    });
  },
};

export default depotActions;
