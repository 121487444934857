import { Divider } from "components";
import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";

const Table = styled.table`
width: 100%;
margin: 0;
border-spacing: 0;
border-collapse: collapse;
`;

const TableHead = styled.thead`
font-size: 4vh;
font-weight: bold;
text-align: left;
padding 2;
border-bottom: 1px solid LightGrey;
}
`;

const TableRow = styled.tr`
border-bottom: 1px solid LightGrey;
height: 50px;
}

`;

export default function ScheduleTable(props: { columns: any, data: any, onClick?: any }) {
    const { columns, data  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </TableHead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => <td {...cell.getCellProps()}>{cell.render("Cell")}</td>)}
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
}