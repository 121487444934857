import { Dispatch } from "redux";
import { getAllScheduleRequest, getScheduleByVersionIdRequest, getScheduleRequest, GetScheduleRequestOptions } from "api";
import axios, { CancelTokenSource } from "axios";
import { toastError } from "components";
import { labels } from "shared/constants";
import { TYPES } from "../types";
import { ScheduleActionType, SetFilters, SetScheduleByVersionIdAction } from "./schedule-actions.types";

let source: CancelTokenSource;
export const scheduleActions = {
  getAllSchedule: () => async (dispatch: Dispatch<any>) => {
    const payload = await getAllScheduleRequest({
      pageIndex: 4,
      pageSize: 10,
    });
    dispatch({
      type: TYPES.SCHEDULE.SET_SCHEDULE,
      payload,
    });
  },

  getScheduleByVersionId: (versionId: string) => async (dispatch: Dispatch<SetScheduleByVersionIdAction>) => {
    try {
      if (source) source.cancel("Operation canceled by the user.");
      source = axios.CancelToken.source();

      const payload = await getScheduleByVersionIdRequest({ versionId: +versionId }, { cancelToken: source.token });
      dispatch({
        type: ScheduleActionType.SET_SCHEDULE_BY_VERSION_ID,
        payload: {
          versionId,
          data: payload,
        },
      });
    } catch (error) {
      toastError(labels.mapNotLoad);
    }

  },

  setFilters: (payload: {
    ContractorIds?: string | null,
    RouteKindIds?: string | null,
    TransportTypes?: string | null,
    CapacityTypes?: string | null,
  }): SetFilters => ({
    type: ScheduleActionType.SET_FILTERS,
    payload,
  }),

  getSchedule: (params: GetScheduleRequestOptions) => async (dispatch: Dispatch<any>) => {
    const payload = getScheduleRequest(params);
    dispatch({
      type: TYPES.SCHEDULE.SET_CURRENT_SCHEDULE,
      payload,
    });
  },
};
