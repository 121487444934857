import React, { memo } from "react";
import styled from "styled-components/macro";
import { withNeutralColor } from "shared/utils";

type PlankProps = {
  color?: string;
};

const PlankBlock = styled.div<PlankProps>`
  background-color: ${(props) => props.color || withNeutralColor("l4")};
  width: 2px;
  height: 100%;
`;

const PlankComponent = (props: PlankProps) => {
  const { color } = props;
  return <PlankBlock color={color} />;
};

export const Plank = memo(PlankComponent);

