import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
`;

export const MapWrapper = styled.div`
  height: 100%;
  max-height: 100vh;
  z-index: 0;
  background-size: cover;
`;
