import { Dispatch } from "redux";
import {
  getVehicleRequest,
  getVehicleVersionRequest,
  searchVehicle,
  SearchVehicleOptions,
  VehicleGetRequestOptions,
} from "api";
import axios, { CancelTokenSource } from "axios";
import { toastError } from "components";
import { labels } from "shared/constants";
import {
  SetActiveVehicleAction,
  SetInactiveVehicleAction,
  SetIsLoadingAction,
  SetSelectedVehicleAction,
  SetVehicleActiveFilter,
  SetVehicleByVersionIdAction,
  SetFilters,
  SetVehiclesAction,
  SetVehicleSearchSubstring,
  UpdateVehicleByVersionIdAction,
  VehicleActionType,
  UpdateVehiclesFilesAction,
} from "./vehicle-actions.types";
import { TabValues } from "../../../features";
import { TYPES } from "../types";
import { VehicleDetails, VehicleRequestItem } from "../../../models";
import { VehicleAnotherFilters, VehicleFilters } from "../../reducers/vehicle";

let source: CancelTokenSource;

export const vehicleActions = {
  setIsLoading: (payload: boolean): SetIsLoadingAction => ({
    type: VehicleActionType.SET_VEHICLE_ISLOADING,
    payload,
  }),

  getVehicleRequest: (params: VehicleGetRequestOptions) => async (dispatch: Dispatch<SetActiveVehicleAction | SetInactiveVehicleAction>) => {
    const payload = await getVehicleRequest({ ...params, pageSize: 50 });
    dispatch({
      type: params.IsActive ? VehicleActionType.SET_ACTIVE_VEHICLE : VehicleActionType.SET_INACTIVE_VEHICLE,
      payload,
    });
  },

  searchVehicle: (params: SearchVehicleOptions) => async (dispatch: Dispatch<any>) => {
    const payload = await searchVehicle({ ...params, pageSize: 50 });
    dispatch({
      type: params.IsActive ? TYPES.VEHICLE.SET_ACTIVE_VEHICLE : TYPES.VEHICLE.SET_INACTIVE_VEHICLE,
      payload,
    });
  },

  setFilters: (payload: VehicleAnotherFilters): SetFilters => ({
    type: VehicleActionType.SET_FILTERS,
    payload,
  }),

  getVehicleByVersionId: (versionId: string) => async (dispatch: Dispatch<SetVehicleByVersionIdAction | SetIsLoadingAction>) => {
    try {
      dispatch(vehicleActions.setIsLoading(true));
      if (source) source.cancel("Operation canceled by the user.");
      source = axios.CancelToken.source();
      const payload = await getVehicleVersionRequest(versionId, { cancelToken: source.token });
      dispatch({
        type: VehicleActionType.SET_VEHICLE_BY_VERSION_ID,
        payload: {
          versionId,
          data: payload,
        },
      });

    } catch (error) {
      toastError(labels.mapNotLoad);
    } finally {
      dispatch(vehicleActions.setIsLoading(false));
    }
  },

  setVehicleByVersionId: (versionId: string, payload: Partial<VehicleDetails>) => ({
    type: VehicleActionType.SET_VEHICLE_BY_VERSION_ID,
    payload: {
      versionId,
      data: payload,
    },
  }),

  updateVehiclesFiles: (vehicleId: string, data: Partial<VehicleDetails>): UpdateVehiclesFilesAction => ({
    type: VehicleActionType.UPDATE_VEHICLES_FILES,
    payload: { vehicleId, data },
  }),

  setActiveFilter: (payload: TabValues) => (dispatch: Dispatch<SetVehicleActiveFilter>) => {
    dispatch({
      type: VehicleActionType.SET_VEHICLE_ACTIVE_FILTER,
      payload,
    });
  },

  updateVehicleByVersion: (versionId: string, data: Partial<VehicleDetails>): UpdateVehicleByVersionIdAction => ({
    type: VehicleActionType.UPDATE_VEHICLE_BY_VERSION,
    payload: { versionId, data },
  }),

  addVehicles: (status: VehicleFilters, data: VehicleRequestItem): SetVehiclesAction => ({
    type: VehicleActionType.SET_VEHICLES,
    payload: { status, data },
  }),

  setSearchSubstring: (payload: string): SetVehicleSearchSubstring => ({
    type: VehicleActionType.SET_VEHICLE_SEARCH_SUBSTRING,
    payload,
  }),

  setSelectedVehicle: (payload: string): SetSelectedVehicleAction => ({
    type: VehicleActionType.SET_SELECTED_VEHICLE,
    payload,
  }),
};

export default vehicleActions;
