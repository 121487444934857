import { v4 } from "uuid";
import { VehicleTypes } from "models";
import { VehicleCardProps } from "../../cards";

const getVehicleType = () => {
  const index = Math.floor(Math.random() * 3);
  const keys = Object.keys(VehicleTypes);
  return keys[index] as VehicleTypes;
};

export const mockItems: VehicleCardProps[] = [...Array(100)].map<VehicleCardProps>((_, index) => ({
  id: v4(),
  contractor: `Перевозчик ${index}`,
  registrationNumber: `1ЕКХ4${index + 1}77`,
  type: getVehicleType(),
  isActual: index % 2 === 0,
}));
