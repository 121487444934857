import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRoute, useRouter } from "react-router5";
import { PAGE_ROUTES } from "shared/definitions";
import { FilterWindow, Tab } from "components/ui";
import { useDriverItemsSelector } from "store/selectors/driver-selectors";
import { DriverCardListPagination } from "features/card-lists/driver-card-list/driver-card-list-pagination";
import { useDispatch, useSelector } from "react-redux";
import { commonActions, driverActions } from "store/actions";
import { DriverFilterContent } from "features/filters-windows/driver-filter-content";
import { DriverFilters } from "store/reducers/driver";
import { RootState } from "store/types";
import { ListPanel, ListPanelHeader, ListPanelHeaderWrapper, ListPanelFilterTabs } from "../components";
import { TabValues } from "../list-panels.constants";

export const DriverListPanel = () => {
  // const [activeFilter, setActiveFilter] = useState("active");
  const [activeId, setActiveId] = useState("");
  const dispatch = useDispatch();
  const router = useRouter();
  const { activeFilter } = useSelector((state: RootState) => ({
    activeFilter: state.driver.driverActiveFilter,
  }));
  const { route: { name: routeName, params: { id: routeId } = {} } } = useRoute();

  const { filterStatus } = useSelector((state: RootState) => ({
    filterStatus: state.common.filterStatus,
  }));
  const toggle = useCallback(() => {
    dispatch(commonActions.setFilterStatus(!filterStatus));
  }, [filterStatus]);

  const handleItemClick = useCallback(
    (id: string) => {
      setActiveId(id);
      dispatch(driverActions.setActiveDriver(id));
      router.navigate(PAGE_ROUTES.DRIVER, { id });
    },
    [dispatch, router],
  );
  
  useEffect(() => {
    if (!routeId && routeName === PAGE_ROUTES.DRIVERS && activeId) {
      router.navigate(PAGE_ROUTES.ROUTE, { id: activeId });
    }
  }, [routeName, routeId, activeId, router]);

  const handleAddClick = useCallback(() => router.navigate(PAGE_ROUTES.DRIVER, { id: "new" }), [router]);

  const handleChangeFilter = useCallback((filter: string) => {
    dispatch(driverActions.setActiveFilter(filter as DriverFilters));
  }, []);

  // const items = useMemo(() => {
  //   const end = ItemsCountMap.all;
  //   return mockItems.slice(0, end);
  // }, []);

  return (
    <ListPanel
      headerSlot={
        <ListPanelHeaderWrapper>
          {/* <FilterWindow headerText="Водители"  isShown={showFilter} hide={toggle}  modalContent={<DriverFilterContent hide={toggle} />}/> */}
          <ListPanelHeader requestPath="Driver" title="Водители" onAddClick={handleAddClick} onFilterClick={toggle} />
        </ListPanelHeaderWrapper>
      }
      filterSlot={
        <ListPanelFilterTabs activeFilterTab={activeFilter} onChange={handleChangeFilter}>
          <Tab value={TabValues.ACTIVE}>Активные</Tab>
          <Tab value={TabValues.INACTIVE}>Неактивные</Tab>
        </ListPanelFilterTabs>
      }
      listSlot={<DriverCardListPagination activeId={activeId} onClick={handleItemClick} />}
    />
  );
};
