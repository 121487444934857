import styled from "styled-components/macro";
import { withContentColor } from "@megapolis/uikit/utils";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  line-height: 16px;
  padding: 8px 0;
`;

export const Title = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 11px;
  color: ${withContentColor("secondary")};
  margin-bottom: 2px;
`;

export const Content = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${withContentColor("primary")};
  flex-grow: 1;
  margin-top: 2px;
`;

export const ContentWrapper = styled.span`
  display: flex;
  flex-flow: row nowrap;
`;

export const LeftIcon = styled.span`
  display: flex;
  align-items: center;
  max-width: 16px;
  max-height: 16px;
  margin-right: 4px;
  flex-grow: 0;
`;
