import { Geometry } from "@turf/turf";
import { setVisibility } from "components/common/main-map/actual-keyZones/utils";
import { useMap } from "components/common/main-map/map.context";
import mapboxgl from "mapbox-gl";
import { useCallback } from "react";


export const TripsDirectionsMap = () => {

  const { map } = useMap();


  const clearLayers = useCallback(() => {
    if (map?.getLayer("route")) {
      map?.removeLayer("route");
    }

    if (map?.getLayer("pointTrip")) {
      map?.removeLayer("pointTrip");
    }

    if (map?.getLayer("selectedTripKeyZonesFill")) map?.removeLayer("selectedTripKeyZonesFill");

    if (map?.getLayer("selectedTripKeyZonesLine")) map?.removeLayer("selectedTripKeyZonesLine");

    if (map?.getLayer("KeyZoneTripFill")) map?.removeLayer("KeyZoneTripFill");

    if (map?.getLayer("KeyZoneTripLine")) map?.removeLayer("KeyZoneTripLine");

    if (map?.getSource("selectedTrip")) {
      map?.removeSource("selectedTrip");
    }

    if (map?.getSource("selectedTripStops")) {
      map?.removeSource("selectedTripStops");
    }

    if (map?.getSource("selectedTripKeyZones")) {
      map?.removeSource("selectedTripKeyZones");
    }

  }, [map]);

  const setVisibilityTripStops = (value: boolean) => {
    if (!map) return;
    setVisibility(map, value, "pointTrip");
  };
  const setVisibilityTripKeyZones = (value: boolean) => {
    if (!map) return;
    setVisibility(map, value, "KeyZoneTripFill");
    setVisibility(map, value, "KeyZoneTripLine");
  };

  const createLineLayersForTrip = () => {

    map?.addLayer({
      "id": "route",
      "type": "line",
      "source": "selectedTrip",
      "layout": {
        "line-join": "round",
        "line-cap": "round",
      },
      "paint": {
        "line-color": "#5297ff",
        "line-width": 6,
      },
    });

    if (map?.getLayer("selectedRoute")) {
      map?.moveLayer("selectedRoute", "route");
    }

  };


  const createStopLayersForTrip = () => {

    if (!map?.getLayer("pointTrip") && map?.getSource("selectedTripStops")) {
      map?.addLayer({
        id: "pointTrip",
        type: "symbol",
        source: "selectedTripStops",
        minzoom: 0,
        maxzoom: 24,
        layout: {
          "icon-image": "bus-pin",
          "icon-size": ["interpolate", ["exponential", 0], ["zoom"], 13.5, 0.7, 14.01, 1, 15, 1, 16.01, 1],
          "visibility": "visible",
          "icon-anchor": "top",
        },
      });
      if (map?.getLayer("route")) {
        map?.moveLayer("route", "pointTrip");
      }

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map?.on("mouseover", "pointTrip", (e: any) => {
        if (e.features) {
          const address = e.features[0].properties ? e.features[0].properties.address : "";
          const coordinates = e.features[0].properties ? e.features[0].geometry.coordinates.slice() : "";
          popup.setLngLat(coordinates).setHTML(address).addTo(map);
        }
        // eslint-disable-next-line no-param-reassign
        map.getCanvasContainer().style.cursor = "pointer";
      });
      map?.on("mouseleave", "pointTrip", () => {
        // eslint-disable-next-line no-param-reassign
        map.getCanvasContainer().style.cursor = "";
        popup.remove();
      });
    }


  };

  const createZoneLayersForTrip = () => {
    if (!map) return;

    if (!map?.getLayer("KeyZoneTripFill") && map?.getSource("selectedTripKeyZones")) {
      map?.addLayer({
        "id": "KeyZoneTripFill",
        "type": "fill",
        "source": "selectedTripKeyZones", // reference the data source
        "layout": {},
        "paint": {
          "fill-color": "#5ACAFD", // blue color fill
          "fill-opacity": 0.7,
        },
      });
    }


    if (!map?.getLayer("KeyZoneTripLine") && map?.getSource("selectedTripKeyZones")) {
      map?.addLayer({
        "id": "KeyZoneTripLine",
        "type": "line",
        "source": "selectedTripKeyZones",
        "layout": {},
        "paint": {
          "line-color": "#5ACAFD",
          "line-width": 0.4,
        },
      });
    }

    if (map?.getLayer("KeyZoneTripFill")) {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.on("mouseover", "KeyZoneTripFill", (e: any) => {
        if (e.features) {
          const address = e.features[0].properties.name ? e.features[0].properties.name : "";
          const coordinates = e.lngLat.wrap();
          popup.setLngLat(coordinates).setHTML(address).addTo(map);
          // const description = e?.features[0].properties.description;
        }
        // eslint-disable-next-line no-param-reassign
        map.getCanvasContainer().style.cursor = "pointer";
      });
      map.on("mouseleave", "KeyZoneTripFill", () => {
        // eslint-disable-next-line no-param-reassign
        map.getCanvasContainer().style.cursor = "";
        popup.remove();
      });
    }
  };


  return {
    clearLayers,
    createStopLayersForTrip,
    createLineLayersForTrip,
    createZoneLayersForTrip,
    setVisibilityTripStops,
    setVisibilityTripKeyZones,
  };
};
