import React, { useMemo, memo } from "react";
import { SkeletonDetailCard } from "components/ui/skeleton-detail-card";
import { useRouteParams } from "shared/hooks";
import { StopStationDetails } from "./stop-station-details/stop-station-details";
import { NewStopStationPanel } from "./new-stop-station";

const StopStationsNew = () => <NewStopStationPanel />;

const StopStationEdit = ( props: { isNew: boolean } ) => <NewStopStationPanel isNew={props.isNew}/>;

const StopStationDetailsPanelComponent = () => {
  const parapms = useRouteParams();
  const { id } = parapms;
  let Component = useMemo(()=> <SkeletonDetailCard />, []);
  const isNew = useMemo(() => id === "new", [id]);
  const isEdit = useMemo(() => String(id).includes("_edit"), [id]);
  const isEditVersion = useMemo(() => id.toString().indexOf("version") > -1, [id]);
  const allAttr = useMemo(()=> [isNew, isEdit, isEditVersion].every((cv)=> cv === false), [isNew, isEdit, isEditVersion]);

  if (isEditVersion) Component = <NewStopStationPanel isNew={!isEditVersion} changeVersion={isEditVersion}/>;
  if (isNew) Component = <StopStationsNew />;
  if (isEdit) Component = <StopStationEdit isNew={!isEdit}/>;
  if (allAttr) Component = <StopStationDetails/>;
  return Component;
};

export const StopStationDetailsPanelView = memo(StopStationDetailsPanelComponent);