import React, { useMemo, memo } from "react";
import { SkeletonDetailCard } from "components/ui/skeleton-detail-card";
import { NewRoutePanel } from "./new-route-panel";
import { useRouteParams } from "../../../shared/hooks";
import { RouteDetailsPanel } from "./route-details-panel";

const RouteNew = () => <NewRoutePanel />;

const RouteEdit = ( props: { isNew: boolean } ) => <NewRoutePanel isNew={props.isNew}/>;

const RouteEditVersion = ( props: { isNew: boolean, changeVersion: boolean } ) => <NewRoutePanel isNew={props.isNew} changeVersion={props.changeVersion} />;

const RouteDetailsPanelComponent = () => {
  const parapms = useRouteParams();
  let Component = useMemo(()=> <SkeletonDetailCard />, []);
  const isCopy = useMemo(() => parapms.id === "copy" && parapms.state, [parapms.id, parapms.state]);
  const isNew = useMemo(() => parapms.id === "new", [parapms.id]);
  const isEdit = useMemo(() => String(parapms.id).includes("_edit"), [parapms.id]);
  const isEditVersion = useMemo(() => parapms.id.toString().indexOf("version") > -1, [parapms.id]);
  const allAttr = useMemo(()=> [isCopy, isNew, isEdit, isEditVersion].every((cv)=> cv === false), [isCopy, isNew, isEdit, isEditVersion]);
  if (isCopy) Component = <NewRoutePanel isNew = {false} isCopy />;
  if (isEditVersion) Component = <RouteEditVersion isNew={!isEditVersion} changeVersion={isEditVersion} />;
  if (isNew) Component = <RouteNew/>;
  if (isEdit) Component = <RouteEdit isNew = {false} />;
  if (allAttr) Component = <RouteDetailsPanel/>;
  return Component;
};

export const RouteDetailsPanelView = memo(RouteDetailsPanelComponent);