import { getDriverRequest } from "api/requests/driver/driver.requests";
import { DriverDepot, DriverVersionResponse, GetDriverRequestOptions } from "api/requests/driver/types";
import { DriverCard } from "features/cards";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DriverItem } from "store/reducers/driver";
import { useRouteFilterSelector, useRouteSearchSubstringSelector, useRouteFiltersSelector } from "store/selectors/route-selectors";
import styled from "styled-components";

const DEFAULT_PAGE_SIZE = 100;

const NoDataLabel = styled.div`
  width: 100%;
  padding: 10px 0;
  background-color: #dbdbdb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;

const Text = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
  padding: 0 16px;
`;


export type RouteCardListPaginationProps = {
  VehicleIds?: string;
  DepotIds?: string;
  DriverIds?: string;
  RouteIds?: string;
  ContractorIds?: string;
};

const DriverTabListPaginationComponent = (props: RouteCardListPaginationProps) => {
  const { VehicleIds, DepotIds, DriverIds, ContractorIds, RouteIds } = props;
  const activeFilter = useRouteFilterSelector();
  const [items, setItems] = useState<DriverItem[]>([]);
  const searchSubstring = useRouteSearchSubstringSelector();
  const filters = useRouteFiltersSelector();
  const [pageNum] = useState<number>(1);

 const params = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
  }), [pageNum]);

  const options: GetDriverRequestOptions = useMemo(() => ({
    DepotIds,
    ContractorIds,
    RouteIds,
  }), [activeFilter, searchSubstring, RouteIds, ContractorIds]);

  const handleSetItems = useCallback((response: DriverVersionResponse) => {
    setItems(response.items);
  }, []);

  const fetch = useCallback(async ()=>{
    const payload = await getDriverRequest({ ...params, ...options });
    handleSetItems(payload);
  }, []);

  useEffect(()=>{
    fetch();
    return ()=>{
      setItems([]);
    };
  }, []);

  

  const itemRender = useCallback((item:  DriverItem) => {
    const { driverId, firstName, middleName, lastName, versionId, depot } = item;

    return <DriverCard
      id={String(versionId)}
      key={String(versionId)}
      firstName={firstName || "-"}
      middleName={middleName || ""}
      lastName={lastName || "-"}
      depot={depot as DriverDepot}
      isActive={false}
      onClick={()=>{}}
    />;
  }, []);

  return <Container>{
    items.length > 0 ?  items.map(item => itemRender(item)) : <NoDataLabel><Text>Нет данных</Text></NoDataLabel>
  }
  </Container>;
};

export const DriverTabListPagination =  memo(DriverTabListPaginationComponent);