import React, { useMemo, memo, useCallback } from "react";
import { SeparatedInlineList, DetailRouteIcon } from "components/common";
import { VehicleData } from "models";
import { Card } from "megapolis-uikit-latest";
import styled from "styled-components";
import { ScheduleItem } from "api";
import { getScheduleTypesCaptionMapShort } from "models/schedule";

export type ScheduleCardProps = {
  /** Идентификатор */
  id: string;
  /** Признак активности карточки */
  isActive?: boolean;
  /** Обработчик нажатия на карточку */
  onClick?: (id: string) => void;
} & ScheduleItem;

const ScheduleIcon = styled.div<{ active: boolean }>`
/* Marker */


width: 8px;
height: 8px;

/* Semantic-Success-Default-[Light] */

background: ${props => props.active ? "#1E7735" : "#69737B"};
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

const ScheduleCardComponent = (props: ScheduleCardProps) => {
  const { id, isActive = false, onClick, daysOfWeek, status, dateTo, holiday  } = props;

  const listItems = useMemo(
    () => {
      const line = daysOfWeek.map((el)=>getScheduleTypesCaptionMapShort(el)).join(",");
      if (holiday) {
        return `${line}; праздничные дни`;
      }
      return line;
    },
    [daysOfWeek, holiday],
  );

  const handleClick = useCallback(() => onClick?.(id), [onClick, id]);

  return (
    <Card.ListItem
      title={`${id} ${status === "Active" ? "Активное" : "Неактивное"}`}
      caption={<SeparatedInlineList items={["тип 1", listItems, dateTo && new Date(dateTo).toLocaleDateString()]} />}
      icon={<ScheduleIcon active={status === "Active"} />}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};
export const ScheduleCard = memo(ScheduleCardComponent);
