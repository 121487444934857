/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { APP_ENV } from "app-env";
import { LineEditor } from "@megapolis/utils";
import React, { useRef } from "react";
import { getStyleByDrawing } from "../utils/getStylesByDrawing";

type PointChangePayload = {
  beforePoint: mapboxgl.MapboxGeoJSONFeature | null;
  point: mapboxgl.MapboxGeoJSONFeature;
  afterPoint: mapboxgl.MapboxGeoJSONFeature | null;
} | null;


interface IRouteEdit {
  map: mapboxgl.Map | undefined;
  selectedRoute: any;
  routeId: string | number;
  createIsGraph?: boolean;
  // onLineChange?: () => void;
  // onDrawEnd?: () => void;
}

export const changeFormatLngLatTime = (coordinates: [number, number][]) =>
  coordinates.map((coordinate, index) => ({
    time: `2022-01-24T10:10:0${index}+03:00`,
    lng: coordinate[0],
    lat: coordinate[1],
  }));

const draw = new MapboxDraw({
  displayControlsDefault: false,
  boxSelect: false,
  modes: { ...MapboxDraw.modes },
  styles: getStyleByDrawing(),
});

export const Draw = () => draw?.getAll();

export const removeDraw = () => draw?.getAll() && draw?.deleteAll();

const getFilteredFeatures = (parsedSelectedTrip: any) => {
  let fromLength: number;

  // eslint-disable-next-line consistent-return
  const coordinates = parsedSelectedTrip.path.coordinates.filter((item: any, idx: number) => {
    const from = parsedSelectedTrip.path.coordinates[idx >= 1 ? idx - 1 : 0];
    const to = parsedSelectedTrip.path.coordinates[idx];
    const line = turf.lineString([from, to]);
    const length = turf.length(line, { units: "meters" });

    if (length - fromLength > 15) {
      return item;
    }
    fromLength = length;

    return true;
  });

  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates,
    },
  };
};

const setParsedSelectedTrip = (parsedSelectedTrip: any) => {
  const features = getFilteredFeatures(parsedSelectedTrip);
  draw.set({
    type: "FeatureCollection",
    features: [features as any],
  });
};

const setSelectedTripLineEditor = (selectedRoute: any): mapboxgl.MapboxGeoJSONFeature[] => [
  {
    type: "Feature",
    layer: { id: "", type: "" },
    source: "",
    sourceLayer: "",
    state: {},
    properties: {},
    geometry: selectedRoute.path,
  },
];

export const RouteEdit = ({ map, selectedRoute }: IRouteEdit) => {
  if (!map) return;

  if (!map.hasControl(draw)) {
    map.addControl(draw);
  }

  setParsedSelectedTrip(selectedRoute);

  const beforeData = draw?.getAll() as any;


  map.on("dblclick", draw.trash);

  // map.on("draw.update", (e: any) => {
  //   if (e.action === "move") {
  //     draw.deleteAll();
  //     draw.add(beforeData); 
  //   }
  // });
};

export const NewRouteEdit = ({ map, selectedRoute }: IRouteEdit) => {
  const lineEditor = React.useRef<LineEditor | null>(null);

  const handleLoad = (
    onLineChange?: (features: mapboxgl.MapboxGeoJSONFeature[]) => void, 
    onDrawEnd?: (features: mapboxgl.MapboxGeoJSONFeature[]) => void,
    onPointChange?: (payload: PointChangePayload) => void,
  ) => {
    lineEditor.current = new LineEditor({
      isDrawable: false,
      linesPaint: { "line-color": "#ff0000", "line-width": 4 },
      pointsPaint: {
        "circle-radius": 6,
        "circle-color": "#ff0000",
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
      onLineChange,
      onDrawEnd,
      onPointChange,

    });
    if (map) map.addControl(lineEditor.current);
    lineEditor.current.continueDrawing(setSelectedTripLineEditor(selectedRoute));
  };


  const removeNewDraw = () => lineEditor?.current && lineEditor?.current.destroy();

  const endDraw = () => lineEditor?.current && lineEditor?.current.finishDrawing();

  return {
    removeNewDraw,
    handleLoad,
    endDraw,
  };
};



