import { ContractorResponse, ContractorsVersionResponse } from "api";
import { ContractorFilters, ContractorsFilter } from "../../reducers/contractor/contractor.types";

type Action<A extends ContractorActionTypes, P> = { type: A, payload: P };

export enum ContractorActionTypes {
  SET_CONTRACTORS = "SET_CONTRACTORS",
  SET_ACTIVE_CONTRACTOR = "SET_ACTIVE_CONTRACTOR",
  SET_INACTIVE_CONTRACTOR = "SET_INACTIVE_CONTRACTOR",
  SET_FILTERS = "SET_FILTERS",
  UPDATE_CONTRACTOR_BY_VERSION = "UPDATE_CONTRACTOR_BY_VERSION",
  UPDATE_CONTRACTORS_FILES = "UPDATE_CONTRACTORS_FILES",
  SET_CONTRACTOR_BY_CONTRACTOR_ID = "SET_CONTRACTOR_BY_CONTRACTOR_ID",
  SET_CONTRACTOR_BY_VERSION_ID = "SET_CONTRACTOR_BY_VERSION_ID",
  SET_CONTRACTOR_ACTIVE_FILTER = "SET_CONTRACTOR_ACTIVE_FILTER",
  SET_CONTRACTOR_ACTIVE_ITEM = "SET_CONTRACTOR_ACTIVE_ITEM",
  SET_IS_CONTRACTOR_CARD_LOADING = "SET_IS_CONTRACTOR_CARD_LOADING",
  SET_CONTRACTOR_SEARCH_STRING = "SET_CONTRACTOR_SEARCH_STRING",
}

export type SetContractorsAction = Action<ContractorActionTypes.SET_CONTRACTORS, { status: ContractorFilters, data: ContractorsVersionResponse }>;
export type SetContractorFilterAction = Action<ContractorActionTypes.SET_CONTRACTOR_ACTIVE_FILTER, ContractorFilters>;
export type SetFilters = Action<ContractorActionTypes.SET_FILTERS, ContractorsFilter>;
export type UpdateContractorByVersionIdAction = Action<ContractorActionTypes.UPDATE_CONTRACTOR_BY_VERSION, { versionId: string, data: Partial<ContractorResponse> }>;
export type UpdateContractorsFilesAction = Action<ContractorActionTypes.UPDATE_CONTRACTORS_FILES, { contractorId: string, data: Partial<ContractorResponse> }>;
export type SetSelectedContractorAction = Action<ContractorActionTypes.SET_CONTRACTOR_ACTIVE_ITEM, ID>;
export type SetContractorSearchStringAction = Action<ContractorActionTypes.SET_CONTRACTOR_SEARCH_STRING, string>;
export type SetContractorByVersionIdAction = Action<ContractorActionTypes.SET_CONTRACTOR_BY_VERSION_ID, { id: ID; data: ContractorResponse }>;
export type SetIsContractorCardLoadingAction = Action<ContractorActionTypes.SET_IS_CONTRACTOR_CARD_LOADING, boolean>;

export type ContractorAction =
  | SetContractorsAction
  | SetFilters
  | UpdateContractorByVersionIdAction
  | SetContractorFilterAction
  | UpdateContractorsFilesAction
  | SetSelectedContractorAction
  | SetContractorSearchStringAction
  | SetContractorByVersionIdAction
  | SetIsContractorCardLoadingAction;
