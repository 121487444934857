import React from "react";
import { encodeSvg } from "shared/helpers/encodeSVG";

export const selectedIconDefault = () => encodeSvg(<svg width="64" height="70" viewBox="0 0 64 70" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_dd_1859_7464)">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 15.8919C8 9.32418 13.3726 4 20 4H44C50.6274 4 56 9.32418 56 15.8919V39.6757C56 46.2434 50.6274 51.5676 44 51.5676H36.1208C35.3261 51.5676 34.6067 52.0381 34.2884 52.7663L32 58L29.7116 52.7663C29.3933 52.0381 28.6739 51.5676 27.8792 51.5676H20C13.3726 51.5676 8 46.2434 8 39.6757V15.8919Z" fill="#027BFF" />
  </g>
  <circle cx="32" cy="62" r="3" fill="white" />
  <circle cx="32" cy="62" r="2" fill="#027BFF" />
  <path fillRule="evenodd" clipRule="evenodd" d="M43.3333 19.0001L19 19V21H22.5C22.7761 21 23 21.2238 23 21.5V27.5C23 27.7761 22.7761 28 22.5 28H19V36L24 36C24 33.7909 25.7909 32 28 32C30.2091 32 32 33.7909 32 36L44.3333 36C44.8856 36 45.3333 35.5523 45.3333 35V29H43C41.8954 29 41 28.1045 41 27V21.5C41 21.2238 41.2238 21 41.5 21H45.3333C45.3333 19.8954 44.4379 19.0001 43.3333 19.0001ZM25.5 21C25.2238 21 25 21.2238 25 21.5V27.5C25 27.7761 25.2238 28 25.5 28H30.5C30.7761 28 31 27.7761 31 27.5V21.5C31 21.2238 30.7761 21 30.5 21H25.5ZM33.5 21C33.2238 21 33 21.2238 33 21.5V32.5C33 32.7761 33.2238 33 33.5 33H37.5C37.7761 33 38 32.7761 38 32.5V21.5C38 21.2238 37.7761 21 37.5 21H33.5ZM28 39C29.6568 39 31 37.6569 31 36C31 34.3431 29.6568 33 28 33C26.3431 33 25 34.3431 25 36C25 37.6569 26.3431 39 28 39Z" fill="white" />
  <defs>
    <filter id="filter0_dd_1859_7464" x="0" y="0" width="64" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="4" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.356863 0 0 0 0 0.407843 0 0 0 0 0.443137 0 0 0 0.24 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1859_7464" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feGaussianBlur stdDeviation="0.5" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.101961 0 0 0 0 0.12549 0 0 0 0 0.141176 0 0 0 0.32 0" />
      <feBlend mode="normal" in2="effect1_dropShadow_1859_7464" result="effect2_dropShadow_1859_7464" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1859_7464" result="shape" />
    </filter>
  </defs>
</svg>,
);


export const selectedIcon = () => encodeSvg(<svg width="64" height="70" viewBox="0 0 64 70" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_dd_1859_7464)">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 15.8919C8 9.32418 13.3726 4 20 4H44C50.6274 4 56 9.32418 56 15.8919V39.6757C56 46.2434 50.6274 51.5676 44 51.5676H36.1208C35.3261 51.5676 34.6067 52.0381 34.2884 52.7663L32 58L29.7116 52.7663C29.3933 52.0381 28.6739 51.5676 27.8792 51.5676H20C13.3726 51.5676 8 46.2434 8 39.6757V15.8919Z" fill="#ff0202" />
  </g>
  <circle cx="32" cy="62" r="3" fill="white" />
  <circle cx="32" cy="62" r="2" fill="#ff0202" />
  <path fillRule="evenodd" clipRule="evenodd" d="M43.3333 19.0001L19 19V21H22.5C22.7761 21 23 21.2238 23 21.5V27.5C23 27.7761 22.7761 28 22.5 28H19V36L24 36C24 33.7909 25.7909 32 28 32C30.2091 32 32 33.7909 32 36L44.3333 36C44.8856 36 45.3333 35.5523 45.3333 35V29H43C41.8954 29 41 28.1045 41 27V21.5C41 21.2238 41.2238 21 41.5 21H45.3333C45.3333 19.8954 44.4379 19.0001 43.3333 19.0001ZM25.5 21C25.2238 21 25 21.2238 25 21.5V27.5C25 27.7761 25.2238 28 25.5 28H30.5C30.7761 28 31 27.7761 31 27.5V21.5C31 21.2238 30.7761 21 30.5 21H25.5ZM33.5 21C33.2238 21 33 21.2238 33 21.5V32.5C33 32.7761 33.2238 33 33.5 33H37.5C37.7761 33 38 32.7761 38 32.5V21.5C38 21.2238 37.7761 21 37.5 21H33.5ZM28 39C29.6568 39 31 37.6569 31 36C31 34.3431 29.6568 33 28 33C26.3431 33 25 34.3431 25 36C25 37.6569 26.3431 39 28 39Z" fill="white" />
  <defs>
    <filter id="filter0_dd_1859_7464" x="0" y="0" width="64" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="4" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.356863 0 0 0 0 0.407843 0 0 0 0 0.443137 0 0 0 0.24 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1859_7464" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feGaussianBlur stdDeviation="0.5" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.101961 0 0 0 0 0.12549 0 0 0 0 0.141176 0 0 0 0.32 0" />
      <feBlend mode="normal" in2="effect1_dropShadow_1859_7464" result="effect2_dropShadow_1859_7464" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1859_7464" result="shape" />
    </filter>
  </defs>
</svg>,
);