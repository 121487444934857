import React, { MouseEventHandler } from "react";

export enum Priority {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Quaternary = "quaternary",
}

export type UPriority = "primary" | "secondary" | "tertiary" | "quaternary";

export type TPriority = UPriority | Priority;

export enum Kind {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Danger = "danger",
}

export type UKind = "info" | "success" | "warning" | "danger";

export type TKind = UKind | Kind;

export enum Size {
  Small = "small",
  Medium = "medium",
}

type TSize = "small" | "medium" | Size;

export enum Content {
  Text = "text",
  IconText = "icon-text",
  TextIcon = "text-icon",
  IconCaption = "icon-caption",
  TextCaption = "text-caption",
  Icon = "icon",
}

export type TContent = "text" | "icon-text" | "text-icon" | "icon-caption" | "text-caption" | "icon" | Content;

export type StyleProps = {
  /** Тип контента */
  content: TContent;
  /** Тип кнопки */
  priority?: TPriority;
  /** Стиль кнопки */
  kind?: TKind;
  /** Размер */
  size?: TSize;
  /** Состояние загрузки */
  isLoading?: boolean;
};

type ContentText = StyleProps & {
  /** Тип контента */
  content: Content.Text | "text";
  /** текст */
  text: string;
};

type ContentIconText = StyleProps & {
  /** Тип контента */
  content: Content.IconText | "icon-text";
  /** текст */
  text: string;
  /** Иконка */
  icon: React.ReactNode;
};

type ContentTextIcon = StyleProps & {
  /** Тип контента */
  content: Content.TextIcon | "text-icon";
  /** текст */
  text: string;
  /** Иконка */
  icon: React.ReactNode;
};

type ContentIconCaption = StyleProps & {
  /** Тип контента */
  content: Content.IconCaption | "icon-caption";
  /** Иконка */
  icon: React.ReactNode;
  /** Подпись */
  caption: string;
};

type ContentTextCaption = StyleProps & {
  /** Тип контента */
  content: Content.TextCaption | "text-caption";
  /** текст */
  text: string;
  /** Подпись */
  caption: string;
};

type ContentIcon = StyleProps & {
  /** Тип контента */
  content: Content.Icon | "icon";
  /** Иконка */
  icon: React.ReactNode;
};

export type Props = (
  | ContentText
  | ContentIconText
  | ContentTextIcon
  | ContentIconCaption
  | ContentTextCaption
  | ContentIcon
) & {
  /** Класс для стилей */
  className?: string;
  /** Отключён */
  isDisabled?: boolean;
  /** обработчик нажатия на кнопку */
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export type DependenciesStyleProps = Required<Pick<StyleProps, "priority" | "kind">>;
