import React, { useState, useCallback, useEffect } from "react";
import { RootState } from "store/types";
import { useSelector } from "react-redux";
import { SkeletonDetailCard } from "components/ui/skeleton-detail-card";
import { VehicleDetails } from "models";
import { RouteTabListPagination } from "features/detail-tabs";
import { DetailsPanel } from "../components";
import { TAB_VALUES } from "./vehicle-details-panel.constants";
import { VehiclePanelHeader, VehiclePanelTabs } from "./components";
import { VehicleDetailsInfoTab } from "./tab-pages";
import { useGetVehicleItemsByVersion } from "./vehicle-details-panel.data";

export const VehicleDetailsPanel = () => {
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const { isLoading } = useSelector((state: RootState) => ({
    isLoading: state.vehicle.isLoading,
  }));
  const { vehicleDetails = {} as VehicleDetails, versionId } = useGetVehicleItemsByVersion();

  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  useEffect(() => {
    setActiveTab(TAB_VALUES.info);
    return () => {
      setActiveTab(TAB_VALUES.info);
    };
  }, [versionId]);

  return !isLoading ?
    <DetailsPanel
      headerSlot={<VehiclePanelHeader />}
      tabsSlot={<VehiclePanelTabs value={activeTab} onChange={handleChangeTab} />}>
      {activeTab === TAB_VALUES.info && <VehicleDetailsInfoTab />}
      {activeTab === TAB_VALUES.route && <RouteTabListPagination VehicleIds={vehicleDetails ? vehicleDetails.id : ""} />}
    </DetailsPanel> : <SkeletonDetailCard />;
};
