import React, { useEffect } from "react";
import mapboxgl, { accessToken } from "mapbox-gl";
import styled from "styled-components";
import { ZoneTools } from "@megapolis/utils";
import { useMap } from "components/common/main-map";
import { Geometry, Polygon } from "geojson";
import { KeyZoneMap } from "../keyzone-details-panel.map";

export default {
  title: "Mapbox/Polygon Tool",
};

export const DrawPolygon = (isNew = true, geometry?: Geometry) => {
  const [polygon, setPolygon] = React.useState<any | null>(null);
  const { map } = useMap();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const polygonTool = React.useRef<ZoneTools.PolygonTool | null | undefined>(null);
  const { clearLayers, createLayersForKeyZone } = KeyZoneMap();


  const handleDrawEnd = (polygon_end: GeoJSON.Feature<GeoJSON.Polygon>) => {
    setIsCreate(false);
    setPolygon(polygon_end);
    createLayersForKeyZone(polygon_end.geometry);
  };

  const handleLoad = () => {
    if (!map) return;
    polygonTool.current = new ZoneTools.PolygonTool({ onDrawEnd: handleDrawEnd, onPolygonChange: console.log });
    map.addControl(polygonTool.current);
    setIsLoaded(true);
    // polygonTool.current?.startDrawing();
    if (!isNew) {
      setPolygon({
        "type": "Feature",
        "properties": {},
        "geometry": geometry as Polygon,
      });
      createLayersForKeyZone(geometry);
    }
  };

  const clearPolygon = () => {
    polygonTool.current?.finishDrawing();
    map?.off("load", handleLoad);
    polygonTool.current?.destroy();
    polygonTool.current = undefined;
  };

  const handleContinueCreate = () => {
    setTimeout(() => {
      if (!polygonTool.current) return;
      clearLayers();
      map?.removeControl(polygonTool.current);
      polygonTool.current = new ZoneTools.PolygonTool({ onDrawEnd: handleDrawEnd });
      map?.addControl(polygonTool.current);
      if (!polygon) return;
      polygonTool.current?.continueDrawing(polygon);
      setIsCreate(() => !isCreate);
      setPolygon(null);
    });
  };

  const handeleStartDrawing = () =>{
    setTimeout(() => {
      if (!polygonTool.current) return;
      clearLayers();
      map?.removeControl(polygonTool.current);
      polygonTool.current = new ZoneTools.PolygonTool({ onDrawEnd: handleDrawEnd });
      map?.addControl(polygonTool.current);
      polygonTool.current?.startDrawing();
      setIsCreate(() => !isCreate);
      setPolygon(null);
    });
  };

  const handleCreateClick = () => {
    if (isCreate) {
      polygonTool.current?.finishDrawing();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isNew ? handeleStartDrawing() : handleContinueCreate();
    }
  };

  return {
    handleLoad,
    polygon,
    clearPolygon,
    isLoaded,
    isCreate,
    handleCreateClick,
    handleContinueCreate,
  };
};