import React from "react";
import { InfoCard, BlockHeader } from "components/common";
import { v4 } from "uuid";
import styled from "styled-components/macro";
import { useGetVehicleInfoTabData } from "../vehicle-details-info-tab.data";


const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Header = styled.span`
  font-weight: 600;
`;

export const GeneralInfo = () => {
  const { infoCards } = useGetVehicleInfoTabData();
  return (
    <Container>
      <BlockHeader left={<Header>Общие сведения информация</Header>} />
      <div>
        <img
          width={300}
          src="https://www.mos.ru/upload/newsfeed/newsfeed/GL(143002).jpg"
          alt="Это электробус"
          style={{ borderRadius: 6 }}
        />
      </div>
      <InfoCards>
        {infoCards.map((card) => (
          <InfoCard key={v4()} title={card.title}>
            {card.text}
          </InfoCard>
        ))}
      </InfoCards>
    </Container>
  );
};
