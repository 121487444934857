/* eslint-disable @typescript-eslint/no-shadow */
import { getRouteversionsDates, RouteVersion } from "api";
import { Card, Divider, Tab } from "components";
import DatePicker from "components/ui/calendar/calendar";
import { CardList } from "features/card-lists/components";
import { DetailsPanel, DetailsPanelTabs } from "features/detail-panels/components";
import { TAB_VALUES } from "features/detail-panels/route-details-panel/route-details-panel.constants";
import React, { useState, useCallback, memo, useEffect } from "react";
import { differenceInCalendarDays } from "date-fns";
import { EditBlue16Icon } from "assets/icons";
import * as Markup from "./version-view.style";


const VersionView = (props: { currentVersion: any, handleItemClick: (versionId: any) => void, id: string | number, fetchVersions: (id: string | number) => Promise<any[]>, onEdit?: () => void }) => {
  const { handleItemClick, currentVersion, id, fetchVersions, onEdit } = props;
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const [activeVersion, setActiveVersion] = useState("");
  const [versions, setVersions] = useState<any[]>([]);
  const [groups, setGroups] = useState<any>([]);
  const [date, setDate] = useState(new Date());
  const [dates, setDates] = useState<any>([date]);
  const [endlessVersions, setEndlessVersions] = useState<any>([]);
  const isSameDay = (a: Date, b: Date) => differenceInCalendarDays(a, b) === 0;


  const groupByYears = (data: any[]) => {
    const groups = data.reduce((groups, item) => {
      if (!item.startDate) return groups;
      const date = item.startDate.split("-")[0];
      if (!groups[date]) {
        // eslint-disable-next-line no-param-reassign
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => ({
      date,
      versions: groups[date],
    }));

    return groupArrays.reverse();
  };

  useEffect(() => {
    if (id === undefined || id === null) return;
    fetchVersions(id).then((data: any) => data.lenght !== 0 && setVersions(data.reverse()));

    // eslint-disable-next-line consistent-return
    return () => {
      setVersions([]);
    };
  }, [fetchVersions, id]);

  useEffect(() => {
    if (!currentVersion) return;
    if (versions.length === 0) return;
    const endlessVer = versions.filter((elm: { startDate: any; }) => elm.startDate === undefined);
    setEndlessVersions(endlessVer);
    const realVers = versions.filter((elm: { startDate: any; }) => elm.startDate !== undefined);
    setGroups(groupByYears(realVers));
    setDate(new Date(currentVersion.startDate));
    setActiveVersion(currentVersion.versionId);
  }, [currentVersion, versions]);

  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  const actualVersion = useCallback((arr: RouteVersion[], item: { versionId: number; startDate: any; }) => {
    if (arr.indexOf(item) === 0) {
      return (<Markup.VersionBar><span>{`от ${item.startDate} `}<Markup.ActualVersion>Актуальная</Markup.ActualVersion></span></Markup.VersionBar>);
    }
    return <Markup.VersionBar>{`от ${item.startDate}`}</Markup.VersionBar>;
  }, []);
  return (
    <DetailsPanel
      headerSlot={<></>}
      tabsSlot={<DetailsPanelTabs value={activeTab} onChange={handleChangeTab}>
        <Tab value={TAB_VALUES.info}>Список</Tab>
        {/* <Tab value={TAB_VALUES.route}>Календарь</Tab> */}
      </DetailsPanelTabs>}>
      {activeTab === TAB_VALUES.info && <><CardList>
        {groups.length > 0 && groups.map((item: { date: string, versions: any[] }, idx: number) => (
          <span key={idx}>
            <span>{item.date}</span>
            {item.versions.map((item, id) => <CardList.Item key={id}>
              <Card
                title={actualVersion(versions, item)}
                caption={""}
                control={<Markup.ControlsVersions><Markup.UnionIcon /><EditBlue16Icon onClick={onEdit} /></Markup.ControlsVersions>}
                onClick={() => { handleItemClick(item.versionId); setActiveVersion(item.versionId); setDate(item.startDate); }}
                // onControlClick={() => { handleItemClick(item.versionId); setActiveVersion(item.versionId); setDate(item.startDate); }}
                isActive={activeVersion === item.versionId}
              />
            </CardList.Item>)}
          </span>

        ))}
      </CardList>
      {endlessVersions.length > 0 && <CardList>
        <span>{"Бессрочные версии"}</span>
        {endlessVersions.map((item: { versionId: any }, id: React.Key | null | undefined) => <CardList.Item key={id}>
          <Card
            title={`Версия ${item?.versionId}`}
            caption={""}
            control={<Markup.ControlsVersions><Markup.UnionIcon /><EditBlue16Icon onClick={onEdit} /></Markup.ControlsVersions>}
            onClick={() => { handleItemClick(item.versionId); setActiveVersion(item?.versionId); }}
            // onControlClick={() => { handleItemClick(item.versionId); setActiveVersion(item.versionId); setDate(item.startDate); }}
            isActive={activeVersion === item?.versionId}
          />
        </CardList.Item>)}
      </CardList>}
      </>}
    </DetailsPanel>
  );
};

export const VersionViewerContent = memo(VersionView);


