/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Content } from "@megapolis/uikit/button/button.types";
import { Button } from "components";
import { useRouter } from "react-router5";
import { EditBlue32Icon, KeyZone32Icon, TestAvatar32 } from "assets/icons";
import { PAGE_ROUTES } from "shared/definitions";
import { useRouteParams } from "shared/hooks";
import { VersionViewer } from "components/ui/version-card";
import styled from "styled-components";
import { VersionViewerContent } from "features/version-view";
import { labels } from "shared/constants";
import { DetailsPanelHeader } from "features/detail-panels/components";
import { useDispatch } from "react-redux";
import { keyZoneActions } from "store/actions";
import { getKeyZoneVersionsDates } from "api";
import { switchProp } from "styled-tools";
import { withNeutralColor } from "shared/utils";
import { TabValues } from "features/list-panels";
import { useKeyZoneActiveFilter } from "store/selectors/keyzone-selectors";
import { useGetKeyZoneItemsByVersion } from "../keyzone-details-panel.data";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;
type KeyZoneCardIconProps = {
  isActual: boolean;
};

type IconWrapperProps = {
  isActive: boolean;
};

const IconWrapper = styled.span<IconWrapperProps>`
  & > svg,
  path:first-child {
    fill: ${switchProp("isActive", { false: withNeutralColor("l2") })};
  }
  & > svg {
    border-radius: 3px;
  }
`;

const KeyZoneCardIcon = ({ isActual }: KeyZoneCardIconProps) => (
  <IconWrapper isActive={isActual}>
    <KeyZone32Icon/>
  </IconWrapper>
);


export const KeyZoneDetailsHeader = () => {
  const { id } = useRouteParams();
  const { keyZoneDetails, versionId, fetchKeyZone } = useGetKeyZoneItemsByVersion();
  const name = useMemo(()=> keyZoneDetails?.name, [keyZoneDetails] );
  const activeFilter = useKeyZoneActiveFilter();
  const router = useRouter();
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    fetchKeyZone(versionId);
    return ()=>{
      setIsShown(false);
    };
  }, [versionId]);

  const toggle = () => {
    setIsShown(!isShown);
  };

  const handleEditClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.KEY_ZONE, { id: `${id}_edit` });
    },
    [router, id],
  );


  const handleItemClick = useCallback((versionId: any) => {
    dispatch(keyZoneActions.setActiveKeyZone(versionId));
    router.navigate(PAGE_ROUTES.KEY_ZONE, { id: versionId.toString() });
    setIsShown(!isShown);
  }, [dispatch, isShown, router]);

  const handleCreateClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.KEY_ZONE, { id: "new" });
    },
    [router],
  );

  const versionCaption = useCallback((itm: any) => {
    let versionString = "";
    if (itm?.startDate) {
      versionString =  `Версия от ${itm?.startDate}`;
    } else if (!itm?.startDate && itm?.endDate) {
      versionString =  `Версия до ${itm?.endDate}`;
    } else {
      versionString =  "Версия бессрочная";
    }
    return versionString;
  }, []);

  const handleEditVersionClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.KEY_ZONE, { id: `${id}_version` });
    },
    [router, id],
  );


  return (
    <DetailsPanelHeader
      title={name || labels.noValue}
      caption={<Container onClick={toggle} ><span >{versionCaption(keyZoneDetails)}</span><VersionViewer isShown={isShown} hide={toggle} modalContent={<VersionViewerContent currentVersion={keyZoneDetails} id={keyZoneDetails?.keyZoneId} onEdit={handleEditVersionClick} handleItemClick={handleItemClick} fetchVersions={getKeyZoneVersionsDates} />} headerText={"Версионность"} onCreateClick={handleCreateClick} /></Container>}
      icon={<KeyZoneCardIcon isActual={activeFilter === TabValues.ACTIVE}/>}
      rightAction={<>
        <Button content={Content.Icon} icon={<EditBlue32Icon />} onClick={handleEditClick} priority="quaternary" />
      </>

      }
    />
  );
};


