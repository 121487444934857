import { Chips, ChipsItem } from "components/common";
import { VehicleTypeArray } from "models";
import React, { useEffect, useMemo, useState } from "react";
import { labels } from "shared/constants";

export type FilterProps = {
  TransportTypes: string | undefined | null;
  updateValue: any;
  field: string;
};

export const Filter = (props: FilterProps) => {

  const { TransportTypes, updateValue, field } = props;
  const routeAllExp = useMemo(()=> TransportTypes !== undefined ? TransportTypes?.length === VehicleTypeArray.map(e=>e.id).toString().length : false, [TransportTypes, VehicleTypeArray]);
  const [vehicleAll, setVehicleAll] = useState(routeAllExp);
  const [transportTypeArray, setTransportTypeArray] = useState<string[]>(TransportTypes ? TransportTypes.split(",") : []);

  useEffect(() => {
    setVehicleAll(routeAllExp);
  }, [routeAllExp]);

  return (
    <Chips label={labels.vehicleType}>
      <ChipsItem isActive={vehicleAll} onClick={() => {
        if (vehicleAll) {
          updateValue(field, "");
          setTransportTypeArray([]);
          setVehicleAll(false);
        } else {
          updateValue(field, VehicleTypeArray.map(e => e.id).toString());
          setTransportTypeArray(VehicleTypeArray.map(e => e.id));
          setVehicleAll(true);
        }

      }} id={"all"} label={"Все"} />
      {VehicleTypeArray.map((chip) => (
        <ChipsItem
          key={chip.id}
          isActive={TransportTypes !== undefined ? TransportTypes?.split(",").findIndex(el => el === chip.id) !== -1 : false}
          {...chip}
          onClick={({ id }) => {
            const idx = transportTypeArray.indexOf(id);
            if (idx === -1) {
              setTransportTypeArray(oldArray => {
                const arr = [...oldArray, id];
                updateValue(field, arr.toString());
                return arr;

              });
            } else {
              setTransportTypeArray(oldArray => {
                const filtered = oldArray.filter((element) => element !== id);
                updateValue(field, filtered.toString());
                return filtered;
              });
            }
          }}
        />
      ))}
    </Chips>
  );
};

export const VehicleTypesFilter = React.memo(Filter);