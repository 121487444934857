import styled from "styled-components/macro";
import { Indicator } from "../indicator";

export const Container = styled(Indicator)`
  justify-content: flex-end;
`;

export const PlankWrapper = styled.div`
  height: calc(50% - 8px + 0.3em);
`;
