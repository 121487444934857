import { APP_ENV } from "app-env";
import { getVehicleType, VehicleTypes } from "../../../models";
import { grzNumberRegexSevastopol, trolleybusGRN, trolleybusGRNRegex, universalGRNRegion } from "./text-masks";

const maskScheme: Record<VehicleTypes, { mask: Array<string | RegExp>, regex: RegExp, maskPlaceholder: string; }> = {
  [VehicleTypes.Bus]: { mask: universalGRNRegion, regex: grzNumberRegexSevastopol, maskPlaceholder: "AA000777" },
  [VehicleTypes.Tram]: { mask: trolleybusGRN, regex: trolleybusGRNRegex, maskPlaceholder: "0000" },
  [VehicleTypes.Electrobus]: { mask: universalGRNRegion, regex: grzNumberRegexSevastopol, maskPlaceholder: "A000AA777" },
  [VehicleTypes.Trolleybus]: { mask: trolleybusGRN, regex: trolleybusGRNRegex, maskPlaceholder: APP_ENV.REACT_APP_CITY === "SEVASTOPOL" ? "АA0000RUS00" : "0000" },
};

export const grnMaskScheme = (transportType: string) => maskScheme[getVehicleType(transportType)];
