import React from "react";
import styled from "styled-components/macro";
import { Divider } from "components/common";
import { VehicleTabListPagination } from "features/detail-tabs";
import { Vehicles } from "./sections";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
`;

export const DepotDetailsVehiclesTab = (props: { VehicleIds?: string, DepotIds?: string, DriverIds?: string, ContractorIds?: string }) => {
  const { DepotIds = "", VehicleIds = "", DriverIds = "", ContractorIds = "" } = props;
  return (
    <Container>
      <VehicleTabListPagination VehicleIds={VehicleIds} DepotIds={DepotIds} DriverIds={DriverIds} ContractorIds={ContractorIds} />
      <Divider margin="8px 0" />
    </Container>);
};
