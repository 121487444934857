import { useCallback, useEffect, useState } from "react";
import { toastError, toastSuccess } from "components";
import { ErrorHandler } from "shared/helpers/error-handler";
import { addRouteRequest, editRouteRequest, getRouteFile, getRouteRequest, getRouteVersionRequest, RouteCreateRequestOptions, RouteVersion, Trip, TripStop } from "api";
import { RouteDetails } from "models";
import { useDispatch, useSelector } from "react-redux";
import { routeActions } from "store/actions";
import { useFileLoadLogic } from "features/file-load-logic";
import router from "router";
import { PAGE_ROUTES } from "shared/definitions";
import { useStopCurrentPage } from "store/selectors/stop-selectors";
import { RootState } from "store/types";
import { useGetRouteItemsByVersion } from "../route-details-panel.data";


export type NewRouteForm = {
  name: string,
  routeId: number;
  versionId?: number;
  number: string,
  transportType: string;
  contractor: RouteDetails["contractor"];
  startDate: string | null;
  capacityType: string | null;
  kind: number | null;
  endDate: string | null;
  trips: Trip[];
};

export type EditRouteForm = {
  versionId?: number,
  routeId: number,
  name: string,
  number: string,
  transportType: string;
  contractor: RouteDetails["contractor"];
  capacityType: string | null;
  startDate: string | null;
  endDate: string | null;
  kind: number | null;
  trips: Trip[];
};

function setProperty<T>(obj: T, key: keyof T, value: any) {
  // eslint-disable-next-line no-param-reassign
  obj[key] = value;
  return obj;
}

function deleteProperty<T>(obj: T, key: keyof T) {
  delete obj[key]; // eslint-disable-line no-param-reassign
}

export const initialForm: NewRouteForm = {
  routeId: 0,
  versionId: 0,
  number: "", // Номер трассы
  name: "", // Название трассы
  transportType: "", // Тип транспорта
  contractor: { name: "", id: 0 },
  capacityType: "",
  kind: null,
  startDate: null, // Время работы трассы
  endDate: null, // Время работы трассы
  trips: [],
};

const formToRequest = (form: NewRouteForm, details?: NewRouteForm): RouteCreateRequestOptions => {
  const { contractor, capacityType, trips, kind, ...rest } = form;

  const tripZones = trips.map((trip) => ({ ...trip, tripKeyZones: trip.tripKeyZones ? trip.tripKeyZones.map((zone, i) => ({ ...zone, sequenceNum: i + 1 })) : trip.tripKeyZones }));


  return {
    ...rest,
    capacityType: capacityType || "",
    contractor: form?.contractor || details?.contractor,
    appearance: { backgroundColor: "#FFFFFF", borderColor: "#000000", foregroundColor: "#000000" },
    startDate: form?.startDate || details?.startDate,
    endDate: form?.endDate || details?.endDate,
    kind: kind ? +kind : null,
    trips: tripZones || details?.trips || [],
  };
};

const formEditToRequest = (form: EditRouteForm, details?: RouteDetails): RouteCreateRequestOptions => ({
  ...form,
  capacityType: form.capacityType || "",
  routeId: details?.routeId || 0,
});

// eslint-disable-next-line consistent-return
const detailsToForm = (details: RouteDetails): EditRouteForm | any => {
  if (details) {
    const { depots, contractor, ...rest } = details || {};
    return {
      contractor: contractor ?? { name: "", id: 0 },
      ...rest,
    };
  }
};


const createCopyForm = (elm: RouteVersion, form: any): NewRouteForm => {
  if (elm) return {
    routeId: 0,
    number: elm.number || "", // Номер трассы
    name: elm.name || "", // Название трассы
    transportType: elm.transportType || "", // Тип транспорта
    contractor: elm.contractor || { name: "", id: 0 },
    kind: elm.kind || null,
    capacityType: elm.capacityType || "",
    startDate: elm.startDate || null, // Время работы трассы
    endDate: elm.endDate || null, // Время работы трассы
    trips: elm.trips || [],
  };
  return { ...form };
};

const getForm = (isNew: boolean, copyForm: any, editForm: any) => {

  if (isNew) {
    return initialForm;
  } if (copyForm) {
    return createCopyForm(copyForm, initialForm);
  }
  return detailsToForm(editForm);


};

export const useNewRouteLogic = (props: { isNew?: boolean, copyRoute?: any, setValue?: (name: keyof NewRouteForm, value: NewRouteForm[keyof NewRouteForm]) => void }) => {
  const { isNew = false, setValue, copyRoute = undefined } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const { routeDetails } = useGetRouteItemsByVersion();
  const [form, setForm] = useState<NewRouteForm>(getForm(isNew, copyRoute, routeDetails));
  const { createMetaDataArr } = useFileLoadLogic();
  const [files, setFiles] = useState<File[]>([]);
  const currentPage = useStopCurrentPage();
  const { routeActiveFilter } = useSelector((state: RootState) => ({
    routeActiveFilter: state.route.routeActiveFilter,
  }));
  
  const dispatch = useDispatch();

  const fetchFiles = useCallback(async (controller: AbortController, route: RouteDetails) => {
    if (!route?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(route.files.map(async fileElem => {
        const payload = await getRouteFile(route.routeId, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);

  const handleExecQuery = useCallback(async (verId: number) => {
    try {
      const payload = await getRouteVersionRequest(verId.toString(), { includeInfo: true, includeTrips: true, includeTripStops: true, includeTripsKeyZones: true });
      dispatch(routeActions.getRouteByVersionId(verId.toString(), payload));
      dispatch(routeActions.updateRoutesFiles(payload.routeId.toString(), payload));
      const response = await getRouteRequest({ pageNum: currentPage, pageSize: 200 });
      dispatch(routeActions.addRoutes(routeActiveFilter, response));
      router.navigate(PAGE_ROUTES.ROUTE, { id: verId });
    } catch (error) {
      router.navigate(PAGE_ROUTES.ROUTES);
    }
  }, [dispatch, currentPage, routeActiveFilter]);

  useEffect(() => {
    const controller = new AbortController();
    if (routeDetails && !isNew) {
      fetchFiles(controller, routeDetails);
    }
    return ()=>{
      controller.abort();
    };
  }, [routeDetails, fetchFiles, isNew]);

  useEffect(() => {
    if (routeDetails && !isNew) {
      setStartDate(routeDetails?.startDate ? routeDetails?.startDate : undefined);
      setEndDate(routeDetails?.endDate ? routeDetails?.endDate : undefined);
    }
  }, [isNew, routeDetails]);

  
  function editTripStop<T>(tripStop: T, start_Date: string | null, end_Date: string | null) {
    deleteProperty<T>(tripStop, "versionId" as keyof T);
    setProperty<T>(tripStop, "startDate" as keyof T, start_Date);
    setProperty<T>(tripStop, "endDate" as keyof T, end_Date);
    return tripStop;
  }

  const createRoute = useCallback(async (values: NewRouteForm) => {

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }

    const trips = form.trips.map((trip: Trip) => {
      deleteProperty<Trip>(trip, "versionId");
      if (!trip.endDate && !trip.startDate) {
        setProperty<Trip>(trip, "startDate", values.startDate);
        setProperty<Trip>(trip, "endDate", values.endDate);
      }
      const tripStops = trip?.tripStops ? trip.tripStops.map(tripStop => editTripStop<typeof tripStop>(tripStop, values.startDate, values.endDate)) : trip.tripStops;
      return { ...trip, tripStops };
    });
    addRouteRequest({ ...formToRequest(values, { ...form, trips } as NewRouteForm), files: fileArr }).then((el: any) => {
      toastSuccess({ title: "Успешно", message: `Добавлен ${values.number}` });
      handleExecQuery(el.versionId);
    })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      });

  }, [form, createMetaDataArr, files, handleExecQuery]);

  const editRoute = useCallback(async (values: EditRouteForm) => {

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }

    const reqObject = formEditToRequest(values, routeDetails);
    deleteProperty(reqObject, "versionId");


    const trips = form.trips.map((trip: Trip) => {
      deleteProperty<Trip>(trip, "versionId");
      setProperty<Trip>(trip, "tripId", null);
      setProperty<Trip>(trip, "startDate", values.startDate);
      setProperty<Trip>(trip, "endDate", values.endDate);
      const tripStops = trip?.tripStops ? trip.tripStops.map(tripStop => editTripStop<typeof tripStop>(tripStop, values.startDate, values.endDate)) : trip.tripStops;
      const tripKeyZones = trip?.tripKeyZones ? trip.tripKeyZones.map((zone, i) => ({ ...zone, sequenceNum: i + 1 })) : trip.tripKeyZones;
      return { ...trip, tripStops, tripKeyZones };
    });

    try {
      await addRouteRequest({
        ...reqObject,
        kind: reqObject.kind ? +reqObject.kind : null,
        files: fileArr,
        trips,
      })
        .then((el: any) => {
          toastSuccess({ title: "Успешно", message: `Изменен ${values.name}` });
          handleExecQuery(el.versionId);
        });
    } catch (error: any) {
      toastError({ title: "Error", message: error.response.data.toString() });
    }
    setIsLoading(false);
  }, [routeDetails, form, createMetaDataArr, files, handleExecQuery]);

  const editRouteVersion = useCallback(async (values: EditRouteForm) => {

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }

    const reqObject = formEditToRequest(values, routeDetails);


    const trips = form.trips.map((trip: Trip) => {
      setProperty<Trip>(trip, "startDate", values.startDate);
      setProperty<Trip>(trip, "endDate", values.endDate);
      const tripStops = trip?.tripStops ? trip.tripStops.map(tripStop => editTripStop<typeof tripStop>(tripStop, values.startDate, values.endDate)) : trip.tripStops;
      const tripKeyZones = trip?.tripKeyZones ? trip.tripKeyZones.map((zone, i) => ({ ...zone, sequenceNum: i + 1 })) : trip.tripKeyZones;
      return { ...trip, tripStops, tripKeyZones };
    });

    try {
      await editRouteRequest({
        ...reqObject,
        kind: reqObject.kind ? +reqObject.kind : null,
        files: fileArr,
        trips,
      }).then(() => {
        toastSuccess({ title: "Успешно", message: `Изменен ${values.name}` });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        reqObject.versionId && handleExecQuery(reqObject.versionId);
      });
    } catch (error: any) {
      toastError({ title: "Error", message: error.response.data.toString() });
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
  }, [routeDetails, form, createMetaDataArr, files, handleExecQuery]);


  const clearUp = useCallback(() => {
    (Object.keys(initialForm) as Array<keyof typeof initialForm>).map(el => setValue && setValue(`${el}`, initialForm[`${el}`]));
    setStartDate(undefined);
    setEndDate(undefined);
  }, [setValue]);

  return {
    form,
    editRoute,
    editRouteVersion,
    isLoading,
    createRoute,
    setForm,
    files,
    handleFiles: setFiles,
    startDate,
    endDate,
    initialForm,
    clearUp,
  };
};

