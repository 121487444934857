import { activeContainerCss } from "components/ui/card/card.styles";
import { withCBColor, withAccentColor } from "megapolis-uikit-latest/utils/styled-helpers";
import styled from "styled-components/macro";

export const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 12px;

  &:hover {
    background-color: ${withCBColor("secondary", "hover")};
    border: 1px solid ${withCBColor("secondary", "hover")};
  }

  &:focus-within {
    outline: none;
    border-color: transparent;
    box-shadow: inset 0 0 0 2px ${withAccentColor("blue", "l3")};
  }
  &:active {
    ${activeContainerCss};
  }

    ${(props) => props.isActive && activeContainerCss};
`;

export const CheckBoxWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center
`;