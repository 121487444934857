import { useEffect } from "react";
import mapboxgl, { VectorSourceImpl } from "mapbox-gl";
import { useSelector } from "react-redux";
import { useRoute } from "react-router5";
import { RootState } from "store/types";
import { getStopRequest } from "api";
import { APP_ENV } from "app-env";
import { MapLayerNames } from "../utils/consts/map.constants";

export const getActualStops = async () => {
  try {
    return (await getStopRequest().then(({ items }) => items)) as any[];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error();
    throw e;
  }
};

export const useActualKeyZones = (map?: mapboxgl.Map) => {
  const { searchSubstring } = useSelector((state: RootState) => ({
    searchSubstring: state.keyZone.keyZoneSearchSubstring,
  }));
  const { route: {
    name: pageRoute,
    params: { id },
  },
  } = useRoute();

  useEffect(() => {
    if (!map) return;
    
    const source = map?.getSource(MapLayerNames.TILE_SET_KEYZONE_SOURCE_ID) as VectorSourceImpl;
    if ( searchSubstring && searchSubstring?.length > 0) {
      source.setTiles([`${APP_ENV.REACT_APP_BASE_URL}/KeyZone/tile/{x}/{y}/{z}?isActive=true&nameSearch=${searchSubstring}`]);
    } else {
      source.setTiles([`${APP_ENV.REACT_APP_BASE_URL}/KeyZone/tile/{x}/{y}/{z}?isActive=true`]);
    } 
  }, [searchSubstring]);

  // useFilterStops(map);
};
