import React, { ButtonHTMLAttributes, InputHTMLAttributes } from "react";
import * as utils from "./button.utils";
import * as Components from "./components";
import * as Markup from "./button.styles";
import * as ButtonTypes from "./button.types";

const normalizedProps = (props: ButtonTypes.Props) => {
  const {
    content,
    priority = ButtonTypes.Priority.Primary as ButtonTypes.Priority,
    size = ButtonTypes.Size.Medium,
    kind = ButtonTypes.Kind.Info,
    isLoading = false,
    isDisabled = false,
  } = props;

  const text = utils.getTextByProps(props);
  const icon = utils.getIconByProps(props);
  const caption = utils.getCaptionByProps(props);

  return { content, priority, size, kind, isLoading, isDisabled, icon, text, caption };
};

type ButtonProps = ButtonTypes.Props & { cyData?: string };

const Component = (props: ButtonProps, ref?: React.ForwardedRef<HTMLButtonElement>) => {
  const { className, cyData } = props;
  const { content, priority, size, kind, isLoading, isDisabled, icon, text, caption } = normalizedProps(props);
  const Loader = <Components.Loader priority={priority} kind={kind} isDisabled={isDisabled} />;

  return (
    <Markup.Button
      className={className}
      content={content}
      priority={priority}
      kind={kind}
      size={size}
      disabled={isDisabled}
      onClick={props.onClick}
      ref={ref}
      data-cypress={cyData}>
      {!!isLoading && Loader}
      <Components.BeforeIcon content={content} icon={icon} />
      <Components.Text content={content} text={text} />
      <Components.Caption content={content} caption={caption} />
      <Components.AfterIcon content={content} icon={icon} />
    </Markup.Button>
  );
};

const ButtonMemo = React.memo(React.forwardRef(Component));

const Button = (props: ButtonProps) => <ButtonMemo {...props} />;

Button.Markup = Markup;
Button.Components = Components;
Button.Content = ButtonTypes.Content;
Button.Priority = ButtonTypes.Priority;
Button.Kind = ButtonTypes.Kind;
Button.Size = ButtonTypes.Size;

export { Button };
