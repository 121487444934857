import React, { useMemo, memo, useCallback } from "react";
import { Card } from "components/ui";
import { SeparatedInlineList, DetailRouteIcon } from "components/common";
import { VehicleData } from "models";

export type RouteCardProps = {
  /** Идентификатор */
  id: string;
  /** Наименование трассы(начальная-конечная точка) */
  routeName: string;
  /** Номер трассы */
  routeNumber: string;
  /** Данные о Транспортном средстве */
  vehicleData: VehicleData;
  /** Цвет иконки трассы */
  routeIconColor?: string;
  /** Цвет текста номера */
  routeNumberColor?: string;
  /** Цвет границ номера */
  routeBorderColor?: string;
  /** Признак активности карточки */
  isActive?: boolean;
  /** Обработчик нажатия на карточку */
  onClick?: (id: string) => void;
};

const RouteCardComponent = (props: RouteCardProps) => {
  const { id, vehicleData, routeNumber, routeName, routeIconColor, routeBorderColor, routeNumberColor, isActive = false, onClick } = props;
  const { transportType, routeType, contractorName } = vehicleData;

  const listItems = useMemo(
    () => [transportType, routeType, contractorName],
    [transportType, routeType, contractorName],
  );

  const handleClick = useCallback(() => onClick?.(id), [onClick, id]);

  const reverseColors = useCallback((mainColor, insideColor) => {
    let finColor = insideColor;
    if (mainColor === finColor) {
      switch (mainColor) {
        case "#FFFFFF":
          finColor = "#000000";
          break;
        case "#000000":
          finColor = "#FFFFFF";
          break;
        default:
          break;
      }
    }
    return finColor;
  }, []);

  return (
    <Card
      title={routeName}
      caption={<SeparatedInlineList items={listItems} />}
      icon={<DetailRouteIcon color={routeIconColor} border={routeBorderColor} ><span style={{ "color": reverseColors(routeIconColor, routeNumberColor) }}>{routeNumber}</span></DetailRouteIcon>}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};
export const RouteCard = memo(RouteCardComponent);
