import React from "react";
import { getDefaultColor, getHoverColor, getActiveColor, getDisabledColor } from "./before-icon.styles.utils";
import { Container } from "./before-icon.styles";
import { TContent, Content } from "../../button.types";

interface Props {
  /** Тип контента */
  content: TContent;
  /** Иконка */
  icon: React.ReactNode;
}

const BeforeIcon = (props: Props) => {
  switch (props.content) {
    case Content.IconText:
    case Content.IconCaption:
    case Content.Icon:
      return <Container>{props.icon}</Container>;
    default:
      return null;
  }
};

BeforeIcon.Markup = { Container, getDefaultColor, getHoverColor, getActiveColor, getDisabledColor };

export { BeforeIcon };
