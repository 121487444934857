/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Trip } from "api";
import mapboxgl from "mapbox-gl";
import { createNewDataTrips, createNewDataTripsKeyZones, createNewDataTripsStop } from "./utils";

export const selectedTrip = "selectedTrip";
export const selectedTripStops = "selectedTripStops";
export const selectedTripKeyZones = "selectedTripKeyZones";

export const selectedTripController = (
  trip: Trip,
  map?: mapboxgl.Map,
) => {
  if (!map) return;
  const { path, tripStops, tripKeyZones, direction } = trip;
  const backgroundColor = "#0066ff";
  let newTripStops;
  let newKeyZones;

  if (!path?.coordinates) return;

  const newData = createNewDataTrips(path, direction, backgroundColor);

  if (tripStops) {
    newTripStops = createNewDataTripsStop(tripStops);
  }

  if (tripKeyZones) {
    newKeyZones = createNewDataTripsKeyZones(tripKeyZones);
  }




  const prevSource = map.getSource(selectedTrip);
  const prevSourceTripStops = map.getSource(selectedTripStops);
  const prevSourceTripKeyZones = map.getSource(selectedTripKeyZones);

  if (!prevSource) {
    map.addSource(selectedTrip, {
      type: "geojson",
      data: newData,
      generateId: true,
    } as mapboxgl.AnySourceData);
  } else {
    // @ts-ignore
    prevSource?.setData(newData);
  }

  if (newTripStops && !prevSourceTripStops) {
    map.addSource(selectedTripStops, {
      type: "geojson",
      data: newTripStops,
      generateId: true,
    } as mapboxgl.AnySourceData);
  } else {
    // @ts-ignore
    prevSourceTripStops?.setData(newTripStops);
  }

  if (newKeyZones && !prevSourceTripKeyZones) {
    map.addSource(selectedTripKeyZones, {
      type: "geojson",
      data: newKeyZones,
      generateId: true,
    } as mapboxgl.AnySourceData);
  } else {
    // @ts-ignore
    prevSourceTripKeyZones?.setData(newKeyZones);
  }
};
