import React, { memo } from "react";
import * as Markup from "./map.styles";
import "mapbox-gl/dist/mapbox-gl.css";
import { useMap } from "./map.context";

const MainMapComponent = () => {
  const { ref } = useMap();
  return (

    <Markup.MapWrapper ref={ref} id={"map"} />
  );
};

export const MainMap = memo(MainMapComponent);
