import styled from "styled-components";
import { withCBColor, withContentColor } from "@megapolis/uikit/utils/styled-helpers";
import { switchProp } from "styled-tools";
import { TabBase } from "../tab-base";

interface ButtonProps {
  active: boolean;
}

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 8px;
  border: 1px solid ${withCBColor("secondary", "active")};
  border-radius: 6px;
  background-color: ${switchProp("active", {
    true: withCBColor("secondary", "active"),
    false: "transparent",
  })};
  max-height: 32px;

  &:disabled,
  &[disabled] {
    pointer-events: none;
    color: ${withContentColor("tertiary")} !important;
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin: 4px 0;
  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export const Content = styled(TabBase)`
  padding: 8px 0;
`;
