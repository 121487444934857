import styled from "styled-components";
import { switchProp } from "styled-tools";
import { withColors } from "shared/utils";
import { withCBColor, withContentColor } from "@megapolis/uikit/utils/styled-helpers";

interface SidebarItemWrapperProps {
  isActive?: boolean;
  isDisabled?: boolean;
}

export const Wrapper = styled.button<SidebarItemWrapperProps>`
  width: 100%;
  display: flex;
  border-radius: 6px;
  padding: 3px 10px 3px;
  background-color: ${switchProp("isActive", {
    true: withCBColor("secondary", "active"),
    false: "transparent",
  })};
  // prettier-ignore
  border: 1px solid ${switchProp("isActive", {
    true:  withCBColor("secondary", "active"),
    false: "transparent",
  })};
  cursor: ${switchProp("isDisabled", {
    true: "auto",
    false: "pointer",
  })};
  user-select: none;
`;

export const Icon = styled.div<{ isDisabled?: boolean; }>`
  display: flex;
  justify-content: flex-start;
  max-height: 24px;
  width: 24px;
  margin: auto 8px auto 0;
  .Icon {
    width: 24px;
    height: 24px;
    margin: auto;
  }
  svg {
    width: 100%;
    display: block;
    color: ${switchProp("isDisabled", {
    true: withContentColor("tertiary"),
    false: withColors(({ blue }) => blue.basic),
  })}
  }
`;

export const Context = styled.div<{ isDisabled?: boolean; }>`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: auto 0 auto;
  color: ${switchProp("isDisabled", {
    true: withContentColor("tertiary"),
    false: "inherit",
  })}
`;

export const Counter = styled.div`
  height: 20px;
  background-color: ${withCBColor("secondary", "active")};
  margin-left: auto;
  margin-right: 0;
  padding: 2px;
  text-align: center;
  box-sizing: content-box;
  border-radius: 12px;
  min-width: 20px;
  font-size: 12px;
  line-height: 20px;
`;
