import React from "react";
import { useRouteParams } from "shared/hooks";
import { ScheduleDetails } from "./schedule-details";

export const ScheduleDetailsPanel = () => {
  const { id } = useRouteParams();
  const isNew = id === "new";
  const isEdit = id.indexOf("edit") > -1;
  return <ScheduleDetails />;
};
