import { AxiosRequestConfig } from "axios";
import * as api from "services";
import { GetAllScheduleRequestOptions, GetScheduleByRouteIdResponce, GetScheduleByVersionIdRequestOptions, GetScheduleRequestOptions, ScheduleItem, ScheduleStopTimesResponce } from "./types";

const requestPath = "Schedule";

export const getAllScheduleRequest = (options?: GetAllScheduleRequestOptions) => api.get<any>(`${requestPath}/`, options);

export const getScheduleRequest = (options?: GetScheduleRequestOptions) => api.get<GetScheduleByRouteIdResponce>(requestPath, options);

export const getScheduleStopTimesRequest = (options: { scheduleVersionId: number }) => api.get<ScheduleStopTimesResponce[]>(`${requestPath}/stop-times`, options);

export const getScheduleByVersionIdRequest = (params: GetScheduleByVersionIdRequestOptions, options?: AxiosRequestConfig) => api.get<any>(`${requestPath}/${params.versionId}`, {}, options);


