/* eslint-disable @typescript-eslint/no-shadow */

import { DriverActionType, DriversAction } from "store/actions/driver-actions/driver-actions.types";
import { findIndexInArrayOfItemArray, updateItemInArrayByIndex } from "shared/utils/array";
import { IInitialDriverState } from "./driver.types";
import { initialState } from "./driver.constants";

export const driverReducer = (state: IInitialDriverState = initialState, action: DriversAction) => {
  switch (action.type) {

    case DriverActionType.SET_DRIVERS: {
      const { payload } = action;
      const prevData = state[payload.status]?.items ?? [];
      prevData[payload.data.pageIndex || 0] = payload.data.items;
      return { ...state, [payload.status]: { ...payload.data, items: prevData } };
    }

    case DriverActionType.SET_DRIVER_FILTERS: {
      const { payload } = action;
      return { ...state, driverFilters: payload };
    }

    case DriverActionType.SET_ACTIVE_DRIVER_ID: {
      const { payload } = action;
      return { ...state, activeDriverId: payload };
    }

    case DriverActionType.SET_DRIVER_BY_VERSION_ID: {
      const { payload } = action;

      return {
        ...state,
        driverByVersionId: {
          ...state.driverByVersionId,
          [payload.versionId]: payload.data,
        },
      };
    }

    case DriverActionType.SET_DRIVER_SEARCH_SUBSTRING: {
      const { payload } = action;

      return { ...state, driverSearchSubstring: payload };
    }

    case DriverActionType.UPDATE_DRIVER_BY_VERSION: {
      const { payload } = action;
      const { versionId, data } = payload;
      const currentItemsKey = state.driverActiveFilter;
      const details = state.driverByVersionId[versionId] ?? {};
      const updatedDetails = { ...details, ...payload.data };

      const items = state[currentItemsKey]?.items ?? [];
      const [page, index] = findIndexInArrayOfItemArray(items, (item) => item.versionId === Number(versionId));
      items[page] = updateItemInArrayByIndex(items[page], index, updatedDetails);
      const updatedItems = { ...state[currentItemsKey], items };

      return {
        ...state,
        [currentItemsKey]: updatedItems,
        vehicleByVersionId: {
          ...state.driverByVersionId,
          [versionId]: updatedDetails,
        },
      };
    }

    case DriverActionType.UPDATE_DRIVERS_FILES: {
      const { payload } = action;
      const { driverId, data } = payload;
      const details = Object.values(state.driverByVersionId);
      const driversById = details.filter(driver => driver.driverId === driverId);
      const updated = driversById.reduce(
        (accumulator, driver) => ({ ...accumulator, [driver.versionId.toString()]: { ...driver, files: data.files } }),
        {},
      );
     
      return {
        ...state,
        driverByVersionId: {
          ...state.driverByVersionId,
          ...updated,
        },
      };
    }

    case DriverActionType.SET_DRIVER_ACTIVE_FILTER: {
      const { payload } = action;

      return {
        ...state,
        driverActiveFilter: payload,
      };
    }

    default:
      return state;
  }
};
