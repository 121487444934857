import styled from "styled-components/macro";
import { withNeutralColor } from "shared/utils";

export const Heading = styled.header`
  flex-grow: 1;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: ${withNeutralColor("d4")};
`;

export const ButtonsList = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;
