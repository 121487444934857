import React, { useMemo } from "react";
import { BlockHeader, Box, InfoCard } from "components/common";
import { RouteCardList } from "features/card-lists";
import { mockItems } from "features/list-panels/route-list-panel/mock";
import { labels } from "shared/constants";
import { v4 } from "uuid";
import styled from "styled-components";
import { useGetKeyZoneItemsByVersion } from "../../keyzone-details-panel.data";
import { InfoCards } from "../../new-keyzone/new-keyzone-panel.styles";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const Header = styled.span`
  font-weight: 600;
`;


export const KeyZoneDetailsRoutesTab = () => {
  const { keyZoneDetails } = useGetKeyZoneItemsByVersion();

  const infoCards = useMemo(() => [
    {
      title: "Описание",
      text: keyZoneDetails?.description || labels.noValue,
    },
    {
      title: "Маршруты",
      text: keyZoneDetails?.routes ? keyZoneDetails?.routes.map((el: { id: number, name: string }) => el.name).join(", ") : labels.noValue,
    },
  ], [keyZoneDetails]);



  return (
    <Box direction="column" padding="0 24px">
      <Container>
        <BlockHeader left={<Header>Общие сведения информация</Header>} />
        <InfoCards>
          {infoCards.map((card: { title: string; text: string }) => (
            <InfoCard key={v4()} title={card.title}>
              {card.text}
            </InfoCard>
          ))}
        </InfoCards>
      </Container>
    </Box>

  );
};
