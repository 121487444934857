import { Trip } from "api";
import { Contractor } from "./contractor";
import { VehicleData } from "./vehicle";

type WithId<T extends Record<string, unknown>> = { id: string | number } & T;

export enum TripModeTypes {
  Usual = "Usual",
  Request = "Request",
  Technical = "Technical",
  Boarding = "Boarding",
}

export enum TripTopologyTypes {
  Pendular = "Pendular",
  Ring = "Ring",
}

/** Маршрут */
export type Route = {
  id: string;
  /** Наименование маршрута(начальная-конечная точка) */
  routeName: string;
  /** Номер маршрута */
  routeNumber: string;
  /** Данные о Транспортном средстве */
  vehicleData: VehicleData;
  /** Цвет иконки маршрута, ВРЕМЕННО */
  routeIconColor?: string;
};

export type RouteRequestItem = {
  /** Массив маршрутов */
  items: Route[];
  /** Номер страницы */
  pageIndex: number;
  /** Размер страниц */
  pageSize: number;
  /** Общее число */
  totalCount: number;
};

export type RouteDetails = {
  /** Идентификатор версии */
  versionId?: number,
  /** Идентификатор маршрута */
  routeId: number,
  /** Имя маршрута */
  name: string,
  /** Номер маршрута */
  number: string,
  /** Тип транспорта */
  transportType: string,
  /** Категория маршрута */
  kind: number,
  /** Топология */
  topology: string,
  /** Класс вместимости ТС */
  capacityType?: string,
  /** Время окончания действия версии */
  endDate: string | null,
  /** Время начала действия версии */
  startDate: string | null,
  /** Представляет сведения о цветовых обозначениях, ассоциируемых с маршрутом */
  appearance: {
    /** Цвет фона (по умолчанию - чёрный) */
    backgroundColor: string,
    /** Цвет рамки (по умолчанию рамка отсутствует) */
    borderColor: string,
    /** Цвет текста (по умолчанию - белый) */
    foregroundColor: string
  },
  /** файлы */
  files?: {
    id: string,
    name: string,
    sizeInBytes: number,
    mimeType: string,
  }[],
  /** Средняя протяженность маршрута, км */
  averageLength: number,
  /** Представление произвольного объекта (только идентификатор и наименование) */
  contractor: { id: number, name: string },
  /** Краткие сведения о парках, обслуживающих маршрут */
  depots: { id: number, name: string }[],

  trips: Trip[],
};

