import styled from "styled-components";
import { withNeutralColor } from "shared/utils";

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
  padding: 16px;
  button:first-child {
    width: 100%;
  }
`;

export const LabelButton = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-weight: 500;
  justify-content: space-between;
`;

export const LabelContainer = styled.span`
  margin-bottom: 8px;
`;

export const Label = styled.span`
  font-weight: 500;
  line-height: 16px;
  padding: 0 8px 4px 8px;
  width: 100%;
`;

export const Container = styled.div`
flex-grow: 1;
height: 100%;
padding: 8px 16px;
`;

