import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastError, toastSuccess } from "components";
import { vehicleActions } from "store/actions/vehicle-actions/vehicle-actions";
import { VehicleDetails } from "models";
import { createVehicleRequest, editVehicleRequest, getVehicleFile, getVehicleRequest, getVehicleVersionRequest, VehicleCreateRequestOptions, VehicleFeatures } from "api";
import { useNavigateToPreviousRoute } from "shared/hooks/use-navigate-to-previous-route";
import { useFileLoadLogic } from "features/file-load-logic";
import { PAGE_ROUTES } from "shared/definitions";
import router from "router";
import { RootState } from "store/types";
import { useStopCurrentPage } from "store/selectors/stop-selectors";
import { useGetVehicleItemsByVersion } from "../vehicle-details-panel.data";

export type EditVehicleForm = {
  brand: string;
  model: string;
  plate: string;
  tmId: number | null;
  transportType: string;
  // depot: VehicleDetails["depot"] | null;
  contractor: VehicleDetails["contractor"] | null;
  vin: string;
  manufactureDate: string | null;
  commissioningDate: string | null;
  capacityType: string;
  startDate: string | null;
  endDate: string | null;
} & VehicleFeatures;

export type SetValue = (name: keyof EditVehicleForm, value: EditVehicleForm[keyof EditVehicleForm]) => void;

const initialForm: EditVehicleForm = {
  brand: "",
  model: "",
  plate: "",
  tmId: null,
  transportType: "А",
  // depot: null,
  contractor: null,
  manufactureDate: null,
  commissioningDate: null,
  vin: "",
  capacityType: "",
  lowFloor: false,
  climateSystem: false,
  startDate: null,
  endDate: null,
};


const formToRequest = (form: EditVehicleForm, details?: VehicleDetails): VehicleCreateRequestOptions => {
  const { contractor, model, lowFloor, climateSystem, ...rest } = form;

  return {
    ...rest,
    id: details?.id || null,
    startDate: form?.startDate || null,
    features: { climateSystem, lowFloor },
    endDate: form?.endDate || null,
    // depotId: Number(depot?.id || details?.depot?.id || contractor?.id || null),
    contractorId: Number(contractor?.id),
    versionId: Number(details?.versionId),
    model: model || null,
  };
};

const detailsToForm = (details: VehicleDetails): EditVehicleForm => {
  const { depot, contractor, diagnosticCard: currentDiagnosticCard, vin = "", features, startDate = null, endDate = null, manufactureDate, commissioningDate, capacityType, ...rest } = details || {};

  const vehicleFeatures: VehicleFeatures = {
    lowFloor: features?.lowFloor || false,
    climateSystem: features?.climateSystem || false,
  };

  return {
    // depot: depot ?? null,
    contractor: contractor ?? null,
    manufactureDate: manufactureDate || null,
    commissioningDate: commissioningDate || null,
    capacityType: capacityType || "",
    endDate,
    startDate,
    vin,
    ...vehicleFeatures,
    ...rest,
  };
};

export const useEditVehicleLogic = (isNew?: boolean, setValue?: SetValue) => {
  const [isLoading, setIsLoading] = useState(false);
  const { vehicleDetails, versionId } = useGetVehicleItemsByVersion();
  const dispatch = useDispatch();
  const [form, setForm] = useState(isNew ? initialForm : detailsToForm(vehicleDetails));
  const { createMetaDataArr } = useFileLoadLogic();
  const [files, setFiles] = useState<File[] | undefined>(undefined);
  const navigateToPrevPage = useNavigateToPreviousRoute();
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const currentPage = useStopCurrentPage();
  const { vehicleActiveFilter } = useSelector((state: RootState) => ({
    vehicleActiveFilter: state.vehicle.vehicleActiveFilter,
  }));

  const fetchFiles = useCallback(async (controller: AbortController, vehicle: VehicleDetails) => {
    if (!vehicle?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(vehicle.files.map(async fileElem => {
        const payload = await getVehicleFile(vehicle.id, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (vehicleDetails && !isNew) {
      fetchFiles(controller, vehicleDetails);
    }
    return ()=>{
      controller.abort();
    };
  }, [vehicleDetails, fetchFiles, isNew]);

  useEffect(() => {
    if (isNew) {
      setForm(initialForm);
    }
  }, [isNew]);

  useEffect(() => {
    if (vehicleDetails && !isNew) {
      setForm(detailsToForm(vehicleDetails));
      setStartDate(vehicleDetails?.startDate ? vehicleDetails?.startDate : undefined);
      setEndDate(vehicleDetails?.endDate ? vehicleDetails?.endDate : undefined);
    }
  }, [vehicleDetails, isNew]);


  const handleExecQuery = useCallback(async (verId: number) => {
    try {
      const payload = await getVehicleVersionRequest(verId.toString());
      dispatch(vehicleActions.setVehicleByVersionId(verId.toString(), payload));
      dispatch(vehicleActions.updateVehiclesFiles(payload.id, payload));
      const response = await getVehicleRequest({ pageNum: currentPage, pageSize: 200 });
      dispatch(vehicleActions.addVehicles(vehicleActiveFilter, response));
      router.navigate(PAGE_ROUTES.VEHICLE, { id: verId });
    } catch (error) {
      router.navigate(PAGE_ROUTES.VEHICLES);
    }

  }, [dispatch, currentPage, vehicleActiveFilter]);

  const editVehicle = useCallback(async (values: EditVehicleForm) => {
    setIsLoading(true);

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files || []);
    } catch (error) {
      return;
    }

    createVehicleRequest({ ...formToRequest(values, vehicleDetails), files: fileArr })
      .then(async () => {
        toastSuccess({ title: "Успешно", message: `Изменен ${values.plate}` });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        vehicleDetails.versionId && handleExecQuery(vehicleDetails.versionId);
      })
      .catch(error => toastError({ title: "Error", message: error.response.data.toString() })).finally(() => {
        setIsLoading(false);
      });
  }, [vehicleDetails, versionId, createMetaDataArr, files, dispatch, navigateToPrevPage]);

  const editVersionVehicle = useCallback(async (values: EditVehicleForm) => {
    setIsLoading(true);

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files || []);
    } catch (error) {
      return;
    }

    editVehicleRequest({ ...formToRequest(values, vehicleDetails), versionId, files: fileArr })
      .then(async () => {
        toastSuccess({ title: "Успешно", message: `Изменен ${values.plate}` });
        const payload = await getVehicleVersionRequest(versionId);
        dispatch(vehicleActions.updateVehicleByVersion(versionId, payload));
        handleExecQuery(+versionId);
      })
      .catch(error => toastError({ title: "Ошибка", message: error.response.data.toString() })).finally(() => {
        setIsLoading(false);
      });
  }, [vehicleDetails, versionId, createMetaDataArr, files, dispatch, navigateToPrevPage]);

  const createVehicle = useCallback(async (values: EditVehicleForm) => {
    setIsLoading(true);
    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files || []);
    } catch (error) {
      return;
    }
    createVehicleRequest({ ...formToRequest(values, vehicleDetails), files: fileArr }).then((el: any) => {
      toastSuccess({ title: "Успешно", message: `Добавлен ${values.plate}` });
      handleExecQuery(el.versionId);
    }).catch(error => toastError({ title: "Ошибка", message: error.response.data.toString() })).finally(() => {
      setIsLoading(false);
    });

  }, [vehicleDetails, createMetaDataArr, files, navigateToPrevPage]);

  const clearUp = useCallback(() => {
    (Object.keys(initialForm) as Array<keyof typeof initialForm>).map(el => setValue && setValue(`${el}`, initialForm[`${el}`]));
    setFiles(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
  }, [setValue]);

  return {
    form,
    editVehicle,
    editVersionVehicle,
    createVehicle,
    handleFiles: setFiles,
    files,
    isLoading,
    clearUp,
    startDate,
    endDate,
  };
};
