import { css, DefaultTheme } from "styled-components";
import * as ButtonTypes from "../../button.types";

export const getDefaultColor = (props: ButtonTypes.DependenciesStyleProps) => (theme: DefaultTheme) =>
  css`
    color: ${theme.button.default[props.priority][props.kind].text?.color};
  `;

export const getHoverColor = (props: ButtonTypes.DependenciesStyleProps) => (theme: DefaultTheme) =>
  css`
    color: ${theme.button.hover[props.priority][props.kind].text?.color};
  `;

export const getActiveColor = (props: ButtonTypes.DependenciesStyleProps) => (theme: DefaultTheme) =>
  css`
    color: ${theme.button.active[props.priority][props.kind].text?.color};
  `;

export const getDisabledColor = (props: ButtonTypes.DependenciesStyleProps) => (theme: DefaultTheme) =>
  css`
    color: ${theme.button.disabled[props.priority][props.kind].text?.color};
  `;
