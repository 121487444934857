import React, { memo } from "react";
import { Tab } from "components/ui";
import { DetailsPanelTabs } from "../../../components";
import { TAB_VALUES } from "../stop-station-details.constants";

export type TabsProps = {
  value: string;
  onChange: (val: string) => void;
};

const StopStationDetailsTabsComponent = (props: TabsProps) => {
  const { value, onChange } = props;
  return (
    <DetailsPanelTabs value={value} onChange={onChange}>
      <Tab value={TAB_VALUES.info}>Справка</Tab>
      <Tab value={TAB_VALUES.route}>Маршруты</Tab>
    </DetailsPanelTabs>
  );
};

export const StopStationDetailsPanelTabs = memo(StopStationDetailsTabsComponent);
