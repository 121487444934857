import React from "react";
import styled from "styled-components/macro";
import { TripsDirections } from "./sections/direction/trips-directions";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

export const RouteDetailsRouteTab = () => (
  <Container>
    <TripsDirections />
  </Container>
);
