import styled, { css } from "styled-components";
import { withAccentColor, withNeutralColor } from "shared/utils";

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
  padding: 16px;
  button:first-child {
    width: 100%;
  }
`;

export const Container = styled.div`
flex-grow: 1;
height: 100%;
padding: 8px 16px;
`;

export const MainBlock = styled.div`
  flex-grow: 1;
  height: 80%;
  padding: 8px 16px;
`;

const requiredLabelCss = css`
  &::after {
    content: "*";
    color: ${withAccentColor("red", "basic")};
  }
`;

export const LabelContainer = styled.span`
  margin-bottom: 8px;
`;

export const Label = styled.span`
  font-weight: 500;
  line-height: 16px;
  padding: 0 8px 4px 8px;
  width: 100%;
`;