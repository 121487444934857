import { useGetStopItemsByVersion } from "../stop-station-details.data";
import { labels } from "../../../../../shared/constants";
import { formatDateToString } from "../../../../../shared/utils/date";

export const useGetStopStationInfoTabData = () => {
  const { item } = useGetStopItemsByVersion();

  // let pavilionBalanceHolder = "Не указано";
  // if (item && "pavilionBalanceHolder" in item) {
  //   pavilionBalanceHolder = item.pavilionBalanceHolder || labels.noValue;
  // }

  const infoCards = [
    {
      title: "Адрес",
      text: item?.address || labels.noValue,
    },
    {
      title: "Улица",
      text: item?.street || labels.noValue,
    },
    {
      title: "Версия от",
      text: item?.startDate ? formatDateToString(new Date(item.startDate)) : labels.noValue,
    },
    {
      title: "Балансодержатель",
      text: labels.noValue,
    },
    {
      title: "Координаты",
      text: `${item?.lat || labels.noValue}; ${item?.lng || labels.noValue}`,
    },
    {
      title: "Кнопка 112",
      text: item.features.alarmButton ? "Да" : "Нет",
    },

    {
      title: "ТПП",
      text: item.features.forecastBoard ? "Да" : "Нет",
    },

    {
      title: "Освещение",
      text: item.features.light ? "Да" : "Нет",
    },

    {
      title: "Пешеходный переход",
      text: item.features.pedestrianPass ? "Да" : "Нет",
    },

    {
      title: "Урна",
      text: item.features.wasteBin ? "Да" : "Нет",
    },

    {
      title: "WI-FI",
      text: item.features.wiFi ? "Да" : "Нет",
    },

    {
      title: "USB-зарядка",
      text: item.features.usbCharge ? "Да" : "Нет",
    },
    {
      title: "Наличие павильона",
      text: item?.hasPavilion ? "Да" : "Нет",
    },
  ];

  return { infoCards };
};
