import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRoute, useRouter } from "react-router5";
import { Box, Chips, ChipsItem, Divider, InfoCard } from "components/common";
import { Toggle } from "@megapolis/uikit";
import { UploadArea } from "components";
import { DatePicker } from "megapolis-uikit-latest";
import { PAGE_ROUTES } from "shared/definitions";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { VehicleClassTypeArray } from "models";
import { getDateToBack } from "models/date.utils";
import { Loader } from "megapolis-uikit-latest/loader";
import { DetailsBlock, FormField } from "../../components";
import { labels } from "../../../../shared/constants";
import { Option } from "../../../../components/common/select/select.types";
import { EditVehicleForm, SetValue, useEditVehicleLogic } from "./use-edit-vehicle-logic";
import { ContractorSelectField } from "../../../../components/common/select-with-source";
import { grnMaskScheme } from "../../../../components/ui/text-masks/grm-scheme";
import { Field } from "../../../../components/common/form/field";
import { PanelForm } from "../../../panel-form";
import { chips } from "./mocks";
import * as Markup from "./edit-vehicle-panel.styles";
import { composeValidators, isCorrectEndDate, isCorrectStartDate, isFromToTM, isMatchesTo, isNotEmptyString, isRequired } from "../../../../shared/utils/validator";
import { vinRegex } from "../../../../components/ui/text-masks/text-masks";

export const EditVehiclePanel = (props: { isNew?: boolean, changeVersion?: boolean }) => {
  const { isNew = true, changeVersion = false } = props;
  const referSetValue = useRef<SetValue>();
  const { form, createVehicle, editVehicle, editVersionVehicle, isLoading, startDate: oldStartDate, endDate: oldEndDate, files, handleFiles, clearUp } = useEditVehicleLogic(isNew, referSetValue.current);
  const [transportType, setTransportType] = useState(form.transportType);
  const { activeId } = useSelector((state: RootState) => ({
    activeId: state.vehicle.selectedVehicle,
  }));

  const [startDate, setStartDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");

  const title = isNew ? labels.createVehicle : labels.editVehicle;

  const { mask: plateMask, maskPlaceholder, regex } = useMemo(() => grnMaskScheme(transportType), [transportType]);

  const router = useRouter();
  const { previousRoute } = useRoute();

  useEffect(() => {
    if (isNew) clearUp();
    return () => {
      clearUp();
      // setTransportType("");
    };
  }, [clearUp, isNew]);

  const handleClosePanel = useCallback(() => {
    if (previousRoute) {
      // router.navigate(previousRoute.name, previousRoute.params);
      router.navigate(PAGE_ROUTES.VEHICLE, { id: activeId });
    } else {
      router.navigate(PAGE_ROUTES.VEHICLES);
    }
  }, [activeId, previousRoute, router]);

  const addDays = useCallback((date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }, []);

  const handleSubmit = useCallback((values: EditVehicleForm) => {
    if (isNew) {
      createVehicle(values);
    } else if (changeVersion) {
      editVersionVehicle(values);
    } else {
      editVehicle(values);
    }
  }, [isNew, createVehicle, editVehicle]);

  const getValueForSelect = (value?: { id: string | number; name: string } | null): Option | null => {
    if (value) {
      return {
        value: String(value.id),
        label: value.name,
      };
    }

    return null;
  };

  const getCapaсityTypes = useCallback(async () => {
    // const CapaсityTypes = await getVehicleCapacityTypesRequest();
    // const result = CapaсityTypes.map((item: { shortName: any; name: any; }) => ({ id: item.shortName, label: item.name }));
    // setCapaсityTypes(VehicleClassTypeArray);
  }, []);

  const scheme = useMemo(() => ({
    brand: isRequired("Введите название марки"),
    plate: composeValidators(isNotEmptyString("Введите номерной знак"), isMatchesTo("Неправильный формат", regex)),
    transportType: isRequired("Укажите тип транспорта"),
    // depot: isRequired("Укажите парк"),
    tmId: composeValidators(isRequired("Укажите ID бортового оборудования"), isFromToTM("Неправильно введено ID бортового оборудования", form.tmId)),
    contractor: isRequired("Укажите перевозчика"),
    startDate: isCorrectStartDate("Дата начала действия трассы не может быть больше даты окончания", endDate || ""),
    endDate: isCorrectEndDate("Дата окончания действия трассы не может быть меньше даты начала", startDate || ""),
    vin: isMatchesTo("Неправильный формат VIN", vinRegex, true),
  }), [form.tmId, regex, startDate, endDate]);

  useEffect(() => {
    getCapaсityTypes();
  }, []);

  return (
    <PanelForm
      title={title}
      initialValues={form}
      validationScheme={scheme}
      onSubmit={handleSubmit}
      onCancel={handleClosePanel}
      isLoading={isLoading}
    >
      {
        ({ names, values, updateValue: setValue, errors }) => {
          referSetValue.current = setValue;
          return (<>
            <Field values={values} updateValue={setValue} name={names.plate} errors={errors} type="masked-text" mask={plateMask} label={labels.grz} cyData="edit-transport-plate" placeholder={maskPlaceholder} />
            <Field values={values} updateValue={setValue} name={names.brand} errors={errors} cyData="edit-transport-brand" type="text" label={labels.brand} />
            <Field values={values} updateValue={setValue} name={names.model} errors={errors} cyData="edit-transport-model" type="text" label={labels.model} />
            <FormField>
              <Chips cyData="transport" label={labels.vehicleType}>
                {chips.map((chip) => (
                  <ChipsItem
                    key={chip.id}
                    {...chip}
                    isActive={values.transportType === chip.id}
                    onClick={({ id }) => {
                      // setValue(names.plate, null);
                      setValue(names.transportType, id);
                      setTransportType(id);
                    }}
                  />
                ))}
              </Chips>
            </FormField>
            <FormField>
              <Markup.Label>{"Состав элементов"}</Markup.Label>
              <Markup.InfoCards>
                <InfoCard>
                  <Toggle value={values.lowFloor || false} onChange={(e) => { setValue("lowFloor", e); }}><Markup.LabelContainer>{"Низкий пол"}</Markup.LabelContainer> </Toggle>
                </InfoCard>
                <InfoCard>
                  <Toggle value={values.climateSystem || false} onChange={(e) => { setValue("climateSystem", e); }}><Markup.LabelContainer>{"Климатическая система"}</Markup.LabelContainer> </Toggle>
                </InfoCard>
              </Markup.InfoCards>
            </FormField>
            <FormField>
              <Chips label={labels.vehicleClassType}>
                {VehicleClassTypeArray.map((chip) => (
                  <ChipsItem
                    key={chip.id}
                    isActive={chip.id === values.capacityType}
                    {...chip}
                    onClick={({ id }) => setValue("capacityType", id)}
                  />
                ))}
              </Chips>
            </FormField>
            <Field values={values} updateValue={setValue} name={"tmId"} errors={errors} cyData="edit-transport-tmId" type="text" label={labels.onboardEquipmentId} />
            <FormField>
              <ContractorSelectField
                value={getValueForSelect(values.contractor)}
                onChange={({ value, label }) => setValue(names.contractor, { id: value, name: label })}
                label={<Markup.Label>{labels.contractor}</Markup.Label>}
                cyData="edit-transport-contractor"
                date={ values?.startDate ? new Date(values.startDate).toLocaleDateString("sv-SE", { year: "numeric", month: "2-digit", day: "2-digit" }) : undefined}
                errorText={errors.contractor ?? undefined}
                hasError={!!errors.contractor}
              />
            </FormField>
            <FormField>
              <Markup.Label>{labels.commissioningDate}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setValue("commissioningDate", `${year}-${month}-${day}`);
                  } else {
                    setValue("commissioningDate", null);
                  }
                }}
                errorText={errors?.commissioningDate || ""}
                hasError={!!errors?.commissioningDate}
                value={values?.commissioningDate ? new Date(values.commissioningDate) : null}
              />
            </FormField>
            <FormField>
              <Markup.Label>{labels.manufactureDate}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setValue("manufactureDate", `${year}-${month}-${day}`);
                  } else {
                    setValue("manufactureDate", null);
                  }
                }}
                errorText={errors?.manufactureDate || ""}
                hasError={!!errors?.manufactureDate}
                value={values?.manufactureDate ? new Date(values.manufactureDate) : null}
              />
            </FormField>
            <FormField>
              <Markup.Label>{labels.startDateVehicle}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const backDate = getDateToBack(e);
                    // setStartDate(backDate);
                    setValue(names.contractor, null);
                    setValue("startDate", backDate);
                  } else {
                    // setStartDate(null);
                    setValue(names.contractor, null);
                    setValue("startDate", null);
                  }
                }}
                errorText={errors?.startDate || ""}
                hasError={!!errors?.startDate}
                minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                maxDate={oldEndDate ? addDays(oldEndDate, 1) : undefined}
                value={values?.startDate ? new Date(values?.startDate) : null}
              />
            </FormField>
            <FormField>
              <Markup.Label>{labels.endDateVehicle}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const backDate = getDateToBack(e);
                    // setEndDate(backDate);
                    setValue("endDate", backDate);
                  } else {
                    // setEndDate(null);
                    setValue("endDate", null);
                  }
                }}
                errorText={errors?.endDate || ""}
                hasError={!!errors?.endDate}
                minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                value={values?.endDate ? new Date(values?.endDate) : null}
              />
            </FormField>
            <Field values={values} updateValue={setValue} name={names.vin} errors={errors} cyData="edit-transport-vin" type="text" label={labels.vin} />
            <Divider margin="16px 0" />

            <DetailsBlock header={<Box>Файлы</Box>}>
              {
                isLoading ? (<Box justifyContent={"center"}><Loader /></Box>) : (<Box direction="column">
                  <UploadArea
                    filesInput={files || []}
                    multiple={true}
                    handleChangeFiles={(el) => {
                      handleFiles(el);
                    }}
                  />
                </Box>)
              }

            </DetailsBlock>
          </>);
        }
      }
    </PanelForm>
  );
};
