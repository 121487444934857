import styled  from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 0 0 0;
  overflow: hidden;
`;

export const HeaderWrapper = styled.div<{ isCollapsed: boolean }>`
  padding: ${({ isCollapsed }) => isCollapsed ? "0 24px 0 24px" : "0 24px"};
  margin-bottom: 6px;
  width: 100%;
`;

export const TabsWrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div<{ headerHeight?: string }>`
  width: 100%;
  height: calc(100% - ${(props) => props.headerHeight || 0});
  flex-grow: 1;
  padding: 8px 0;
  overflow: auto;
`;
