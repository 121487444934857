import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { stopActions } from "store/actions";
import { useRoute } from "react-router5";
import { PAGE_ROUTES } from "../../../../shared/definitions";

export const useGetStopItemsByVersion = () => {
  const dispatch = useDispatch();
  const {
    route: {
      name,
      params: { id },
    },
  } = useRoute();

  let [stopId, versionId] = ["", ""];
  const { stopByVersionId } = useSelector((state: RootState) => ({
    stopByVersionId: state.stop.stopByVersionId,
  }));

  if (id) {
    [stopId, versionId] = String(id).split("_");
  }

  useEffect(() => {
    if ([PAGE_ROUTES.STOP_STATION, PAGE_ROUTES.STOP_STATIONS].includes(name)  && versionId) {
      if (!stopByVersionId) {
        dispatch(stopActions.getStopByVersionId(versionId));
      } else if (!stopByVersionId[versionId])
        dispatch(stopActions.getStopByVersionId(versionId));
    }
  }, [dispatch, name, stopByVersionId, versionId]);

  return {
    item: stopByVersionId?.[versionId],
    versionId,
    stopId,
  };
};
