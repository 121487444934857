import { getContractorTypes, getPavilionHolders, getVehicleCapacityTypesRequest } from "api";
import { Chips, ChipsItem } from "components/common";
import { VehicleClassTypeArrayString } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { labels } from "shared/constants";

export type FilterProps = {
  ContractorTypesFilter: string | undefined | null;
  updateValue: any;
  field: string;
};

export const Filter = (props: FilterProps) => {

  const { ContractorTypesFilter, updateValue, field } = props;
  const [contractorTypeArray, setContractorTypeArray] = useState<string[]>(ContractorTypesFilter ? ContractorTypesFilter.split(",") : []);
  const [contractorHoldersChips, setContractorHoldersChips] = useState<{ id: string, label: string }[]>([]);
  const contractorAllExp = useMemo(()=> ContractorTypesFilter !== undefined ? ContractorTypesFilter?.length === contractorHoldersChips.map(e=>e.id).toString().length : false, [ContractorTypesFilter, contractorHoldersChips]);
  const [contractorAll, setContractorAll] = useState(contractorAllExp);

  const getContractorTypesChips = useCallback(async () => {
    const contractorTypes = await getContractorTypes();
    const result = contractorTypes.map(item => ({ id: item.id.toString(), label: item.name }));
    setContractorHoldersChips(result);
    setContractorAll(contractorAllExp);
  }, [contractorAllExp]);


  useEffect(() => {
    getContractorTypesChips();
    return ()=> {
      setContractorHoldersChips([]);
      setContractorTypeArray([]);
      setContractorAll(false);
    };
  }, []);

  useEffect(() => {
    setContractorAll(contractorAllExp);
  }, [contractorAllExp]);

  const allClick = useCallback(() => {
    if (contractorAll) {
      updateValue(field, "");
      setContractorTypeArray([]);
      setContractorAll(false);
    } else {
      updateValue(field, contractorHoldersChips.map(e => e.id).toString());
      setContractorTypeArray(contractorHoldersChips.map(e => e.id));
      setContractorAll(true);
    }
  }, [contractorHoldersChips, contractorAll]);

  return (
    <Chips label={labels.balanceholder}>
      <ChipsItem isActive={contractorAll} onClick={allClick} id={"all"} label={"Все"} />
      {contractorHoldersChips.map((chip) => (
        <ChipsItem
          key={chip.id}
          isActive={ContractorTypesFilter !== undefined ? ContractorTypesFilter?.split(",").findIndex(el => el === chip.id) !== -1 : false}
          {...chip}
          onClick={({ id }) => {
            const idx = contractorTypeArray.indexOf(id);
            if (idx === -1) {
              setContractorTypeArray(oldArray => {
                const arr = [...oldArray, id];
                updateValue(field, arr.toString());
                return arr;

              });
            } else {
              setContractorTypeArray(oldArray => {
                const filtered = oldArray.filter((element) => element !== id);
                updateValue(field, filtered.toString());
                return filtered;
              });
            }
          }}
        />
      ))}
    </Chips>
  );
};

export const ContractorTypesFilter = React.memo(Filter);