import { uploadFileRequest } from "api";
import { toastError } from "components";
import { useCallback } from "react";




export const useFileLoadLogic = () => {
    
  const createMetaDataArr = useCallback(async (filesArr: File[]) => {
    if (filesArr.length > 0) {
      let fileArr: { id: string; name: any; sizeInBytes: any;  mimeType: string }[] = [];
      try {
        fileArr = await Promise.all(filesArr.map(async fileElem => {
          const formElem = new FormData();
          formElem.append("file", fileElem);
          formElem.append("metadata", JSON.stringify({
            ChunkIndex: 0,
            ContentType: fileElem.type,
            FileSize: fileElem.size,
            FileName: fileElem.name,
            ChunkSize: fileElem.size,
            TotalChunks: 1,
          }));
          const data = formElem.get("metadata") && JSON.parse(formElem.get("metadata") as string);
          const resp = await uploadFileRequest(formElem).then((payload) => ({ id: payload, name: data.FileName, sizeInBytes: data.FileSize, mimeType: fileElem.type }));
          return resp;
        }));
        return fileArr;
      } catch (error: any) {
        toastError({ title: "Ошибка", message: error });
        return [];
      }
    } else {
      return [];
    } 
  }, []);
  return {
    createMetaDataArr,
  };
};