/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
import styled from "styled-components/macro";
import { switchProp } from "styled-tools";
import { withNeutralColor, withAccentColor } from "shared/utils";

type ContainerProps = {
  hasInputValue: boolean;
};

export const Container = styled.span<ContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: ${withNeutralColor("l5")};
  border: 1px solid ${withNeutralColor("l2")};
  border-radius: 6px;
  padding: 0 8px;
  width: 100%;

  &:focus-within {
    box-shadow: 0 0 0 3px ${withAccentColor("blue", "l3")};
  }
  &:hover {
    background-color: ${switchProp("hasInputValue", {
      true: withNeutralColor("l4"),
      false: withNeutralColor("l3"),
    })};
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  line-height: 16px;
  border: none;
  outline: none;
  padding: 0;
  margin: 7px 0 7px 4px;
  background-color: transparent;
  color: ${withNeutralColor("d4")};
  &:focus {
    caret-color: ${withAccentColor("blue", "l5")};
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
    color: ${withNeutralColor("l2")};
  }
`;

export const ClearButton = styled.button`
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
`;
