import React, { ReactNode, memo } from "react";
import * as Markup from "./chips.styles";

const createLabel = (label: ReactNode) => (typeof label === "string" ? <Markup.Label>{label}</Markup.Label> : label);

export type ChipsProps = {
  children: ReactNode;
  label?: ReactNode;
  isRequired?: boolean;
  cyData?: string;
};

const ChipsComponent = (props: ChipsProps) => {
  const { label, children, isRequired = false, cyData } = props;
  return (
    <Markup.Container>
      {label && <Markup.LabelContainer isRequired={isRequired}>{createLabel(label)}</Markup.LabelContainer>}
      <Markup.List data-cypress={cyData}>{children}</Markup.List>
    </Markup.Container>
  );
};

export const Chips = memo(ChipsComponent);
