import { APP_ENV } from "app-env";
import mapboxgl, { VectorSourceImpl, AnyLayer } from "mapbox-gl";
import { v4 } from "uuid";
import { setVisibility } from "../actual-stops/utils";
import { MapLayerNames } from "../utils/consts/map.constants";
import { actualStopLayer, actualStopSelectedLayer } from "./stop.layers";

export const AddStopTiles = (map?: mapboxgl.Map) => {
  if (!map) return;
  const tiles = [`${APP_ENV.REACT_APP_BASE_URL}/Stop/version/tile/{x}/{y}/{z}?includeActive=true&includeInactive=false`];
  const sourceId = MapLayerNames.ACTUAL_STOP_SOURCE_ID;
  const layerId = MapLayerNames.ACTUAL_STOP_LAYER_ID;

  const prevSource = map.getSource(sourceId) as VectorSourceImpl;

  if (prevSource) {
    if (prevSource.tiles?.[0] !== tiles[0]) {
      prevSource.setTiles(tiles);
      prevSource.tiles = tiles;
    }
  } else {
    map.addSource(sourceId, {
      type: "vector",
      tiles,
    });
  }

  map.addLayer(actualStopLayer(sourceId) as AnyLayer);

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  map.on("mouseover", layerId, (e: any) => {
    if (e.features) {
      const address = e.features[0].properties.stopAddress ? `№ ${e.features[0].properties.stopId || "-"} ${e.features[0].properties.stopAddress}` : (e.features[0].properties.stopName || "");
      
      const coordinates = e.features[0].geometry.coordinates ? e.features[0].geometry.coordinates.slice() : "";
      popup.setLngLat(coordinates).setHTML(address).addTo(map);
      // const description = e?.features[0].properties.description;
    }


    // eslint-disable-next-line no-param-reassign
    map.getCanvasContainer().style.cursor = "pointer";
  });
  map.on("mouseleave", layerId, () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvasContainer().style.cursor = "";
    popup.remove();
  });
};

export const AddStopSelected = (map?: mapboxgl.Map) => {
  if (!map) return;
  const sourceId = MapLayerNames.ACTUAL_STOP_SELECTED_SOURCE_ID;

  const data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        id: v4(),
        geometry: {
          type: "Point",
          coordinates: [],
        },
        properties: {},
      },
    ],
  };

  const source = map.getSource(sourceId);
  if (source && "setData" in source) {
    source.setData(data);
  } else {
    map.addSource(sourceId, {
      type: "geojson",
      data,
    });
  }

  map.addLayer(actualStopSelectedLayer(sourceId) as AnyLayer);
};

export const updateSelectedStopSource = (map: mapboxgl.Map, activeActualStop: { lng:number | null, lat: number | null } ) => {
  const data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        id: v4(),
        geometry: {
          type: "Point",
          coordinates: [activeActualStop.lng || 0, activeActualStop.lat || 0],
        },
        properties: {},
      },
    ],
  };

  const source = map.getSource(MapLayerNames.ACTUAL_STOP_SELECTED_SOURCE_ID);
  if (source && "setData" in source) {
    source.setData(data);
  } else {
    map.addSource(MapLayerNames.ACTUAL_STOP_SELECTED_SOURCE_ID, {
      type: "geojson",
      data,
    });
  }
  if (map) setVisibility(map, true, MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID);
};






