import { ScheduleActionType, SchedulesAction } from "store/actions/schedule-actions/schedule-actions.types";
import { IInitialScheduleState } from "./schedule.types";
import { initialState } from "./schedule.constants";

export const scheduleReducer = (state: IInitialScheduleState = initialState, action: SchedulesAction) => {
  switch (action.type) {
    case ScheduleActionType.SET_SCHEDULE_BY_VERSION_ID: {
      const { payload } = action;

      return {
        ...state,
        scheduleByVersionId: {
          ...state.scheduleByVersionId,
          [payload.versionId]: payload.data,
        },
      };
    }

    case ScheduleActionType.SET_SCHEDULE_ACTIVE_FILTER: {
      const { payload } = action;

      return {
        ...state,
        scheduleActiveFilter: payload,
      };
    }
    
    // case ScheduleActionType.SET_CURRENT_PAGE: {
    //   const { payload } = action;
    //   return {
    //     ...state,
    //     scheduleCurrentPage: payload,
    //   };
    // }


    default:
      return state;
  }
};
