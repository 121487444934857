import React, { PropsWithChildren } from "react";
import { PanelFormProps } from "./panel-form.types";
import * as Markup from "./panel-form.styles";
import { Form } from "../../components/common/form";
import { BlockHeader, IconButton } from "../../components";
import { XClose24BlueIcon } from "../../assets/icons";

export const PanelForm = <T extends Record<string, unknown>>(props: PropsWithChildren<PanelFormProps<T>>) => {
  const { title, children, initialValues, ...formProps } = props;
  return (
    <Markup.Container>
      <Markup.Header>
        <BlockHeader
          left={title}
          right={<IconButton content="icon" onClick={props.onCancel} data-cypress="Cancel" icon={<XClose24BlueIcon />} />}
        />
      </Markup.Header>
      {initialValues && <Form initialValues={ initialValues } { ...formProps}>
        { children }
      </Form> }
    </Markup.Container>
  );
};
