import styled, { css } from "styled-components/macro";

const commonRowCss = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Container = styled.header`
  display: flex;
  flex-flow: column nowrap;
`;

export const Before = styled.span`
  ${commonRowCss};
`;

export const ContentWrapper = styled.span`
  ${commonRowCss};
`;

export const Content = styled.span`
  ${commonRowCss};
  flex-grow: 1;
  line-height: 16px;
`;

const leftRightCss = css`
  max-width: 32px;
  max-height: 32px;
`;

export const Left = styled.span`
  ${commonRowCss};
  ${leftRightCss};
  margin-right: 16px;
`;

export const Right = styled.span`
  ${commonRowCss};
  ${leftRightCss};
  margin-left: 16px;
`;
