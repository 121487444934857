/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PanelForm } from "features/panel-form";
import { isCorrectEndDate, isCorrectStartDate, isRequired } from "shared/utils/validator";
import { DatePicker } from "@megapolis/uikit";
import { DetailsPanelTabs, FormField } from "features/detail-panels/components";
import { labels } from "shared/constants";
import { Trip, getRouteStops, TripStop } from "api";
import { createNewDataTripsStop } from "components/common/main-map/selected-route/utils";
import { MapLayerNames } from "components/common/main-map/utils/consts/map.constants";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { routeActions } from "store/actions";
import { RootState } from "store/types";
import { FeatureCollection } from "geojson";
import { useMap } from "components/common/main-map/map.context";
import { AlternativeSelect } from "components/common/alternative-select";
import { selectedRoute } from "components/common/main-map/selected-route/selected-route";
import { NewRouteEdit } from "components/common/main-map/route-edit";
import axios, { CancelTokenSource } from "axios";
import { MapUtilsForLayers } from "components/common/main-map/utils/map.utils";
import { Tab } from "components";
import { KeyZoneDetails } from "models";
import { Geometry } from "@turf/turf";
import * as Markup from "./new-route-panel.styles";
import { TripsStepper } from "../tab-pages/route-details-route-tab/sections/trip-stepper/trips-stepper";
import { KeyZoneStepper } from "../tab-pages/route-details-route-tab/sections/keyZone-stepper/keyzone-stepper";


type LineProps = {
  startDate: string | null,
  endDate: string | null,
  direction: { label: string, value: string },
  type: { label: string, value: string },
  path: {
    type: string,
    coordinates?: any,
  }
};

let axiosSource: CancelTokenSource;

export const EditRouteTrip: React.FC<{ onCancel: () => void, editRoute: any }> = ({ onCancel, editRoute }) => {
  const { map } = useMap();
  const [startDate, setStartDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");
  const [curStops, setCurStops] = useState<Array<TripStop>>([]);
  const [curKeyZones, setKeyZones] = useState<Array<any>>([]);
  const [filter, setFilter] = useState<string>("tripStops");
  const [lenghtRoute, setLenghtRoute] = useState(editRoute.path.coordinates.length || 0);
  const [valid, setValid] = useState<boolean>(false);
  const [tripCoords, setTripCoords] = useState<Trip>({
    direction: "",
    path: {
      "type": "LineString",
      "coordinates": [],
    },
    tripStops: [],
    type: "",
    startDate: null,
    endDate: null,
    tripId: editRoute.tripId || null,
    tripKeyZones: [],
  });

  const { removeNewDraw, handleLoad, endDraw } = NewRouteEdit({ map, routeId: 0, selectedRoute: editRoute });
  const { setVisibility } = MapUtilsForLayers();

  const dispatch = useDispatch();

  const { newRouteTrips } = useSelector((state: RootState) => ({
    newRouteTrips: state.route.newRouteTrips,
  }));
  const memoType: Array<{ label: string, value: string }> = [
    {
      label: "Основной вариант",
      value: "00",
    },
    {
      label: "Укороченный вариант",
      value: "У1",
    },
    {
      label: "Дополнительный вариант",
      value: "Д1",
    },
  ];

  const memoDirection: Array<{ label: string, value: string }> = [
    {
      label: "Прямой",
      value: "Forward",
    },
    {
      label: "Обратный",
      value: "Backward",
    },
  ];

  const form: LineProps = {
    startDate: editRoute.startDate || null, // Время работы трассы
    endDate: editRoute.endDate || null, // Время работы трассы
    direction: memoDirection.find(el => el.value === editRoute.direction) || {
      label: "Прямое",
      value: "Forward",
    },
    type: memoType.find(el => el.value === editRoute.type) || {
      label: "Основной вариант",
      value: "00",
    },
    path: editRoute.path || {
      type: "LineString",
      coordinates: [],
    } as {
      type: string;
      coordinates: any[][];
    },
  };

  const updateLayers = useCallback((data) => {
    if (!map?.getSource("StopsRoute")) {
      map?.addSource("StopsRoute", {
        type: "geojson",
        data: createNewDataTripsStop(data),
      } as mapboxgl.AnySourceData);
    } else {
      const source: mapboxgl.GeoJSONSource = map?.getSource("StopsRoute") as mapboxgl.GeoJSONSource;
      source.setData(createNewDataTripsStop(data) as FeatureCollection);
    }
    if (!map?.getLayer("StopsRoute")) {

      map?.addLayer({
        id: "StopsRoute",
        type: "symbol",
        source: "StopsRoute",
        minzoom: 0,
        maxzoom: 24,
        layout: {
          // "icon-image": "selectedStopIcon",
          "icon-image": [
            "case",
            ["==", ["get", "isActive"], true],
            "selectedListStopIcon",
            "selectedStopIcon",
          ],
          "icon-size": ["interpolate", ["exponential", 0], ["zoom"], 13.5, 0.7, 14.01, 1, 15, 1, 16.01, 1],
          "visibility": "visible",
          "icon-anchor": "bottom",
        },
      });
    }
  }, [map]);


  const kindSet = useCallback((arr) => {
    const stops = arr;
    stops.forEach((element: { kind: string; order: number; }, index: number, oldArr: { length: number; }) => {
      switch (index) {
        case 0:
          element.kind = "First";
          element.order = index + 1;
          break;
        case oldArr.length - 1:
          element.kind = "Last";
          element.order = index + 1;
          break;

        default:
          element.kind = "Middle";
          element.order = index + 1;
          break;
      }
    });
    return stops;

  }, []);

  const clickOnStop = useCallback((e: any) => {
    if (e.features[0]) {
      setCurStops((stops) => {
        const searchIndex = stops.findIndex((car) => car.stop.id === e.features[0].properties.stopId);
        if (searchIndex === -1) {
          const tripStop = {
            versionId: 0,
            kind: "Middle",
            mode: "Usual",
            noBoarding: false,
            active: true,
            keyPoint: false,
            stop: {
              id: e.features[0].properties.stopId,
              name: e.features[0].properties.stopName,
              lat: e.features[0].geometry.coordinates[1],
              lng: e.features[0].geometry.coordinates[0],
            },
          };
          stops = stops.concat([tripStop as TripStop]);
          setTripCoords((trip) => ({ ...trip, tripStops: stops } as Trip));
          updateLayers(stops);
          return stops;
        }
        return stops;
      });
    }

  }, [updateLayers]);

  const selectStop = useCallback((arr: any) => {
    setCurStops(arr);
    updateLayers(arr.filter((elm: any) => elm.active === true));
  }, [curStops, updateLayers]);

  const selectKeyZone = useCallback((arr: any) => {
    setKeyZones(arr);
    // updateLayers(arr.filter((elm: any) => elm.active === true));
  }, [curKeyZones, updateLayers]);

  const clickOnKeyZone = useCallback((e: any) => {
    if (e.features[0]) {
      setKeyZones((zones) => {
        const searchIndex = zones.findIndex((zone) => zone.keyZoneId === e.features[0].properties.keyZoneId);
        if (searchIndex === -1) {
          const elm = e.features[0].properties;
          const keyZone = {
            keyZoneId: elm.keyZoneId,
            name: elm.name,
            geometry: e.features[0].geometry,
            active: true,
          };
          zones = zones.concat([keyZone]);
          zones = zones.map((zone, idx) => ({ ...zone, sequenceNum: idx + 1 }));
          setTripCoords((trip) => ({ ...trip, tripKeyZones: zones } as Trip));
          return zones;
        }
        return zones;
      });

    }
  }, []);

  const uniqueArrays = useCallback((chars: Array<Array<number>>) => {
    const set = chars.map(el => JSON.stringify(el)).filter((item, pos, self) => item !== self[pos + 1]).map(el => JSON.parse(el));
    return set;
  }, []);

  const clearLayers = useCallback(() => {
    if (map?.getLayer("StopsRoute")) map?.removeLayer("StopsRoute");
  }, [map]);

  const startUpdate = useCallback((e: any) => {
    if (axiosSource) axiosSource.cancel("Operation canceled by the user.");
    axiosSource = axios.CancelToken.source();
    const featureArr: any[] = e[0].geometry.coordinates;
    setLenghtRoute(featureArr.length >= 3 ? featureArr.length : 0);
    getRouteStops(JSON.stringify({
      "type": "LineString",
      "coordinates": e[0].geometry.coordinates,
    }), { filterByStopAngle: false }, { cancelToken: axiosSource.token }).then((data: TripStop[]) => {
      data = data.map(element => ({ ...element, active: false }));


      setCurStops((CurStops) => {
        const arr: TripStop[] = data.map(reqStop => {
          const found = CurStops.find(element => element.stop.id === reqStop.stop.id);
          if (found === undefined) return reqStop;
          return { ...reqStop, active: found?.active, isControl: found.isControl || false };
        });

        setTripCoords((trip) => ({
          ...trip, tripStops: kindSet(arr), path: {
            "type": "LineString",
            "coordinates": featureArr,
          },
        }));
        updateLayers(arr);
        return kindSet(arr);
      });
    });
  }, [editRoute, kindSet, updateLayers]);

  const scheme = useMemo(() => ({
    type: isRequired("Введите тип трассы"),
    startDate: isCorrectStartDate("Дата начала действия трассы не может быть больше даты окончания", endDate || ""),
    endDate: isCorrectEndDate("Дата окончания действия трассы не может быть меньше даты начала", startDate || ""),
    direction: isRequired("Укажите направление"),
  }), [endDate, startDate]);

  useEffect(() => {
    if (!map) return;
    if (map?.getLayer("route")) map?.removeLayer("route");
    const selTrip: Trip | undefined = editRoute;
    if (selTrip) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      map?.getLayer(selectedRoute) && map?.setLayoutProperty(selectedRoute, "visibility", "none");
      selTrip.path.coordinates = uniqueArrays(selTrip.path.coordinates);
      handleLoad(startUpdate, undefined, undefined);
      const newTrip: Trip = {
        ...selTrip,
        direction: selTrip.direction,
        path: selTrip.path,
        tripStops: kindSet(selTrip.tripStops),
        tripKeyZones: selTrip?.tripKeyZones || [],
        type: selTrip.type,
        startDate: selTrip.startDate,
        endDate: selTrip.endDate,
        workId: selTrip.workId || selTrip?.tripId?.toString(),

      };
      setTripCoords(newTrip);
      selTrip.tripStops = selTrip.tripStops.map(element => ({ ...element, active: true }));
      setCurStops(kindSet(selTrip.tripStops));
      setKeyZones(selTrip.tripKeyZones ? selTrip.tripKeyZones.map((zone, i) => ({ ...zone, sequenceNum: i + 1, active: true })) : []);
      updateLayers(selTrip.tripStops);
    }
    if (map) setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
    if (map) setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
    if (map) setVisibility(map, true, MapLayerNames.ACTUAL_STOP_LAYER_ID);
    if (map) setVisibility(map, true, MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID);
    map?.on("click", MapLayerNames.ACTUAL_STOP_LAYER_ID, clickOnStop);
    map?.on("click", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, clickOnKeyZone);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      if (!map) return;
      if (map?.isStyleLoaded()) {
        clearLayers();
        endDraw();
        removeNewDraw();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        map?.getLayer(selectedRoute) && map?.setLayoutProperty(selectedRoute, "visibility", "visible");

        if (map) setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
        if (map) setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
        if (map) setVisibility(map, false, MapLayerNames.ACTUAL_STOP_LAYER_ID);
        if (map) setVisibility(map, false, MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID);
        if (map) setVisibility(map, false, MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL);

        map?.off("click", MapLayerNames.ACTUAL_STOP_LAYER_ID, clickOnStop);
        map?.off("click", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, clickOnKeyZone);
      }

    };
  }, []);

  const onStopClick = useCallback((tripStop: TripStop[], idx: number) => {
    const arr = tripStop.map((elm) => ({ ...elm, isActive: false }));
    arr[idx].isActive = true;
    updateLayers(arr.filter((elm: any) => elm.active === true));
  }, []);

  useEffect(() => {
    if (filter === "tripStops") {
      map && setVisibility(map, true, MapLayerNames.ACTUAL_STOP_LAYER_ID);
      map && setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
      map && setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
      map && setVisibility(map, false, MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL);
      if (map?.getLayer(MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL)) {
        map?.removeLayer(MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL);
      }
      if (map?.getSource("KeyZoneRouteSource")) {
        map?.removeSource("KeyZoneRouteSource");
      }

    } else {

      map && setVisibility(map, false, MapLayerNames.ACTUAL_STOP_LAYER_ID);
      map && setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
      map && setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
      map && setVisibility(map, true, MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL);
      if (map?.getLayer(MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL)) {
        map?.removeLayer(MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL);
      }
      if (map?.getSource("KeyZoneRouteSource")) {
        map?.removeSource("KeyZoneRouteSource");
      }
    }
  }, [filter]);

  const updateKeyZoneLayers = useCallback((geometry: KeyZoneDetails["geometry"]) => {
    if (!map) return;
    const data = {
      type: "Feature",
      geometry: geometry as Geometry || {
        type: "Polygon",
        // These coordinates outline Maine.
        coordinates: [],
      } as Geometry,
      properties: {},
    };

    const prevSource = map?.getSource("KeyZoneRouteSource");

    if (!prevSource) {
      map?.addSource("KeyZoneRouteSource", {
        type: "geojson",
        data,
      } as mapboxgl.AnySourceData);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      prevSource?.setData(data);
    }

    if (!map?.getLayer(MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL)) {
      map?.addLayer({
        "id": MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL,
        "type": "fill",
        "source": "KeyZoneRouteSource", // reference the data source
        "layout": {},
        "paint": {
          "fill-color": "#5ACAFD", // blue color fill
          "fill-opacity": 0.7,
        },
      });
    }
  }, [map]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const lenghtKeyZone = curKeyZones.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.active ? 1 : 0),
      0,
    );
    lenghtRoute > 0 && curStops.length > 1 && (lenghtKeyZone === 0 || lenghtKeyZone > 1) ? setValid(true) : setValid(false);
  }, [lenghtRoute, curStops, curKeyZones]);


  const handleSubmit = useCallback((values: LineProps) => {

    const trip: Trip = {
      ...tripCoords,
      tripId: tripCoords.tripId ? -tripCoords.tripId : 0,
      versionId: tripCoords.versionId ? -tripCoords.versionId : 0,
      path: tripCoords.path,
      type: values.type.value,
      direction: values.direction.value,
      startDate: values.startDate,
      endDate: values.endDate,
      tripStops: kindSet(curStops.filter((e) => e.active)),
      tripKeyZones: curKeyZones ? curKeyZones.filter((e) => e.active).map((zone, i) =>  ({ ...zone, sequenceNum: i + 1 })) : curKeyZones,
    };
    // eslint-disable-next-line no-return-assign
    const updatedArray = newRouteTrips.map(el => el.workId === trip.workId ? el = trip : el);
    dispatch(routeActions.setNewRouteTrips(updatedArray));
    onCancel();
  }, [curKeyZones, curStops, dispatch, kindSet, newRouteTrips, onCancel, tripCoords]);

  return (
    <PanelForm
      title={""}
      initialValues={form}
      validationScheme={scheme}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isDisabled={!valid}
    >
      {
        ({ names, values, updateValue: setValue, errors }) => (
          <>
            <FormField>
              <AlternativeSelect label={"Тип трассы"} options={memoType} value={values.type}
                onChange={({ value, label }) => {
                  setValue(names.type, { value, label });
                }}
              ></AlternativeSelect>
            </FormField>
            <FormField>
              <AlternativeSelect label={"Направление"} options={memoDirection} value={values.direction}
                onChange={({ value, label }) => setValue(names.direction, { value, label })}
              ></AlternativeSelect>
            </FormField>
            <FormField>
              <Markup.Label>{labels.startDateRoute}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setStartDate(`${year}-${month}-${day}`);
                    setValue("startDate", `${year}-${month}-${day}`);
                  } else {
                    setStartDate(null);
                    setValue("startDate", null);
                  }
                }}
                errorText={errors?.startDate}
                hasError={errors?.startDate}
                value={values?.startDate ? new Date(values.startDate) : null}
              />

            </FormField>
            <FormField>
              <Markup.Label>{labels.endDateRoute}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setEndDate(`${year}-${month}-${day}`);
                    setValue("endDate", `${year}-${month}-${day}`);
                  } else {
                    setEndDate(null);
                    setValue("endDate", null);
                  }
                }}
                errorText={errors?.endDate}
                hasError={errors?.endDate}
                value={values?.endDate ? new Date(values.endDate) : null}
              />
            </FormField>
            <DetailsPanelTabs value={filter} onChange={setFilter}>
              <Tab value={"tripStops"}>Остановки</Tab>
              <Tab value={"tripKeyZones"}>Зоны</Tab>
            </DetailsPanelTabs>
            {filter === "tripStops" && <Markup.TripsStepperWrapper>
              <TripsStepper tripStops={curStops} isEdit onStopClick={onStopClick} onStopSelect={selectStop} onStopChangePlace={setCurStops} />
            </Markup.TripsStepperWrapper>}
            {filter === "tripKeyZones" && <Markup.TripsStepperWrapper>
              <KeyZoneStepper keyZones={curKeyZones as any} isEdit onKeyZoneSelect={selectKeyZone} onKeyZoneClick={(keyzone) => keyzone.geometry && updateKeyZoneLayers(keyzone.geometry)} />
            </Markup.TripsStepperWrapper>}
          </>
        )
      }
    </PanelForm>
  );
};



