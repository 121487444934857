import React, { memo, ReactNode, useMemo } from "react";
import { Loader } from "../../loader";
import { ReactComponent as ErrorIndicator24Icon } from "./error-indicator24.svg";
import * as Markup from "./text-field-base.styles";

export type TextFieldBaseProps = {
  children: React.ReactNode;
  className?: string;
  errorText?: string;
  hasError?: boolean;
  isLoading?: boolean;
  label?: ReactNode;
  caption?: ReactNode;
  isRequired?: boolean;
  isDisabled?: boolean;
};

const TextFieldBaseComponent = (props: TextFieldBaseProps) => {
  const { className, hasError, errorText, isLoading, label, caption, children, isRequired = false, isDisabled = false } = props;

  const indicationContent = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }
    return hasError && errorText ? <ErrorIndicator24Icon /> : null;
  }, [isLoading, hasError, errorText]);

  return (
    <Markup.Container className={className} hasIndication={!!indicationContent}>
      {label && <Markup.Label isRequired={isRequired} isDisabled={isDisabled}>{label}</Markup.Label>}
      <Markup.Content>
        {children}
        {/* {indicationContent && (
          <Tooltip content={errorText}>
            <Markup.Indication>{indicationContent}</Markup.Indication>
          </Tooltip>
        )} */}
      </Markup.Content>
      {caption && <Markup.Caption>{caption}</Markup.Caption>}
    </Markup.Container>
  );
};

export const TextFieldBase = memo(TextFieldBaseComponent);
