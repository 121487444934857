import { KeyZoneVersionDetails } from "api";
import { Dispatch } from "react";
import { KeyZoneFilters } from "store/reducers/key-zone";
import { KeyZoneActionType, SetActiveKeyZoneAction, SetKeyZoneActiveFilterAction, SetKeyZoneByVersionIdAction, SetKeyZoneFilters, SetKeyZonesAction, SetKeyZoneSearchSubstring } from "./keyzone-actions.types";

export const keyZoneActions = {

  setActiveKeyZone: (payload: string): SetActiveKeyZoneAction => ({
    type: KeyZoneActionType.SET_ACTIVE_KEY_ZONE_ID,
    payload,
  }),

  getKeyZoneByVersionId: (versionId: string, payload: KeyZoneVersionDetails) => async (dispatch: Dispatch<SetKeyZoneByVersionIdAction>) => {
    dispatch({
      type: KeyZoneActionType.SET_KEY_ZONE_BY_VERSION_ID,
      payload: {
        versionId,
        data: payload,
      },
    });
  },

  setSearchSubstring: (payload: string): SetKeyZoneSearchSubstring => ({
    type: KeyZoneActionType.SET_KEY_ZONE_SEARCH_SUBSTRING,
    payload,
  }),

  setKeyZones: (payload: SetKeyZonesAction["payload"]): SetKeyZonesAction => ({
    type: KeyZoneActionType.SET_KEY_ZONES,
    payload,
  }),

  setFilters: (payload: {
    ContractorIds?: string | null,
    RouteIds?: string | null,
    DepotIds?: string | null,
  }): SetKeyZoneFilters => ({
    type: KeyZoneActionType.SET_KEY_ZONE_FILTERS,
    payload,
  }),

  setActiveFilter: (payload: KeyZoneFilters): SetKeyZoneActiveFilterAction => ({
    type: KeyZoneActionType.SET_KEY_ZONE_ACTIVE_FILTER,
    payload,
  }),

  setActiveItem: (payload: string) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: KeyZoneActionType.SET_KEY_ZONE_ACTIVE_ITEM,
      payload,
    });
  },

  setMapActiveItem: (payload: string) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: KeyZoneActionType.SET_KEY_ZONE_MAP_ACTIVE_ITEM,
      payload,
    });
  },
};

export default keyZoneActions;