/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from "react";
import { Card, Divider, IconButton } from "components";
import { ArrowDown16BlueIcon } from "assets/icons";
import { Stepper, StartIndicator, MidIndicator, EndIndicator, Step } from "components/ui/stepper";
import { TripStop } from "api";
import { getTripByMode } from "models/route.utils";
import { Checkbox, Tooltip } from "megapolis-uikit-latest";
import { Toggle } from "@megapolis/uikit/toggle";
import { CardWrapper, StepWrapper, TripCard, TripHeader, TripHeaderItem, Wrapper } from "../direction/trip-directions.styles";
import { CheckBoxWrapper, Container } from "./trips-stepper.style";




const StepContent = ({ title, caption }: { title?: string; caption?: string }) => (
  <CardWrapper>
    <Card title={title} caption={caption} />
  </CardWrapper>
);

export const StepWithCaption = ({
  indicator,
  title,
  caption,
  children,
}: {
  showCaption?: boolean;
  indicator: React.ReactNode;
  time?: string;
  title?: string;
  caption?: string;
  children?: React.ReactNode;
}) => (
  <Wrapper>
    <StepWrapper>
      <Step indicator={indicator}>{children || <StepContent title={title} caption={caption} />}</Step>
    </StepWrapper>
  </Wrapper>
);

export const TripsStepper = (props: { tripStops: TripStop[], isEdit?: boolean, onStopSelect?: (tripStops: TripStop[]) => void, onStopClick?: (tripStops: TripStop[], idx: number) => void, onStopChangePlace?: (tripStops: TripStop[]) => void }) => {
  const { tripStops, isEdit = false, onStopSelect, onStopChangePlace, onStopClick } = props;
  const [currentTrips, setCurrentTrips] = useState<TripStop[]>(tripStops);
  const [activeIdx, setActiveIdx] = useState<number | null>(null);
  // const dispatch = useDispatch();

  const kindSet = useCallback((arr) => {
    arr.forEach((element: { kind: string; order: number }, index: number, oldArr: { length: number; }) => {
      switch (index) {
        case 0:
          element.kind = "First";
          element.order = 1;
          break;
        case oldArr.length - 1:
          element.kind = "Last";
          element.order = oldArr.length;
          break;

        default:
          element.kind = "Middle";
          element.order = index + 1;
          break;
      }
    });
    return arr;

  }, []);

  useEffect(() => {
    if (tripStops.length > 0) {
      const arr: TripStop[] = kindSet(tripStops);
      setCurrentTrips(arr.map((item) => ({ ...item, active: item.active === undefined ? true : item.active, isControl: item.isControl ? item.isControl : false })));
      // onStopSelect && onStopSelect(arr);
    }
    return function cleanup() {
      setCurrentTrips([]);
      // onStopSelect && onStopSelect(tripStops);
    };

  }, [tripStops]);



  const handleCheck = useCallback((elm: TripStop, idx: number) => {
    let ar = currentTrips.map((e, i) => {
      if (i === idx) {
        return { ...e, active: !elm.active };
      }
      return e;
    });
    ar = kindSet(ar);
    setCurrentTrips(ar as TripStop[]);
    onStopSelect && onStopSelect(ar);
  }, [currentTrips, kindSet, onStopSelect]);

  const handleCheckFlag = useCallback((elm: TripStop, idx: number) => {
    let ar = currentTrips.map((e, i) => {
      if (i === idx) {
        return { ...e, isControl: !elm.isControl };
      }
      return e;
    });
    ar = kindSet(ar);
    setCurrentTrips(ar as TripStop[]);
    onStopSelect && onStopSelect(ar);
  }, [currentTrips, kindSet, onStopSelect]);

  const handleUpClick = useCallback((id: number) => {
    setCurrentTrips(array => {
      const element = array.splice(id, 1)[0];
      array.splice(id - 1, 0, element);
      onStopChangePlace && onStopChangePlace([...kindSet(array)]);
      return [...kindSet(array)];
    });
  }, [kindSet, onStopChangePlace]);

  const handleDownClick = useCallback((id: number) => {

    setCurrentTrips(array => {
      const element = array.splice(id, 1)[0];
      array.splice(id + 1, 0, element);
      onStopChangePlace && onStopChangePlace([...kindSet(array)]);
      return [...kindSet(array)];
    });
  }, [kindSet, onStopChangePlace]);

  const indicatorType = useCallback((kind) => {
    switch (kind) {
      case "First":
        return <StartIndicator pointColor="blue" />;
      case "Last":
        return <EndIndicator pointColor="red" />;
      default:
        return <MidIndicator />;
    }
  }, []);

  if (tripStops.length === 0) return (<></>);

  return (
    <>
      {
        isEdit ? <TripHeader isEdit>
          <TripHeaderItem>
          </TripHeaderItem>
          <TripHeaderItem>
            Наименование
          </TripHeaderItem>
          <TripHeaderItem>
            Режим
          </TripHeaderItem>
          <TripHeaderItem>
            Протяж.
          </TripHeaderItem>
          <TripHeaderItem>
          </TripHeaderItem>
        </TripHeader> :

          <TripHeader>
            <TripHeaderItem>
              Наименование
            </TripHeaderItem>
            <TripHeaderItem>
              Режим
            </TripHeaderItem>
            <TripHeaderItem>
              Протяж.
            </TripHeaderItem>
          </TripHeader>

      }

      <Divider></Divider>
      <Stepper>
        {
          currentTrips.map((elm, idx) => (isEdit ?
            <Container isActive={idx === activeIdx} key={idx}>
              <TripHeader isEdit key={idx}>
                <CheckBoxWrapper>
                  <Checkbox.Component
                    onClick={() => handleCheck(elm, idx)}
                    value={elm.active || false}
                  />
                </CheckBoxWrapper>
                <StepWithCaption
                  indicator={indicatorType(elm.kind)}
                >
                  <TripCard onClick={() => {
                    setActiveIdx(idx);
                    if (onStopClick) {
                      onStopClick(currentTrips, idx);
                    }
                  }}><Tooltip content={`№ ${elm.stop.id}`}>{elm.stop.name}</Tooltip></TripCard>
                </StepWithCaption>
                <TripCard>{getTripByMode(elm.mode)}</TripCard>
                <TripCard>{elm.distance && Math.ceil((elm.distance) * 100) / 100 }</TripCard>
                <div style={{ display: "flex" }} >
                  <CheckBoxWrapper><Tooltip content={<>Контрольный пункт</>}><Toggle value={elm.isControl ? elm.isControl : false} onChange={() => handleCheckFlag(elm, idx)}></Toggle></Tooltip></CheckBoxWrapper>
                  <div style={{ display: "grid", alignContent: "center" }}>
                    <IconButton content="icon" onClick={() => handleUpClick(idx)} icon={<ArrowDown16BlueIcon style={{ transform: "rotate(180deg)" }} />} />
                    <IconButton content="icon" onClick={() => handleDownClick(idx)} icon={<ArrowDown16BlueIcon />} />
                  </div>
                </div>
              </TripHeader>
            </Container>
            :
            <TripHeader key={idx + 1}>
              <StepWithCaption
                key={idx + 1}
                indicator={indicatorType(elm.kind)}
              >
                <TripCard>{elm.stop.name}</TripCard>
              </StepWithCaption>
              <TripCard>{getTripByMode(elm.mode)}</TripCard>
              <TripCard>{elm.distance && Math.ceil((elm.distance) * 100) / 100 }</TripCard>
            </TripHeader>


          ))
        }
      </Stepper>
    </>
  );

};




