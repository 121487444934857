import React, { createContext, FC, RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import mapboxgl, { } from "mapbox-gl";
import { useAccessToken } from "@megapolis/react-auth";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { APP_ENV, MAP_CENTER } from "app-env";
import { useRoute } from "react-router5";
import { busPin } from "./assets/images/bus-pin";
import { selectedIcon, selectedIconDefault } from "./assets/images/selectedIcon";
import { addIcon } from "./assets/images/addIcon";
import { stopTiles } from "./actual-stops/utils";
import { AddStopSelected } from "./stop-layers-controller/stop.utils";
import { useActualRoute } from "./actual-routes";
import { useActualStop } from "./actual-stops";
import { keyZoneTiles } from "./actual-keyZones/utils";
import { useActualKeyZones } from "./actual-keyZones/use-actual-keyZones";

type MapContextProps = {
  map: mapboxgl.Map | undefined;
  ref: RefObject<HTMLDivElement>;
  onResize: ()=> void;
};
const MapContext = createContext<MapContextProps>({} as MapContextProps);

export const MapProvider: FC = ({ children }) => {
  const [map, setMap] = useState<mapboxgl.Map>();
  const ref = useRef<HTMLDivElement>(null);
  const center = useRef<mapboxgl.LngLatLike>([37.618423, 55.751244]);
  const styleLoad = useRef<boolean>(false);
  const zoom = useRef(9.9);
  const {
    route: {
      name,
      params: { id },
    },
  } = useRoute();
  const isDisplayMap = React.useMemo(() => {
    if (["schedule", "schedules"].indexOf(name) === -1) return true;
    return false;
  }, [name]);

  const accessToken = useAccessToken() ?? "";

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleLoad = (map: mapboxgl.Map) => {
    if (!map) return;
    const imageActualStop = new Image();
    imageActualStop.src = addIcon();
    imageActualStop.onload = () => {
      if (map.hasImage("actualStopIcon")) map.removeImage("actualStopIcon");
      map.addImage("actualStopIcon", imageActualStop);
    };
    const imageSelectedActualStop = new Image();
    imageSelectedActualStop.src = selectedIconDefault();
    imageSelectedActualStop.onload = () => {
      if (map.hasImage("selectedStopIcon")) map.removeImage("selectedStopIcon");
      map.addImage("selectedStopIcon", imageSelectedActualStop);
    };

    const imageSelectedListStop = new Image();
    imageSelectedListStop.src = selectedIcon();
    imageSelectedListStop.onload = () => {
      if (map.hasImage("selectedListStopIcon")) map.removeImage("selectedListStopIcon");
      map.addImage("selectedListStopIcon", imageSelectedListStop);
    };


    const imageStopsAroundRoute = new Image();
    imageStopsAroundRoute.src = busPin();
    imageStopsAroundRoute.onload = () => {
      if (map.hasImage("bus-pin")) map.removeImage("bus-pin");
      map.addImage("bus-pin", imageStopsAroundRoute);
    };

    stopTiles(map);
    keyZoneTiles(map);
    AddStopSelected(map);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    if (!isDisplayMap) return;
    const currentMap = new mapboxgl.Map({
      container: ref.current!,
      style: `${APP_ENV.REACT_APP_MAP_STYLE}/styles/megapolis-light/style.json`,
      center: MAP_CENTER as unknown as mapboxgl.LngLatLike,
      zoom: 9.9,
      minZoom: 0,
      attributionControl: false,
      transformRequest: (url) => {
        /** For sputnik map style don`t add token */
        if (url.match(/mt0.google.com/)) {
          return { url };
        }
        if (
          url.includes("Stop/version/tile")
        ) {
          return {
            url,
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": `Bearer ${accessToken}`,
            },
            body: "",
          };
        }
        return {
          url,
          headers: { Authorization: `Bearer ${accessToken}` },
        };
      },
    });

    // disable map rotation using right click + drag
    currentMap.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    currentMap.touchZoomRotate.disableRotation();

    currentMap.on("load", () => {
      handleLoad(currentMap);
    });


    currentMap.on("styledata", () => {
      const waiting = () => {
        if (!currentMap.isStyleLoaded()) {
          setTimeout(waiting, 200);
        } else {
          styleLoad.current = true;
        }
      };
      waiting();
    });

    //* *вывод зума для тестирования */
    currentMap.on("zoom", () => {
      zoom.current = currentMap.getZoom();
    });
    setMap(currentMap);

    currentMap.on("idle", () => {
      currentMap.resize();
    });

    // eslint-disable-next-line consistent-return
    return () => {
      const { lng, lat } = currentMap.getCenter();
      center.current = [lng, lat];
      currentMap.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayMap]);
  
  useActualKeyZones(map);
  useActualStop(map);
  useActualRoute(map);

  const onResize = useCallback(()=>{
    if (!isDisplayMap) return;
    if (!map) return;
    map.resize();
  }, [isDisplayMap, map]);

  return (
    <MapContext.Provider value={{ map, ref, onResize }}>{children}</MapContext.Provider>
  );
};

export const useMap = () => ({ ...useContext(MapContext) });