import { withNeutralColor } from "shared/utils";
import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
`;

export const Label = styled.span`
  font-weight: 500;
`;

export const MainBlock = styled.div`
  flex-grow: 1;
  height: 80%;
  padding: 8px 16px;
  overflow: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  border-top: 1px solid ${withNeutralColor("l2")};
  width: 100%;
  padding: 16px;
  button:first-child {
    margin-right: 8px;
  }
`;

export const Block = styled.div`
  margin-top: 8px;
`;
