import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
`;

export const Header = styled.span`
  padding: 16px 16px 0 24px;
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
`;
