import { RouteDetails } from "models";
import { RouteFilters } from "store/reducers/route";
import { RoutesVersionResponse, RouteVersionDetails, ScheduleItem, Trip, TripStop } from "../../../api";

type Action<A extends ScheduleActionType, P> = { type: A, payload: P };

export enum ScheduleActionType {
  ADD_SCHEDULES = "ADD_SCHEDULES",
  SET_FILTERS = "SET_FILTERS",
  SET_ALL_SCHEDULE = "SET_ALL_SCHEDULE",
  SET_ACTIVE_SCHEDULE = "SET_ACTIVE_SCHEDULE",
  SET_PLANNED_SCHEDULE = "SET_PLANNED_SCHEDULE",
  SET_ARCHIVED_SCHEDULE = "SET_ARCHIVED_SCHEDULE",
  SET_SCHEDULE_BY_SCHEDULE_ID = "SET_SCHEDULE_BY_SCHEDULE_ID",
  SET_SCHEDULE_BY_VERSION_ID = "SET_SCHEDULE_BY_VERSION_ID",
  SET_SCHEDULE_ACTIVE_FILTER = "SET_SCHEDULE_ACTIVE_FILTER",
  SET_NEW_SCHEDULE_STATION_COORDS = "SET_NEW_SCHEDULE_STATION_COORDS",
  UPDATE_SCHEDULE_BY_VERSION = "UPDATE_SCHEDULE_BY_VERSION",
  DELETE_SCHEDULE_BY_VERSION = "DELETE_SCHEDULE_BY_VERSION",
  SET_NEW_SCHEDULE_STATION_DATA = "SET_NEW_SCHEDULE_STATION_DATA",
  SET_SCHEDULE_SEARCH_SUBSTRING = "SET_SCHEDULE_SEARCH_SUBSTRING",
  SET_ACTIVE_SCHEDULE_ID = "SET_ACTIVE_SCHEDULE_ID",
  SET_SCHEDULE_ISLOADING = "SET_SCHEDULE_ISLOADING",
}

export type AddSchedulesAction = Action<ScheduleActionType.ADD_SCHEDULES, { status: any; data: any }>;
export type SetScheduleActiveFilterAction = Action<ScheduleActionType.SET_SCHEDULE_ACTIVE_FILTER, any>;
export type SetScheduleByVersionIdAction = Action<ScheduleActionType.SET_SCHEDULE_BY_VERSION_ID, { versionId: ID, data: ScheduleItem }>;
export type UpdateScheduleByVersionIdAction = Action<ScheduleActionType.UPDATE_SCHEDULE_BY_VERSION, { versionId: string, data: Partial<any> }>;
export type DeleteScheduleByVersionIdAction = Action<ScheduleActionType.DELETE_SCHEDULE_BY_VERSION, { versionId: string }>;
export type SetScheduleSearchSubstring = Action<ScheduleActionType.SET_SCHEDULE_SEARCH_SUBSTRING, string>;
export type SetActiveScheduleAction = Action<ScheduleActionType.SET_ACTIVE_SCHEDULE_ID, string>;
export type SetScheduleIsLoadingAction = Action<ScheduleActionType.SET_SCHEDULE_ISLOADING, boolean>;
export type SetFilters = Action<ScheduleActionType.SET_FILTERS, {
  ContractorIds?: string | null,
  RouteKindIds?: string | null,
  TransportTypes?: string | null,
  CapacityTypes?: string | null,
}>;

export type SchedulesAction =
  | AddSchedulesAction
  | SetFilters
  | SetScheduleActiveFilterAction
  | SetScheduleSearchSubstring
  | SetScheduleIsLoadingAction
  | SetScheduleByVersionIdAction
  | UpdateScheduleByVersionIdAction
  | DeleteScheduleByVersionIdAction
  | SetActiveScheduleAction;
