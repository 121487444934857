import React, { JSXElementConstructor } from "react";
import { useRoute } from "react-router5";
import { KeyZones } from "pages/containers/key-zones";
import { MainMap, MapProvider } from "components/common/main-map";
import { RouteDetailsPanelView } from "features/detail-panels/route-details-panel/route-details-panel.routes";
import { StopStationDetailsPanelView } from "features/detail-panels/stop-station-details-panel/stop-station-details-panel.routes";
import { VehicleDetailsPanelView } from "features/detail-panels/vehicle-details-panel/vehicle-details-panel.routes";
import { ContractorDetailsPanelView } from "features/detail-panels/contractor-details-panel/contractor-details-panel.routes";
import { DepotDetailsPanelView } from "features/detail-panels/depot-details-panel/depot-details-panel.routes";
import { DriverDetailsPanelView } from "features/detail-panels/driver-details-panel/driver-details-panel.routes";
import { KeyZoneDetailsPanel } from "features/detail-panels/keyzone-details-panel";
import { ScheduleDetailsPanel } from "features/detail-panels/schedule-details-panel";
import { DepotsPage } from "./pages/containers/depots-page";
import { PAGE_ROUTES } from "./shared/definitions";
import { ReactNode } from "./shared/interfaces";
import {
  MainWrapper,
  RoutesPage,
  VehiclesPage,
  UIPage,
  NotFoundPage,
  ContractorsPage,
  Drivers,
  StopStations,
  Schedules,
} from "./pages";

export default function Routes() {
  const routeState = useRoute();

  let Wrapper;
  let Component: ReactNode;
  let DetailComponent: JSXElementConstructor<any> | undefined;
  let MainMapComponent: JSXElementConstructor<any> | undefined;

  switch (routeState.route.name as any) {
    case PAGE_ROUTES.MAIN:
      Wrapper = MainWrapper;
      Component = RoutesPage;
      break;
    case PAGE_ROUTES.ROUTES:
      Wrapper = MainWrapper;
      Component = RoutesPage;
      break;
    case PAGE_ROUTES.ROUTE:
      Wrapper = MainWrapper;
      Component = RoutesPage;
      DetailComponent = RouteDetailsPanelView;
      MainMapComponent = MainMap;

      break;
    case PAGE_ROUTES.STOP_STATION:
      Wrapper = MainWrapper;
      Component = StopStations;
      DetailComponent = StopStationDetailsPanelView;
      MainMapComponent = MainMap;
      break;
    case PAGE_ROUTES.STOP_STATIONS:
      Wrapper = MainWrapper;
      Component = StopStations;
      break;
    case PAGE_ROUTES.VEHICLES:
      Wrapper = MainWrapper;
      Component = VehiclesPage;
      break;
    case PAGE_ROUTES.VEHICLE:
      Wrapper = MainWrapper;
      Component = VehiclesPage;
      DetailComponent = VehicleDetailsPanelView;
      MainMapComponent = MainMap;
      break;
    case PAGE_ROUTES.CONTRACTORS:
      Wrapper = MainWrapper;
      Component = ContractorsPage;
      break;
    case PAGE_ROUTES.CONTRACTOR:
      Wrapper = MainWrapper;
      Component = ContractorsPage;
      DetailComponent = ContractorDetailsPanelView;
      MainMapComponent = MainMap;
      break;
    case PAGE_ROUTES.DEPOTS:
      Wrapper = MainWrapper;
      Component = DepotsPage;
      break;
    case PAGE_ROUTES.DEPOT:
      Wrapper = MainWrapper;
      Component = DepotsPage;
      DetailComponent = DepotDetailsPanelView;
      MainMapComponent = MainMap;
      break;
    case PAGE_ROUTES.DRIVER:
      Wrapper = MainWrapper;
      Component = Drivers;
      DetailComponent = DriverDetailsPanelView;
      MainMapComponent = MainMap;
      break;
    case PAGE_ROUTES.DRIVERS:
      Wrapper = MainWrapper;
      Component = Drivers;
      break;
    case PAGE_ROUTES.KEY_ZONE:
      Wrapper = MainWrapper;
      Component = KeyZones;
      DetailComponent = KeyZoneDetailsPanel;
      MainMapComponent = MainMap;
      break;
    case PAGE_ROUTES.KEY_ZONES:
      Wrapper = MainWrapper;
      Component = KeyZones;
      break;
    case PAGE_ROUTES.SCHEDULE:
      Wrapper = MainWrapper;
      Component = Schedules;
      DetailComponent = ScheduleDetailsPanel;
      break;
    case PAGE_ROUTES.SCHEDULES:
      Wrapper = MainWrapper;
      Component = Schedules;
      break;
    case PAGE_ROUTES.UI:
      Wrapper = MainWrapper;
      Component = UIPage;
      break;
    default:
      Wrapper = MainWrapper;
      Component = NotFoundPage;
      break;
  }

  return !Component ? (
    <div>
      <h1>Not Found</h1>
    </div>
  ) : (
    <MapProvider>
      <Wrapper routeState={routeState} detailComponent={DetailComponent} mainMapComponent={MainMapComponent} >
        {/* eslint-disable-current-line
      @ts-ignore @typescript-eslint/ban-ts-comment */}
        <Component routeState={routeState} />
      </Wrapper>
    </MapProvider>

  );
}
