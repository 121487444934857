import { useCallback, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { driverActions, routeActions } from "store/actions";
import { useRouteParams } from "shared/hooks";
import { Trip } from "api";
import { getDriverVersionRequest } from "api/requests/driver/driver.requests";

export const useGetDriverItemsByVersion = () => {
  const dispatch = useDispatch();
  const { id } = useRouteParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const  pageId = typeof id !== "undefined" ? id : "";
  const versionId = useMemo(() => !pageId.toString().includes("_") ? pageId : pageId.split("_")[0], [pageId]);
  const stopId = "";
  const { driverByVersionId } = useSelector((state: RootState) => ({
    driverByVersionId: state.driver.driverByVersionId ?? {},
  }), shallowEqual);

  // eslint-disable-next-line consistent-return
  const fetchDriver = useCallback(async (verId) => {
    if (!(verId in driverByVersionId)) {
      setIsLoading(true);
      try {
        const payload = await getDriverVersionRequest(verId);
        dispatch(driverActions.getDriverByVersionId(verId, payload));
        return payload;
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    } else driverByVersionId[verId];
  }, [driverByVersionId, dispatch]);

  const deleteFromRouteItems = useCallback(()=>{
    if ((versionId in driverByVersionId)) {
      dispatch(routeActions.deleteRouteByVersionId(versionId));
    }
  }, [dispatch, driverByVersionId, versionId]);

  const addToEditRoute = useCallback((payload: Trip[] | [])=>{
    dispatch(routeActions.setEditRouteTrips(payload));
  }, [dispatch]);

  // useEffect(() => {
  //   if (!routeIsLoading) fetchRoute(versionId);
  // }, [fetchRoute, versionId]);

  return {
    driverDetails: driverByVersionId[versionId] ?? null,
    deleteFromRouteItems,
    addToEditRoute,
    fetchDriver,
    versionId,
    isLoading,
    stopId,
  };
};