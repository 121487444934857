import { useSelector } from "react-redux";
import { RootState } from "../../types";
import { VehicleFilters } from "../../reducers/vehicle";
import { VehicleDetails } from "../../../models";

export const useVehicleDetailsSelector = (versionId: string) => useSelector((state: RootState) => state.vehicle.vehicleByVersionId[versionId]);

export const useVehiclesItemsSelector = (status: VehicleFilters) => useSelector((state: RootState) => {
  const vehicles = state.vehicle[status];
  if (!vehicles) return { totalCount: 0, items: [] };
  const { totalCount } = vehicles;

  return { totalCount, items: vehicles.items.reduce((acc, item) => [...acc, ...item], []).slice(0, totalCount) as VehicleDetails[] || [] };
});

export const useVehicleFilterSelector = (): VehicleFilters => useSelector((state: RootState) => state.vehicle.vehicleActiveFilter);

export const useVehicleSearchSubstringSelector = () => useSelector((state: RootState) => state.vehicle.vehicleSearchSubstring);
export const useVehicleFiltersSelector = () => useSelector((state: RootState) => state.vehicle.vehicleFilters);
export const useSelectedVehicleSelector = () => useSelector((state: RootState) => state.vehicle.selectedVehicle);
