import React, { memo } from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  overflow-y: scroll;
`;

export type StepperProps = {
  children: React.ReactNode;
};

export const StepperComponent = (props: StepperProps) => {
  const { children } = props;
  return <Container>{children}</Container>;
};

export const Stepper = memo(StepperComponent);
