import { withAccentColor, withCBColor, withContentColor } from "@megapolis/uikit/utils";
import styled, { css } from "styled-components/macro";

export const TripCard = styled.div`
  margin: 8px 0;
`;

const CommonControlCss = css`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const TabsHeader = styled.span`
  padding: 0 8px;
  color: ${withContentColor("secondary")};
  line-height: 24px;
  font-size: 11px;
  font-weight: 600;
`;

export const DirectionsBlock = styled.div`
margin: 8px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`;

export const CardWrapper = styled.div`
  padding: 4px 0;
  width: 100%;
  flex-grow: 1;
`;

export const ModalContentContainer = styled.div`
&::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 10px;
  background-color: #03284C26;
}
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
`;
type ContainerProps = {
  isActive: boolean;
};

export const activeContainerCss = css`
  background-color: ${withCBColor("secondary", "active")};
  border-color: ${withCBColor("secondary", "active")};
`;


export const Card = styled.div`
  margin: 8px 0;
  align-self: center;
  text-align: center;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  padding-left: 10px;
`;

export const HeaderItem = styled.div`
margin: 8px 0px;
text-align: center;
`;


export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

export const Title = styled.span`
  display: block;
  color: ${withContentColor("primary")};
`;

export const Caption = styled.span`
  display: block;
  color: ${withContentColor("secondary")};
  * {
    font-size: 11px;
  }
`;

export const Content = styled.span`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  line-height: 16px;
  overflow: hidden;
  text-align: left;
  margin: 7px 0;
  white-space: nowrap;
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MainControl = styled.button`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
  ${CommonControlCss};
`;
