import React, { memo } from "react";
import styled from "styled-components/macro";
import { prop } from "styled-tools";

type IconProps = {
  color: string;
  border: string;
};

const Icon = styled.span<IconProps>`
  display: block;
  padding: 4px;
  width: 48px;
  line-height: 16px;
  border-radius: 4px;
  background-color: ${prop("color", "#000")};
  border-color: ${prop("border", "#000")};
  border: 1px solid;
  text-align: center;
`;

export type DetailRouteIconProps = {
  children: React.ReactNode;
  color?: string;
  className?: string;
  border?: string;
};

export const DetailRouteIconComponent = (props: DetailRouteIconProps) => {
  const { color = "transparent", border = "black", className, children } = props;
  return (
    <Icon className={className} color={color} border={border}>
      {children}
    </Icon>
  );
};

export const DetailRouteIcon = memo(DetailRouteIconComponent);
