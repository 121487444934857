import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { vehicleActions } from "store/actions";
import { useRouteParams } from "shared/hooks";
import { VehicleDetails } from "../../../models";


export const useGetVehicleItemsByVersion = (): { vehicleDetails: VehicleDetails, versionId: string } => {
  const dispatch = useDispatch();
  const { id } = useRouteParams();
  const [versionId] = String(id).split("_");

  const { vehicleByVersionId } = useSelector((state: RootState) => ({
    vehicleByVersionId: state.vehicle.vehicleByVersionId,
  }));

  const fetchVehicle = useCallback(async () => {
    if (!(versionId in vehicleByVersionId) && versionId !== "new") {
      dispatch(vehicleActions.getVehicleByVersionId(versionId));
    }
  }, [versionId, dispatch, vehicleByVersionId]);


  useEffect(() => {
    fetchVehicle();
  }, [fetchVehicle]);

  return {
    vehicleDetails: vehicleByVersionId[versionId],
    versionId,
  };
};
