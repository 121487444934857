import React, { useState } from "react";
import ReactDOM from "react-dom";
import { v1 as uuid } from "uuid";
import { Alert } from "@megapolis/uikit";
import { lightTheme } from "@megapolis/uikit/theme";
import { ThemeProvider } from "styled-components";
import { ToastOptions, Toast, ToastHandler } from "./types";
import { removeItemByIndex } from "../../../shared/utils/array";
import { ToasterWrapper, ToastWrapper } from "./toaster.styles";
import "./toaster.css";

let toast: ToastHandler = () => {};

const Toaster = () => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const removeToast = (id: string) => {
    setToasts(prev => {
      const foundToastIndex = prev.findIndex(item => item.id === id);
      return foundToastIndex < 0 ? prev : removeItemByIndex(prev, foundToastIndex);
    });
  };

  const addTimer = (id: string): NodeJS.Timeout =>
    setTimeout(() => {
      removeToast(id);
    }, 5000);

  const addToast = (options: ToastOptions) => {
    const id = uuid();
    const timer = addTimer(id);
    setToasts(prev => [...prev, { id, timer, ...options }]);
  };

  toast = (options: ToastOptions) => {
    addToast(options);
  };

  return (
    <ToasterWrapper>
      {
        toasts.map(item => (
          <ToastWrapper  key={item.id}>
            <Alert title={item.title || ""} severity={item.type} description={item.message} />
          </ToastWrapper>
        ))
      }
    </ToasterWrapper>
  );
};

const toasterNode = <Toaster/>;

ReactDOM.render(
  <ThemeProvider theme={lightTheme}>
    {toasterNode}
  </ThemeProvider>,
  document.getElementById("toaster"),
);

export const toaster = {
  toast,
};
