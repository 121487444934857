import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Authentication } from "@megapolis/react-auth";
import { lightTheme } from "@megapolis/uikit/theme";
import { RouterProvider } from "react-router5";
import { DefaultTheme, ThemeProvider } from "styled-components";
import router from "./router";
import store from "./store/store";
import App from "./App";
import { APP_ENV } from "./app-env";
import { GlobalStyle } from "./app.styles";
import "./static/styles/common.css";

const theme = lightTheme as unknown as DefaultTheme;

// expose store when run in Cypress
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.store = store;
}

const application = (
  <Authentication
    authority={APP_ENV.REACT_APP_AUTH_URL}
    client_id={APP_ENV.REACT_APP_CLIENT_ID}
    scope={"dtm.router.api rpd.api"}>
    <Provider store={store}>
      <RouterProvider router={router}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App store={store} router={router} />
        </ThemeProvider>
      </RouterProvider>
    </Provider>
  </Authentication>
);

ReactDOM.render(application, document.getElementById("root"));
