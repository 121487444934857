import React, { memo, useMemo } from "react";
import { VehicleTypes, VehicleTypeIconMap } from "models";
import * as Markup from "./vehicle-card-icon.styles";

export type VehicleIconProps = {
  type: VehicleTypes;
  isActive: boolean;
};

const VehicleCardIconComponent = ({ isActive, type }: VehicleIconProps) => {
  const Icon = useMemo(() => VehicleTypeIconMap[type], [type]);
  return (
    <Markup.IconWrapper isActive={isActive}>
      <Icon />
    </Markup.IconWrapper>
  );
};

export const VehicleCardIcon = memo(VehicleCardIconComponent);
