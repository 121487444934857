import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useRouteParams } from "shared/hooks";
import { DriverTabListPagination, RouteTabListPagination, VehicleTabListPagination } from "features/detail-tabs";
import { DetailsPanel } from "../components";
import { TAB_VALUES } from "./depot-details-panel.constants";
import { DepotPanelHeader, DepotPanelTabs } from "./components";
import {
  DepotDetailsInfoTab,
} from "./tab-pages";
import { useGetDepotItemsByVersion } from "./depot-details-panel.data";

export const DepotDetailsPanel = () => {
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  const { depotDetails, versionId, fetchDepot } = useGetDepotItemsByVersion();

  useEffect(() => {
    fetchDepot(versionId);
  }, [fetchDepot, versionId]);

  useEffect(() => {
    setActiveTab(TAB_VALUES.info);
    return () => {
      setActiveTab(TAB_VALUES.info);
    };
  }, [versionId]);

  return (<DetailsPanel
    headerSlot={<DepotPanelHeader />}
    tabsSlot={<DepotPanelTabs value={activeTab} onChange={handleChangeTab} />}>
    {activeTab === TAB_VALUES.info && <DepotDetailsInfoTab />}
    {activeTab === TAB_VALUES.route && <RouteTabListPagination DepotIds={depotDetails ? depotDetails.depotId.toString() : ""} />}
    {activeTab === TAB_VALUES.drivers && <DriverTabListPagination DepotIds={depotDetails ? depotDetails.depotId.toString() : ""} />}
    {activeTab === TAB_VALUES.vehicles && <VehicleTabListPagination DepotIds={depotDetails ? depotDetails.depotId.toString() : ""} />}
  </DetailsPanel>);
};
