import React from "react";
import { encodeSvg } from "shared/helpers/encodeSVG";


export const addIcon = () =>
  encodeSvg(
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0H0V16H16V0Z" fill="white" />
      <path d="M16 0H0V16H16V0Z" fill="#027BFF" />
      <path d="M1.99997 5H5.99997V8H1.99997V5ZM6.99997 5H8.49997V11.0317H6.99997V5ZM9.49997 5H11V11.0317H9.49997V5ZM4.23456 13.127C3.5432 13.127 2.93826 12.5159 2.93826 11.8175C2.93826 11.119 3.5432 10.5079 4.23456 10.5079C4.92591 10.5079 5.53085 11.119 5.53085 11.8175C5.53085 12.6032 4.92591 13.127 4.23456 13.127ZM1.29628 11.8175C1.29628 11.381 1.3827 11.0317 1.55554 10.6825C1.72838 10.3333 1.90122 9.98413 2.16048 9.72222C2.41974 9.46032 2.76542 9.19841 3.1111 9.11111C3.45678 8.93651 3.88888 8.84921 4.23456 8.84921V9.63492C3.02468 9.63492 2.07406 10.5952 2.07406 11.8175C2.07406 13.0397 3.02468 14 4.23456 14C5.18517 14 5.96295 13.3889 6.22221 12.5159H13V11H14V9H13V4.13492L12 3H-1.16229e-05V5H0.999969V8H-3.05176e-05L-1.16229e-05 12.6032H1.3827C1.29628 12.3413 1.29628 12.0794 1.29628 11.8175Z"
        fill="white"/>
    </svg>,
);