import { common } from "./common";
import { toastLabelsErrors, toastLabelsSuccess } from "./toastLabels/index";
import { vehicle } from "./vehicle";
import { route } from "./route";
import { driver } from "./driver";
import { stop } from "./stop";
import { depot } from "./depot";
import { keyZone } from "./keyZone";

export const labels = {
  ...common,
  ...route,
  ...vehicle,
  ...toastLabelsErrors,
  ...toastLabelsSuccess,
  ...driver,
  ...stop,
  ...depot,
  ...keyZone,
} as const;
