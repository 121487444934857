import * as api from "services";
import {
  GetKeyZoneRequestOptions,
  KeyZoneVersionDetails,
  KeyZoneVersionResponse,
} from "./types";

const requestPath = "KeyZone";

export const getKeyZoneRequest = (options?: GetKeyZoneRequestOptions) => api.get<KeyZoneVersionResponse>(`${requestPath}/version`, options);
export const getKeyZoneVersionRequest = (versionId: string) => api.get<KeyZoneVersionDetails>(`${requestPath}/version/${versionId}`);
export const getKeyZoneVersionsDates = (keyZoneId: string | number) => api.get<KeyZoneVersionDetails[]>(`${requestPath}/${keyZoneId}/versionsDates`);
export const createKeyZoneRequest = (options: Partial<KeyZoneVersionDetails>) => api.post<{ keyZoneId: number; versionId:number }>(`${requestPath}/version`, {}, options);
export const editKeyZoneRequest = (options: KeyZoneVersionDetails) =>api.put(`${requestPath}/version`, {}, options);