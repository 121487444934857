/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useState } from "react";
import { Content } from "@megapolis/uikit/button/button.types";
import { DetailRouteIcon } from "components/common";
import { Button } from "components";
import { useRouter } from "react-router5";
import { CopyIcon32Icon, EditBlue32Icon } from "assets/icons";
import { PAGE_ROUTES } from "shared/definitions";
import { useRouteParams } from "shared/hooks";
import { VersionViewer } from "components/ui/version-card";
import styled from "styled-components";
import { VersionViewerContent } from "features/version-view";
import { routeActions } from "store/actions";
import { useDispatch } from "react-redux";
import { getRouteversionsDates } from "api";
import { DetailsPanelHeader } from "../../components";
import { useGetRouteItemsByVersion } from "../route-details-panel.data";
import { labels } from "../../../../shared/constants";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const RoutePanelHeader = () => {
  const { id } = useRouteParams();
  const { routeDetails, versionId, fetchRoute } = useGetRouteItemsByVersion();
  const router = useRouter();
  const dispatch = useDispatch();
  const [appearance, setAppearence] = useState({
    backgroundColor: "#FFFFFF",
    borderColor: "#000000",
    foregroundColor: "#FFFFFF",
  });

  const reverseColors = useCallback((mainColor, insideColor) => {
    let finColor: any = insideColor;
    if (mainColor === finColor) {
      switch (mainColor) {
        case "#FFFFFF":
          finColor = "#000000";
          break;
        case "#000000":
          finColor = "#FFFFFF";
          break;
        default:
          break;
      }
    }
    return finColor;
  }, []);

  useEffect(() => {
    fetchRoute(versionId);
    if (routeDetails?.appearance) {
      setAppearence(routeDetails.appearance);
    }
  }, [fetchRoute, routeDetails, versionId]);

  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => {
    setIsShown(!isShown);
  };

  const handleEditClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.ROUTE, { id: `${id}_edit` });
    },
    [router, id],
  );

  const handleCopyClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.ROUTE, { id: "copy", state: { copy: routeDetails } });
    },
    [router, routeDetails],
  );

  const handleEditVersionClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.ROUTE, { id: `${id}_version` });
    },
    [router, id],
  );


  const handleItemClick = useCallback((versionId: any) => {
    dispatch(routeActions.setActiveRoute(versionId));
    router.navigate(PAGE_ROUTES.ROUTE, { id: versionId.toString() });
    setIsShown(!isShown);
  }, [dispatch, isShown, router]);

  const handleCreateClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.ROUTE, { id: "new" });
    },
    [router],
  );

  const versionCaption = useCallback((itm: any) => {
    let versionString = "";
    if (itm?.startDate) {
      versionString =  `Версия от ${itm?.startDate}`;
    } else if (!itm?.startDate && itm?.endDate) {
      versionString =  `Версия до ${itm?.endDate}`;
    } else {
      versionString =  "Версия бессрочная";
    }
    return versionString;
  }, []);


  return (
    <DetailsPanelHeader
      title={routeDetails?.name || labels.noValue}
      caption={<Container onClick={toggle} ><span >{versionCaption(routeDetails)}</span><VersionViewer isShown={isShown} hide={toggle} modalContent={<VersionViewerContent currentVersion={routeDetails} id={routeDetails?.routeId} handleItemClick={handleItemClick} fetchVersions={getRouteversionsDates} onEdit={handleEditVersionClick} />} headerText={"Версионность"} onCreateClick={handleCreateClick} /></Container>}
      icon={<DetailRouteIcon color={appearance.backgroundColor} border={appearance.borderColor ? appearance.borderColor : "black"}><span style={{ "color": reverseColors(appearance.backgroundColor, appearance.foregroundColor) }}>{routeDetails?.number || labels.noValue}</span></DetailRouteIcon>}
      rightAction={<><Button content={Content.Icon} icon={<CopyIcon32Icon />} onClick={handleCopyClick} priority="quaternary" />
        <Button content={Content.Icon} icon={<EditBlue32Icon />} onClick={handleEditClick} priority="quaternary" />
      </>

      }
    />
  );
};
