import { Tab } from "components";
import { KeyZoneCardListPagination } from "features/card-lists/keyzone-card-list/keyzone-card-list-pagination";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoute, useRouter } from "react-router5";
import { PAGE_ROUTES } from "shared/definitions";
import { keyZoneActions } from "store/actions";
import { KeyZoneFilters } from "store/reducers/key-zone";
import { RootState } from "store/types";
import { ListPanel, ListPanelFilterTabs, ListPanelHeader, ListPanelHeaderWrapper } from "../components";
import { TabValues } from "../list-panels.constants";

export const KeyZoneListPanel = () => {
  // const [activeFilter, setActiveFilter] = useState("active");
  const [activeId, setActiveId] = useState("");
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const router = useRouter();
  const { activeFilter } = useSelector((state: RootState) => ({
    activeFilter: state.keyZone.keyZoneActiveFilter,
  }));
  const { route: { name: routeName, params: { id: routeId } = {} } } = useRoute();

  const handleItemClick = useCallback(
    (id: string) => {
      setActiveId(id);
      dispatch(keyZoneActions.setActiveKeyZone(id));
      router.navigate(PAGE_ROUTES.KEY_ZONE, { id });
    },
    [dispatch, router],
  );
    
  useEffect(() => {
    if (!routeId && routeName === PAGE_ROUTES.KEY_ZONES && activeId) {
      router.navigate(PAGE_ROUTES.ROUTE, { id: activeId });
    }
  }, [routeName, routeId, activeId, router]);

  const handleAddClick = useCallback(() => router.navigate(PAGE_ROUTES.KEY_ZONE, { id: "new" }), [router]);

  const handleChangeFilter = useCallback((filter: string) => {
    dispatch(keyZoneActions.setActiveFilter(filter as KeyZoneFilters));
  }, []);

  const toggle = useCallback(() => {
    setShowFilter(show => !show);
  }, []);
  
  return (
    <ListPanel
      headerSlot={
        <ListPanelHeaderWrapper>
          <ListPanelHeader requestPath="KeyZone" title="Зоны" onAddClick={handleAddClick} />
        </ListPanelHeaderWrapper>
      }
      filterSlot={
        <ListPanelFilterTabs activeFilterTab={activeFilter} onChange={handleChangeFilter}>
          <Tab value={TabValues.ACTIVE}>Активные</Tab>
          <Tab value={TabValues.INACTIVE}>Неактивные</Tab>
        </ListPanelFilterTabs>
      }
      listSlot={<KeyZoneCardListPagination activeId={activeId} onClick={handleItemClick} />}
    />
  );
};
