import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteCard, TabValues } from "features";
import { useRouteFilterSelector, useRouteFiltersSelector, useRouteItemsSelector, useRouteSearchSubstringSelector } from "../../../store/selectors/route-selectors";
import { getVehicleType, VehicleTypeCaptionMap } from "../../../models";
import { labels } from "../../../shared/constants";
import { getRouteRequest, GetRouteRequestOptions, RoutesVersionResponse, RouteVersion } from "../../../api";
import { PaginationCardList } from "../components/pagination-card-list";
import { routeActions } from "../../../store/actions/route-actions/route-actions";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

const DEFAULT_PAGE_SIZE = 100;

export type RouteCardListPaginationProps = {
  activeId?: string;
  onClick?: (id: string) => void;
};

const RouteCardListPaginationComponent = (props: RouteCardListPaginationProps) => {
  const { activeId, onClick } = props;
  const dispatch = useDispatch();
  const activeFilter = useRouteFilterSelector();
  const { items, totalCount } = useRouteItemsSelector(activeFilter);
  const searchSubstring = useRouteSearchSubstringSelector();
  const filters = useRouteFiltersSelector();
  const { showDetails } = useLayoutDesignState();
  const [pageNum] = useState<number>(1);

  const firstItem = items[0];

  const activeIndex = useMemo(() => items.findIndex((item) => String(item.versionId) === String(activeId)), [items, activeId]);

  useEffect(() => {
    if (!firstItem) return;
    if (!activeId && firstItem?.versionId && onClick) {
      onClick(String(firstItem.versionId));
    }
  }, [activeId, firstItem?.versionId, onClick]);

  const handleClick = useCallback((id: string) => {
    if (onClick) {
      onClick(id);
      // showDetails();
    }
  }, [onClick, showDetails]);

  const params = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
  }), [pageNum]);

  const options: GetRouteRequestOptions = useMemo(() => ({
    IncludeActive: TabValues.ALL === activeFilter || activeFilter === TabValues.ACTIVE,
    IncludeArchive: TabValues.ALL === activeFilter || activeFilter === TabValues.ARCHIVED,
    IncludeFuture: TabValues.ALL === activeFilter || activeFilter === TabValues.PLANNED,
    SearchSubstring: searchSubstring,
    ...filters,
  }), [activeFilter, searchSubstring, filters]);

  const handleSetItems = useCallback((response: RoutesVersionResponse) => {
    dispatch(routeActions.addRoutes(activeFilter, response));
  }, [activeFilter, dispatch]);

  const itemRender = useCallback((item: RouteVersion) => {
    const { versionId, transportType, number, name, kind, contractor, appearance = {
      backgroundColor: "#FFFFFF",
      borderColor: "#000000",
      foregroundColor: "#FFFFFF",
    } } = item;
    const vehicleData = {
      contractorName: contractor?.name || labels.noValue,
      routeType: kind ? `Тип трассы ${kind}` : "",
      transportType: `Тип транспорта ${VehicleTypeCaptionMap[getVehicleType(transportType)]}`,
    };

    return (
      <RouteCard
        id={String(versionId)}
        routeName={name || "Трасса отстутствует"}
        routeNumber={String(number)}
        routeIconColor={appearance?.backgroundColor}
        routeNumberColor={appearance?.foregroundColor}
        routeBorderColor={appearance?.borderColor}
        isActive={String(activeId) === String(versionId)}
        vehicleData={vehicleData}
        onClick={handleClick}
      />
    );
  }, [handleClick, activeId]);

  return (
    <PaginationCardList
      itemRender={itemRender}
      options={options}
      params={params}
      fetcher={getRouteRequest}
      setItems={handleSetItems}
      items={items}
      totalCount={totalCount}
      scrollTo={activeIndex}
    />
  );
};

export const RouteCardListPagination = memo(RouteCardListPaginationComponent);
