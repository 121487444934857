import { Dispatch } from "redux";
import {
  getRouteRequest,
  GetRouteRequestOptions,
  RoutesVersionResponse,
  RouteVersionDetails,
  Trip,
  TripStop,
} from "api";
import { RouteDetails } from "models";
import { NewRouteForm } from "features/detail-panels/route-details-panel/new-route-panel/new-route.panel.logic";
import { TYPES } from "../types";
import { RouteFilters } from "../../reducers/route";
import {
  AddRoutesAction,
  DeleteNewRouteTrip,
  DeleteRouteByVersionIdAction,
  RouteActionType, SetActiveRouteAction,
  SetEditRouteTrips,
  SetFilters,
  SetNewRouteForm,
  SetNewRouteTrips,
  SetRouteActiveFilterAction,
  SetRouteByVersionIdAction,
  SetRouteIsLoadingAction,
  SetRouteSearchSubstring,
  SetSelectedRouteTrips,
  UpdateRoutesFilesAction,
} from "./route-actions.types";

export const routeActions = {

  getRouteByVersionId: (versionId: string, payload: RouteVersionDetails) => async (dispatch: Dispatch<SetRouteByVersionIdAction>) => {
    dispatch({
      type: RouteActionType.SET_ROUTE_BY_VERSION_ID,
      payload: {
        versionId,
        data: payload,
      },
    });
  },

  deleteRouteByVersionId: (versionId: string) => async (dispatch: Dispatch<DeleteRouteByVersionIdAction>) => {
    dispatch({
      type: RouteActionType.DELETE_ROUTE_BY_VERSION,
      payload: { versionId },
    });
  },

  getRoute: (params: GetRouteRequestOptions) => async (dispatch: Dispatch<any>) => {
    const payload = await getRouteRequest({ ...params, pageSize: 50 });
    if (params.IncludeActive) {
      dispatch({
        type: TYPES.ROUTES.SET_ACTIVE_ROUTE,
        payload,
      });
    } else if (params.IncludeArchive) {
      dispatch({
        type: TYPES.ROUTES.SET_ARCHIVED_ROUTE,
        payload,
      });
    } else {
      dispatch({
        type: TYPES.ROUTES.SET_PLANNED_ROUTE,
        payload,
      });
    }
  },

  setActiveFilter: (payload: RouteFilters): SetRouteActiveFilterAction => ({
    type: RouteActionType.SET_ROUTE_ACTIVE_FILTER,
    payload,
  }),

  updateRouteByVersion: (versionId: string, data: RouteDetails): SetRouteByVersionIdAction => ({
    type: RouteActionType.SET_ROUTE_BY_VERSION_ID,
    payload: { versionId, data },
  }),

  addRoutes: (status: RouteFilters, data: RoutesVersionResponse): AddRoutesAction => ({
    type: RouteActionType.ADD_ROUTES,
    payload: { status, data },
  }),

  setFilters: (payload: {
    ContractorIds?: string | null,
    RouteKindIds?: string | null,
    TransportTypes?: string | null,
    CapacityTypes?: string | null,
  }): SetFilters => ({
    type: RouteActionType.SET_FILTERS,
    payload,
  }),

  setSearchSubstring: (payload: string): SetRouteSearchSubstring => ({
    type: RouteActionType.SET_ROUTE_SEARCH_SUBSTRING,
    payload,
  }),

  setActiveRoute: (payload: string): SetActiveRouteAction => ({
    type: RouteActionType.SET_ACTIVE_ROUTE_ID,
    payload,
  }),

  setIsLoading: (payload: boolean): SetRouteIsLoadingAction => ({
    type: RouteActionType.SET_ROUTE_ISLOADING,
    payload,
  }),

  setSelectedRouteTrips: (payload: TripStop[]): SetSelectedRouteTrips => ({
    type: RouteActionType.SET_SELECTED_ROUTE_TRIPS,
    payload,
  }),

  setNewRouteTrips: (payload: Trip[]): SetNewRouteTrips => ({
    type: RouteActionType.SET_NEW_ROUTE_TRIPS,
    payload,
  }),

  setNewRouteForm: (payload: NewRouteForm | undefined): SetNewRouteForm => ({
    type: RouteActionType.SET_NEW_ROUTE_FORM,
    payload,
  }),

  setEditRouteTrips: (payload: Trip[]): SetEditRouteTrips => ({
    type: RouteActionType.SET_EDIT_ROUTE_TRIPS,
    payload,
  }),

  updateRoutesFiles: (routeId: string, data: Partial<RouteVersionDetails>): UpdateRoutesFilesAction => ({
    type: RouteActionType.UPDATE_ROUTES_FILES,
    payload: { routeId, data },
  }),

  deleteNewRouteTrip: (payload: { type: string, direction: string }): DeleteNewRouteTrip => ({
    type: RouteActionType.DELETE_NEW_ROUTE_TRIP,
    payload,
  }),
};

export default routeActions;
