import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Add24BlueIcon } from "assets/icons";
import { v4 } from "uuid";
import { InfoCard, Divider, Box } from "components/common";
import { NoDataLabel, toastError, ViewFileArea } from "components/ui";
import { StopDetailsResponse, getStopFile } from "api";
import { Loader } from "megapolis-uikit-latest/loader";
import { DetailsBlock } from "../../../components";
import { useGetStopItemsByVersion } from "../stop-station-details.data";
import { useGetStopStationInfoTabData } from "./stop-station-details-info-tab.data";


const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 16px;
  div:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  width: 100%;
`;

const StopStationDetailsInfo = () => {
  const { item } = useGetStopItemsByVersion();
  const { infoCards } = useGetStopStationInfoTabData();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [files, setFiles] = useState<File[]>([]);

  const fetchFiles = useCallback(async (controller: AbortController, stop: StopDetailsResponse) => {
    if (!stop?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(stop.files.map(async fileElem => {
        const payload = await getStopFile(stop.id, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);

  useEffect(() => {
    setFiles([]);
    const controller = new AbortController();
    fetchFiles(controller, item);
    return () => {
      setFiles([]);
      controller.abort();
    };
  }, [item, fetchFiles]);

  return (
    <Box direction="column" padding="0 16px">
      <DetailsBlock padding="0 8px" header="Общие сведения">
        <InfoCards>
          {infoCards.map((card) => (
            <InfoCard key={v4()} title={card.title}>
              {card.text}
            </InfoCard>
          ))}
        </InfoCards>
      </DetailsBlock>
      <Divider margin="8px 0" />
      <DetailsBlock header={<Box>Файлы</Box>}>
        {
          isLoading && (<Box justifyContent={"center"}><Loader /></Box>)
        }
        {files.length > 0 ?
          (<Box direction="column" margin="-24px 0 0 0">
            <ViewFileArea
              filesInput={files}
              multiple={true} />
          </Box>) : (!isLoading && <NoDataLabel/>)
        }
      </DetailsBlock>
      
    </Box>
  );
};

export const StopStationDetailsInfoTab = memo(StopStationDetailsInfo);
