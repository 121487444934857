import { RouteVersion } from "api";
import { Add24BlueIcon } from "assets/icons";
import { FilterWindow, IconButton } from "components";
import { DepotCard, RouteCard } from "features/cards";
import { DepotVersion } from "models";
import { useState, useCallback, useEffect } from "react";
import { ListRowRenderer, AutoSizer, List } from "react-virtualized";
import { labels } from "shared/constants";
import * as Markup from "./depot-filter-element.style";
import { SearchDepotContent } from "./filter-search-depot-content";

export const DepotFilterElement = (props: { arr?: DepotVersion[], update: (arr: DepotVersion[]) => void }) => {
  const { arr, update } = props;
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState<DepotVersion[]>(arr || []);
  
  const toggle = useCallback((visible_: boolean) => {
    setVisible(visible_);
  }, []);

  useEffect(() => {
    if (arr?.length === 0) {
      setSelected([]);
    }
  }, [arr]);
  
  const itemRender = useCallback((item: DepotVersion & { depotId: number }) => {
    const { versionId, name, depotId, ...rest } = item;

    return (
      <DepotCard
        {...rest}
        name={name}
        key={versionId}
        id={versionId}
        isActive={false}
        onClick={undefined}
      />
    );
  }, []);
  
  // eslint-disable-next-line consistent-return
  const rowRenderer: ListRowRenderer = useCallback(({ index, style, key }) => {
    if (selected) {
      const item = selected[index];
      const updatedStyles = {
        ...style,
        height: (Number(style.height)) - 8,
        top: ((Number(style.top) || 0) + 8 / 2),
      };
  
      return <div style={updatedStyles} key={key} >
        {itemRender(item)}
      </div>;
    }
  }, [selected, itemRender]);
  
  return (<>
    <FilterWindow headerText={labels.depots} isShown={visible} hide={() => toggle(false)} modalContent={<SearchDepotContent filterItems={selected || []} onSubmit={(filtredRoute) => {
      toggle(false);
      setSelected(filtredRoute);
      update(filtredRoute || []);
    }} />} />
    <Markup.LabelButton><Markup.Label>{labels.depots}</Markup.Label><IconButton content="icon" icon={<Add24BlueIcon />} onClick={() => toggle(true)} /></Markup.LabelButton>
    <div style={{ width: "100%", height: (selected.length > 10 ? 6 : selected.length) * 46 }}>
      <AutoSizer>
        {({ width }) => (
          <List
            height={(selected.length > 10 ? 6 : selected.length) * 46}
            ref={undefined}
            rowCount={selected.length}
            rowHeight={46 + 8}
            rowRenderer={rowRenderer}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  </>);
  
};
  