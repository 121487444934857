import React, { useMemo } from "react";
import * as Markup from "./upload-area.styles";
import { File } from "../file";
import {
  convertBytesToKilobytes,
  PreviewListType,
  UploadAreaProps,
  DEFAULT_ACCEPT_EXTENSIONS,
  IMAGES_ACCEPT_EXTENSIONS,
} from "./upload-area.utils";
import { useUploadAreaLogic } from "./use-upload-area-logic";

const UploadAreaComponent = (props: UploadAreaProps) => {
  const {
    label,
    files,
    multiple,
    fileInputField,
    acceptOnlyImages,
    handleFileRemove,
    handleNewFileUpload,
    handleUploadButtonClick,
    otherProps,
  } = useUploadAreaLogic(props);

  const previewList = useMemo(() => {
    const list = {
      images: {},
      otherFiles: {},
    } as PreviewListType;

    Object.keys(files).forEach((fileName: string) => {
      const file = files[fileName];
      const isImage = file.type.split("/")[0] === "image";
      list[isImage ? "images" : "otherFiles"][fileName] = file;
    });
    return list;
  }, [files]);

  const imagesPreviewContainer = useMemo(
    () => (
      <Markup.PreviewContainerGrid>
        {Object.keys(previewList.images).map((fileName: string) => {
          const { size, name } = previewList.images[fileName];
          return (
            <div key={fileName}>
              <Markup.PreviewContainer>
                <Markup.ImagePreview
                  //  eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  style={{ backgroundImage: `url(${URL.createObjectURL(previewList.images[fileName])})` }}
                />
                <File
                  withoutUserEvents={true}
                  withoutIcon={true}
                  title={name}
                  canBeCancel={true}
                  caption={`${convertBytesToKilobytes(size)}kb`}
                  onControlClick={() => {
                    handleFileRemove(fileName);
                  }}
                />
              </Markup.PreviewContainer>
            </div>
          );
        })}
      </Markup.PreviewContainerGrid>
    ),
    [previewList.images, handleFileRemove],
  );

  const filesPreviewContainer = useMemo(
    () => (
      <Markup.PreviewContainerGrid>
        {Object.keys(previewList.otherFiles).map((fileName: string) => {
          const { size, name } = previewList.otherFiles[fileName];
          return (
            <div key={fileName}>
              <File
                title={name}
                canBeCancel={true}
                caption={`${convertBytesToKilobytes(size)}kb`}
                onControlClick={() => handleFileRemove(fileName)}
              />
            </div>
          );
        })}
      </Markup.PreviewContainerGrid>
    ),
    [previewList.otherFiles, handleFileRemove],
  );

  return (
    <Markup.Wrapper>
      <Markup.Container>
        <Markup.DragDropText>{label}</Markup.DragDropText>
        <Markup.UploadFileBtn type="button" onClick={handleUploadButtonClick}>
          <span>Выбрать {multiple ? "файлы" : "файл"}</span>
        </Markup.UploadFileBtn>
        <Markup.FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          accept={acceptOnlyImages ? IMAGES_ACCEPT_EXTENSIONS : DEFAULT_ACCEPT_EXTENSIONS}
          title=""
          value=""
          {...otherProps}
        />
      </Markup.Container>
      <Markup.FilePreviewContainer>
        <Markup.PreviewList>{imagesPreviewContainer}</Markup.PreviewList>
      </Markup.FilePreviewContainer>
      <Markup.FilePreviewContainer>
        <Markup.PreviewList>{filesPreviewContainer}</Markup.PreviewList>
      </Markup.FilePreviewContainer>
    </Markup.Wrapper>
  );
};

export const UploadArea = React.memo(UploadAreaComponent);
