import styled, { keyframes } from "styled-components";

const toast = keyframes`
  from {
    transform: translateX(100%);
  }
  
  to {
    transform: translateX(0);
  }
`;

export const ToastWrapper = styled.div`
  will-change: transform;
  animation: ${toast} .3s ease-in-out forwards;
  margin-bottom: 5px;
  width: 300px;
  z-index: 100;
`;

export const ToasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
