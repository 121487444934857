import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useRouteParams } from "shared/hooks";
import { ContractorResponse } from "api";
import { DepotTabListPagination } from "features/detail-tabs/depot-tab/depot-tab";
import { DriverTabListPagination, RouteTabListPagination } from "features/detail-tabs";
import { DetailsPanel } from "../components";
import { TAB_VALUES } from "./contractor-details-panel.constants";
import { ContractorPanelHeader, ContractorPanelTabs } from "./components";
import {
  ContractorDetailsInfoTab,
} from "./tab-pages";
import { useIsContractorCardLoading } from "../../../store/selectors/contractor-selectors";
import { SkeletonDetailCard } from "../../../components/ui/skeleton-detail-card";
import { useGetContractorItemsByVersion } from "./contractor-details.data";

export const ContractorDetailsPanel = () => {
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const isCardLoading = useIsContractorCardLoading();
  const { contractorDetails = {} as ContractorResponse, versionId, fetchContractor } = useGetContractorItemsByVersion();


  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  useEffect(() => {
    setActiveTab(TAB_VALUES.info);
    fetchContractor(versionId);
    return () => {
      setActiveTab(TAB_VALUES.info);
    };
  }, [versionId]);

  return !isCardLoading
    ? (
      <DetailsPanel
        headerSlot={<ContractorPanelHeader />}
        tabsSlot={<ContractorPanelTabs value={activeTab} onChange={handleChangeTab} />}>
        {activeTab === TAB_VALUES.info && <ContractorDetailsInfoTab />}
        {activeTab === TAB_VALUES.route && <RouteTabListPagination ContractorIds={contractorDetails?.Id ? contractorDetails?.Id.toString() : ""} />}
        {activeTab === TAB_VALUES.depot && <DepotTabListPagination ContractorIds={contractorDetails?.Id ? contractorDetails?.Id.toString() : ""} />}
        {activeTab === TAB_VALUES.drivers && <DriverTabListPagination ContractorIds={contractorDetails?.Id ? contractorDetails?.Id.toString() : ""} />}
      </DetailsPanel>
    ) : <SkeletonDetailCard />;
};
