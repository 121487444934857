export const common = {
  noValue: "-",
  noData: "Нет данных",
  notDefined: "Не указано",
  startDateVersion: "Версия от",
  averageWorkingTime: "Среднее время работы",
  hoursPerDay: "часов/сутки",
  noName: "Имя не указано",
  enterValue: "Введите",
  balanceholder: "Балансодержатель",
  address: "Адрес",
  climateSystem: "Климатическая система",
  lowFloor: "Низкий пол",
} as const;
