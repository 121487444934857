import { RootState } from "store/types";
import { shallowEqual, useSelector } from "react-redux";
import { DepotFilters } from "store/reducers/depot";
import { DepotVersion } from "models";

export const useDepotFilterSelector = () => useSelector((state: RootState) => state.depot.depotActiveFilter);
export const useDepotFiltersSelector = () => useSelector((state: RootState) => state.depot.depotFilters);
export const useDepotSearchStringSelector = () => useSelector((state: RootState) => state.depot.depotSearchSubstring);
// export const useActiveDepotSelector = () => useSelector((state: RootState) => state.depot.activeDepot);

export const useDepotItemsSelector = (filter: DepotFilters): { totalCount: number, items: any[] } =>
  useSelector((state: RootState) => {
    const drivers = state.depot[filter];
    if (!drivers) return { totalCount: 0, items: [] };
    const { totalCount } = drivers;

    // return drivers.items.reduce((acc, item) => [...acc, ...item], []).slice(0, totalCount) || [];
    return { totalCount, items: drivers.items
      .reduce((acc: any, page: any) => [...acc, ...(page ?? [])], [])
      .slice(0, totalCount) };
  }, shallowEqual);
