import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Chips, ChipsItem, IconButton } from "components/common";
import { ContractorSelectField } from "components/common/select-with-source";
import { labels } from "shared/constants";
import { PanelForm } from "features/panel-form";
import { isCorrectEndDate, isCorrectStartDate, isRequiredRouteForm, isRequiredRouteKeyZonesEnough } from "shared/utils/validator";
import { useRoute, useRouter } from "react-router5";
import { RootState } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleCapacityTypesRequest, Trip } from "api";
import { Add24BlueIcon, EditBlue16Icon, XClose16Icon } from "assets/icons";
import { routeActions } from "store/actions";
import { createNewDataTripsKeyZones, createNewDataTripsStop } from "components/common/main-map/selected-route/utils";
import { FeatureCollection } from "geojson";
import mapboxgl from "mapbox-gl";
import { useMap } from "components/common/main-map/map.context";
import { DatePicker } from "components/common/date-picker";
import { v4 } from "uuid";
import { Tab, UploadArea } from "components";
import { PAGE_ROUTES } from "shared/definitions";
import { TextField } from "megapolis-uikit-latest";
import { selectedTripsController } from "components/common/main-map/selected-route/selected-route";
import { Loader } from "megapolis-uikit-latest/loader";
import * as Markup from "./new-route-panel.styles";
import { DetailsBlock, DetailsPanelTabs, FormField } from "../../components";
import { initialForm, NewRouteForm, useNewRouteLogic } from "./new-route.panel.logic";
import { EditRouteTrip } from "./new-route-panel.edit-trip";
import { TripsStepper } from "../tab-pages/route-details-route-tab/sections";
import { KeyZoneStepper } from "../tab-pages/route-details-route-tab/sections/keyZone-stepper/keyzone-stepper";
import { NewRouteTripLine } from "./new-route-panel.trip";
import { chips, routeTypeChipLabel } from "../mocks";

export const NewRoutePanel = (props: { isNew?: boolean; changeVersion?: boolean; isCopy?: boolean }) => {
  const { isNew = true, isCopy = false, changeVersion = false } = props;
  const { map } = useMap();
  const emptyTrip = useMemo(() => ({
    direction: "",
    path: {
      "type": "LineString",
      "coordinates": [],
    },
    tripStops: [],
    tripKeyZones: [],
    type: "",
    startDate: null,
    endDate: null,
  }), []);

  const { newRouteTrips, newRouteForm, activeId } = useSelector((state: RootState) => ({
    newRouteTrips: state.route.newRouteTrips,
    newRouteForm: state.route.newRouteForm,
    activeId: state.route.activeRouteId,
  }));

  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const upValues = useRef<NewRouteForm>();
  const [startDate, setStartDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");
  const [capacityType, setCapacityTypes] = useState<{
    id: string;
    label: string;
  }[]>([]);
  const [filter, setFilter] = useState<string>("tripStops");
  const [transportClassTypeArray, setTransportClassTypeArray] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<Trip>(emptyTrip);
  const router = useRouter();
  const { previousRoute, route } = useRoute();
  const copyRoute = route.params.state?.copy || undefined;
  // eslint-disable-next-line no-nested-ternary
  const title = isNew ? labels.createRoute : (copyRoute ? labels.copyRoute : labels.editRoute);
  const referSetValue = useRef<(name: keyof NewRouteForm, value: NewRouteForm[keyof NewRouteForm]) => void>();
  const { form, createRoute, editRoute, isLoading, editRouteVersion, setForm, startDate: oldStartDate, endDate: oldEndDate, files, handleFiles, clearUp } = useNewRouteLogic({ isNew, setValue: referSetValue.current, copyRoute });
  const forwardTrips = useRef<Array<Trip>>([]);
  const backwardTrips = useRef<Array<Trip>>([]);

  const [showA, setshowA] = useState(forwardTrips.current);
  const [showB, setshowB] = useState(backwardTrips.current);

  const addDays = useCallback((date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }, []);

  const getCapacityTypes = useCallback(async () => {
    const capacityTypes = await getVehicleCapacityTypesRequest();
    const result = capacityTypes.map(item => ({ id: item.shortName, label: item.name }));
    setCapacityTypes(result);
  }, []);

  useEffect(() => {
    if (form) {
      getCapacityTypes();
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (copyRoute !== undefined) {
        const trips = copyRoute.trips.map((el: Trip) => ({ ...el, workId: v4() }));
        dispatch(routeActions.setNewRouteTrips(trips));
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (!isNew && !copyRoute) {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        const trips = form.trips.map((el: Trip) => ({ ...el, workId: v4() }));
        dispatch(routeActions.setNewRouteTrips(trips));
      }
    }
    return () => {
      dispatch(routeActions.setNewRouteTrips([]));
      dispatch(routeActions.setNewRouteForm(initialForm));
      setForm(initialForm);
      if (map?.getLayer("routeStops")) map?.removeLayer("routeStops");
      if (map?.getLayer("route")) map?.removeLayer("route");
      if (map?.getLayer("KeyZoneLine")) map?.removeLayer("KeyZoneLine");
      if (map?.getLayer("KeyZonePolygon")) map?.removeLayer("KeyZonePolygon");
    };
  }, [route]);

  useEffect(() => {
    dispatch(routeActions.setNewRouteForm(form));
  }, []);

  useEffect(() => {
    if (showNew || showEdit) {
      dispatch(routeActions.setNewRouteForm(form));
    }
  }, [showNew, showEdit]);

  // useEffect(() => {
  //   dispatch(routeActions.setNewRouteForm(form));
  // }, [setForm]);

  useEffect(() => {
    if (isNew) clearUp();
    return () => {
      clearUp();
    };
  }, [clearUp, isNew]);


  const updateLayerStops = useCallback((data) => {
    if (!map?.getSource("routeStops")) {
      map?.addSource("routeStops", {
        type: "geojson",
        data: createNewDataTripsStop(data),
      } as mapboxgl.AnySourceData);
    } else {
      const source: mapboxgl.GeoJSONSource = map?.getSource("routeStops") as mapboxgl.GeoJSONSource;
      source.setData(createNewDataTripsStop(data) as FeatureCollection);
    }

    if (!map?.getLayer("routeStops")) {

      map?.addLayer({
        id: "routeStops",
        type: "symbol",
        source: "routeStops",
        minzoom: 0,
        maxzoom: 24,
        layout: {
          "icon-image": "actualStopIcon",
          "icon-size": ["interpolate", ["exponential", 0], ["zoom"], 13.5, 0.7, 14.01, 1, 15, 1, 16.01, 1],
          "visibility": "visible",
          "icon-allow-overlap": true,
          "icon-anchor": "bottom",
        },
      });
    }
  }, [map]);

  const updateLayerKeyZones = useCallback((data) => {
    if (!map?.getSource("routeKeyZonesSource")) {
      map?.addSource("routeKeyZonesSource", {
        type: "geojson",
        data: createNewDataTripsKeyZones(data),
      } as mapboxgl.AnySourceData);
    } else {
      const source: mapboxgl.GeoJSONSource = map?.getSource("routeKeyZonesSource") as mapboxgl.GeoJSONSource;
      source.setData(createNewDataTripsKeyZones(data) as FeatureCollection);
    }

    if (!map?.getLayer("KeyZonePolygon")) {
      map?.addLayer({
        "id": "KeyZonePolygon",
        "type": "fill",
        "source": "routeKeyZonesSource", // reference the data source
        "layout": {},
        "paint": {
          "fill-color": "#5ACAFD", // blue color fill
          "fill-opacity": 0.3,
        },
      });
    }

    if (!map?.getLayer("KeyZoneLine")) {
      map?.addLayer({
        "id": "KeyZoneLine",
        "type": "line",
        "source": "routeKeyZonesSource",
        "layout": {},
        "paint": {
          "line-color": "#5ACAFD",
          "line-width": 1,
        },
      });
    }
  }, [map]);

  const toggleLayersVisibility = useCallback((visibility: boolean) => {
    const waiting = () => {
      if (!map?.isStyleLoaded()) {
        setTimeout(waiting, 200);
      } else {
        const toggleableLayerIds = ["route", "routeStops", "selectedRoute", "KeyZonePolygon", "KeyZoneLine"];
        toggleableLayerIds.map(el => map?.getLayer(el) && map?.setLayoutProperty(el, "visibility", visibility ? "none" : "visible"));
      }
    };
    waiting();
  }, [map]);

  useEffect(() => {
    if ((showNew && !showEdit) || (!showNew && showEdit)) {
      toggleLayersVisibility(true);
    }

    if (!showNew && !showEdit) {
      toggleLayersVisibility(false);
    }

  }, [showEdit, showNew, toggleLayersVisibility]);

  const updateLayerLine = useCallback((data) => {
    if (!map?.getSource("route")) {
      map?.addSource("route", {
        type: "geojson",
        data,
        generateId: true,
      } as mapboxgl.AnySourceData);
    } else {
      const source: mapboxgl.GeoJSONSource = map?.getSource("route") as mapboxgl.GeoJSONSource;
      source.setData(data);
    }

    if (!map?.getLayer("route")) {

      map?.addLayer({
        "id": "route",
        "type": "line",
        "source": "route",
        "layout": {
          "line-join": "round",
          "line-cap": "round",
        },
        "paint": {
          "line-color": "#5297ff",
          "line-width": 6,
        },
      });
    }
  }, [map]);

  const showOnMap = useCallback((trip: Trip) => {
    if (trip.path.coordinates.length > 0) {
      updateLayerLine(trip.path);
      updateLayerStops(trip.tripStops);
      if (trip.tripKeyZones) {
        updateLayerKeyZones(trip.tripKeyZones);
      }
    } else {
      updateLayerLine({
        "type": "LineString",
        "coordinates": [],
      });
      updateLayerStops([]);
      updateLayerKeyZones([]);
    }
    if (map?.getLayer("selectedRoute")) {
      map?.moveLayer("selectedRoute", "route");
    }
  }, [map, updateLayerKeyZones, updateLayerLine, updateLayerStops]);

  useEffect(() => {
    showOnMap(selected);
  }, [selected]);

  useEffect(() => {
    forwardTrips.current = [];
    backwardTrips.current = [];
    newRouteTrips.map((elm) => {
      switch (elm.direction) {
        case "Backward":
          backwardTrips.current.push(elm);
          break;
        case "Forward":
          forwardTrips.current.push(elm);
          break;
        default:
          break;
      }
      return elm;
    });
    // forwardTrips.current.sort((a, b) => a.type.localeCompare(b.type));
    // backwardTrips.current.sort((a, b) => a.type.localeCompare(b.type));
    // setForm((oldForm: any) => ({ ...oldForm, trips: newRouteTrips }));
    setshowA(forwardTrips.current.sort((a, b) => a.type.localeCompare(b.type)));
    setshowB(backwardTrips.current.sort((a, b) => a.type.localeCompare(b.type)));
    if (map) {
      selectedTripsController(newRouteTrips as any, map);
    }
    setForm((oldForm: any) => ({ ...oldForm, trips: newRouteTrips }));
    setSelected(emptyTrip);
    // if (forwardTrips.current.length > 0) setSelected(forwardTrips.current[0]);
  }, [emptyTrip, newRouteTrips, setForm]);

  const handleClosePanel = useCallback(() => {
    if (previousRoute) {
      // router.navigate(previousRoute.name, previousRoute.params);
      router.navigate(PAGE_ROUTES.ROUTE, { id: activeId });
    } else {
      router.navigate(PAGE_ROUTES.ROUTES);
    }
    if (map?.getLayer("routeStops")) map?.removeLayer("routeStops");
    if (map?.getLayer("route")) map?.removeLayer("route");
    if (map?.getLayer("KeyZoneLine")) map?.removeLayer("KeyZoneLine");
    if (map?.getLayer("KeyZonePolygon")) map?.removeLayer("KeyZonePolygon");
    dispatch(routeActions.setNewRouteForm(undefined));
  }, [activeId, dispatch, map, previousRoute, router]);

  useEffect(() => function cleanup() {
    if (!map?.isStyleLoaded()) return;
    if (map?.getLayer("routeStops")) map?.removeLayer("routeStops");
    if (map?.getLayer("route")) map?.removeLayer("route");
    if (map?.getLayer("KeyZoneLine")) map?.removeLayer("KeyZoneLine");
    if (map?.getLayer("KeyZonePolygon")) map?.removeLayer("KeyZonePolygon");
    dispatch(routeActions.setNewRouteTrips([]));
  }, [dispatch, map]);

  const handleSubmit = useCallback(() => {
    if (isNew) {
      createRoute(form);
    } else if (changeVersion) {
      editRouteVersion(form);
    } else if (isCopy) {
      createRoute(form);
    } else {
      editRoute(form);
    }
  }, [changeVersion, createRoute, editRoute, editRouteVersion, form, isCopy, isNew]);

  const handleDelete = useCallback((type: string, direction: string) => {
    dispatch(routeActions.deleteNewRouteTrip({ type, direction }));
    setSelected(emptyTrip);
  }, [dispatch, emptyTrip]);

  const scheme = useMemo(() => ({
    name: isRequiredRouteForm("Введите название трассы", form?.name || ""),
    number: isRequiredRouteForm("Введите номер трассы", form?.number || ""),
    startDate: isCorrectStartDate("Дата начала действия трассы не может быть больше даты окончания", endDate || ""),
    endDate: isCorrectEndDate("Дата окончания действия трассы не может быть меньше даты начала", startDate || ""),
    transportType: isRequiredRouteForm("Укажите тип транспорта", form?.capacityType || ""),
  }), [endDate, startDate, form]);

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        !showNew && !showEdit ? <PanelForm
          title={title}
          initialValues={newRouteForm || form}
          validationScheme={scheme}
          onSubmit={handleSubmit}
          onCancel={handleClosePanel}
          isLoading={isLoading}
        >
          {
            ({ names, values, updateValue: setValue, errors }) => {
              upValues.current = values;
              referSetValue.current = setValue;
              return (<>
                <FormField>
                  <TextField
                    value={form?.name || ""}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value: newValue } = event.target;
                      const clearedValue = newValue;
                      setForm(() => ({ ...form, name: clearedValue }));
                      setValue(names.name, clearedValue);
                      setForm((oldForm: any) => ({ ...oldForm, name: clearedValue }));
                    }}
                    label={labels.routeName}
                    errorText={errors?.name ?? undefined}
                    hasError={!!errors?.name}
                    placeholder={labels.enterValue}
                  />
                </FormField>
                <FormField>
                  <TextField
                    isDisabled={!isCopy && !isNew}
                    value={form?.number || ""}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value: newValue } = event.target;
                      const clearedValue = newValue;
                      setValue(names.number, clearedValue);
                      setForm((oldForm: any) => ({ ...oldForm, number: clearedValue }));
                    }}
                    label={labels.routeNumber}
                    errorText={errors?.number ?? undefined}
                    hasError={!!errors?.number}
                    placeholder={labels.enterValue}
                  />
                </FormField>

                <FormField>
                  <Chips label={labels.vehicleType}>
                    {chips.map((chip: any) => (
                      <ChipsItem
                        key={chip.id}
                        isDisabled={!isCopy && !isNew}
                        {...chip}
                        isActive={form?.transportType === chip.id}
                        onClick={({ id }) => {
                          setValue(names.transportType, id);
                          setForm((oldForm: any) => ({ ...oldForm, transportType: id }));
                        }}
                      />
                    ))}
                  </Chips>
                </FormField>
                <FormField>
                  <Chips label={labels.routeType}>
                    {routeTypeChipLabel.map((chip: any) => (
                      <ChipsItem
                        key={chip.id}
                        isDisabled={!isCopy && !isNew}
                        {...chip}
                        isActive={form?.kind ? form?.kind.toString() === chip.id : false}
                        onClick={({ id }) => {
                          setValue(names.kind, id);
                          setForm((oldForm: any) => ({ ...oldForm, kind: id }));
                        }}
                      />
                    ))}
                  </Chips>
                </FormField>
                <FormField>
                  <Chips label={labels.vehicleClassType}>
                    <ChipsItem isActive={false} onClick={() => {
                      capacityType.map((chip) => setTransportClassTypeArray(oldArray => {
                        const arr = [...oldArray, chip.id];
                        setValue("capacityType", arr.toString());
                        setForm((oldForm: any) => ({ ...oldForm, capacityType: arr.toString() }));
                        return arr;
                      }));
                    }} id={"all"} label={"Все"} />
                    {capacityType.map((chip) => (
                      <ChipsItem
                        key={chip.id}
                        isActive={form?.capacityType?.split(",").findIndex((el: any) => el === chip.id) !== -1}
                        {...chip}
                        onClick={({ id }) => {
                          const idx = transportClassTypeArray.indexOf(id);
                          if (idx === -1) {
                            setTransportClassTypeArray(oldArray => {
                              const arr = [...oldArray, id];
                              setValue("capacityType", arr.toString());
                              setForm((oldForm: any) => ({ ...oldForm, capacityType: arr.toString() }));
                              return arr;
                            });

                          } else {
                            setTransportClassTypeArray(oldArray => {
                              const filtered = oldArray.filter((element) => element !== id);
                              setValue("capacityType", filtered.toString());
                              setForm((oldForm: any) => ({ ...oldForm, capacityType: filtered.toString() }));
                              return filtered;
                            });
                          }
                        }}
                      />
                    ))}
                  </Chips>
                </FormField>
                <FormField>
                  <ContractorSelectField
                    value={{ label: form?.contractor?.name, value: String(form?.contractor?.id) }}
                    onChange={({ value, label }) => {
                      setValue(names.contractor, { id: parseInt(value, 10), name: label });
                      setForm((oldForm: any) => ({ ...oldForm, contractor: { id: parseInt(value, 10), name: label } }));
                    }}
                    label={labels.contractor}
                    errorText={errors.contractor ?? undefined}
                    hasError={!!errors.contractor}
                  />
                </FormField>
                <FormField>
                  <Markup.Label>{labels.startDateRoute}</Markup.Label>
                  <DatePicker
                    onChange={(e) => {
                      if (e) {
                        const year = e?.getFullYear();
                        const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                        const day = e?.getDate().toString().padStart(2, "0");
                        setForm((oldForm: any) => ({ ...oldForm, startDate: `${year}-${month}-${day}` }));
                        setStartDate(`${year}-${month}-${day}`);
                        setValue("startDate", `${year}-${month}-${day}`);
                      } else {
                        setForm((oldForm: any) => ({ ...oldForm, startDate: null }));
                        setStartDate(null);
                        setValue("startDate", null);
                      }
                    }}
                    errorText={errors?.startDate || ""}
                    hasError={!!errors?.startDate}
                    minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                    maxDate={oldEndDate ? addDays(oldEndDate, 1) : undefined}
                    value={form?.startDate ? new Date(form?.startDate) : null}
                  />
                </FormField>
                <FormField>
                  <Markup.Label>{labels.endDateRoute}</Markup.Label>
                  <DatePicker
                    onChange={(e) => {
                      if (e) {
                        const year = e?.getFullYear();
                        const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                        const day = e?.getDate().toString().padStart(2, "0");
                        setForm((oldForm: any) => ({ ...oldForm, endDate: `${year}-${month}-${day}` }));
                        setEndDate(`${year}-${month}-${day}`);
                        setValue("endDate", `${year}-${month}-${day}`);
                      } else {
                        setForm((oldForm: any) => ({ ...oldForm, endDate: null }));
                        setEndDate(null);
                        setValue("endDate", null);
                      }
                    }}
                    errorText={errors?.endDate || ""}
                    hasError={!!errors?.endDate}
                    minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                    value={form?.endDate ? new Date(form?.endDate) : null}
                  />
                </FormField>
                <FormField>
                  <Markup.LabelButton><Markup.Label>{labels.trips}</Markup.Label><IconButton content="icon" icon={<Add24BlueIcon />} onClick={() => { setShowNew((elshow) => { toggleLayersVisibility(!elshow); return !elshow; }); setSelected(() => ({ ...emptyTrip })); }} /></Markup.LabelButton>
                  <Markup.DirectionsBlock>
                    {
                      showA.length > 0 && <Chips label="Прямое направление">
                        {showA.map((elm, idx) => (
                          <Markup.ContainerBubble key={idx}>
                            <ChipsItem
                              key={idx}
                              onClick={() => { setSelected(elm); showOnMap(elm); }}
                              isActive={selected.workId === elm.workId && selected.direction === elm.direction}
                              label={elm.type}
                              id={elm.tripId ? elm.tripId.toString() : "0"} />
                            <Markup.EditBubble show={selected.workId === elm.workId && selected.direction === elm.direction} onClick={() => setShowEdit(true)}><EditBlue16Icon /></Markup.EditBubble>
                            <Markup.DeleteBubble show={selected.workId === elm.workId && selected.direction === elm.direction} onClick={() => { handleDelete(elm.type, elm.direction); setSelected(emptyTrip); }}><XClose16Icon /></Markup.DeleteBubble>
                          </Markup.ContainerBubble>

                        ))}
                      </Chips>
                    }

                  </Markup.DirectionsBlock>
                  <Markup.DirectionsBlock>
                    {showB.length > 0 && <Chips label="Обратное направление">
                      {showB.map((elm, idx) => (
                        <Markup.ContainerBubble key={idx}>
                          <ChipsItem
                            key={idx}
                            onClick={() => { setSelected(elm); showOnMap(elm); }}
                            isActive={selected.workId === elm.workId && selected.direction === elm.direction}
                            label={elm.type}
                            id={elm.tripId ? elm.tripId.toString() : "0"} />
                          <Markup.EditBubble show={selected.workId === elm.workId && selected.direction === elm.direction} onClick={() => setShowEdit(true)}><EditBlue16Icon /></Markup.EditBubble>
                          <Markup.DeleteBubble show={selected.workId === elm.workId && selected.direction === elm.direction} onClick={() => { handleDelete(elm.type, elm.direction); setSelected(emptyTrip); }}><XClose16Icon /></Markup.DeleteBubble>
                        </Markup.ContainerBubble>
                      ))}
                    </Chips>
                    }
                  </Markup.DirectionsBlock>
                </FormField>
                <FormField>
                  <DetailsPanelTabs value={filter} onChange={setFilter}>
                    <Tab value={"tripStops"}>Остановки</Tab>
                    <Tab value={"tripKeyZones"}>Зоны</Tab>
                  </DetailsPanelTabs>
                  {filter === "tripStops" && <Markup.TripsStepperWrapper>
                    <TripsStepper tripStops={selected.tripStops} />
                  </Markup.TripsStepperWrapper>}
                  {filter === "tripKeyZones" && <Markup.TripsStepperWrapper>
                    <KeyZoneStepper keyZones={selected.tripKeyZones as any} />
                  </Markup.TripsStepperWrapper>}
                </FormField>

                <DetailsBlock header={<Box>Файлы</Box>}>
                  {
                    isLoading ? (<Box justifyContent={"center"}><Loader /></Box>) : (<Box direction="column">
                      <UploadArea
                        filesInput={files}
                        multiple={true}
                        handleChangeFiles={(el) => {
                          handleFiles(el);
                        }}
                      />
                    </Box>)
                  }

                </DetailsBlock>

              </>);
            }

          }

        </PanelForm> : (showNew ? <NewRouteTripLine onCancel={() => {
          setShowNew(false);
          setSelected(newRouteTrips[0] || emptyTrip);
          toggleLayersVisibility(false);
        }}></NewRouteTripLine> : <EditRouteTrip onCancel={() => { setShowEdit(false); setSelected(newRouteTrips[0] || emptyTrip); }} editRoute={selected}></EditRouteTrip>)
      }
    </>
  );
};
