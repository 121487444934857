/* eslint-disable indent, @typescript-eslint/indent */
import styled from "styled-components";
import { withContentColor } from "@megapolis/uikit/utils";
import { switchProp } from "styled-tools";
import { TabBase } from "../tab-base";

type ContainerProps = {
  active: boolean;
  isDisabled: boolean;
};

export const Content = styled(TabBase)`
  padding: 8px 0;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: flex-start;
  max-height: 24px;
  width: 24px;
  margin: 4px 0;
  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export const Container = styled.button<ContainerProps>`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: auto;
  max-height: 32px;
  border: none;
  background-color: transparent;
  padding: 0;

  align-items: center;
  
  & > div {
    color: ${switchProp("isDisabled", {
      true: withContentColor("tertiary"),
      false: "inherit",
    })};
  }

  &::after {
    content: "";
    height: 1px;
    width: 100%;

    position: absolute;
    left: 0;
    bottom: 0;

    background-color: ${switchProp("active", {
      true: withContentColor("primary"),
      false: "transparent",
    })};
  }
`;
