import { useMap } from "components/common/main-map/map.context";
import { Dispatch } from "redux";
import { TYPES } from "../types";
import { CommonActionTypes, SetFlyToAction } from "./common-actions.types";

export const commonActions = {
  setApplicationOnloadedStatus: (status: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: TYPES.COMMON.SET_APPLICATION_ONLOADED_STATUS,
      payload: status,
    });
  },
  setFilterStatus: (status: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: CommonActionTypes.FILTER_STATUS,
      payload: status,
    });
  },
  flyToPoint: (params: { lat: number, lng: number }) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: CommonActionTypes.FLY_TO_POINT,
      payload: params,
    });
  },
  setFilters: (payload: { lat: number, lng: number }): SetFlyToAction => ({
    type: CommonActionTypes.FLY_TO_POINT,
    payload,
  }),
};

export default commonActions;
