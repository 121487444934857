import React, { useMemo, memo, useCallback } from "react";
import { Card } from "components/ui";
import { SeparatedInlineList } from "components/common";
import { VehicleTypes, VehicleTypeCaptionMap } from "models";
import { VehicleCardIcon } from "./components";

export type VehicleCardProps = {
  /** Идентификатор */
  id: string | number;
  /** Государственный регистрационный номер */
  registrationNumber: string;
  /** Перевозчик */
  contractor: string;
  /** Тип ТС */
  type: VehicleTypes;
  /** Признак актуальности ТС */
  isActual: boolean;
  /** Признак активности карточки т.с */
  isActive?: boolean;
  /** Обработчик нажатия на карточку */
  onClick?: (id: string | number) => void;
};

const VehicleCardComponent = (props: VehicleCardProps) => {
  const { id, type, contractor, registrationNumber, isActive = false, isActual, onClick } = props;

  const captionItems = useMemo(() => {
    const typeStr = VehicleTypeCaptionMap[type];
    return [typeStr, contractor];
  }, [type, contractor]);

  const handleClick = useCallback(() => onClick?.(id), [onClick, id]);

  return (
    <Card
      title={registrationNumber}
      caption={<SeparatedInlineList items={captionItems} />}
      icon={<VehicleCardIcon type={type} isActive={isActual} />}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};

export const VehicleCard = memo(VehicleCardComponent);
