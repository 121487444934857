import { IInitialStopState } from "./stop.types";
import { initialState } from "./stop.constants";
import { StopActionTypes, StopsAction } from "../../actions/stop-actions/stop-actions.types";

export const stopReducer = (state: IInitialStopState = initialState, action: StopsAction) => {
  switch (action.type) {
    case StopActionTypes.SET_STOP_BY_VERSION_ID: {
      const { payload } = action;

      return {
        ...state,
        stopByVersionId: {
          ...state.stopByVersionId,
          [payload.versionId]: payload.data,
        },
      };
    }

    case StopActionTypes.SET_STOP_ACTIVE_FILTER: {
      const { payload } = action;

      return {
        ...state,
        stopActiveFilter: payload,
      };
    }

    case StopActionTypes.SET_STOP_FILTERS: {
      const { payload } = action;

      return {
        ...state,
        stopFilters: payload,
      };
    }

    case StopActionTypes.SET_STOP_SEARCH_STRING: {
      const { payload } = action;

      return {
        ...state,
        searchSubstring: payload,
      };
    }

    case StopActionTypes.SET_STOPS: {
      const { status, pageNum, data } = action.payload;
      const { items, totalCount } = data;

      const itemsDraft = [...(state[status]?.items ?? [])];
      itemsDraft[pageNum] = items;

      return {
        ...state,
        [status]: { totalCount, items: itemsDraft },
      };
    }

    case StopActionTypes.SET_STOP_ACTIVE_ITEM: {
      const { payload } = action;

      return { ...state, stopActiveItem: payload };
    }

    case StopActionTypes.SET_NEW_STOP_STATION_COORDS: {
      const { payload } = action;
      return {
        ...state,
        newStopStationCoords: payload,
      };
    }

    case StopActionTypes.SET_NEW_STOP_STATION_DATA: {
      const { payload } = action;
      return {
        ...state,
        newStopStationData: payload,
      };
    }

    // case StopActionTypes.SET_CURRENT_PAGE: {
    //   const { payload } = action;
    //   return {
    //     ...state,
    //     stopCurrentPage: payload,
    //   };
    // }

    case StopActionTypes.UPDATE_STOPS_FILES: {
      const { payload } = action;
      const { stopId, data } = payload;
      const details = Object.values(state.stopByVersionId);
      const stopsById = details.filter(stop => stop.id.toString() === stopId);
      const updated = stopsById.reduce(
        (accumulator, stop) => ({ ...accumulator, [stop.versionId.toString()]: { ...stop, files: data.files } }),
        {},
      );
     
      return {
        ...state,
        stopByVersionId: {
          ...state.stopByVersionId,
          ...updated,
        },
      };
    }

    case StopActionTypes.SET_CURRENT_PAGE: {
      const { payload } = action;

      return {
        ...state,
        stopCurrentPage: payload,
      };
    }



    default:
      return state;
  }
};
