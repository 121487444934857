import styled, { css } from "styled-components/macro";
import { withAccentColor } from "shared/utils";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const Label = styled.span`
  display: flex;
  flex-flow: row nowrap;
  line-height: 16px;
  padding: 0 8px 4px 8px;
`;

const requiredCss = css`
  &::after {
    content: "*";
    color: ${withAccentColor("red", "basic")};
  }
`;

export const LabelContainer = styled.span<{ isRequired: boolean }>`
  display: flex;
  ${({ isRequired }) => isRequired && requiredCss};
`;
