import React, { memo } from "react";
import styled from "styled-components/macro";

export type FormFieldProps = {
  children: React.ReactNode;
};

const Container = styled.div`
  margin-top: 8px;
`;

const FormFieldComponent = ({ children }: FormFieldProps) => <Container>{children}</Container>;

export const FormField = memo(FormFieldComponent);
