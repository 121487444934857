export const getLastItem = <T>(array: T[]): T | undefined => array[array.length - 1];

export const updateItemInArrayByIndex = <T>(array: T[], index: number, item: T): T[] => {
  if (index < 0) return array;
  const draft = [...array];
  draft.splice(index, 1, item);
  return draft;
};

export const findIndexInArrayOfItemArray = <T>(arr: T[][], comparator: (item: T) => boolean ): [outerIndex: number, itemIndex: number] => {
  let itemIndex = -1;
  const outerIndex = arr.findIndex(i => {
    itemIndex = i?.findIndex(comparator);
    return itemIndex >= 0;
  });

  return [outerIndex, itemIndex];
};

export const removeItemByIndex = <T>(arr: T[], index: number): T[] => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1),
];

