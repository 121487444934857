import { DatePicker } from "@megapolis/uikit";
import { Box, Chips, ChipsItem, Divider, SelectField, Textarea, UploadArea } from "components";
import { Field } from "components/common/form/field";
import { ruMobilePhoneNumber } from "components/ui/text-masks/text-masks";
import { DetailsBlock, FormField } from "features/detail-panels/components";
import { chips } from "features/detail-panels/route-details-panel/mocks";
import { PanelForm } from "features/panel-form";
import { Loader } from "megapolis-uikit-latest/loader";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useRoute, useRouter } from "react-router5";
import { labels } from "shared/constants";
import { PAGE_ROUTES } from "shared/definitions";
import { removeMarker } from "shared/helpers/add-marker";
import { isRequired } from "shared/utils/validator";
import { RootState } from "store/types";
import { EditContractorForm, useEditContractorLogic } from "./new-contractor-panel.logic";
import * as Markup from "./new-contractor-panel.styles";

export const NewContractorPanel = (props: { isNew?: boolean, changeVersion?: boolean }) => {
  const { isNew = true, changeVersion = false } = props;
  const referSetValue = useRef<(name: keyof EditContractorForm, value: any) => void>();
  const { form, createContractor, editContractor, clearUp, editContractorVersion, isLoading, contractorType, startDate: oldStartDate, endDate: oldEndDate, handleFiles, files } = useEditContractorLogic(isNew, referSetValue.current);

  const title = isNew ? "Создание перевозчика" : "Редактирование перевозчика";

  const { activeId } = useSelector((state: RootState) => ({
    activeId: state.contractor.selectedContractor,
  }));

  const router = useRouter();
  const { previousRoute } = useRoute();

  const handleClosePanel = useCallback(() => {
    if (previousRoute) {
      // router.navigate(previousRoute.name, previousRoute.params);
      router.navigate(PAGE_ROUTES.CONTRACTOR, { id: activeId });
    } else {
      router.navigate(PAGE_ROUTES.CONTRACTORS);
    }
  }, [activeId, previousRoute, router]);

  const prepareOptions = useCallback((old: {
    id: string;
    label: string;
  }[]) => old.map((elm) => ({
    value: elm.id, label: elm.label,

  })), []);

  // eslint-disable-next-line consistent-return
  const valueOption = useCallback((option: any, values: any[]) => {

    if (values.length > 0 && option) {
      const optn = values.find((elm: any) => elm.id === option.toString());
      if (optn) {
        return {
          value: optn?.id,
          label: optn?.label,
        };
      }
      return {
        value: "",
        label: "",
      };
    }
  }, []);

  useEffect(() => {
    if (isNew) clearUp();
    return () => {
      clearUp();
      removeMarker();
    };
  }, [clearUp, removeMarker, isNew]);

  const handleSubmit = useCallback((values: any) => {
    if (isNew) {
      createContractor(values);
    } else if (changeVersion) {
      editContractorVersion(values);
    } else {
      editContractor(values);
    }
  }, [createContractor, editContractor, isNew]);

  const addDays = useCallback((date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }, []);

  const scheme = useMemo(() => ({
    fullName: isRequired("Введите имя"),
    phone: isRequired("Введите телефон"),
    address: isRequired("Введите адрес"),
    contractorTypeId: isRequired(""),
    startDate: isRequired("Добавьте стартовую дату"),
    // endDate: isCorrectEndDate("Дата окончания действия трассы не может быть меньше даты начала", strtDate || ""),
  }), []);

  return (
    <PanelForm
      title={title}
      initialValues={form}
      validationScheme={scheme}
      onSubmit={handleSubmit}
      onCancel={handleClosePanel}
      isLoading={isLoading}
    >
      {
        ({ names, values, updateValue: setValue, errors }) => {
          referSetValue.current = setValue;
          return (<>
            <Field
              values={values}
              updateValue={setValue}
              name={names.fullName}
              errors={errors}
              type="text"
              label={"Полное название"}
              cyData="contractor-fullName-add" />
            <Field
              values={values}
              updateValue={setValue}
              name={names.shortName}
              errors={errors}
              type="text"
              label={"Краткое название"}
              cyData="contractor-shortName-add" />

            {/* <Field type="select" options={prepareOptions(contractorType)} values={values} updateValue={setValue} name={names.contractorTypeId as string | number} label={<Markup.Label>{labels.contractorType}</Markup.Label>}></Field> */}
            <FormField>
              <SelectField
                value={valueOption(values.contractorTypeId, contractorType)}
                label="Тип перевозчика"
                options={prepareOptions(contractorType)}
                onChange={(e) => setValue("contractorTypeId", +e.value)} />
            </FormField>
            {/* <FormField>
              <Chips cyData="contractor-vehicleType-add" label={labels.vehicleType}>
                {chips.map((chip) => (
                  <ChipsItem
                    key={chip.id}
                    {...chip}
                    isActive={false}
                    onClick={() => {
                    }}
                  />
                ))}
              </Chips>
            </FormField> */}
            <Field
              name={names.address}
              label={"Адрес"}
              placeholder="Введите"
              errors={errors}
              cyData="contractor-address-add"
              updateValue={setValue}
              values={values}
              type="text"
            />
            <Field
              name={names.phone}
              label={"Контактные данные"}
              placeholder="+ 7 (___) - ___ - __ - __"
              errors={errors}
              cyData="contractor-phone-add"
              updateValue={setValue}
              values={values}
              mask={ruMobilePhoneNumber}
              type="masked-text"
            />
            {/* <Field
              name={names.contractorFile}
              label={labels.contractorFile}
              placeholder="Введите"
              errors={errors}
              cyData="contractor-address-add"
              updateValue={setValue}
              values={values}
              type="text"
            /> */}
            <FormField>
              <Markup.Label>{labels.contractorFile}</Markup.Label>
              <Textarea onChange={(el) => setValue("documents", el)} value={values?.documents ? values.documents : ""} />
            </FormField>
            <FormField>
              <Markup.Label>{"Начало работы"}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setValue("startDate", `${year}-${month}-${day}`);
                  } else {
                    setValue("startDate", null);
                  }
                }}
                minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                maxDate={oldEndDate ? addDays(oldEndDate, 1) : undefined}
                errorText={errors?.startDate ?? undefined}
                hasError={!!errors?.startDate}
                value={values?.startDate ? new Date(values.startDate) : null}
              />
            </FormField>
            <FormField>
              <Markup.Label>{"Конец работы"}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setValue("endDate", `${year}-${month}-${day}`);
                  } else {
                    setValue("endDate", null);
                  }
                }}
                minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                errorText={errors?.endDate ?? undefined}
                hasError={!!errors?.endDate}
                value={values?.endDate ? new Date(values.endDate) : null}
              />
            </FormField>
            <Divider margin="16px 0" />

            <DetailsBlock header={<Box>Файлы</Box>}>
              {
                isLoading ? (<Box justifyContent={"center"}><Loader /></Box>) : (<Box direction="column">
                  <UploadArea
                    filesInput={files}
                    multiple={true}
                    handleChangeFiles={(el) => {
                      handleFiles(el);
                    }}
                  />
                </Box>)
              }

            </DetailsBlock>
          </>);
        }

      }
    </PanelForm>
  );
};
