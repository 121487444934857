export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 31457280;
export const DEFAULT_ACCEPT_EXTENSIONS = ".bmp,.tif,.pdf,.doc,.docx,.txt,.csv,.xls,.xlsx,.mp4,.png,.jpg,.jpeg";
export const IMAGES_ACCEPT_EXTENSIONS = "image/*";

export type CustomFileType = {
  readonly lastModified: number;
  readonly name: string;
  size: number;
  type: string;
  lastModifiedDate?: string;
  [key: string]: any;
};

export type FilesObjectType = {
  [index: string]: CustomFileType;
};

export type FilesArrayType = CustomFileType[] | [];

export const convertNestedObjectToArray = (nestedObj: FilesObjectType): FilesArrayType =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

export type PreviewListType = {
  images: { [index: string]: CustomFileType };
  otherFiles: { [index: string]: CustomFileType };
};

export type UploadAreaProps = {
  handleChangeFiles?: (files: any) => void;
  maxFileSizeInBytes?: number;
  label?: string;
  multiple?: boolean;
  acceptOnlyImages?: boolean;
  filesInput?: File[];
};

export const convertBytesToKilobytes = (bytes: number) => Math.round(bytes / 1000);
