import { labels } from "shared/constants";
import { useGetContractorItemsByVersion } from "../../contractor-details.data";

export const useGetContractorInfoTabData = () => {
  const { contractorDetails } = useGetContractorItemsByVersion();

  const infoCards = [
    {
      title: "Адрес",
      text: contractorDetails?.address || labels.noValue,
    },
    {
      title: "Версия от",
      text: String(contractorDetails?.versionId) || labels.noValue,
    },
    {
      title: "Телефон",
      text: contractorDetails?.phone || labels.noValue,
    },
    {
      title: "Документ на перевозку",
      text: contractorDetails?.documents ? contractorDetails.documents.reduce((accumulator, currentValue) => `${accumulator}  <li style="list-style-type: '- '">${currentValue}</li>`, "") : labels.noValue,
    },
  ];

  return { infoCards };
};
