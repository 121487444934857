/* eslint-disable no-console */
/* eslint-disable consistent-return */
// import { OptionTypeBase } from "react-select";
import OptionTypeBase from "react-select";
import {
  APP_ENV,
  MAP_ACTUAL_ROUTES,
  MAP_ACTUAL_STAT,
  MAP_ACTUAL_STOPS,
  MAP_LAYER,
  MAP_LAYER_DTM_VALHALLA_ROUTE,
} from "app-env/index";
import { FeatureCollection } from "geojson";
import { LngLatLike } from "mapbox-gl";
import { newSignal } from "../utils/abort-controller";

type ActualRouteStopsType = {
  stopId: number;
  name: string;
  position: {
    coordinates: number[];
    type: string;
  };
};

type CarriersType = {
  title: string;
  contractorIds: number[];
  depotIds: number[] | number;
  transportTypes: null | number[];
  isActive?: boolean;
  id: string;
  items: [] | CarriersType[];
};

export function getToken() {
  if (window.localStorage) {
    const storage = window.localStorage.getItem("Authorization");
    if (!storage) {
      return "";
    }
    const token = JSON.parse(storage).access_token;
    return token;
  }
}

export const getHeaderPost = (prop?: any) => {
  const token = getToken();
  return {
    "Content-Type": "application/json;charset=UTF-8",
    "Authorization": `Bearer ${  token}` || "",
    ...prop,
  };
};

const fetchDTMGet = async (path: string) => fetch(new Request(path)).then((res) => res.json());

const fetchDTMPost = async (path: string, body: any) => {
  const headers = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify(body),
  };
  const signal = newSignal("fetchDTMPost");
  if (signal?.aborted) return;
  return fetch(new Request(path, headers), { signal })
    .then((res) => res && res.json())
    .catch(() => {});
};

export const loadData = async (layer: string) => {
  let response;
  const token = getToken();

  try {
    response = await (
      await fetch(`${MAP_LAYER}${layer}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${  token}` || "",
        },
      })
    ).json();
  } catch {
    console.error();
  }
  return response;
};

export const loadDataDTM = async (type: "get" | "post", path: string, body?: OptionTypeBase) => {
  let response;
  if (type === "get") {
    response = await fetchDTMGet(path);
  }
  if (type === "post" && !!body) {
    response = await fetchDTMPost(path, body);
  }
  return response;
};

export const getActualStopHover = async (path: string) => {
  const token = getToken();
  const headers = {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Authorization": `Bearer ${  token}` || "",
    },
  };
  const signal = newSignal("getActualStopHover");
  if (signal?.aborted) return;
  return fetch(new Request(path, headers), { signal })
    .then((res) => res && res.json())
    .catch(() => {});
};


const fetchDTMValhallaPost = async (path: string, body: any) => {
  const headers = {
    method: "POST",
    // headers: {
    //     "Content-Type": "application/json;charset=UTF-8",
    // },
    body: JSON.stringify(body),
  };
  return fetch(new Request(path, headers))
    .then((res) => res && res.json())
    .catch(() => "Error");
};

export const getActualRoute = async (routeId: number | string, date: string) => {
  try {
    const token = getToken();
    return (await fetch(
      `${MAP_ACTUAL_ROUTES}${routeId}/trips?onlyMainTrips=true&includeStops=true${date && `&date=${date}`}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${  token}` || "",
        },
      },
    ).then((res) => res.json())) as FeatureCollection;
  } catch {
    console.error();
  }
};

export const getMetro = async () => {
  try {
    const token = getToken();

    return (await fetch("https://dev-api.megapolis-it.ru/aisms/layers/Metro", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${  token}` || "",
      },
    }).then((res) => res.json())) as any[];
  } catch {
    console.error();
  }
};

export const getPlannedMetro = async () => {
  try {
    const token = getToken();

    return (await fetch("https://dev-api.megapolis-it.ru/aisms/layers/Metro/plan", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${  token}` || "",
      },
    }).then((res) => res.json())) as any[];
  } catch {
    console.error();
  }
};

export const filterSelectedCarrier = (contractorFilters: CarriersType[]) => {
  let arrContractorFilters = [] as any[];
  contractorFilters?.forEach((el) => {
    const list = [] as any[];

    el.items.forEach((item) => {
      if (item.isActive) {
        list.push({
          contractorIds: item.contractorIds,
          depotIds: item.depotIds,
          title: item.title,
          transportTypes: item.transportTypes,
        });
      }
    });
    if (el.items.length === list.length) {
      if (el.items.length === 0) {
        if (el.isActive) {
          arrContractorFilters.push({
            contractorIds: el.contractorIds,
            depotIds: el.depotIds,
            parentTitle: el.title,
            transportTypes: el.transportTypes,
          });
        }
      } else {
        arrContractorFilters.push({
          contractorIds: el.contractorIds,
          depotIds: el.depotIds,
          parentTitle: el.title,
          transportTypes: el.transportTypes,
        });
      }
    } else {
      arrContractorFilters = [...arrContractorFilters, ...list];
    }
  });
  return arrContractorFilters;
};

export const getActualRouteStatistics = async (
  date: string | undefined,
  routeType: string[],
  transportType: string[],
  registryTypeIds: number[],
  rateId: string | number,
  contractorFilters?: CarriersType[],
) => {
  const today = new Date().toISOString().split("T")[0];
  const contractorFiltersSelected = contractorFilters && filterSelectedCarrier(contractorFilters);
  let allSelected = true;
  contractorFilters?.forEach((contractorFilter) => {
    if (!contractorFilter.isActive) {
      allSelected = false;
            
    }
  });
  try {
    const token = getToken();
    const data = {
      date: date === "" ? today : date,
      rateId: rateId === "" ? undefined : rateId,
      transportTypes: transportType,
      routeTypes: routeType[0] === "" ? undefined : routeType,
      registryTypeIds,
      contractorFilters: allSelected ? [] : contractorFiltersSelected,
    };
    return await fetch(`${MAP_ACTUAL_STAT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${  token}` || "",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json());
  } catch (e) {
    console.error();
  }
};

export const getActualRouteStops = async (routeId: number) => {
  try {
    const token = getToken();

    return (await fetch(`${MAP_ACTUAL_ROUTES}${routeId}/trips?onlyMainTrips=true&includeStops=true`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${  token}` || "",
      },
    })
      .then((res) => res.json())
      .then((data) => ({
        type: "FeatureCollection",
        features: data?.stops?.map(({ stopId, name, position }: ActualRouteStopsType) => ({
          type: "Feature",
          geometry: position,
          properties: { stopId, name },
        })),
      }))) as FeatureCollection;
  } catch {
    console.error();
  }
};

export const changeFormatLngLat = (coordinates: [number, number][]) => {
  const changFormatCoordinates = [] as LngLatLike[];
  coordinates.forEach((coordinate) => {
    changFormatCoordinates.push({ lon: coordinate[0], lat: coordinate[1] });
  });
  return changFormatCoordinates;
};

export const changeFormatLngLatTime = (coordinates: [number, number][]) => {
  const changFormatCoordinates = [] as any;
  coordinates.forEach((coordinate, index) => {
    changFormatCoordinates.push({
      time: `2022-01-24T10:10:0${index}+03:00`,
      lng: coordinate[0],
      lat: coordinate[1],
    });
  });
  return changFormatCoordinates;
};

export const getEditRoute = async (routeId: number, coordinates: [number, number][]) => {
  try {
    const changFormatCoordinates = changeFormatLngLatTime(coordinates);

    return await fetchDTMPost(`${APP_ENV.REACT_APP_API_DTM}router-pre/api/router/mapmatch/attributes`, [
      ...changFormatCoordinates,
    ]);
  } catch {
    console.error("SSSSSS");
  }
};

export const getGraphLine = async (coordinates: [number, number][]) => {
  try {
    const changFormatCoordinates = changeFormatLngLat(coordinates);

    return await fetchDTMValhallaPost(MAP_LAYER_DTM_VALHALLA_ROUTE, {
      costing: "bus",
      shape_match: "map_snap",
      filters: {
        attributes: ["edge.begin_shape_index", "edge.end_shape_index", "shape"],
        action: "include",
      },
      shape: changFormatCoordinates,
    });
  } catch {
    console.error();
  }
};

export const getNGPTStops = async (stopsId: number) => {
  try {
    return (await fetch(`${MAP_ACTUAL_STOPS}${stopsId}?includePassFlow=true&includeRoutes=true`).then((res) =>
      res.json(),
    )) as any[];
  } catch {
    console.error();
  }
};
