import React, { FC } from "react";
import * as Markup from "./button-tab.styles";

export interface ButtonTabProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
  active?: boolean;
  className?: string;
  isDisabled?: boolean;
}

export const ButtonTab: FC<ButtonTabProps> = ({ isDisabled, children, icon, active = false, onClick, className }) => (
  <Markup.Button className={className} active={active} onClick={onClick} disabled={isDisabled}>
    {icon && <Markup.Icon>{icon}</Markup.Icon>}
    {children && <Markup.Content>{children}</Markup.Content>}
  </Markup.Button>
);
