import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Divider } from "components/common";
import { getDepotFile } from "api";
import { NoDataLabel, toastError, ViewFileArea } from "components";
import { DetailsBlock } from "features/detail-panels/components";
import { DepotVersion } from "models";
import { Loader } from "megapolis-uikit-latest/loader";
import { APP_ENV } from "app-env";
import axios from "axios";
import { useGetDepotItemsByVersion } from "../../depot-details-panel.data";
import * as Markup from "./depot-details-info-tab.styles";
import { GeneralInfo } from "./sections";

export const DepotDetailsInfoTab = () => {

  const { depotDetails } = useGetDepotItemsByVersion();

  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchFiles = useCallback(async (controller: AbortController, depot: DepotVersion) => {
    if (!depot?.files) return;
    const fileArr: File[] = [];

    setIsLoading(true);
    const urls = depot.files.map(({ id: id1 }) => `${APP_ENV.REACT_APP_BASE_URL}/Depot/${depot.depotId}/${id1}`);
    const accessToken = localStorage.getItem("Authorization") ?? "";
    const requests = urls.map((url) => axios.get(url, {
      signal: controller.signal,
      responseType: "blob",
      headers: { Authorization: `Bearer ${accessToken}` },
    }));
    axios.all(requests).then((responses) => {
      responses.forEach(async (resp, idx) => {
        if (!depot.files) return;
        const blob = await resp.data;
        const file = new File([blob], depot.files[idx].name, {
          type: depot.files[idx].mimeType,
        });
        fileArr.push(file);
      });
    }).then(() => {
      setFiles(fileArr);
      setIsLoading(false);
    }).finally(()=>{
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setFiles([]);
    const controller = new AbortController();
    fetchFiles(controller, depotDetails);
    return () => {
      setFiles([]);
      controller.abort();
    };
  }, [depotDetails, fetchFiles]);

  return (
    <Markup.Container>
      <GeneralInfo />
      <Divider margin="8px 0" />
      <DetailsBlock header={<Box>Файлы</Box>}>
        {
          isLoading && (<Box justifyContent={"center"}><Loader /></Box>)
        }
        {files.length > 0 ?
          (<Box direction="column" margin="-24px 0 0 0">
            <ViewFileArea
              filesInput={files}
              multiple={true} />
          </Box>) : (!isLoading && <NoDataLabel />)
        }
      </DetailsBlock>
    </Markup.Container>
  );
};
