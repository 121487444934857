import { DatePicker } from "@megapolis/uikit";
import { AlternativeMultiSelect, Box, Divider, UploadArea } from "components";
import { Field } from "components/common/form/field";
import { ContractorSelectField } from "components/common/select-with-source";
import { DetailsBlock, FormField } from "features/detail-panels/components";
import { PanelForm } from "features/panel-form";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRoute, useRouter } from "react-router5";
import { labels } from "shared/constants";
import { isCorrectEndDate, isCorrectStartDate, isRequired } from "shared/utils/validator";
import { DepotSelectFieldCustom } from "components/common/select-with-source/depot-select-field/depot-select-field.custom";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { PAGE_ROUTES } from "shared/definitions";
import { Loader } from "megapolis-uikit-latest/loader";
import { Option } from "../../../../components/common/select/select.types";
import { EditDriverForm, SetValue, useEditDriverLogic } from "./new-driver-panel.logic";
import * as Markup from "./new-driver-panel.styles";
import * as  mock from "./mocks";

export const NewDriverPanel = (props: { isNew?: boolean, changeVersion?: boolean; }) => {
  const { isNew = true, changeVersion = false } = props;
  const referSetValue = useRef<SetValue>();
  const { form, createDriver, editDriver, editVersionDriver, isLoading, startDate: oldStartDate, endDate: oldEndDate, handleFiles, files, clearUp } = useEditDriverLogic(isNew, referSetValue.current);
  const [strtDate, setStrtDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");


  const { activeId } = useSelector((state: RootState) => ({
    activeId: state.driver.activeDriverId,
  }));

  const title = isNew ? labels.createDriver : labels.editDriver;
  const router = useRouter();
  const { previousRoute } = useRoute();

  const handleClosePanel = useCallback(() => {
    if (previousRoute) {
      // router.navigate(previousRoute.name, previousRoute.params);
      router.navigate(PAGE_ROUTES.DRIVER, { id: activeId });
    } else {
      router.navigate(PAGE_ROUTES.DRIVERS);
    }
  }, [activeId, previousRoute, router]);

  const handleSubmit = useCallback((values: EditDriverForm) => {
    if (isNew) {
      createDriver(values);
    } else if (changeVersion) {
      editVersionDriver(values);
    } else {
      editDriver(values);
    }
  }, [isNew, createDriver, editDriver]);

  const getValueForSelect = (value?: { id: string | number; name: string; } | null): Option | null => {
    if (value) {
      return {
        value: String(value.id),
        label: value.name || "",
      };
    }


    return null;
  };

  const getId = (value?: { id: string | number; name: string; } | null): string => {
    if (value) {
      return value?.id?.toString();
    }

    return "";
  };

  const getValueForLisenceSelect = (value: string | null | undefined): Option[] | null => {
    const newOptions: Option[] = [];

    if (value) {
      const newArray: string[] = value.split(",");
      newArray.map((el) => {
        const opt = mock.selectOptionsLisence.find((option) => option.value === el);
        return opt && newOptions.push(opt);
      });
    }

    return newOptions;
  };

  const addDays = useCallback((date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }, []);

  useEffect(() => {
    if (isNew) clearUp();
    return () => {
      clearUp();
    };
  }, [clearUp, isNew]);


  const scheme = useMemo(() => ({
    lastName: isRequired("Введите фамилию"),
    firstName: isRequired("Введите имя"),
    birthDate: isRequired("Введите дату рождения"),
    // driverLisence: isRequired("Введите категорию прав"),
    contractor: isRequired("Укажите перевозчика"),
    startDate: isCorrectStartDate("Дата начала действия версии не может быть больше даты окончания", endDate || ""),
    endDate: isCorrectEndDate("Дата окончания действия версии не может быть меньше даты начала", strtDate || ""),
  }), [endDate, strtDate]);

  return (
    <PanelForm
      title={title}
      initialValues={form}
      validationScheme={scheme}
      onSubmit={handleSubmit}
      onCancel={handleClosePanel}
      isLoading={isLoading}
    >
      {
        ({ names, values, updateValue: setValue, errors }) => {
          referSetValue.current = setValue;
          return (<div id={"driver box"}>
            {/* <Box direction="column" overflow="auto" padding="0 16px 8px 16px">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  borderRadius: 50,
                  margin: "auto",
                  width: 100,
                  height: 100,
                  backgroundSize: "cover",
                  backgroundImage: `url(${img})`,
                }}
              />
            </div>
          </Box>
          <FormField>
            <Markup.Container>
              <Markup.UploadFileBtn type="button" onClick={handleUploadButtonClick}>
                <span>Выбрать фото</span>
              </Markup.UploadFileBtn>
              <Markup.FormField
                type="file"
                ref={fileInputField}
                onChange={onImageChange}
                accept={IMAGES_ACCEPT_EXTENSIONS}
                title=""
                value=""
              />
            </Markup.Container>
          </FormField> */}
            <Field values={values} updateValue={setValue} name={names.lastName} errors={errors} type="text" label={labels.driverSurname} cyData="edit-driver-surname" />
            <Field values={values} updateValue={setValue} name={names.firstName} errors={errors} type="text" label={labels.driverName} cyData="edit-driver-name" />
            <Field values={values} updateValue={setValue} name={names.middleName} errors={errors} type="text" label={labels.driverMiddlename} cyData="edit-driver-middlename" />
            <FormField>
              <Markup.Label>{labels.birthDate}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setValue("birthDate", `${year}-${month}-${day}`);
                  } else {
                    setValue("birthDate", null);
                  }
                }}
                errorText={errors?.birthDate ?? undefined}
                cyData="edit-driver-birthDate"
                hasError={!!errors?.birthDate}
                value={values?.birthDate ? new Date(values.birthDate) : null}
              />
            </FormField>
            <FormField>
              <Markup.Label>{labels.driverLisence}</Markup.Label>
              <AlternativeMultiSelect options={mock.selectOptionsLisence} value={getValueForLisenceSelect(values.licenseCategories)} onChange={(option) => setValue("licenseCategories", option.map((elm) => elm.value).join(","))} />
            </FormField>
            <Field values={values} updateValue={setValue} name={names.personnelNumber} errors={errors} type="text" label={labels.personNumber} cyData="edit-driver-personnelNumber" />
            <FormField>
              <ContractorSelectField
                value={getValueForSelect(values.contractor)}
                onChange={(e) => {
                  setValue("contractor", { id: e.value, name: e.label });
                  setValue("depot", { id: "", name: "" });
                }}
                date={ values?.startDate ? new Date(values.startDate).toLocaleDateString("sv-SE", { year: "numeric", month: "2-digit", day: "2-digit" }) : undefined}
                label={labels.contractor}
                cyData="edit-driver-contractor"
                errorText={errors.contractor ?? undefined}
                hasError={!!errors.contractor}
              />
            </FormField>
            <FormField>
              <Markup.Label>{labels.depot}</Markup.Label>
              <DepotSelectFieldCustom isDisabled={getId(values.contractor) === ""} ContractorIds={getId(values.contractor)} value={getValueForSelect(values.depot)} onChange={(opt) => { setValue("depot", { id: opt.value, name: opt.label }); }} />
            </FormField>
            <FormField>
              <Markup.Label>{"Дата начала действия версии"}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setStrtDate(`${year}-${month}-${day}`);
                    setValue("contractor", null);
                    setValue("startDate", `${year}-${month}-${day}`);
                  } else {
                    setStrtDate(null);
                    setValue("contractor", null);
                    setValue("startDate", null);
                  }
                }}
                minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                maxDate={oldEndDate ? addDays(oldEndDate, 1) : undefined}
                errorText={errors?.startDate ?? undefined}
                hasError={!!errors?.startDate}
                value={values?.startDate ? new Date(values.startDate) : null}
              />
            </FormField>
            <FormField>
              <Markup.Label>{"Дата окончания действия версии"}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setEndDate(`${year}-${month}-${day}`);
                    setValue("endDate", `${year}-${month}-${day}`);
                  } else {
                    setEndDate(null);
                    setValue("endDate", null);
                  }
                }}
                minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                errorText={errors?.endDate ?? undefined}
                hasError={!!errors?.endDate}
                value={values?.endDate ? new Date(values.endDate) : null}
              />
            </FormField>
            <Divider margin="16px 0" />

            <DetailsBlock header={<Box>Файлы</Box>}>
              {
                isLoading ? (<Box justifyContent={"center"}><Loader /></Box>) : (<Box direction="column">
                  <UploadArea
                    filesInput={files}
                    multiple={true}
                    handleChangeFiles={(el) => {
                      handleFiles(el);
                    }}
                  />
                </Box>)
              }

            </DetailsBlock>
          </div>);
        }
      }
    </PanelForm>
  );
};
