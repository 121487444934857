import React, { memo } from "react";
import { Tab } from "components/ui";
import { DetailsPanelTabs } from "../../../components";
import { TAB_VALUES } from "../schedule-details.constants";

export type TabsProps = {
  value: string;
  onChange: (val: string) => void;
};

const ScheduleDetailsTabsComponent = (props: TabsProps) => {
  const { value, onChange } = props;
  return (
    <DetailsPanelTabs value={value} onChange={onChange}>
      <Tab value={TAB_VALUES.info}>Справка</Tab>
      {/* <Tab value={TAB_VALUES.route}>Маршруты</Tab>
      <Tab value={TAB_VALUES.schedule}>Расписание</Tab> */}
    </DetailsPanelTabs>
  );
};

export const ScheduleDetailsPanelTabs = memo(ScheduleDetailsTabsComponent);
