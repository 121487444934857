import React, { PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as Markup from "./card.styles";

export type SkeletonCardProps = {
  /** количество скеллетонов */
  count?: number;

};

export const SkeletonCard = (props: SkeletonCardProps) => {


  const { count = 1 } = props;

  return <>{[...Array(count)].map((e, i) => <Markup.Container key={i}>
    <Markup.Icon>
      <Skeleton height={"2rem"} />
    </Markup.Icon>
    <Markup.Content>
      <Markup.Title><Skeleton /></Markup.Title>
      <Markup.Caption><Skeleton style={{ width: "70%" }} /></Markup.Caption>
    </Markup.Content>
  </Markup.Container>)}</>;
};

