import React, { useCallback, useState } from "react";
import { Content } from "@megapolis/uikit/button/button.types";
// import { PAGE_ROUTES } from "shared/definitions";
import { useRouteParams } from "shared/hooks";
import { Button } from "components";
import { Contractor24Icon, EditBlue32Icon } from "assets/icons";
import router from "router";
import { PAGE_ROUTES } from "shared/definitions";
import styled from "styled-components";
import { VersionViewer } from "components/ui/version-card";
import { VersionViewerContent } from "features/version-view";
import { getContractorVersionsDates } from "api";
import { contractorActions } from "store/actions";
import { useDispatch } from "react-redux";
import { useSelectedContractorVersion } from "../../../../store/selectors/contractor-selectors";
import { DetailsPanelHeader } from "../../components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const ContractorPanelHeader = () => {

  const { id } = useRouteParams();
  const contractor = useSelectedContractorVersion(id);
  // const router = useRouter();
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => {
    setIsShown(!isShown);
  };
  const dispatch = useDispatch();

  const handleEditClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.CONTRACTOR, { id: `${id}_edit` });
    },
    [router, id],
  );

  const handleCreateClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.CONTRACTOR, { id: "new" });
    },
    [router],
  );

  const handleEditVersionClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.CONTRACTOR, { id: `${id}_version` });
    },
    [router, id],
  );

  const handleItemClick = useCallback((versionId: any) => {
    dispatch(contractorActions.setSelectedContractor(versionId));
    router.navigate(PAGE_ROUTES.CONTRACTOR, { id: versionId.toString() });
    setIsShown(!isShown);
  }, [dispatch, isShown, router]);

  const versionCaption = useCallback((itm: any) => {
    let versionString = "";
    if (itm?.startDate) {
      versionString =  `Версия от ${itm?.startDate}`;
    } else if (!itm?.startDate && itm?.endDate) {
      versionString =  `Версия до ${itm?.endDate}`;
    } else {
      versionString =  "Версия бессрочная";
    }
    return versionString;
  }, []);


  return (
    <DetailsPanelHeader
      title={contractor?.fullName}
      caption={<Container onClick={toggle} ><span>{versionCaption(contractor)}</span><VersionViewer isShown={isShown} hide={toggle} modalContent={<VersionViewerContent currentVersion={contractor} id={contractor?.Id} handleItemClick={handleItemClick} onEdit={handleEditVersionClick} fetchVersions={getContractorVersionsDates} />} headerText={"Версионность"} onCreateClick={handleCreateClick} /></Container>}
      icon={<Contractor24Icon />}
      rightAction={
        <Button onClick={handleEditClick} icon={<EditBlue32Icon />} content={Content.Icon} priority="quaternary"/>
      }
    />
  );
  // <DetailsPanelHeader
  //   title="ГУП «Мосгортранс»"
  //   caption="Версия 50 - актуальная"
  //   icon={<DetailRouteIcon color="red">1337</DetailRouteIcon>}
  // />
};
