import styled from "styled-components/macro";

export const CrossWrapper = styled.div<{ color?: string }>`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 6px 0;
  svg,
  path {
    stroke: ${({ color }) => color};
  }
`;

export const PlankWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  min-height: 4px;
`;
