import { useCallback } from "react";
import { MapLayerNames } from "./consts/map.constants";


export const MapUtilsForLayers = () => {


  const clearRouteLayers = useCallback((map) => {
    if (map.getLayer("route")) {
      map.removeLayer("route");
    }

    if (map.getLayer("pointTrip")) {
      map.removeLayer("pointTrip");
    }

    if (map.getSource("selectedTrip")) {
      map.removeSource("selectedTrip");

    }

    if (map.getSource("selectedTripStops")) {
      map.removeSource("selectedTripStops");

    }
  }, []);

  const clearStopLayers = useCallback((map) => {
    if (map.getLayer(MapLayerNames.ACTUAL_STOP_LAYER_ID)) {
      map.removeLayer(MapLayerNames.ACTUAL_STOP_LAYER_ID);
    }

    if (map.getLayer(MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID)) {
      map.removeLayer(MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID);
    }

    if (map.getSource(MapLayerNames.ACTUAL_STOP_SOURCE_ID)) {
      map.removeSource(MapLayerNames.ACTUAL_STOP_SOURCE_ID);

    }

    if (map.getSource(MapLayerNames.ACTUAL_STOP_SELECTED_SOURCE_ID)) {
      map.removeSource(MapLayerNames.ACTUAL_STOP_SELECTED_SOURCE_ID);

    }
  }, []);

  const setVisibility = (map: mapboxgl.Map, visible = false, layer: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    map && map.getLayer(layer) && map.setLayoutProperty(layer, "visibility", visible ? "visible" : "none");
  };




  return {
    clearRouteLayers,
    clearStopLayers,
    setVisibility,
  };
};
