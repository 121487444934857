import { getVehicleCaptionByType, VehicleDetails } from "models";
import { useGetVehicleItemsByVersion } from "../../vehicle-details-panel.data";
import { labels } from "../../../../../shared/constants";

export const useGetVehicleInfoTabData = () => {
  const { vehicleDetails = {} as VehicleDetails } = useGetVehicleItemsByVersion();

  const infoCards = [
    {
      title: labels.brand,
      text: vehicleDetails.brand || labels.noValue,
    },
    {
      title: labels.model,
      text: vehicleDetails.model ? vehicleDetails.model : labels.noValue,
    },
    {
      title: labels.vehicleType,
      text: vehicleDetails.transportType ? getVehicleCaptionByType(vehicleDetails.transportType) : labels.noValue,
    },
    {
      title: labels.vehicleClassType,
      text: vehicleDetails.capacityType ? vehicleDetails.capacityType : labels.noValue,
    },
    {
      title: labels.onboardEquipment,
      text: labels.notDefined,
    },
    {
      title: "Дата ввода в эксплуатацию",
      text: vehicleDetails?.commissioningDate ? vehicleDetails.commissioningDate : labels.noValue,
    },
    {
      title: "Дата производства",
      text: vehicleDetails?.manufactureDate ? vehicleDetails.manufactureDate : labels.noValue,
    },
    {
      title: labels.depot,
      text: vehicleDetails?.depot?.name || labels.noValue,
    },
    {
      title: labels.onboardEquipmentId,
      text: vehicleDetails.tmId || labels.noValue,
    },
    {
      title: labels.contractor,
      text: vehicleDetails.contractor?.name || labels.noValue,
    },
    {
      title: labels.lowFloor,
      text: vehicleDetails.features?.lowFloor ? "Да" : "Нет",
    },
    {
      title: labels.climateSystem,
      text: vehicleDetails.features?.climateSystem ? "Да" : "Нет",
    },
    {
      title: labels.averageWorkingTime,
      text: labels.notDefined,
    },
  ];

  return { infoCards };
};
