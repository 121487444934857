import { TripModeTypes, TripTopologyTypes } from "models";

export const TripModeCaptionMap = {
  [TripModeTypes.Usual]: "Обычная",
  [TripModeTypes.Request]: "По запросу",
  [TripModeTypes.Technical]: "Техническая",
  [TripModeTypes.Boarding]: "Высадка",
};

export const TripTopologyMap = {
  [TripTopologyTypes.Pendular]: "Маятниковый",
  [TripTopologyTypes.Ring]: "Кольцевой",
};

export const getTripByMode = (mode: string) => TripModeCaptionMap[mode as keyof typeof TripModeTypes];

export const getTopologyByMode = (mode: string) => TripTopologyMap[ mode as keyof typeof TripTopologyTypes];



