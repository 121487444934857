import styled from "styled-components";

export const Container = styled.span`
  display: block;
  text-align: center;

  /* UI Small/UI Text 11 Reg */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
`;
