import React, { forwardRef, memo, ForwardedRef } from "react";
import styled from "styled-components/macro";
import { Input, InputProps } from "../input";
import { TextFieldBase, TextFieldBaseProps } from "./text-field-base";
import { loadingCss } from "./common.styles";

export type TextFieldProps = InputProps & Omit<TextFieldBaseProps, "children">;

const WrappedInput = styled(Input)<{ isLoading: boolean }>`
  ${({ isLoading }) => isLoading && loadingCss}
`;

const TextFieldComponent = (props: TextFieldProps, ref?: ForwardedRef<HTMLInputElement>) => {
  const {
    className,
    errorText,
    caption,
    label,
    hasError,
    isLoading = false,
    isDisabled,
    isRequired,
    ...inputProps
  } = props;

  return (
    <TextFieldBase
      className={className}
      errorText={errorText}
      caption={caption}
      label={label}
      hasError={hasError}
      isLoading={isLoading}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <WrappedInput
        ref={ref}
        hasError={hasError}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        {...inputProps}
      />
    </TextFieldBase>
  );
};

const TextFieldWithRef = forwardRef(TextFieldComponent);

export const TextField = memo(TextFieldWithRef);
