import { shallowEqual, useSelector } from "react-redux";
import { DriverFilters } from "store/reducers/driver/driver.types";
import { RootState } from "../../types";

export const useDriverItemsSelector = (filter: DriverFilters): { totalCount: number, items: any[] } =>
  useSelector((state: RootState) => {
    const drivers = state.driver[filter];
    const { driverSearchSubstring } = state.driver;
    if (!drivers) return { totalCount: driverSearchSubstring.length > 0 ? 0 : -1, items: [] };
    const { totalCount = 0, items } = drivers;

    // return drivers.items.reduce((acc, item) => [...acc, ...item], []).slice(0, totalCount) || [];
    return {
      items: items
        .reduce((acc, page) => [...acc, ...(page ?? [])], [])
        .slice(0, totalCount),
      totalCount,
    };
  }, shallowEqual);

export const useDriverFiltersSelector = () => useSelector((state: RootState) => state.driver.driverFilters);
export const useDriverSearchSubstringSelector = () => useSelector((state: RootState) => state.driver.driverSearchSubstring);
export const useDriverActiveFilter = () => useSelector((state: RootState) => state.driver.driverActiveFilter);
