import { css, DefaultTheme } from "styled-components";
import * as ButtonTypes from "../../button.types";

export const getDefaultColor = (props: ButtonTypes.DependenciesStyleProps) => (theme: DefaultTheme) =>
  css`
    fill: ${theme.button.default[props.priority][props.kind].beforeIcon?.fill};
  `;

export const getHoverColor = (props: ButtonTypes.DependenciesStyleProps) => (theme: DefaultTheme) =>
  css`
    fill: ${theme.button.hover[props.priority][props.kind].beforeIcon?.fill};
  `;

export const getActiveColor = (props: ButtonTypes.DependenciesStyleProps) => (theme: DefaultTheme) =>
  css`
    fill: ${theme.button.active[props.priority][props.kind].beforeIcon?.fill};
  `;

export const getDisabledColor = (props: ButtonTypes.DependenciesStyleProps) => (theme: DefaultTheme) =>
  css`
    fill: ${theme.button.disabled[props.priority][props.kind].beforeIcon?.fill};
  `;
