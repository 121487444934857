import React, { useCallback, useState } from "react";
import router from "router";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_ROUTES } from "shared/definitions";
import { RootState } from "store/types";
import { FilterWindow, Tab } from "components/ui";
import { commonActions, depotActions } from "store/actions";
import { DepotCardListPagination } from "features/card-lists/depot-card-list/depot-card-list-pagination";
import { DepotFilterContent } from "features/filters-windows";
import { ListPanel, ListPanelHeader, ListPanelHeaderWrapper, ListPanelFilterTabs } from "../components";
import { TabValues } from "../list-panels.constants";

export const DepotListPanel = () => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState("");
  const { depotActiveFilter } = useSelector((state: RootState) => ({
    depotActiveFilter: state.depot.depotActiveFilter,
  }));
  const { filterStatus } = useSelector((state: RootState) => ({
    filterStatus: state.common.filterStatus,
  }));
  const toggle = useCallback(() => {
    dispatch(commonActions.setFilterStatus(!filterStatus));
  }, [filterStatus]);

  const handleItemClick = useCallback((id: number | string) => {
    setActiveId(id.toString());
    dispatch(depotActions.setActiveDepot(id.toString()));
    router.navigate(PAGE_ROUTES.DEPOT, { id });
  }, [dispatch]);

  const handleAddItem = useCallback(
    () =>
      router.navigate(PAGE_ROUTES.DEPOT, { id: "new" }),
    [],
  );

  const handleChangeFilter = useCallback((filter: string) => {
    dispatch(depotActions.setActiveFilter(filter));
  }, [dispatch]);

  return (
    <ListPanel
      headerSlot={
        <ListPanelHeaderWrapper>
          {/* <FilterWindow headerText="Парки" isShown={showFilter} hide={toggle} modalContent={<DepotFilterContent hide={toggle} />} /> */}
          <ListPanelHeader requestPath="Depot" title="Парки" onAddClick={handleAddItem} onFilterClick={toggle} />
        </ListPanelHeaderWrapper>
      }
      filterSlot={
        <ListPanelFilterTabs activeFilterTab={depotActiveFilter} onChange={handleChangeFilter}>
          <Tab value={TabValues.ACTIVE}>Активные</Tab>
          <Tab value={TabValues.INACTIVE}>Неактивные</Tab>
        </ListPanelFilterTabs>
      }
      listSlot={<DepotCardListPagination activeId={activeId} onClick={handleItemClick} />}
    />
  );
};
