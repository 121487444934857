import { withAccentColor } from "shared/utils";
import styled, { css } from "styled-components/macro";
import { ifProp, switchProp } from "styled-tools";
import { withContentColor } from "@megapolis/uikit/utils";

const commonCss = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

type ContainerProps = {
  hasIndication: boolean;
};

export const Container = styled.label<ContainerProps>`
  ${commonCss};
  flex-flow: column nowrap;
  width: 100%;
  & > input {
    padding: ${ifProp("hasIndication", "7px 48px 7px 7px", "7px")};
    flex-grow: 1;
  }
`;

export const Content = styled.span`
  ${commonCss};
  position: relative;
  width: 100%;
`;

export const Indication = styled.span`
  ${commonCss};
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
`;

const requiredCss = css`
  &::after {
    content: "*";
    color: ${withAccentColor("red", "basic")};
  }
`;

export const Label = styled.span<{ isRequired: boolean; isDisabled: boolean; }>`
  ${commonCss};
  color: ${switchProp("isDisabled", {
    true: withContentColor("tertiary"),
    false: withContentColor("primary"),
  })};
  line-height: 16px;
  padding: 0 8px 4px 8px;
  width: 100%;
  ${({ isRequired }) => isRequired && requiredCss}
`;

export const Caption = styled.span`
  ${commonCss};
  color: ${withContentColor("secondary")};
  font-size: 11px;
  line-height: 16px;
  padding: 4px 8px 0px 8px;
  width: 100%;
`;
