import React, { ReactNode } from "react";
import styled from "styled-components/macro";

export type PanelProps = {
  children: ReactNode;
  className?: string;
};

const Container = styled.div<{ padding?: string }>`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Panel = ({ children, className }: PanelProps) => <Container className={className}>{children}</Container>;
