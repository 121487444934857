import styled from "styled-components/macro";

export const Wrapper = styled.button`
  width: 100%;
  border: none;
  background: transparent;
  svg {
    display: block;
    width: 24px;
  }
`;
