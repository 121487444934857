import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRoute, useRouter } from "react-router5";
import { PAGE_ROUTES } from "shared/definitions";
import { latScheme, UploadArea } from "components/ui";
import { Box, Chips, ChipsItem, Divider } from "components/common";
import { labels } from "shared/constants";
import { DatePicker } from "@megapolis/uikit";
import { Field } from "components/common/form/field";
import { Option } from "components/common/select/select.types";
import { FormField, DetailsBlock } from "features/detail-panels/components";
import { PanelForm } from "features/panel-form";
import { isRequired, isCorrectStartDate, isCorrectEndDate } from "shared/utils/validator";
import { ContractorSelectField } from "components/common/select-with-source";
import { VehicleTypeArray } from "models";
import { RootState } from "store/types";
import { useSelector } from "react-redux";
import { Loader } from "megapolis-uikit-latest/loader";
import * as Markup from "./new-depot-panel.styles";
import { SetValue, useNewDepotLogic } from "./new-depot-panel.logic";

export const NewDepotPanel = (props: { isNew?: boolean, changeVersion?: boolean }) => {
  const { isNew = true, changeVersion = false } = props;
  const referSetValue = useRef<SetValue>();
  const {
    form,
    createDepot,
    editDepot,
    editVersionDepot,
    isLoading,
    clearUp,
    startDate: oldStartDate,
    endDate: oldEndDate,
    files,
    handleFiles,
    handleDeleteFiles,
    handleClearFiles } = useNewDepotLogic(isNew, referSetValue.current);
  const [strtDate, setStrtDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");

  const { maskPlaceholder: latPlaceHolder } = useMemo(() => latScheme, []);

  const { activeId } = useSelector((state: RootState) => ({
    activeId: state.depot.activeDepotId,
  }));


  const title = isNew ? labels.createDepot : labels.editDepot;

  const router = useRouter();

  const { previousRoute } = useRoute();

  const handleClosePanel = useCallback(() => {
    handleClearFiles();
    if (previousRoute) {
      // router.navigate(previousRoute.name, previousRoute.params);
      router.navigate(PAGE_ROUTES.DEPOT, { id: activeId });
    } else {
      router.navigate(PAGE_ROUTES.DEPOTS);
    }
  }, [activeId, previousRoute, router, handleClearFiles]);

  const getValueForSelect = (value?: { Id: string | number; shortName: string; } | null): Option | null => {
    if (value) {
      return {
        value: String(value.Id),
        label: value.shortName || "",
      };
    }

    return null;
  };

  const handleSubmit = useCallback((values: any) => {
    if (isNew) {
      createDepot(values);
    } else if (changeVersion) {
      editVersionDepot(values);
    } else {
      editDepot(values);
    }
  }, [createDepot, editDepot, isNew]);

  useEffect(() => {
    if (isNew) clearUp();
    return () => {
      clearUp();
    };
  }, []);

  const addDays = useCallback((date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }, []);

  const scheme = useMemo(() => ({
    name: isRequired("Введите имя"),
    address: isRequired("Введите адрес"),
    // lat: composeValidators(isMatchesTo("Неправильный формат", latRegex)),
    // lng: composeValidators(isMatchesTo("Неправильный формат", latRegex)),
    startDate: isCorrectStartDate("Дата начала действия трассы не может быть больше даты окончания", endDate || ""),
    endDate: isCorrectEndDate("Дата окончания действия трассы не может быть меньше даты начала", strtDate || ""),
  }), [endDate, strtDate]);

  return (
    <PanelForm
      title={title}
      initialValues={form}
      validationScheme={scheme}
      onSubmit={handleSubmit}
      onCancel={handleClosePanel}
      isLoading={isLoading}
    >
      {
        ({ names, values, updateValue: setValue, errors }) => {
          referSetValue.current = setValue;
          return (
            <>
              <Field
                values={values}
                updateValue={setValue}
                name={names.name}
                errors={errors}
                type="text"
                label={labels.driverName}
                cyData="stop-station-name-add" />
              <Field
                name={names.address}
                label={"Адрес"}
                placeholder="Введите"
                errors={errors}
                cyData="stop-station-address-add"
                updateValue={setValue}
                values={values}
                type="text"
              />
              <FormField>
                <Chips label={labels.vehicleType}>
                  {VehicleTypeArray.map((chip) => (
                    <ChipsItem
                      key={chip.id}
                      {...chip}
                      isActive={values.transportType === chip.id}
                      onClick={({ id }) => {
                        setValue(names.transportType, id);
                      }}
                    />
                  ))}
                </Chips>
              </FormField>
              <FormField>
                <ContractorSelectField
                  value={getValueForSelect(values.contractor)}
                  onChange={({ value, label }) => setValue(names.contractor, { Id: parseInt(value, 10), shortName: label })}
                  label={<Markup.Label>{labels.contractor}</Markup.Label>}
                  errorText={errors.contractor ?? undefined}
                  hasError={!!errors.contractor}
                />
              </FormField>
              <FormField>
                <Markup.Label>{"Дата начала действия"}</Markup.Label>
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      const year = e?.getFullYear();
                      const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                      const day = e?.getDate().toString().padStart(2, "0");
                      setStrtDate(`${year}-${month}-${day}`);
                      setValue("startDate", `${year}-${month}-${day}`);
                    } else {
                      setStrtDate(null);
                      setValue("startDate", null);
                    }
                  }}
                  errorText={errors?.startDate ?? undefined}
                  minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                  maxDate={oldEndDate ? addDays(oldEndDate, 1) : undefined}
                  hasError={!!errors?.startDate}
                  value={values?.startDate ? new Date(values.startDate) : null}
                />
              </FormField>
              <FormField>
                <Markup.Label>{"Дата окончания действия"}</Markup.Label>
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      const year = e?.getFullYear();
                      const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                      const day = e?.getDate().toString().padStart(2, "0");
                      setEndDate(`${year}-${month}-${day}`);
                      setValue("endDate", `${year}-${month}-${day}`);
                    } else {
                      setEndDate(null);
                      setValue("endDate", null);
                    }
                  }}
                  errorText={errors?.endDate ?? undefined}
                  minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                  hasError={!!errors?.endDate}
                  value={values?.endDate ? new Date(values.endDate) : null}
                />
              </FormField>
              <Field
                label={"Широта"}
                name={names.lat}
                type="text"
                placeholder={latPlaceHolder}
                errors={errors}
                data-cypress="depot-lat-add"
                values={values}
                updateValue={setValue}
              />
              <Field
                label={"Долгота"}
                name={names.lng}
                type="text"
                placeholder={latPlaceHolder}
                errors={errors}
                data-cypress="depot-lng-add"
                values={values}
                updateValue={setValue}
              />
              <Divider margin="16px 0" />
              <DetailsBlock header={<Box>Файлы</Box>}>
                {
                  isLoading ? (<Box justifyContent={"center"}><Loader /></Box>) : (<Box direction="column">
                    <UploadArea
                      filesInput={files}
                      multiple={true}
                      handleChangeFiles={(el) => {
                        handleFiles(el);
                      }}
                      handleDeleteFiles={(el) => {
                        handleDeleteFiles(el);
                      }}
                    />
                  </Box>)
                }

              </DetailsBlock>
            </>
          );
        }
      }
    </PanelForm>
  );
};

