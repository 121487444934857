import React, { useCallback } from "react";
import * as api from "services";
import { DefaultSelectProps } from "../select-with-source.types";
import { SelectField } from "../../select-field";
import { useSourceLoader } from "../use-source-loader";

export const PavilionHoldersSelectField = (props: DefaultSelectProps) => {
  const fetcher = useCallback(() => api.get<{ id: number, name: string }[]>("Stop/pavilionHolders"), []);
  const mapper = useCallback((data: { id: number, name: string }[]) => {
    const items = data;
    return items
      .map(item => ({ value: item.id.toString(), label: item.name }))
      .filter(({ label }) => label);
  }, []);

  const { options, isLoading } = useSourceLoader("pavilionHolders", fetcher, mapper);

  return <SelectField options={isLoading ? [] : options} { ...props } />;
};
