import { DepotRequestItem, DepotVersion } from "models";
import { TabValues } from "features/index";
import { DepotVersionResponce } from "api";
import { DepotFilters } from "store/reducers/depot";
import { DepotFilter } from "store/reducers/depot/depot.constants";

export enum DepotActionTypes {
  SET_ACTIVE_DEPOT = "SET_ACTIVE_DEPOT",
  SET_DEPOT = "SET_DEPOT",
  SET_FILTERS = "SET_FILTERS",
  SET_INACTIVE_DEPOT = "SET_INACTIVE_DEPOT",
  UPDATE_DEPOTS_FILES = "UPDATE_DEPOTS_FILES",
  SET_DEPOT_BY_DEPOT_ID = "SET_DEPOT_BY_DEPOT_ID",
  SET_DEPOT_BY_VERSION_ID = "SET_DEPOT_BY_VERSION_ID",
  SET_DEPOT_ACTIVE_FILTER = "SET_DEPOT_ACTIVE_FILTER",
  SET_DEPOT_ACTIVE_ITEM = "SET_DEPOT_ACTIVE_ITEM",
  SET_DEPOT_FILTERS = "SET_DEPOT_FILTERS",
  SET_DEPOT_SEARCH_STRING = "SET_DEPOT_SEARCH_STRING",

}

type Action<A extends DepotActionTypes, P> = { type: A, payload: P };

export type SetDepotsAction = Action<
DepotActionTypes.SET_DEPOT,
{ status: DepotFilters, data: DepotVersionResponce; pageNum: number }
>;
export type SetDepotFilters = Action<DepotActionTypes.SET_DEPOT_FILTERS, {
  ContractorIds?: string,
  RouteIds?: string,
  DepotIds?: string,
}>;
export type SetActiveDepotAction = Action<DepotActionTypes.SET_ACTIVE_DEPOT, DepotRequestItem>;
export type SetInactiveDepotAction = Action<DepotActionTypes.SET_INACTIVE_DEPOT, DepotRequestItem>;
export type SetActiveFilterAction = Action<DepotActionTypes.SET_DEPOT_ACTIVE_FILTER, TabValues>;
export type SetFilters = Action<DepotActionTypes.SET_FILTERS, DepotFilter>;
export type SetActiveDepotIdAction = Action<DepotActionTypes.SET_DEPOT_ACTIVE_ITEM, string>;
export type SetDepotSearchStringAction = Action<DepotActionTypes.SET_DEPOT_SEARCH_STRING, string>;
export type SetDepotByVersionIdAction = Action<DepotActionTypes.SET_DEPOT_BY_VERSION_ID, { versionId: ID, data: DepotVersion }>;
export type UpdateDepotsFilesAction = Action<DepotActionTypes.UPDATE_DEPOTS_FILES, { depotId: string, data: Partial<DepotVersion> }>;

export type DepotActions = 
| SetActiveDepotAction
| SetFilters
| SetActiveDepotIdAction
| SetDepotFilters
| SetDepotsAction
| UpdateDepotsFilesAction
| SetInactiveDepotAction
| SetDepotSearchStringAction
| SetDepotByVersionIdAction
| SetActiveFilterAction;