import { Add24BlueIcon, XClose24BlueIcon } from "assets/icons";
import { IconButton } from "components/common";
import React, { useCallback, useState, useMemo, useEffect, useRef, RefObject } from "react";
import * as Markup from "./version-card.styles";

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => setIsShown(show => !show);
  return {
    isShown,
    toggle,
  };
};

type ClickOutsideProps = {
  ref: RefObject<HTMLElement>;
  callback: (event: MouseEvent) => void;
};

export const useClickOutside = ({ ref, callback }: ClickOutsideProps) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref.current || ref.current?.contains(event.target as Node)) return;
      callback(event);
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [ref, callback]);
};

export type SelectProps = {
  isShown: boolean;
  hide: () => void;
  onCreateClick: () => void;
  modalContent: JSX.Element;
  headerText: string;
};

/** Временный компонент, позже будет переработан и вынесен в UIKit */
export const Modal = (props: SelectProps) => {
  const {
    isShown,
    hide,
    modalContent,
    headerText,
    onCreateClick,
  } = props;
  const containerRef = useRef(null);

  // useClickOutside({ ref: containerRef, callback: hide });

  return (
    <Markup.Container ref={containerRef}>
      {isShown ? (
        <Markup.StyledModal>
          <Markup.Header>
            <Markup.Heading>{headerText}</Markup.Heading>
            <Markup.ButtonsList>
              <IconButton content="icon" icon={<Add24BlueIcon />} onClick={onCreateClick} />
              <IconButton content="icon" icon={<XClose24BlueIcon />} onClick={hide} />
            </Markup.ButtonsList>
          </Markup.Header>
          <Markup.Content>{modalContent}</Markup.Content>
        </Markup.StyledModal>
      ) : null}
    </Markup.Container>
  );
};

export const VersionViewer = React.memo(Modal);
