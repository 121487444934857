import mapboxgl, { Anchor, Expression } from "mapbox-gl";
import { MapLayerNames } from "../utils/consts/map.constants";



export const getKeyZoneTilesLayers = (): mapboxgl.AnyLayer[] => [

  {
    id: MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID,
    type: "line",
    source: MapLayerNames.TILE_SET_KEYZONE_SOURCE_ID,
    "source-layer": "keyZone",
    minzoom: 10,
    maxzoom: 24,
    "layout": {
      "line-join": "round",
      "line-cap": "round",
      "visibility": "none",
    },
    "paint": {
      "line-color": "#5ACAFD",
      "line-width": 1,
    },
  },
  {
    "id": MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID,
    "type": "fill",
    "source-layer": "keyZone",
    "source": MapLayerNames.TILE_SET_KEYZONE_SOURCE_ID,
    "layout": {
      "visibility": "none",
    },
    "paint": {
      "fill-color": "#5ACAFD",
      "fill-opacity": 0.3,
    },
  },
];
