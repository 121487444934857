import { useEffect, RefObject } from "react";

type ClickOutsideProps = {
  ref: RefObject<HTMLElement>;
  callback: (event: MouseEvent) => void;
};

export const useClickOutside = ({ ref, callback }: ClickOutsideProps) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref.current || ref.current?.contains(event.target as Node)) return;
      callback(event);
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [ref, callback]);
};
