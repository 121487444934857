import styled from "styled-components";
import { withCBColor } from "@megapolis/uikit/utils/styled-helpers";

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${withCBColor("secondary", "active")};
    z-index: -1;
  }
`;
