import { TabValues } from "../../../features";
import { IInitialVehicleState } from "./vehicle.types";

export const initialState: IInitialVehicleState = {
  [TabValues.ACTIVE]: null,
  [TabValues.INACTIVE]: null,
  vehicleByVehicleId: {},
  vehicleByVersionId: {},
  vehicleActiveFilter: TabValues.ACTIVE,
  currentVehicleStation: {},
  newVehicleStationCoords: undefined,
  vehicleFilters: {
    ContractorIds: undefined,
    RouteIds: undefined,
    TransportTypes: undefined,
    VehicleBrands: undefined,
    VehicleMaxAge: undefined,
    VehicleClimateSystem: undefined,
    VehicleLowFloor: undefined,
  },
  newVehicleStationData: undefined,
  vehicleSearchSubstring: "",
  selectedVehicle: "",
  isLoading: false,
};
