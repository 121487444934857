import styled from "styled-components";
import { withNeutralColor } from "shared/utils";
import { withAccentColor } from "@megapolis/uikit/utils";

export const Wrapper = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  background: transparent;
  border: none;
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  user-select: none;
`;

export const Avatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #f9f9f9;
  margin-left: 0;
  overflow: hidden;
  
  svg {
    fill: ${withAccentColor("blue", "basic")};
  }
  
  img {
    width: 100%;
  }
`;

export const Title = styled.span`
  max-width: calc(100% - 36px);
  color: ${withNeutralColor("d4")};
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  margin: auto auto auto 12px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
