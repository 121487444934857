import React from "react";
import * as Markup from "./card.styles";

type ClickCallbackSign = (e: React.MouseEvent<HTMLButtonElement>) => void;

export type CardProps = {
  /** Заголовок карточки */
  title: React.ReactNode;
  /** флаг, отвечающий за активное состояние карточки */
  isActive?: boolean;
  /** кастомный className */
  className?: string;
  /** блок подписи под заголовком */
  caption?: React.ReactNode;
  /** блок правого контрола(предполагается для иконок, с поддержкой надатия) */
  control?: React.ReactNode;
  /** иконка слева от основного текстового блока */
  icon?: React.ReactNode;
  /** обработчик нажатия на основной блок */
  onClick?: ClickCallbackSign;
  /** обработчик нажатия на правый контрол */
  onControlClick?: ClickCallbackSign;
};

export const Card = (props: CardProps) => {
  const { icon, title, caption, control, onClick, onControlClick, className, isActive = false } = props;
  return (
    <Markup.Container className={className} isActive={isActive}>
      <Markup.MainControl onClick={onClick}>
        {icon && <Markup.Icon>{icon}</Markup.Icon>}
        <Markup.Content>
          <Markup.Title>{title}</Markup.Title>
          <Markup.Caption>{caption}</Markup.Caption>
        </Markup.Content>
      </Markup.MainControl>
      {control && <Markup.AdditionalControl onClick={onControlClick}>{control}</Markup.AdditionalControl>}
    </Markup.Container>
  );
};
