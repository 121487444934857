import { useLayoutLogic } from "@megapolis/uikit";
import { Types } from "@megapolis/uikit/layout/reducers/show-state";
import { useCallback } from "react";
import { DETAILS_ID, PANEL_ID } from "../constants";

export const useLayoutDesignState = () => {
  const { showState, showStateActions } = useLayoutLogic();

  const isCollapsed = true;

  const showDetails = useCallback(() => {
    
  }, [isCollapsed, showStateActions]);

  const showPanel = useCallback(() => {
   
  }, [isCollapsed, showStateActions]);

  return { isCollapsed, isExpanded: !isCollapsed, showDetails, showPanel };
};
