const getFormattedDateNumber = (date: number) => {
  if (date < 10) {
    return `0${date}`;
  }

  return date;
};

export const formatDateToString = (date: Date): string => (
  `${getFormattedDateNumber(date.getDate())}.${getFormattedDateNumber(date.getMonth() + 1)}.${date.getFullYear()}`
);
