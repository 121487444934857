import React, { useCallback, useEffect, useState } from "react";
import { isEmptyObject } from "shared/utils";
import { SkeletonDetailCard } from "components/ui/skeleton-detail-card";
import { useOnStopClick } from "components/common/main-map/actual-stops/use-on-stop-click";
import { RouteTabListPagination } from "features/detail-tabs";
import { DetailsPanel } from "../../components";
import { TAB_VALUES } from "./stop-station-details.constants";
import { StopStationDetailsPanelHeader, StopStationDetailsPanelTabs } from "./components";
import { StopStationDetailsInfoTab } from "./tab-pages";
import { useGetStopItemsByVersion } from "./stop-station-details.data";

export const StopStationDetails = () => {
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const { item, stopId, versionId } = useGetStopItemsByVersion();
  useOnStopClick();

  useEffect(()=>{
    setActiveTab(TAB_VALUES.info);
  }, [stopId]);

  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  if (!stopId?.length || !versionId?.length || !item || isEmptyObject(item)) return <SkeletonDetailCard />;

  return (
    <DetailsPanel
      headerSlot={<StopStationDetailsPanelHeader />}
      tabsSlot={<StopStationDetailsPanelTabs value={activeTab} onChange={handleChangeTab} />}>
      {activeTab === TAB_VALUES.info && <StopStationDetailsInfoTab />}
      {activeTab === TAB_VALUES.route && <RouteTabListPagination StopIds={item.id ? item.id.toString() : undefined} />}
      {activeTab === TAB_VALUES.schedule && <></>}
    </DetailsPanel>
  );
};
