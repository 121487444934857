import React, { memo, ReactNode } from "react";
import Select, { ActionMeta } from "react-select";
import { SelectProps } from "../select";
import * as Markup from "./alternative-seelect.styles";

export type AlternativeSelectProps = SelectProps & {
  label?: ReactNode;
  isRequired?: boolean;
  // onChange?: React.MouseEventHandler<HTMLElement>;
};

const createLabel = (label: ReactNode) => (typeof label === "string" ? <Markup.Label>{label}</Markup.Label> : label);

const AlternativeSelectComponent = (props: AlternativeSelectProps) => {
  const { cyData, onChange, isRequired = false, label, options, ...selectProps } = props;


  return (
    <Markup.Container>
      {label && <Markup.LabelContainer isRequired={isRequired}>{createLabel(label)}</Markup.LabelContainer>}
      <Select
        defaultValue={options[0]}
        data-cypress={cyData}
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onChange={(newValue)=>{ newValue && onChange ? onChange(newValue) : undefined; }}
        options={options}
        { ...selectProps}
      />
    </Markup.Container>
  );
};

export const AlternativeSelect = memo(AlternativeSelectComponent);
