import React, { memo } from "react";
import { Route } from "models";
import { RouteCard } from "../../cards";
import { CardList } from "../components";

export type RouteCardListProps = {
  items: Route[];
  activeId?: string;
  onClick?: (id: string) => void;
};

const RouteCardListComponent = (props: RouteCardListProps) => {
  const { items, activeId, onClick } = props;

  return (
    <CardList>
      {items.map((item) => (
        <CardList.Item key={item.id}>
          <RouteCard {...item} isActive={activeId === item.id} onClick={onClick} />
        </CardList.Item>
      ))}
    </CardList>
  );
};

export const RouteCardList = memo(RouteCardListComponent);
