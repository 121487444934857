import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
 * {
   font-size: 12px;
   box-sizing: border-box;
 }
 .user-auth-popup {
  z-index: 10;
}
`;
