import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toastError, toastSuccess } from "components";
import { DriverDetails, VehicleDetails } from "models";
import { ErrorHandler } from "shared/helpers/error-handler";
import { addDriverVersionRequest, editDriverVersionRequest, getDriverFile, getDriverRequest, getDriverVersionRequest } from "api/requests/driver/driver.requests";
import { TabValues, useFileLoadLogic } from "features";
import { driverActions } from "store/actions";
import { useStopCurrentPage } from "store/selectors/stop-selectors";
import { useDriverActiveFilter, useDriverFiltersSelector, useDriverSearchSubstringSelector } from "store/selectors/driver-selectors";
import { DriverGetRequestOptions } from "api/requests/driver/types";
import { PAGE_ROUTES } from "shared/definitions";
import { useRouter } from "react-router5";
import { useGetDriverItemsByVersion } from "../driver-details-panel.data";

export type EditDriverForm = {
  lastName: string | null;
  firstName: string | null;
  middleName: string | null;
  birthDate: string | null;
  licenseCategories: string | null;
  personnelNumber: string | null;
  lastMedDate: string | null;
  depot: { id: number, name: string } & { contractor: VehicleDetails["contractor"] } | null;
  contractor: VehicleDetails["contractor"] | null;
  startDate: string | null;
  endDate: string | null;
};

export type SetValue = (name: keyof EditDriverForm, value: EditDriverForm[keyof EditDriverForm]) => void;

const initialForm: EditDriverForm = {
  lastName: null,
  firstName: null,
  middleName: null,
  birthDate: null,
  licenseCategories: null,
  lastMedDate: null,
  personnelNumber: null,
  depot: null,
  contractor: null,
  startDate: null,
  endDate: null,
};

const changeDay = (start: string | null, str: string | null, numOfDays = 1) => {
  if (!str) return null;
  if (str === start) return str;
  const words = str.split("-");
  words[2] = words[2] !== "01" ? (+words[2] - numOfDays).toString().padStart(2, "0") : "01";
  return words.join("-");
};

const formToRequest = (form: EditDriverForm, details?: any, fileArr: any[] = []): any => {
  const { contractor, licenseCategories, depot } = form;
  return {
    firstName: form.firstName,
    middleName: form.middleName || "",
    lastName: form.lastName,
    depot: {
      depotId: depot?.id || "",
      name: depot?.name || "",
      contractor: {
        Id: contractor?.id,
        shortName: contractor?.name,
      },
    },
    personnelNumber: form.personnelNumber || null,
    birthDate: form.birthDate,
    licenseCategories,
    startDate: form.startDate || null,
    endDate: form.endDate || null,
    files: fileArr,

  };
};

const detailsToForm = (details: DriverDetails): any => {
  const { depot, ...rest } = details || {};

  return {
    depot: { id: depot?.depotId, name: depot?.name },
    contractor: { id: depot?.contractor?.Id, name: depot?.contractor?.shortName } ?? null,
    ...rest,
  };
};

export const useEditDriverLogic = (isNew?: boolean, setValue?: SetValue) => {
  const [isLoading, setIsLoading] = useState(false);
  const { driverDetails } = useGetDriverItemsByVersion();
  const dispatch = useDispatch();
  const router = useRouter();
  const { createMetaDataArr } = useFileLoadLogic();
  const curentPage = useStopCurrentPage();
  const activeFilter = useDriverActiveFilter();
  const filters = useDriverFiltersSelector();
  const searchSubstring = useDriverSearchSubstringSelector();
  const [files, setFiles] = useState<File[]>([]);
  const [form, setForm] = useState(isNew ? initialForm : detailsToForm(driverDetails));
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);

  const fetchFiles = useCallback(async (controller: AbortController, driver: DriverDetails) => {
    if (!driver?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(driver.files.map(async fileElem => {
        const payload = await getDriverFile(driver.driverId, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);


  useEffect(() => {
    if (driverDetails && !isNew) {
      setForm(detailsToForm(driverDetails));
      setStartDate(driverDetails.startDate ? driverDetails.startDate : undefined);
      setEndDate(driverDetails.endDate ? driverDetails.endDate : undefined);
    } else {
      setForm(initialForm);
    }
  }, [driverDetails, isNew]);

  useEffect(() => {
    const controller = new AbortController();
    if (driverDetails && !isNew) {
      fetchFiles(controller, driverDetails);
    }
    return ()=>{
      controller.abort();
    };
  }, [driverDetails, fetchFiles, isNew]);

  const params: DriverGetRequestOptions = useMemo(() => ({
    PageNum: curentPage,
    pageSize: 200,
  }), [curentPage]);

  const options: DriverGetRequestOptions = useMemo(() => ({
    IncludeActive: activeFilter === TabValues.ACTIVE,
    searchSubstring,
    ...filters,
  }), [activeFilter, filters, searchSubstring]);

  const handleExecQuery = useCallback(async (verId: number) => {
    try {
      const driver = await getDriverVersionRequest(verId.toString());
      dispatch(driverActions.updateDriversFiles(driver.driverId, driver));
      dispatch(driverActions.getDriverByVersionId(verId.toString(), driver));
      const payload = await getDriverRequest({ ...params, ...options });
      dispatch(driverActions.setDrivers({
        data: payload,
        status: activeFilter,
        pageNum: curentPage || -1,
      }));
      router.navigate(PAGE_ROUTES.DRIVER, { id: verId });
    } catch (error) {
      router.navigate(PAGE_ROUTES.DRIVERS);
    }
  }, [dispatch, params, options, activeFilter, curentPage, router]);

  /// //////////////////////////////////////////////////////////////////////////////////

  const editDriver = useCallback(async (values: EditDriverForm) => {
    setIsLoading(true);
    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }

    await addDriverVersionRequest({ ...formToRequest(values, driverDetails, fileArr), driverId: driverDetails.driverId || null })
      .then(async (el: any) => {
        toastSuccess({ title: "Успешно", message: `Изменен ${values.firstName} ${values.lastName}` });
        const payload = await getDriverVersionRequest(String(driverDetails.versionId));
        dispatch(driverActions.getDriverByVersionId(String(driverDetails.versionId), payload));
        handleExecQuery(el.versionId);
      })
      .catch((error: any) => toastError({ title: "Error", message: error.response.data.toString() })).finally(() => {
        setIsLoading(false);
      });
    setIsLoading(false);
  }, [driverDetails, createMetaDataArr, files, dispatch, handleExecQuery]);

  /// //////////////////////////////////////////////////////////////////////////////////

  const editVersionDriver = useCallback(async (values: EditDriverForm) => {
    setIsLoading(true);
    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }
    try {
      await editDriverVersionRequest({ ...formToRequest(values, driverDetails, fileArr), driverId: driverDetails.driverId || null, versionId: driverDetails.versionId || null });
      toastSuccess({ title: "Успешно", message: `Изменен ${values.firstName} ${values.lastName}` });
      handleExecQuery(driverDetails.versionId);
    } catch (error) {
      toastError({ title: "Error", message: `Не удалось обновить ${driverDetails.driverId}` });
      setIsLoading(false);
    }
  }, [driverDetails, createMetaDataArr, files, dispatch, handleExecQuery]);

  /// //////////////////////////////////////////////////////////////////////////////////

  const createDriver = useCallback(async (values: EditDriverForm) => {
    setIsLoading(true);

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      setIsLoading(false);
      return;
    }
    await addDriverVersionRequest(formToRequest(values, driverDetails, fileArr)).then((el: any) => {
      toastSuccess({ title: "Успешно", message: `Добавлен ${values.firstName} ${values.lastName}` });
      handleExecQuery(el.versionId);
    }).catch((error: any) => {
      if (error.response) {
        if (error.response.data.errors) {
          ErrorHandler(error.response.data);
        } else {
          toastError({ title: "Ошибка", message: error.response.data.toString() });
        }

      }
    }).finally(() => {
      setIsLoading(false);
    });
    setIsLoading(false);
  }, [driverDetails, createMetaDataArr, files, handleExecQuery]);

  const clearUp = useCallback(() => {
    (Object.keys(initialForm) as Array<keyof typeof initialForm>).map(el => setValue && setValue(`${el}`, initialForm[`${el}`]));
    setStartDate(undefined);
    setEndDate(undefined);
  }, [setValue]);

  return {
    form,
    editDriver,
    createDriver,
    editVersionDriver,
    handleFiles: setFiles,
    files,
    startDate,
    endDate,
    isLoading,
    clearUp,
  };
};
