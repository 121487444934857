import { APP_ENV } from "app-env";
import { DepotVersion } from "models";
import * as api from "services";
import { DepotCreateRequestOptions, DepotVersionResponce, GetDepotRequestOptions } from "./types";

const requestPath = "Depot";

export const addDepotRequest = (options: DepotCreateRequestOptions) => api.post<{ versionId: number }>(`${requestPath}/version`, {}, options);

export const editDepotRequest = (options: DepotCreateRequestOptions) => api.put(`${requestPath}/version`, {}, options);

export const getDepotRequest = (options?: GetDepotRequestOptions) => api.get<DepotVersionResponce>(`${requestPath}/version`, options);

export const getDepotVersionsDates = (depotId: string | number) => api.get<any[]>(`${requestPath}/${depotId}/versionsDates`);

export const getDepotVersionRequest = (versionId: string) => api.get<DepotVersion>(`${requestPath}/version/${versionId}`, {});

export const getDepotFile = async (depotId: number | string, id: number | string, signal?: AbortSignal | undefined) => {
  const myHeaders = new Headers();
  const accessToken = localStorage.getItem("Authorization") ?? "";
  if (accessToken.length) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }
  
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  
  const payload = await fetch(`${APP_ENV.REACT_APP_BASE_URL}/${requestPath}/${depotId}/${id}`, { ...requestOptions, signal });
  return payload;
};

