import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Content } from "@megapolis/uikit/button/button.types";
import { Button } from "megapolis-uikit-latest/button";
import { FormProps } from "./form.types";
import * as Markup from "./form.styles";
import { useFormValidator } from "../../../shared/utils/validator";

export const Form = <T extends Record<string, unknown>>(props: FormProps<T>) => {
  const { children, initialValues, onSubmit, onCancel, validationScheme, isLoading = false, isDisabled = false } = props;
  const [values, setValues] = useState(initialValues);
  const { errors, hasError, validate } = useFormValidator(values, validationScheme);

  const names: Record<keyof T, string> = useMemo(() => {
    const keys = Object.keys(initialValues) as (keyof T)[];
    return keys.reduce((acc, name) => ({ ...acc, [name]: name }), {}) as Record<keyof T, string>;
  }, [initialValues]);

  const handleSubmit = useCallback(() => {
    const { hasError: isError } = validate();
    if (isError) return;

    onSubmit(values);
  }, [validate, onSubmit, values]);

  useEffect(() => {
    if (hasError) {
      validate();
    }
  }, [validate, hasError]);

  const updateValue = useCallback(<P extends keyof T>(name: P, value: T[P] | null) => {
    setValues(prev => ({ ...prev, [name]: value }));
  }, []);

  return (
    <>
      <Markup.MainBlock>
        {children({ values, updateValue, names, errors, formHasError: hasError })}
      </Markup.MainBlock>
      <Markup.Footer>
        <Button content={Content.Text} text="Отмена" onClick={onCancel} priority="quaternary" />
        <Button content={Content.Text} text="Сохранить" onClick={handleSubmit} isDisabled={hasError || isLoading || isDisabled} isLoading={isLoading} />
      </Markup.Footer>
    </>
  );
};
