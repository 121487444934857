import ReactDOMServer from "react-dom/server";
import mapboxgl from "mapbox-gl";
import { centroid, feature, Feature, LineString, Position } from "@turf/turf";

import React, { useCallback, useRef } from "react";
import { selectedRouteLayers } from "./selected-route";

export const createNewData = (geometryForward: any, geometryBackward: any, route: any, active: boolean, bgColor: string) => {
  const features: any[] = [];
  if (geometryForward?.length)
    // eslint-disable-next-line array-callback-return
    geometryForward.map((geo: any, idx: number) => {
      features.push({
        type: "Feature",
        id: `Forward ${idx}`,
        geometry: geo,
        properties: {
          ...route,
          direction: "Forward",
          bgColor,
          fontColor: active ? "#1d2123" : route?.color?.foreground || "#1d2123",
        },
      });
    });


  if (geometryBackward?.length)
    // eslint-disable-next-line array-callback-return
    geometryBackward.map((geo: any, idx: number) => {
      features.push({
        type: "Feature",
        id: `Backward ${idx}`,
        geometry: geo,
        properties: {
          ...route,
          direction: "Backward",
          bgColor,
          fontColor: active ? "#1d2123" : route?.color?.foreground || "#1d2123",
        },
      });
    });

  return {
    type: "FeatureCollection",
    features,
  };
};

export const createNewDataTrip = (geometryForward: any, geometryBackward: any, active: boolean, bgColor: string) => {
  const features: any[] = [];
  if (geometryForward?.length)
    // eslint-disable-next-line array-callback-return
    geometryForward.map((geo: any, idx: number) => {
      features.push({
        type: "Feature",
        id: `Forward ${idx}`,
        geometry: geo,
        properties: {
          direction: "Forward",
          bgColor,
          fontColor: "#1d2123",
        },
      });
    });


  if (geometryBackward?.length)
    // eslint-disable-next-line array-callback-return
    geometryBackward.map((geo: any, idx: number) => {
      features.push({
        type: "Feature",
        id: `Backward ${idx}`,
        geometry: geo,
        properties: {
          direction: "Backward",
          bgColor,
          fontColor: "#1d2123",
        },
      });
    });

  return {
    type: "FeatureCollection",
    features,
  };
};

export const createNewDataTrips = (path: any, direction: string, bgColor: string) => {
  const features = [];
  features.push({
    type: "Feature",
    id: "Forward",
    geometry: path,
    properties: {
      direction,
      bgColor,
      fontColor: "#1d2123",
    },
  });
  return {
    type: "FeatureCollection",
    features,
  };
};


export const createNewDataTripsStop = (tripStops: any) => {
  const features: Feature[] = [];
  tripStops.forEach((element: any) => {
    features.push({
      type: "Feature",
      id: element.stop.id,
      geometry: {
        type: "Point",
        coordinates: [element.stop.lng, element.stop.lat],
      },
      properties: {
        "address": element.stop.name,
        "isActive": element.isActive || false,
      },
    });
  });
  return {
    type: "FeatureCollection",
    features,
  };
};

export const createNewDataTripsKeyZones = (keyZones: any) => {
  const features: Feature[] = [];
  keyZones.forEach((element: any) => features.push({
    type: "Feature",
    id: element.keyZoneId,
    geometry: element.geometry,
    properties: {
      name: element.name,
    },
  }));
  return {
    type: "FeatureCollection",
    features,
  };
};

export const createNewDataTripsLine = (Line: any) => ({
  type: "geojson",
  data: Line,
});



const getHexToRgb = (hexRGB: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexRGB.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};

export const isGrayscale = (hexRGB?: string) => {
  if (!hexRGB) return "";
  const hexToRgb = getHexToRgb(hexRGB);
  if (!hexToRgb) return "";
  const colorRate = hexToRgb.r * 0.3 + hexToRgb.g * 0.59 + hexToRgb?.b * 0.11;
  return Math.round(colorRate) > 180;
};

export const encodeSvg = (reactElement: any) =>
  `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToStaticMarkup(reactElement))}`;


export const ArrowIconSelected = (color: string) =>
  encodeSvg(
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.147087 2.72994C0.126779 2.45454 0.333567 2.21483 0.608961 2.19452L6.09407 1.79003L6.00755 0.616821C5.99662 0.468547 6.14554 0.360603 6.28305 0.417133L9.75005 1.84241C9.90248 1.90508 9.91795 2.11487 9.77636 2.19921L6.55594 4.11764C6.42821 4.19373 6.26506 4.1088 6.25413 3.96053L6.16761 2.78732L0.682505 3.19181C0.40711 3.21212 0.167396 3.00533 0.147087 2.72994Z"
        fill={color}
      />
    </svg>,
  );

type TimerState = { [name: string]: NodeJS.Timeout };

export const useTimer = () => {
  const state = useRef<TimerState>({} as TimerState);

  const addTimer = useCallback((map: mapboxgl.Map | undefined, nameController: string, callback: () => void) => {
    if (state.current?.[nameController]) clearTimeout(state.current?.[nameController]);
    if (!map?.isStyleLoaded()) {
      // eslint-disable-next-line no-return-assign
      return (state.current[nameController] = setTimeout(() => {
        addTimer(map, nameController, callback);
      }, 300));
    }
    callback();
    return null;

  }, []);

  const removeTimer = useCallback((nameController: string) => {
    if (state.current?.[nameController]) clearTimeout(state.current?.[nameController]);
  }, []);

  return {
    state: state.current,
    addTimer,
    removeTimer,
  };
};

export const visibility = (map: mapboxgl.Map, visible: boolean) =>
  selectedRouteLayers.forEach(
    (item) => map.getLayer(item) && map.setLayoutProperty(item, "visibility", visible ? "visible" : "none"),
  );

export const centerOfLine = (data?: any) => {
  if (!data?.features) return null;
  const reduceCoordinate = (reduce: Position, point: Position) =>
    reduce[0] === 0 || reduce[1] === 0
      ? [point[0], point[1]]
      : [(reduce[0] + point[0]) / 2, (reduce[1] + point[1]) / 2];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const centerCoordinates: centroidType = data.features.reduce((acc: centroidType, cur: Feature<LineString, any>) => {
    const [lng, lat] = cur?.geometry?.coordinates.reduce(reduceCoordinate, [0, 0]) || [0, 0];
    return !acc
      ? {
        maxLng: lng,
        maxLat: lat,
        minLng: lng,
        minLat: lat,
      }
      : {
        maxLng: acc.maxLng > lng ? acc.maxLng : lng,
        maxLat: acc.maxLat > lat ? acc.maxLat : lat,
        minLng: acc.minLng < lng ? acc.minLng : lng,
        minLat: acc.minLat < lat ? acc.minLat : lat,
      };
  });

  const centerFeature = centroid({
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [centerCoordinates.maxLng, centerCoordinates.maxLat],
          [centerCoordinates.maxLng, centerCoordinates.minLat],
          [centerCoordinates.minLng, centerCoordinates.maxLat],
          [centerCoordinates.minLng, centerCoordinates.minLat],
        ],
      ],
    },
  });

  return centerFeature?.geometry?.coordinates;
};
