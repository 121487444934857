
import { findIndexInArrayOfItemArray, updateItemInArrayByIndex } from "shared/utils/array";
import { KeyZoneActionType, KeyZonesAction } from "store/actions/keyzone-actions/keyzone-actions.types";
import { IInitialKeyZoneState } from "./key-zone.types";
import { initialState } from "./key-zone.constants";

export const keyZoneReducer = (state: IInitialKeyZoneState = initialState, action: KeyZonesAction) => {
  switch (action.type) {

    case KeyZoneActionType.SET_KEY_ZONES: {
      const { status, pageNum, data } = action.payload;
      const { items, totalCount } = data;

      const itemsDraft = [...(state[status]?.items ?? [])];
      itemsDraft[pageNum] = items;

      return {
        ...state,
        [status]: { totalCount, items: itemsDraft },
      };
    }

    case KeyZoneActionType.SET_KEY_ZONE_FILTERS: {
      const { payload } = action;
      return { ...state, keyZoneFilters: payload };
    }

    case KeyZoneActionType.SET_KEY_ZONE_BY_VERSION_ID: {
      const { payload } = action;

      return {
        ...state,
        keyZoneByVersionId: {
          ...state.keyZoneByVersionId,
          [payload.versionId]: payload.data,
        },
      };
    }

    case KeyZoneActionType.SET_KEY_ZONE_SEARCH_SUBSTRING: {
      const { payload } = action;

      return { ...state, keyZoneSearchSubstring: payload };
    }

    case KeyZoneActionType.SET_KEY_ZONE_ACTIVE_FILTER: {
      const { payload } = action;

      return {
        ...state,
        keyZoneActiveFilter: payload,
      };
    }

    case KeyZoneActionType.SET_ACTIVE_KEY_ZONE_ID: {
      const { payload } = action;

      return { ...state, keyZoneActiveItem: payload };
    }

    case KeyZoneActionType.SET_KEY_ZONE_MAP_ACTIVE_ITEM: {
      const { payload } = action;

      return { ...state, keyZoneMapActiveItem: payload };
    }

    default:
      return state;
  }
};
