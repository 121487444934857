import { withNeutralColor } from "shared/utils";
import styled from "styled-components/macro";
import { withCBColor } from "@megapolis/uikit/utils";
import { withPrimaryAccentColor } from "../../../../shared/utils";

export const Header = styled.span`
  padding: 16px 16px 0 24px;
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const LabelContainer = styled.span`
  margin-bottom: 8px;
`;

export const Label = styled.span`
font-weight: 500;
line-height: 16px;
padding: 0 8px 4px 8px;
width: 100%;
`;

export const Container = styled.section`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  user-select: none;
  &:hover {
    border-color: ${withCBColor("secondary", "active")};
  }
  &:active {
    background-color: rgba(221, 231, 237, 0.24);
    border-color: ${withCBColor("secondary", "active")};
  }
`;

export const UploadFileBtn = styled.button`
  box-sizing: border-box;
  appearance: none;
  width: 100%;
  border: 1px solid ${withCBColor("secondary", "active")};
  color: ${withPrimaryAccentColor()};
  background-color: transparent;
  cursor: pointer;
  height: 32px;
  display: flex;
  text-align: center;
  border-radius: 6px;
  span {
    margin: auto;
  }
`;


export const Heading = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding-left: 8px;
`;

export const InfoCards = styled.div`
  display: grid;
  width: 100%;
`;

export const MainBlock = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 8px 16px;
  overflow: auto;
`;

export const UploadAreaCaption = styled.span`
  display: block;
  color: ${withNeutralColor("d4")};
  padding: 4px 8px;
  margin-top: 8px;
`;

export const NoZoneBlock = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px 16px 8px;
  gap: 8px;

  /* CB-Transparent-Info-[Light] */

  background: rgba(214, 232, 251, 0.6);
  /* Content-Quinary-[Light] */

  border: 1px solid rgba(3, 40, 76, 0.1);
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;


export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  border-top: 1px solid ${withNeutralColor("l2")};
  width: 100%;
  padding: 16px;
  button:first-child {
    margin-right: 8px;
  }
`;

export const BlockLabel = styled.span`
  font-weight: 500;
  padding-left: 8px;
`;

export const Block = styled.div`
  margin-top: 8px;
`;

export const TwoFieldBlock = styled(Block)`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
    flex-basis: 150px;
  }
`;

export const Chips = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 8px 0;
  button {
    color: ${withNeutralColor("d4")};
    margin: 0 8px 8px 0;
  }
`;

export const UploadAreaLabel = styled.span`
  display: block;
  font-weight: 500;
  padding-left: 8px;
  margin-bottom: 8px;
`;
