import { TabValues } from "../../../features";
import { IInitialDriverState } from "./driver.types";

export const initialState: IInitialDriverState = {
  driverByVersionId: {},
  driverActiveFilter: TabValues.ACTIVE,
  driverSearchSubstring: "",
  activeDriverId: "",
  driverFilters: {
    ContractorIds: null,
    RouteIds: null,
    DepotIds: null,
  },
  [TabValues.ACTIVE]: null,
  [TabValues.INACTIVE]: null,
};
