import React from "react";
import styled from "styled-components/macro";
import { BlockHeader, File } from "components";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const Items = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
`;

const Header = styled.span`
  font-weight: 600;
`;

const FilesOfDetailComponent = () => (
  <Container>
    <BlockHeader left={<Header>Файлы</Header>} />
    <Items>
      <File extension="png" title={"xxx"} isDisabled={true} caption="165.7 KB" />
      <File extension="pdf" title={"xxx"} isActive={true} caption="165.7 KB" />
      <File extension="jpg" title={"xxx"} isLoading={true} isActive={false} caption="165.7 KB" />
      <File extension="docx" title={"xxx"} isActive={false} canBeCancel={true} caption="165.7 KB" />
      <File extension="mp4" title={"xxx"} isActive={false} isError={true} caption="165.7 KB" />
      <File extension="txt" title={"xxx"} isActive={false} caption="165.7 KB" />
    </Items>
  </Container>
);

export const FilesOfDetail = FilesOfDetailComponent;
