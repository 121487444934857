import { RouteVersion } from "api";
import { Add24BlueIcon } from "assets/icons";
import { FilterWindow, IconButton } from "components";
import { RouteCard } from "features/cards";
import { VehicleTypeCaptionMap, getVehicleType } from "models";
import { useState, useCallback, useEffect } from "react";
import { ListRowRenderer, AutoSizer, List } from "react-virtualized";
import { labels } from "shared/constants";
import { SearchRouteContent } from "./filter-search-route-content";
import * as Markup from "./route-filter-element.style";

export const RouteFilterElement = (props: { arr?: RouteVersion[], update: (arr: RouteVersion[]) => void }) => {
  const { arr, update } = props;
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState<RouteVersion[]>(arr || []);

  const toggle = useCallback((visible_: boolean) => {
    setVisible(visible_);
  }, []);

  useEffect(() => {
    if (arr?.length === 0) {
      setSelected([]);
    }
  }, [arr]);

  const itemRender = useCallback((item: RouteVersion) => {
    if (item) {
      const { versionId = "", transportType, number, name, kind, contractor, appearance = {
        backgroundColor: "#FFFFFF",
        borderColor: "#000000",
        foregroundColor: "#FFFFFF",
      } } = item;
      const vehicleData = {
        contractorName: contractor?.name || labels.noValue,
        routeType: kind ? `Тип трассы ${kind}` : "",
        transportType: `Тип транспорта ${VehicleTypeCaptionMap[getVehicleType(transportType)]}`,
      };

      return (
        <RouteCard
          id={String(versionId)}
          routeName={name || "Трасса отстутствует"}
          routeNumber={String(number)}
          routeIconColor={appearance?.backgroundColor}
          routeNumberColor={appearance?.foregroundColor}
          routeBorderColor={appearance?.borderColor}
          isActive={false}
          vehicleData={vehicleData}
          onClick={() => { }}
        />
      );
    }
    return <></>;
  }, []);

  // eslint-disable-next-line consistent-return
  const rowRenderer: ListRowRenderer = useCallback(({ index, style, key }) => {
    if (selected) {
      const item = selected[index];
      const updatedStyles = {
        ...style,
        height: (Number(style.height)) - 8,
        top: ((Number(style.top) || 0) + 8 / 2),
      };

      return <div style={updatedStyles} key={key} >
        {itemRender(item)}
      </div>;
    }
  }, [selected, itemRender]);

  return (<>
    <FilterWindow headerText="Маршруты" isShown={visible} hide={() => toggle(false)} modalContent={<SearchRouteContent filterRoutes={selected || []} onSubmit={(filtredRoute) => {
      toggle(false);
      setSelected(filtredRoute);
      update(filtredRoute || []);
    }} />} />
    <Markup.LabelButton><Markup.Label>{labels.routes}</Markup.Label><IconButton content="icon" icon={<Add24BlueIcon />} onClick={() => toggle(true)} /></Markup.LabelButton>
    <div style={{ width: "100%", height: (selected.length > 10 ? 6 : selected.length) * 46 }}>
      <AutoSizer>
        {({ width }) => (
          <List
            height={(selected.length > 10 ? 6 : selected.length) * 46}
            ref={undefined}
            rowCount={selected.length}
            rowHeight={46 + 8}
            rowRenderer={rowRenderer}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  </>);

};
