import React, { useCallback, useState } from "react";
import { FormField } from "features/detail-panels/components";
import { TestButton } from "components";
import { labels } from "shared/constants";
import { ContractorMultiSelectField } from "components/common/select-with-source";
import { Content } from "@megapolis/uikit/button/button.types";
import { Kind, Priority } from "components/ui/buttontest/button.types";
import { useDispatch } from "react-redux";
import { depotActions, routeActions } from "store/actions";
import { useRouteFiltersSelector } from "store/selectors/route-selectors";
import { RouteVersion } from "api";
import { VehicleTypesFilter } from "features/filter-element";
import * as Markup from "./depot-filter-content.style";
import { RouteFilterElement } from "../route-filter-element";

export const DepotFilterContent = (props: { hide: () => void }) => {

  type Filter = {
    ContractorIds?: string | null,
    TransportTypes?: string | null,
    RouteIds?: string | null,
    RouteFilterElements?: RouteVersion[],
  };

  const initialFIlter = {
    ContractorIds: null,
    TransportTypes: "",
    RouteIds: null,
    RouteFilterElements: undefined,
  };

  const dispatch = useDispatch();
  const filters = useRouteFiltersSelector();
  const [filter, setFilter] = useState<Filter>(filters);

  const handleSubmit = useCallback(() => {
    dispatch(routeActions.setFilters(filter));
    props.hide();
  }, [filter]);

  const handleReset = useCallback(() => {
    dispatch(depotActions.setFilters(initialFIlter));
    setFilter(initialFIlter);
  }, [initialFIlter]);

  const updateValue = useCallback(<P extends keyof Filter>(name: P, value: Filter[P] | null) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  }, []);

  return (


    <><Markup.Container>
      <FormField>
        <VehicleTypesFilter TransportTypes={filter.TransportTypes || ""} field={"TransportTypes"} updateValue={updateValue} />
      </FormField>
      <FormField>
        <ContractorMultiSelectField
          value={filter.ContractorIds ? filter.ContractorIds?.split(",") : null}
          onChange={el => {
            const result = el.map(({ value }) => value);
            updateValue("ContractorIds", result.toString());
          }}
          label={labels.contractor}
          cyData="route-contractor"
          errorText={undefined}
          hasError={false}
        />
      </FormField>
      <FormField>
        <RouteFilterElement arr={filter.RouteFilterElements || []} update={(arr) => updateValue("RouteFilterElements", arr)} />
      </FormField>
    </Markup.Container>
    <Markup.Footer>
      <TestButton content={Content.Text} priority={Priority.Tertiary} text="Применить фильтр" onClick={handleSubmit} cyData="Filter" />
      <TestButton kind={Kind.Danger} content={Content.Text} priority={Priority.Tertiary} text="Сброс" onClick={handleReset} cyData="Filter" />
    </Markup.Footer>
    </>);
};
