import React, { memo } from "react";
import styled from "styled-components/macro";
import { Heading } from "components/ui";
import { withNeutralColor } from "shared/utils";

const Caption = styled.span`
  color: ${withNeutralColor("d1")};
`;

export type RouteTimeHeaderProps = {
  title: string;
  caption: string;
};

const RouteTimeHeaderComponent = ({ title, caption }: RouteTimeHeaderProps) => (
  <Heading.H5>
    {title} <Caption>({caption})</Caption>
  </Heading.H5>
);

export const RouteTimeHeader = memo(RouteTimeHeaderComponent);
