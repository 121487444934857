import { APP_ENV } from "app-env";
import mapboxgl, { AnyLayer, VectorSourceImpl } from "mapbox-gl";

import { useCallback, useRef } from "react";
import { MapLayerNames } from "../utils/consts/map.constants";
import { getKeyZoneTilesLayers } from "./shema";

export const keyZoneLayers = [MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID];

type TimerState = { [name: string]: NodeJS.Timeout };

export const useTimer = () => {
  const state = useRef<TimerState>({} as TimerState);

  const addTimer = useCallback((map: mapboxgl.Map | undefined, nameController: string, callback: () => void) => {
    if (state.current?.[nameController]) clearTimeout(state.current?.[nameController]);
    if (!map?.isStyleLoaded()) {
      // eslint-disable-next-line no-return-assign
      return (state.current[nameController] = setTimeout(() => {
        addTimer(map, nameController, callback);
      }, 300));
    }
    callback();
    return null;

  }, []);

  const removeTimer = useCallback((nameController: string) => {
    if (state.current?.[nameController]) clearTimeout(state.current?.[nameController]);
  }, []);

  return {
    state: state.current,
    addTimer,
    removeTimer,
  };
};

export const getVisibilityKeyZoneTiles = (map?: mapboxgl.Map): boolean => {
  if (!map) return false;
  const arr = keyZoneLayers.every(
    (item) => {
      if (map.getLayer(item)) {
        return map.getLayoutProperty(item, "visibility") === true;
      }
      return false;
    });
  return arr;
};

export const setVisibility = (map: mapboxgl.Map, visible = false, layerName: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  map && map?.getLayer(layerName) && map.setLayoutProperty(layerName, "visibility", visible ? "visible" : "none");
};

export const keyZoneTiles = (map?: mapboxgl.Map) => {
  if (!map) return;
  const tiles = [`${APP_ENV.REACT_APP_BASE_URL}/KeyZone/tile/{x}/{y}/{z}`];
  const sourceId = MapLayerNames.TILE_SET_KEYZONE_SOURCE_ID;
  const prevSource = map.getSource(sourceId) as VectorSourceImpl;

  if (prevSource) {
    if (prevSource.tiles?.[0] !== tiles[0]) {
      prevSource.setTiles(tiles);
      prevSource.tiles = tiles;
    }
  } else {
    map.addSource(sourceId, {
      type: "vector",
      tiles,
    });
  }


  const layers = getKeyZoneTilesLayers();

  layers.forEach((layer: AnyLayer) => map.addLayer(layer));

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  map.on("mouseover", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, (e: any) => {
    if (e.features) {
      const address = e.features[0].properties.name ? e.features[0].properties.name : "";
      const coordinates = e.lngLat.wrap();
      popup.setLngLat(coordinates).setHTML(address).addTo(map);
      // const description = e?.features[0].properties.description;
    }


    // eslint-disable-next-line no-param-reassign
    map.getCanvasContainer().style.cursor = "pointer";
  });
  map.on("mouseleave", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvasContainer().style.cursor = "";
    popup.remove();
  });
};