import styled from "styled-components";
import { withCBColor } from "@megapolis/uikit/utils";
import { withPrimaryAccentColor } from "../../../shared/utils";

export const Wrapper = styled.div`
  padding: 15px 0 0 0;
  width: 100%;
`;

export const Container = styled.section`
  min-height: 164px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  border: 1px dashed ${withCBColor("secondary", "hover")};
  padding: 40px 16px;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  user-select: none;
  &:hover {
    border-color: ${withCBColor("secondary", "active")};
  }
  &:active {
    background-color: rgba(221, 231, 237, 0.24);
    border-width: 2px;
    border-color: ${withCBColor("secondary", "active")};
  }
`;

export const FormField = styled.input`
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  &:focus {
    outline: none;
  }
`;

export const DragDropText = styled.p`
  margin-top: 0;
  text-align: center;
`;

export const UploadFileBtn = styled.button`
  box-sizing: border-box;
  appearance: none;
  border: 1px solid ${withCBColor("secondary", "active")};
  color: ${withPrimaryAccentColor()};
  background-color: transparent;
  cursor: pointer;
  height: 32px;
  display: flex;
  text-align: center;
  border-radius: 6px;
  span {
    margin: auto;
  }
`;

export const FilePreviewContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const PreviewList = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const PreviewContainerGrid = styled.div`
  width: calc(100% - 8px);
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 50%);
`;

export const PreviewContainer = styled.section`
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const ImagePreview = styled.div`
  border-radius: 6px;
  width: 100%;
  height: 125px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
`;
