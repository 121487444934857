import { MouseEventHandler, useMemo } from "react";
import { getScheduleTypesCaptionMapShort } from "models/schedule";
import { ReisSVG } from "assets/icons";
import styled from "styled-components";
import { useGetScheduleItemsByVersion } from "../schedule-details.data";
import { labels } from "../../../../../shared/constants";

export const useGetScheduleInfoCardData = () => {
  const { item } = useGetScheduleItemsByVersion();

  const listItems = useMemo(
    () => {
      const line = item?.daysOfWeek.map((el)=>getScheduleTypesCaptionMapShort(el)).join(",");
      if (item?.holiday) {
        return `${line}; праздничные дни`;
      }
      return line;
    },
    [item],
  );

  const infoCards = [
    {
      title: "Версия",
      text: item?.versionId || labels.noValue,
    },
    {
      title: "Статус",
      text: item?.status === "Active" ? "Активное" : "Неактивное",
    },
    {
      title: "Период работы",
      text: labels.noValue,
    },
    {
      title: "Дни недели",
      text: listItems || labels.noValue,
    },
    {
      title: "Выходы",
      text: item?.launchCount || labels.noValue,
    },
    {
      title: "Рейсы",
      text: item?.tripCount || labels.noValue,
    },
  ];

  return { infoCards };
};

export const useGetScheduleStopTimeData = (onClick: (el:any) => any ) => {
  const ReisIcon = styled(ReisSVG)`
  vertical-align: sub;
  margin-right: 6px;
`;

  const memoDirection: { Forward : string, Backward : string } = {
    Forward : "Прямой",
    Backward : "Обратный",
  };

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Рейс",
            accessor: "sequenceNumber",
            Cell: (cell: any) => (<div onClick={()=> onClick(cell.data[0])}><ReisIcon/>{`Рейс ${cell.value}`}</div>),
          },
          {
            Header: "Вариант",
            accessor: "typeCode",

          },
          {
            Header: "Направление",
            accessor: "direction",
            Cell: ({ cell: { value } }: any) => (<>{memoDirection[value as keyof { Forward : string, Backward : string }]}</>),

          },
          {
            Header: "Начало",
            accessor: "tripBegin",

          },
          {
            Header: "Завершение",
            accessor: "tripEnd",

          },
          {
            Header: "Длительность",
            accessor: "tripDuration",

          },
          {
            Header: "Тип ТС",
            accessor: "tripInterval",

          },
          {
            Header: "Интервал",
            accessor: "capacity",

          },
        ],
      },
    ], []);

  return { columns };
};
