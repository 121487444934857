import React, { memo, useCallback } from "react";
import { Card } from "components/ui";
import { Contractor24Icon } from "assets/icons";

export type ContractorCardProps = {
  /** Идентификатор */
  id: number;
  /** Наименование перевозчика */
  shortName: string;
  /** Тип перевозчика */
  versionId?: number;
  /** Признак активности карточки */
  isActive?: boolean;
  /** Обработчик нажатия на карточку */
  onClick?: (id: string) => void;
};

const ContractorCardComponent = (props: ContractorCardProps) => {
  const { id, shortName, isActive = false, onClick } = props;

  const handleClick = useCallback(() => onClick?.(id.toString()), [onClick, id]);

  return <Card title={shortName} isActive={isActive} icon={<Contractor24Icon />} onClick={handleClick} />;
};

export const ContractorCard = memo(ContractorCardComponent);
