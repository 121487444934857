import { DatePicker, Toggle } from "@megapolis/uikit";
import { getPavilionHolders } from "api";
import { Box, Divider, Heading, InfoCard, latScheme, UploadArea } from "components";
import { Field } from "components/common/form/field";
import { PavilionHoldersSelectField } from "components/common/select-with-source";
import { DetailsBlock, FormField } from "features/detail-panels/components";
import { PanelForm } from "features/panel-form";
import { Loader } from "megapolis-uikit-latest/loader";
import { getDateToBack } from "models/date.utils";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useRoute, useRouter } from "react-router5";
import { labels } from "shared/constants";
import { PAGE_ROUTES } from "shared/definitions";
import { removeMarker } from "shared/helpers/add-marker";
import { composeValidators, isCorrectEndDate, isCorrectStartDate, isMatchesTo, isRequired } from "shared/utils/validator";
import { RootState } from "store/types";
import { NewStopStationForm, useNewStopLogic } from "./new-stop-station-panel.logic";
import * as Markup from "./new-stop-station-panel.styles";

export const NewStopStationPanel = (props: { isNew?: boolean, changeVersion?: boolean }) => {
  const { isNew = true, changeVersion = false } = props;
  const referSetValue = useRef<(name: keyof NewStopStationForm, value: NewStopStationForm[keyof NewStopStationForm]) => void>();
  const { form, createStop, editStop, editStopVersion, isLoading, clearUp, startDate: oldStartDate, endDate: oldEndDate, files, handleFiles } = useNewStopLogic(isNew, referSetValue.current);
  const [strtDate, setStrtDate] = useState<string | null>("");
  const [pavilion, setPavilion] = useState<{
    label: string,
    value: string,
  }>({ value: "", label: "" });
  const [endDate, setEndDate] = useState<string | null>("");
  const { stopActiveItem } = useSelector((state: RootState) => ({
    stopActiveItem: state.stop.stopActiveItem,
  }));
  const { maskPlaceholder: latPlaceHolder, regex: latRegex } = useMemo(() => latScheme, []);

  const title = isNew ? labels.createStop : labels.editStop;

  const router = useRouter();
  const { previousRoute } = useRoute();

  const [pavilionHoldersChips, setPavilionHoldersChips] = useState<{ id: string, label: string }[]>([]);

  const getPavilionHoldersChips = useCallback(async () => {
    const pavilionHolders = await getPavilionHolders();
    const result = pavilionHolders.map(item => ({ id: item.id.toString(), label: item.name }));
    setPavilionHoldersChips(result);
  }, []);

  useEffect(() => {
    getPavilionHoldersChips();
  }, []);

  const handleClosePanel = useCallback(() => {
    if (previousRoute) {
      // router.navigate(previousRoute.name, previousRoute.params);
      router.navigate(PAGE_ROUTES.STOP_STATION, { id: stopActiveItem });
    } else {
      router.navigate(PAGE_ROUTES.STOP_STATIONS);
    }
  }, [previousRoute, router, stopActiveItem]);

  

  const handleSubmit = useCallback((values: any) => {
    if (isNew) {
      createStop(values);
    } else if (changeVersion) {
      editStopVersion(values);
    } else {
      editStop(values);
    }
  }, [changeVersion, createStop, editStop, editStopVersion, isNew]);

  const addDays = useCallback((date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }, []);

  useEffect(() => {
    if (isNew) clearUp();
    return () => {
      clearUp();
      removeMarker();
    };
  }, [isNew]);

  const valueForSelect = useCallback((id: number | undefined) => {
    if (id) {
      const option = pavilionHoldersChips.find((chip) => chip.id === id.toString());
      if (option) {
        return { value: option.id, label: option.label };
      }
      return { value: "", label: "" };
    }
    return { value: "", label: "" };
  }, [pavilionHoldersChips]);

  const scheme = useMemo(() => ({
    name: isRequired("Введите имя"),
    address: isRequired("Введите адрес"),
    // street: isRequired("Введите улицу"),
    // lat: composeValidators(isMatchesTo("Неправильный формат", latRegex)),
    // lng: composeValidators(isMatchesTo("Неправильный формат", latRegex)),
    startDate: isCorrectStartDate("Дата начала действия трассы не может быть больше даты окончания", endDate || ""),
    endDate: isCorrectEndDate("Дата окончания действия трассы не может быть меньше даты начала", strtDate || ""),
  }), [endDate, latRegex, strtDate]);

  return (
    <PanelForm
      title={title}
      initialValues={form}
      validationScheme={scheme}
      onSubmit={handleSubmit}
      onCancel={handleClosePanel}
      isLoading={isLoading}
    >
      {
        ({ names, values, updateValue: setValue, errors }) => {
          referSetValue.current = setValue;
          return (
            <>
              <Field
                values={values}
                updateValue={setValue}
                name={names.name}
                errors={errors}
                type="text"
                label={labels.driverName}
                cyData="stop-station-name-add" />
              <Field
                name={names.address}
                label={"Адрес"}
                placeholder="Введите"
                errors={errors}
                cyData="stop-station-address-add"
                updateValue={setValue}
                values={values}
                type="text"
              />
              {/* <Field
                name={names.pavilionBalanceHolder}
                label={"Балансодержатель"}
                placeholder="Введите"
                errors={errors}
                cyData="stop-station-balanceholder-add"
                updateValue={setValue}
                values={values}
                type="text"
              />

              <Heading.H5>Наличие павильон</Heading.H5>
              <Markup.Label>{"Балансодержатель"}</Markup.Label> */}
              <FormField>
                <PavilionHoldersSelectField value={valueForSelect(values.pavilionBalanceHolderId)} label={<Markup.Label>{"Балансодержатель"}</Markup.Label>} cyData="stop-station-balanceholder-add" onChange={({ value, label }) => { setValue(names.pavilionBalanceHolderId, +value); setPavilion({ value, label }); }} />
              </FormField>
              <Field
                values={values}
                updateValue={setValue}
                name={names.regNum}
                errors={errors}
                type="text"
                label={labels.driverNumber}
                cyData="stop-station-number-add" />

              <FormField>
                <Markup.Label>{"Состав элементов"}</Markup.Label>
                <Markup.InfoCards>
                  <InfoCard>
                    <Toggle value={values.hasPavilion || false} onChange={(e: string | number | boolean | null) => {
                      setValue("hasPavilion", e);
                    }}><Markup.LabelContainer>{"Наличие павильона"}</Markup.LabelContainer> </Toggle>
                  </InfoCard>
                  <InfoCard>
                    <Toggle value={values.light || false} onChange={(e: string | number | boolean | null) => { setValue("light", e); }}><Markup.LabelContainer>{"Освещение"}</Markup.LabelContainer> </Toggle>
                  </InfoCard>
                  <InfoCard>
                    <Toggle value={values.pedestrianPass || false} onChange={(e: string | number | boolean | null) => { setValue("pedestrianPass", e); }}><Markup.LabelContainer>{"Пешеходный переход"}</Markup.LabelContainer> </Toggle>
                  </InfoCard>
                  <InfoCard>
                    <Toggle value={values.wasteBin || false} onChange={(e: string | number | boolean | null) => { setValue("wasteBin", e); }}><Markup.LabelContainer>{"Урна"}</Markup.LabelContainer> </Toggle>
                  </InfoCard>
                  <InfoCard>
                    <Toggle value={values.forecastBoard || false} onChange={(e: string | number | boolean | null) => { setValue("forecastBoard", e); }}><Markup.LabelContainer>{"ТПП"}</Markup.LabelContainer> </Toggle>
                  </InfoCard>
                  <InfoCard>
                    <Toggle value={values.alarmButton || false} onChange={(e: string | number | boolean | null) => { setValue("alarmButton", e); }}><Markup.LabelContainer>{"Кнопка 112"}</Markup.LabelContainer> </Toggle>
                  </InfoCard>
                  <InfoCard>
                    <Toggle value={values.wiFi || false} onChange={(e: string | number | boolean | null) => { setValue("wiFi", e); }}><Markup.LabelContainer>{"WI-FI"}</Markup.LabelContainer> </Toggle>
                  </InfoCard>
                  <InfoCard>
                    <Toggle value={values.usbCharge || false} onChange={(e: string | number | boolean | null) => { setValue("usbCharge", e); }}><Markup.LabelContainer>{"USB-зарядка"}</Markup.LabelContainer> </Toggle>
                  </InfoCard>
                </Markup.InfoCards>
              </FormField>
              <FormField>
                <Markup.Label>{"Дата начала действия остановки"}</Markup.Label>
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      const backDate = getDateToBack(e);
                      setStrtDate(backDate);
                      setValue("startDate", backDate);
                    } else {
                      setStrtDate(null);
                      setValue("startDate", null);
                    }
                  }}
                  minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                  maxDate={oldEndDate ? addDays(oldEndDate, 1) : undefined}
                  errorText={errors?.startDate ?? undefined}
                  hasError={!!errors?.startDate}
                  value={values?.startDate ? new Date(values.startDate) : null}
                />
              </FormField>
              <FormField>
                <Markup.Label>{"Дата окончания действия остановки"}</Markup.Label>
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      const backDate = getDateToBack(e);
                      setEndDate(backDate);
                      setValue("endDate", backDate);
                    } else {
                      setEndDate(null);
                      setValue("endDate", null);
                    }
                  }}
                  errorText={errors?.endDate || ""}
                  hasError={!!errors?.endDate}
                  minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                  value={values?.endDate ? new Date(values.endDate) : null}
                />
              </FormField>
              <Field
                label={"Широта"}
                name={names.lat}
                type="text"
                placeholder={latPlaceHolder}
                errors={errors}
                data-cypress="depot-lat-add"
                values={values}
                updateValue={setValue}
              />
              <Field
                label={"Долгота"}
                name={names.lng}
                type="text"
                placeholder={latPlaceHolder}
                errors={errors}
                data-cypress="depot-lng-add"
                values={values}
                updateValue={setValue}
              />
              <Divider margin="16px 0" />
              <DetailsBlock header={<Box>Файлы</Box>}>
                {
                  isLoading ? (<Box justifyContent={"center"}><Loader /></Box>) : (<Box direction="column">
                    <UploadArea
                      filesInput={files}
                      multiple={true}
                      handleChangeFiles={(el) => {
                        handleFiles(el);
                      }}
                    />
                  </Box>)
                }

              </DetailsBlock>
            </>
          );
        }
      }
    </PanelForm>
  );
};
