import React, { memo } from "react";
import { Plank } from "../plank";
import { Point } from "../point";
import type { IndicatorPropsBase } from "../types";
import * as Markup from "./start-indicator.styles";

export type StartIndicatorProps = IndicatorPropsBase;

const StartIndicatorComponent = (props: StartIndicatorProps) => {
  const { plankColor, pointColor, className } = props;
  return (
    <Markup.Container className={className}>
      <Point color={pointColor} />
      <Markup.PlankWrapper>
        <Plank color={plankColor} />
      </Markup.PlankWrapper>
    </Markup.Container>
  );
};

export const StartIndicator = memo(StartIndicatorComponent);
