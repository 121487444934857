import { withCBColor } from "@megapolis/uikit/utils";
import styled from "styled-components/macro";
import { prop } from "styled-tools";
import type { DividerOrientation } from "./divider.types";

type DividerProps = {
  orientation: DividerOrientation;
  thickness?: string;
  margin?: string;
  padding?: string;
};

export const Divider = styled.hr<DividerProps>`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  border: none;
  margin: ${prop("margin")};
  padding: ${prop("padding")};
  background-color: ${prop("color", withCBColor("secondary", "active"))};
  ${({ orientation, thickness }) =>
    orientation === "horizontal"
      ? `
    width: 100%;
    height: ${thickness || "1px"};
  `
      : `
    width: ${thickness || "1px"};
    height: 100%;
  `}
`;
