import React from "react";
import router from "router";
import { PAGE_ROUTES } from "shared/definitions/routes";
import { ContractorMainIcon, DepotMainIcon, DriverMainIcon, KeyZoneMainIcon, RouterMainIcon, ScheduleMainIcon, StopMainIcon, VehicleMainIcon } from "assets/icons";
import { APP_ENV } from "app-env";

const handleClickRoute = router.navigate;

export const devRoutes = [
  {
    title: "Маршруты",
    icon: <RouterMainIcon />,
    page: [PAGE_ROUTES.ROUTES, PAGE_ROUTES.ROUTE],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Остановки",
    icon: <StopMainIcon />,
    page: [PAGE_ROUTES.STOP_STATIONS, PAGE_ROUTES.STOP_STATION],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Транспортные средства",
    icon: <VehicleMainIcon />,
    page: [PAGE_ROUTES.VEHICLES, PAGE_ROUTES.VEHICLE],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Водители",
    icon: <DriverMainIcon />,
    page: [PAGE_ROUTES.DRIVERS, PAGE_ROUTES.DRIVER],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Парки",
    icon: <DepotMainIcon />,
    page: [PAGE_ROUTES.DEPOTS, PAGE_ROUTES.DEPOT],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Перевозчики",
    icon: <ContractorMainIcon />,
    page: [PAGE_ROUTES.CONTRACTORS, PAGE_ROUTES.CONTRACTOR],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Зоны",
    icon: <KeyZoneMainIcon />,
    page: [ PAGE_ROUTES.KEY_ZONES, PAGE_ROUTES.KEY_ZONE],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Расписание",
    icon: <ScheduleMainIcon />,
    page: [PAGE_ROUTES.SCHEDULES, PAGE_ROUTES.SCHEDULE],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
];

export const prodRoutes = [
  {
    title: "Маршруты",
    icon: <RouterMainIcon />,
    page: [PAGE_ROUTES.ROUTES, PAGE_ROUTES.ROUTE],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Остановки",
    icon: <StopMainIcon />,
    page: [PAGE_ROUTES.STOP_STATIONS, PAGE_ROUTES.STOP_STATION],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Транспортные средства",
    icon: <VehicleMainIcon />,
    page: [PAGE_ROUTES.VEHICLES, PAGE_ROUTES.VEHICLE],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Водители",
    icon: <DriverMainIcon />,
    page: [PAGE_ROUTES.DRIVERS, PAGE_ROUTES.DRIVER],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Парки",
    icon: <DepotMainIcon />,
    page: [PAGE_ROUTES.DEPOTS, PAGE_ROUTES.DEPOT],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Перевозчики",
    icon: <ContractorMainIcon />,
    page: [PAGE_ROUTES.CONTRACTORS, PAGE_ROUTES.CONTRACTOR],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
  {
    title: "Зоны",
    icon: <KeyZoneMainIcon />,
    page: [ PAGE_ROUTES.KEY_ZONES, PAGE_ROUTES.KEY_ZONE],
    action: handleClickRoute,
    counter: 0,
    isDisabled: false,
  },
];

export const routes = APP_ENV.REACT_APP_BUILD === "DEV" ? devRoutes : prodRoutes;

export default routes;
