import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { DepotCard, TabValues } from "features";
import { depotActions } from "store/actions";
import { useDepotFilterSelector, useDepotFiltersSelector, useDepotItemsSelector, useDepotSearchStringSelector } from "store/selectors/depot-selectors";
import { DepotVersion } from "../../../models";
import { DepotVersionResponce, getDepotRequest, GetDepotRequestOptions } from "../../../api";
import { PaginationCardList } from "../components/pagination-card-list";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

const DEFAULT_PAGE_SIZE = 100;

export type DepotCardListPaginationProps = {
  activeId?: string;
  RouteIds?: string;
  TransportTypes?: string;
  ContractorIds?: string;
  onClick?: (id: string) => void;
};

const DepotCardListPaginationComponent = (props: DepotCardListPaginationProps) => {
  const { activeId, onClick, ContractorIds = "", RouteIds = "", TransportTypes = "" } = props;
  const dispatch = useDispatch();
  const activeFilter = useDepotFilterSelector();
  const filters = useDepotFiltersSelector();
  const { items, totalCount } = useDepotItemsSelector(activeFilter);
  const { showDetails } = useLayoutDesignState();
  const [pageNum] = useState<number>(1);
  const searchSubString = useDepotSearchStringSelector();


  const firstItem = items[0];

  const activeIndex = useMemo(() => items.findIndex((item) => String(item.versionId) === String(activeId)), [items, activeId]);

  useEffect(() => {
    if (!firstItem) return;
    if (!activeId && firstItem?.versionId && onClick) {
      onClick(String(firstItem.versionId));
    }
  }, [activeId, firstItem, onClick]);

  const handleClick = useCallback((id: string | number) => {
    if (onClick) {
      onClick(id.toString());
      // showDetails();
    }
  }, [onClick, showDetails]);

  const params = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
  }), [pageNum]);

  const options: any = useMemo(() => {
    const fil = {
      ContractorIds: ContractorIds || filters.ContractorIds,
      TransportTypes: TransportTypes || filters.TransportTypes,
      RouteIds: RouteIds || filters.RouteFilterElements?.map(el => el.routeId).join(",") || undefined,
    };
    return {
      isActive: activeFilter === TabValues.ACTIVE,
      SearchSubstring: searchSubString || undefined,
      ...fil,
    };
  }, [filters, ContractorIds, TransportTypes, RouteIds, activeFilter, searchSubString]);

  const handleSetItems = useCallback((response: DepotVersionResponce, pageParams?: GetDepotRequestOptions) => {
    dispatch(depotActions.setDepot({
      data: response,
      status: activeFilter,
      pageNum: pageParams?.pageNum || -1,
    }));
  }, [activeFilter, dispatch]);

  const itemRender = useCallback((item: DepotVersion & { depotId: number }) => {
    const { versionId, name, depotId, ...rest } = item;

    return (
      <DepotCard
        {...rest}
        name={name}
        key={versionId}
        id={versionId}
        isActive={String(activeId) === String(versionId)}
        onClick={handleClick}
      />
    );
  }, [handleClick, activeId]);

  return (
    <PaginationCardList
      itemRender={itemRender}
      options={options}
      params={params}
      fetcher={getDepotRequest}
      setItems={handleSetItems}
      items={items}
      totalCount={totalCount}
      scrollTo={activeIndex}
    />
  );
};

export const DepotCardListPagination = memo(DepotCardListPaginationComponent);
