import axios, { AxiosRequestConfig, AxiosRequestHeaders, Method } from "axios";
import { APP_ENV } from "../app-env";

export const API_DOMAIN = `${APP_ENV.REACT_APP_BASE_URL}`;

export function apiResponseTreatment<T>(response: T): Promise<T> {
  return new Promise((resolve) => {
    resolve(response);
  });
}

export function invoke<T = unknown>(
  method: Method,
  name: string,
  params: { [key: string]: string },
  options?: AxiosRequestConfig,
  body?: { [key: string]: string },
): Promise<T> {
  return new Promise<T>((resolve, reject) => {

    const headers: AxiosRequestHeaders = {
      "content-type": "application/json",
      "Authorization": "",
    };

    const accessToken = localStorage.getItem("Authorization") ?? "";
    if (accessToken.length) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const apiEntry = options?.baseURL || API_DOMAIN;

    const config: AxiosRequestConfig = {
      baseURL: apiEntry,
      method,
      headers,
      data: body || null,
      params: params || null,
      url: `/${name}`,
      ...options,
    };

    axios(config).then((response) => {
      const { data } = response;
      switch (response.status) {
        case 201:
        case 200:
        case 204:
          resolve(data as T);
          break;
        default:
          reject(data);
          break;
      }
    }).catch((error) => {

      if (!axios.isCancel(error)) {
        reject(error);
      }

    });
  });
}

export function get<T = unknown>(name: string, params = {}, options?: AxiosRequestConfig) {
  return invoke<T>("GET", name, params, options);
}

export function post<T = unknown>(name: string, params = {}, body = {}, options?: AxiosRequestConfig) {
  return invoke<T>("POST", name, params, options, body);
}

export function put<T = unknown>(name: string, params = {}, body = {}, options?: AxiosRequestConfig) {
  return invoke<T>("PUT", name, params, options, body);
}

export function patch(name: string, params = {}, options?: AxiosRequestConfig) {
  return invoke("PATCH", name, params, options);
}

export function del(name: string, params = {}, options?: AxiosRequestConfig) {
  return invoke("DELETE", name, params, options);
}
