import React  from "react";
import { SwitchIcon } from "components";
import { ReactComponent as IconStarSVG } from "assets/icons/unified/star.svg";

const UiPageContent = () => {
  const renderSwitchItems = () => (
    <div>
      <SwitchIcon icon={ <IconStarSVG /> } isActive={ true } onClick={ () => {
      } } />
      <SwitchIcon icon={ <IconStarSVG /> } isActive={ false } onClick={ () => {
      } } />
    </div>
  );

  return <>{ renderSwitchItems() }</>;
};

export const UIPage = React.memo(UiPageContent);
