import styled from "styled-components/macro";
import { Tabs } from "components/ui";

export const Container = styled.div`
  width: 100%;
`;

export const EnhancedTabs = styled(Tabs)`
  padding: 0 20px 0 24px;
`;
