import { APP_ENV } from "app-env";
import { Bus24Icon, Trolleybus24Icon, Electrobus24Icon, Tram24Icon } from "assets/icons";
import { VehicleClasses, VehicleTypes } from "models";

export const VehicleTypeCaptionMap = {
  [VehicleTypes.Bus]: "Автобус",
  [VehicleTypes.Trolleybus]: "Троллейбус",
  [VehicleTypes.Electrobus]: "Электробус",
  [VehicleTypes.Tram]: "Катер",
};

export const VehicleClassCaptionMap = {
  [VehicleClasses.ОМК]: "Особо малый",
  [VehicleClasses.МК]: "Малый",
  [VehicleClasses.СК]: "Средний",
  [VehicleClasses.БК]: "Большой",
  [VehicleClasses.ОБК]: "Особо большой",
};

const VehicleTypeArrayMoscow = [{ id: "А", label: "Автобус" }, { id: "Тб", label: "Троллейбус" }, { id: "Э", label: "Электробус" }, { id: "Тм", label: "Трамвай" }];

const VehicleTypeArraySevastopol = [{ id: "А", label: "Автобус" }, { id: "Тб", label: "Троллейбус" }, { id: "Э", label: "Электробус" }, { id: "Тм", label: "Катер" }];

const VehicleTypeArrayMoscowString = "А,Тб,Э,Тм";

const VehicleTypeArraySevastopolString = "А,Тб,Э,Тм";

export const VehicleTypeArray = APP_ENV.REACT_APP_CITY === "SEVASTOPOL" ? VehicleTypeArraySevastopol : VehicleTypeArrayMoscow;

export const VehicleTypeArrayString: string = APP_ENV.REACT_APP_CITY === "SEVASTOPOL" ? VehicleTypeArraySevastopolString : VehicleTypeArrayMoscowString;

export const VehicleClassTypeArray = [{ id: "ОМК", label: "Особо малый класс" }, { id: "МК", label: "Малый класс" }, { id: "СК", label: "Средний класс" }, { id: "БК", label: "Большой класс" }, { id: "ОБК", label: "Особо большой класс" }];

export const VehicleClassTypeArrayString = "ОМК,МК,СК,БК,ОБК";

export const VehicleTypeIconMap = {
  [VehicleTypes.Bus]: Bus24Icon,
  [VehicleTypes.Trolleybus]: Trolleybus24Icon,
  [VehicleTypes.Electrobus]: Electrobus24Icon,
  [VehicleTypes.Tram]: Tram24Icon,
};

export const getVehicleType = (type?: string) => {
  switch (type) {
    case "А":
      return VehicleTypes.Bus;
    case "Тб":
      return VehicleTypes.Trolleybus;
    case "Тм":
      return VehicleTypes.Tram;
    case "Э":
      return VehicleTypes.Electrobus;
    default:
      return VehicleTypes.Bus;
  }
};

export const getVehicleCaptionByType = (type: string): string =>
  VehicleTypeCaptionMap[type as keyof typeof VehicleTypes];

export const getVehicleClassByType = (type: string): string =>
  VehicleClassCaptionMap[type as keyof typeof VehicleClasses];


