import { getRouteRequest } from "api";
import { Option } from "components/common/select/select.types";
import React, { memo, useCallback, useEffect, useState } from "react";
import Select, { ActionMeta } from "react-select";
import { InfiniteLoader, AutoSizer, List, ListRowRenderer } from "react-virtualized";
import { RenderedRows } from "react-virtualized/dist/es/List";
import { DefaultSelectProps } from "../select-with-source.types";

const pageSize = 10;
const rowHeight = 30;
const maxListheight = pageSize * rowHeight;

export const RouteSelectFieldCustomComponent = (props: DefaultSelectProps) => {
  const { value, onChange } = props;
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [total, setTotal] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const fetchReposByPage = useCallback(async (newPage: number) => {
    setLoading(true);
    try {
      const response = await getRouteRequest({ pageNum: newPage, pageSize: pageSize + 10 });
      setCollection((arr: any) => arr.concat(response.items));
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onChange && onChange({ value: response.items[0].routeId ? response.items[0].routeId?.toString() : "", label: response.items[0].name ? response.items[0].name : "" });
      setCurrentPage(response.pageIndex ? response.pageIndex + 1 : 1);
      setTotal(response?.totalCount || 1);
      setHasNext((response.pageIndex || 1) * pageSize < response.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setCollection([]);
    fetchReposByPage(1);
  }, []);

  const options = collection.map((el: any) => ({ value: el.routeId, label: el.name }));

  const loadMoreRows = () => fetchReposByPage(currentPage);

  const onChangeSelect = (option: Option | null, actionMeta: ActionMeta<Option>) => {
    if (option && onChange)onChange(option); 
  };

  const handleScroll = useCallback((rows: RenderedRows) => {
    const { overscanStopIndex } = rows;
    if (overscanStopIndex >= collection.length - 1 && !loading && hasNext) {
      fetchReposByPage(currentPage);
    }
  }, [collection.length, loading, hasNext, fetchReposByPage, currentPage]);

  const currentListHeight = pageSize * rowHeight;
  const listHeight = collection.length > currentListHeight ? maxListheight : currentListHeight;

  const MenuList = (el: any) => {
    const childrenArray = React.Children.toArray(el.children);

    const rowRenderer: ListRowRenderer = useCallback(({ index, isScrolling, isVisible, style, ...rest }) => {
      const child = childrenArray[index];
      return (
        <div
          style={{
            borderBottom: "1px solid #ccc",
            display: "flex",
            alignItems: "center",
            ...style,
          }}
          {...rest}
        >
          {child || `${index}. Loading...`}
        </div>
      );
    }, []);
    return (
      <InfiniteLoader
        isRowLoaded={({ index }) => !!collection[index]}
        loadMoreRows={loadMoreRows}
        rowCount={total}
      >
        {({ registerChild }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={listHeight}
                onRowsRendered={handleScroll}
                ref={registerChild}
                rowCount={total}
                rowHeight={rowHeight}
                rowRenderer={rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  };

  return (
    <Select
      defaultMenuIsOpen={false}
      isLoading={loading}
      options={options}
      placeholder={<div>Выберите маршрут</div>}
      components={{
        MenuList,
      }}
      value={value || options[0]}
      onChange={onChangeSelect}
    />
  );
};

export const RouteSelectFieldCustom = memo(RouteSelectFieldCustomComponent);