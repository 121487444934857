export const PAGE_ROUTES = {
  MAIN: "main",
  ROUTES: "routes",
  ROUTE: "route",
  STOP_STATION: "stop-station",
  STOP_STATIONS: "stop-stations",
  SCHEDULE: "schedule",
  SCHEDULES: "schedules",
  VEHICLES: "vehicles",
  VEHICLE: "vehicle",
  CONTRACTORS: "contractors",
  CONTRACTOR: "contractor",
  DEPOTS: "depots",
  DEPOT: "depot",
  DRIVER: "driver",
  DRIVERS: "drivers",
  UI: "ui",
  NOT_FOUND: "not_found",
  KEY_ZONE: "keyzone",
  KEY_ZONES: "keyzones",
};

export default PAGE_ROUTES;
