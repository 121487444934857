import React from "react";
import { v4 } from "uuid";
import { TestAvatar32 } from "assets/icons";
import { Driver } from "api/requests/driver/types";
import { DriverItem } from "store/reducers/driver";
import { DriverCardProps } from "../../cards";

export const mockItems: DriverItem[] = [...Array(100)].map<DriverItem>((_, index) => ({
  driverId: v4(),
  versionId: +v4(),
  firstName: `Василий ${index}`,
  middleName: `Васильевич ${index}`,
  lastName: `Пупкин ${index}`,
}));
