import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastError, toastSuccess } from "components";
import { ErrorHandler } from "shared/helpers/error-handler";
import { useNavigateToPreviousRoute } from "shared/hooks/use-navigate-to-previous-route";
import { addContractorVersionRequest, ContractorResponse, ContractorVersionRequest, editContractorRequest, getContractorFile, getContractorRequest, getContractorTypes, getContractorVersionRequest } from "api";
import { contractorActions } from "store/actions";
import { RootState } from "store/types";
import { PAGE_ROUTES } from "shared/definitions";
import router from "router";
import { useStopCurrentPage } from "store/selectors/stop-selectors";
import { useFileLoadLogic } from "features/file-load-logic";
import { useGetContractorItemsByVersion } from "../contractor-details.data";

export type Option = {
  label: string;
  value: string;
};

export type EditContractorForm = {
  shortName: string;
  fullName: string;
  address: string;
  phone: string;
  documents: string | null;
  startDate: string | null;
  endDate: string | null;
  contractorTypeId: number | null;
};

const initialForm: EditContractorForm = {
  shortName: "",
  fullName: "",
  address: "",
  phone: "",
  documents: null,
  startDate: null,
  endDate: null,
  contractorTypeId: null,
};

const formToRequest = (form: EditContractorForm, details?: any): ContractorVersionRequest => {
  const { contractorTypeId, fullName, documents, shortName, ...rest } = form;
  const arr = documents ? documents.split("\n") : [];
  return {
    ...rest,
    contractorTypeId,
    fullName,
    documents: documents ? documents.split("\n") : [],
    shortName: shortName || null,
    Id: null,
    versionId: null,
  };
};

const formToUpdate = (form: EditContractorForm, details: ContractorResponse): ContractorVersionRequest => {
  const { contractorTypeId, documents, ...rest } = form;
  return {
    ...rest,
    versionId: details.versionId,
    Id: details.Id,
    documents: documents ? documents.split("\n") : [],
    contractorTypeId: contractorTypeId || null,
  };
};

const detailsToForm = (details: ContractorResponse): EditContractorForm => {
  const { contractorTypeId, documents, ...rest } = details || {};

  return {
    // depot: depot ?? null,
    contractorTypeId,
    documents: documents ? documents.join("\n") : null,
    ...rest,
  };
};

export const useEditContractorLogic = (isNew: boolean, setValue?: (name: keyof EditContractorForm, value: EditContractorForm[keyof EditContractorForm]) => void) => {
  const [isLoading, setIsLoading] = useState(false);
  const { contractorDetails, versionId } = useGetContractorItemsByVersion();
  const dispatch = useDispatch();
  const { createMetaDataArr } = useFileLoadLogic();
  const [files, setFiles] = useState<File[]>([]);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [form, setForm] = useState(isNew ? initialForm : detailsToForm(contractorDetails));
  const [contractorType, setContractorTypes] = useState<{
    id: string;
    label: string;
  }[]>([]);
  const navigateToPrevPage = useNavigateToPreviousRoute();
  const { newStopStationData, activeFilter } = useSelector((state: RootState) => ({
    activeFilter: state.contractor.contractorActiveFilter,
    newStopStationData: state.stop.newStopStationData,
  }));
  const currentPage = useStopCurrentPage();

  const fetchFiles = useCallback(async (controller: AbortController, contractor: ContractorResponse) => {
    if (!contractor?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(contractor.files.map(async fileElem => {
        const payload = await getContractorFile(contractor.Id, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);



  const getTypes = useCallback(async () => {
    const contractorTypes = await getContractorTypes();
    const result = contractorTypes.map(item => ({ id: item.id.toString(), label: item.name }));
    setContractorTypes(result);
  }, []);

  useEffect(() => {
    if (newStopStationData === undefined) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setValue && setValue("address", newStopStationData.address);
    setForm((prevState: any) => ({
      ...prevState,
      address: newStopStationData.address,
    }));
  }, [newStopStationData]);

  useEffect(() => {
    getTypes();
    if (contractorDetails && !isNew) {
      setForm(detailsToForm(contractorDetails));
      setStartDate(contractorDetails?.startDate ? contractorDetails?.startDate : undefined);
      setEndDate(contractorDetails?.endDate ? contractorDetails?.endDate : undefined);
    } else {
      setForm(initialForm);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (contractorDetails && !isNew) {
      fetchFiles(controller, contractorDetails);
    }
    return ()=>{
      controller.abort();
    };
  }, [contractorDetails, fetchFiles, isNew]);

  const handleExecQuery = useCallback(async (verId: number) => {
    try {
      const payload = await getContractorVersionRequest(verId.toString());
      dispatch(contractorActions.setContractorByVersionId(verId.toString(), payload));
      dispatch(contractorActions.updateContractorFiles(payload.Id.toString(), payload));
      const response = await getContractorRequest({ pageNum: currentPage, pageSize: 200 });
      dispatch(contractorActions.setContractors(activeFilter,
        response));
      router.navigate(PAGE_ROUTES.CONTRACTOR, { id: `${verId}` });
    } catch (error) {
      router.navigate(PAGE_ROUTES.CONTRACTORS);
    }

  }, [dispatch, activeFilter, currentPage]);

  const editContractor = useCallback(async (values: EditContractorForm) => {
    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }
    // editContractorRequest({ ...contractorDetails, endDate: changeDay(contractorDetails?.startDate, values.startDate) });
    addContractorVersionRequest({ ...formToUpdate(values, contractorDetails), files: fileArr })
      .then(async (el: any) => {
        toastSuccess({ title: "Успешно", message: `Изменен ${values.fullName}` });
        handleExecQuery(el.versionId);
      })
      .catch((error: any) => toastError({ title: "Error", message: error.response.data.toString() })).finally(() => {
        setIsLoading(false);
      });
  }, [contractorDetails, createMetaDataArr, files, dispatch, versionId, handleExecQuery, navigateToPrevPage]);


  const editContractorVersion = useCallback(async (values: EditContractorForm) => {
    // eslint-disable-next-line no-param-reassign
    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }
    editContractorRequest({ ...formToUpdate(values, contractorDetails), files: fileArr }).then(async () => {
      toastSuccess({ title: "Успешно", message: `Изменен ${values.fullName}` });
      handleExecQuery(contractorDetails?.versionId);
      navigateToPrevPage();
    })
      .catch((error: any) => toastError({ title: "Error", message: error.response.data.toString() })).finally(() => {
        setIsLoading(false);
      });
  }, [contractorDetails, createMetaDataArr, files, dispatch, versionId, handleExecQuery, navigateToPrevPage]);

  const createContractor = useCallback(async (values: EditContractorForm) => {
    setIsLoading(true);
    // setProperty<EditContractorForm>(values, "endDate", null);
    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files);
    } catch (error) {
      toastError({ title: "Error", message: "Не удалось загрузить файлы" });
      setIsLoading(false);
      return;
    }
    addContractorVersionRequest({ ...formToRequest(values, contractorDetails), files: fileArr }).then((el: any) => {
      toastSuccess({ title: "Успешно", message: `Добавлен ${values.fullName}` });
      handleExecQuery(el.versionId);
      navigateToPrevPage();
    })
      .catch((error: any) => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => {
        setIsLoading(false);
      });

  }, [contractorDetails, createMetaDataArr, files, handleExecQuery, navigateToPrevPage]);

  const clearUp = useCallback(() => {
    (Object.keys(initialForm) as Array<keyof typeof initialForm>).map(el => setValue && setValue(`${el}`, initialForm[`${el}`]));
    setStartDate(undefined);
    setEndDate(undefined);
  }, [setValue]);

  return {
    form,
    editContractor,
    editContractorVersion,
    createContractor,
    handleFiles: setFiles,
    files,
    isLoading,
    startDate,
    endDate,
    contractorType,
    clearUp,
  };
};
