import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../types";
import { StopFilters } from "../../reducers/stop";
import { StopResponse } from "../../../api";

export const useStopSubstringSearchSelector = () => useSelector((state: RootState) => state.stop.searchSubstring);

export const useStopItemsSelector = (filter: StopFilters): { items: StopResponse[], totalCount: number } => useSelector((state: RootState) => {
  const stops = state.stop[filter];
  const { searchSubstring = "" } = state.stop;
  if (!stops) return { totalCount: searchSubstring.length > 0 ? 0 : -1, items: [] };
  const { totalCount = 0, items } = stops;
  return {
    items: items
      .reduce((acc, page) => [...acc, ...(page ?? [])], [])
      .slice(0, totalCount),
    totalCount,
  };
}, shallowEqual);

export const useStopActiveFilter = () => useSelector((state: RootState) => state.stop.stopActiveFilter);
export const useStopFiltersSelector = () => useSelector((state: RootState) => state.stop.stopFilters);
export const useStopCurrentPage = () => useSelector((state: RootState) => state.stop.stopCurrentPage);
