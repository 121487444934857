import styled, { css } from "styled-components/macro";
import { withAccentColor } from "shared/utils";
import { withCBColor, withContentColor } from "@megapolis/uikit/utils/styled-helpers";

export const Icon = styled.span`
    width: 15%;
    height: 100%;
    margin-right: 7px;
`;

export const Content = styled.span`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  line-height: 16px;
  overflow: hidden;
  text-align: left;
  margin: 7px 0;
  white-space: nowrap;
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const activeContainerCss = css`
  background-color: ${withCBColor("secondary", "active")};
  border-color: ${withCBColor("secondary", "active")};
`;

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 12px;
  padding: 0 8px;
  overflow: hidden;
`;

export const Title = styled.span`
  display: block;
  color: ${withContentColor("primary")};
`;

export const Caption = styled.span`
  display: block;
  color: ${withContentColor("secondary")};
  * {
    font-size: 11px;
  }
`;
