import styled, { css } from "styled-components/macro";
import { withAccentColor } from "shared/utils";
import { withCBColor, withContentColor } from "@megapolis/uikit/utils/styled-helpers";

type ContainerProps = {
  isActive: boolean;
};

export const Icon = styled.span`
  display: block;
  margin-right: 8px;
`;

const CommonControlCss = css`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.span`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  line-height: 16px;
  overflow: hidden;
  text-align: left;
  margin: 7px 0;
  white-space: nowrap;
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MainControl = styled.button`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
  ${CommonControlCss};
`;

export const MainControlNoClick = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
`;

export const AdditionalControl = styled.button`
  display: block;
  max-height: 24px;
  margin-left: 20px;
  justify-self: flex-end;
  ${CommonControlCss}
`;

export const activeContainerCss = css`
  background-color: ${withCBColor("secondary", "active")};
  border-color: ${withCBColor("secondary", "active")};
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 12px;
  padding: 0 8px;
  overflow: hidden;

  &:hover {
    background-color: ${withCBColor("secondary", "hover")};
    border: 1px solid ${withCBColor("secondary", "hover")};
  }

  &:focus-within {
    outline: none;
    border-color: transparent;
    box-shadow: inset 0 0 0 2px ${withAccentColor("blue", "l3")};
  }
  &:active {
    ${activeContainerCss};
  }
  ${(props) => props.isActive && activeContainerCss};
`;

export const Title = styled.span`
  display: block;
  color: ${withContentColor("primary")};
`;

export const Caption = styled.span`
  display: block;
  color: ${withContentColor("secondary")};
  * {
    font-size: 11px;
  }
`;
