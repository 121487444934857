import React, { memo } from "react";
import { Tab } from "components/ui";
import { DetailsPanelTabs } from "../../components";
import { TAB_VALUES } from "../vehicle-details-panel.constants";

export type TabsProps = {
  value: string;
  onChange: (val: string) => void;
};

const VehiclePanelTabsComponent = (props: TabsProps) => {
  const { value, onChange } = props;
  return (
    <DetailsPanelTabs value={value} onChange={onChange}>
      <Tab value={TAB_VALUES.info}>Справка</Tab>
      <Tab value={TAB_VALUES.route}>Трассы</Tab>
    </DetailsPanelTabs>
  );
};

export const VehiclePanelTabs = memo(VehiclePanelTabsComponent);
