import React, { useState, useCallback, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { SkeletonDetailCard } from "components/ui/skeleton-detail-card";
import { useRouteParams } from "shared/hooks";
import { DetailsPanel } from "../components";
import { TAB_VALUES } from "./route-details-panel.constants";
import { RoutePanelHeader, RoutePanelTabs } from "./components";
import { RouteDetailsInfoTab, RouteDetailsRouteTab } from "./tab-pages";

export const RouteDetailsPanelViewComponent = () => {
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const { id } = useRouteParams();
  const { isLoading } = useSelector((state: RootState) => ({
    isLoading: state.route.routeIsLoading,
  }));

  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  useEffect(()=>{
    setActiveTab(TAB_VALUES.info);
  }, [id]);
  
  return !isLoading ?
    <DetailsPanel
      headerSlot={<RoutePanelHeader />}
      tabsSlot={<RoutePanelTabs value={activeTab} onChange={handleChangeTab} />}>
      {activeTab === TAB_VALUES.info && <RouteDetailsInfoTab />}
      {activeTab === TAB_VALUES.route && <RouteDetailsRouteTab />}
    </DetailsPanel> : <SkeletonDetailCard />;
};

export const RouteDetailsPanel = memo(RouteDetailsPanelViewComponent);
