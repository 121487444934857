import React from "react";
import { InfoCard, BlockHeader } from "components/common";
import { v4 } from "uuid";
import styled from "styled-components/macro";
import { useGetRouteInfoTabData } from "../route-details-info-tab.data";


const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Header = styled.span`
  font-weight: 600;
`;

export const GeneralInfo = () => {
  const { infoCards } = useGetRouteInfoTabData();
  return (
    <Container>
      <BlockHeader left={<Header>Общая информация</Header>} />
      <InfoCards>
        {infoCards.map((card: { title: string; text: string }) => (
          <InfoCard key={v4()} title={card.title}>
            {card.text}
          </InfoCard>
        ))}
      </InfoCards>
    </Container>
  );
};
