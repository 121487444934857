import { Dispatch } from "redux";
import { DriverFilters } from "store/reducers/driver";
import { DriverVersionDetails } from "api/requests/driver/types";
import { DriverDetails } from "models";
import { DriverActionType, SetActiveDriverAction, SetDriverActiveFilterAction, SetDriverByVersionIdAction, SetDriverFilters, SetDriversAction, SetDriverSearchSubstring, UpdateDriverByVersionIdAction, UpdateDriversFilesAction } from "./driver-actions.types";

export const driverActions = {

  setActiveDriver: (payload: string): SetActiveDriverAction => ({
    type: DriverActionType.SET_ACTIVE_DRIVER_ID,
    payload,
  }),

  getDriverByVersionId: (versionId: string, payload: DriverVersionDetails) => async (dispatch: Dispatch<SetDriverByVersionIdAction>) => {
    dispatch({
      type: DriverActionType.SET_DRIVER_BY_VERSION_ID,
      payload: {
        versionId,
        data: payload,
      },
    });
  },

  setSearchSubstring: (payload: string): SetDriverSearchSubstring => ({
    type: DriverActionType.SET_DRIVER_SEARCH_SUBSTRING,
    payload,
  }),

  setDrivers: (payload: SetDriversAction["payload"]): SetDriversAction => ({
    type: DriverActionType.SET_DRIVERS,
    payload,
  }),

  setFilters: (payload: {
    ContractorIds?: string | null,
    RouteIds?: string | null,
    DepotIds?: string | null,
  }): SetDriverFilters => ({
    type: DriverActionType.SET_DRIVER_FILTERS,
    payload,
  }),

  updateDriverByVersion: (versionId: string, data: Partial<DriverDetails>): UpdateDriverByVersionIdAction => ({
    type: DriverActionType.UPDATE_DRIVER_BY_VERSION,
    payload: { versionId, data },
  }),

  updateDriversFiles: (driverId: string, data: Partial<DriverVersionDetails>): UpdateDriversFilesAction => ({
    type: DriverActionType.UPDATE_DRIVERS_FILES,
    payload: { driverId, data },
  }),

  setActiveFilter: (payload: DriverFilters): SetDriverActiveFilterAction => ({
    type: DriverActionType.SET_DRIVER_ACTIVE_FILTER,
    payload,
  }),
};

export default driverActions;