import React, { FC, useContext, useCallback, useMemo } from "react";
import { TextTab } from "./text-tab";
import { ButtonTab } from "./button-tab";
import { TabsContext } from "../tabs";

export interface TabProps {
  value: string;
  variant?: "text" | "button";
  icon?: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
}

export const Tab: FC<TabProps> = ({ children, variant = "text", icon, value, className, isDisabled }) => {
  const { activeTabValue, onClick } = useContext(TabsContext);
  const isActive = useMemo(() => activeTabValue === value, [activeTabValue, value]);

  const handleClick = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  return variant === "text" ? (
    <TextTab className={className} icon={icon} active={isActive} onClick={handleClick} isDisabled={isDisabled}>
      {children}
    </TextTab>
  ) : (
    <ButtonTab className={className} icon={icon} active={isActive} onClick={handleClick} isDisabled={isDisabled}>
      {children}
    </ButtonTab>
  );
};
