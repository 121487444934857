import { findIndexInArrayOfItemArray, updateItemInArrayByIndex } from "shared/utils/array";
import { initialState } from "./contractor.constants";
import { IInitialContractorState } from "./contractor.types";
import { ContractorAction, ContractorActionTypes } from "../../actions/contractor-actions/contractor-actions.types";

export const contractorReducer = (state: IInitialContractorState = initialState, action: ContractorAction) => {
  switch (action.type) {
    case ContractorActionTypes.SET_CONTRACTORS: {
      const { payload } = action;
      const prevData = state[payload.status]?.items ?? [];
      prevData[payload.data.pageIndex || 0] =  payload.data.items;

      return { ...state, [payload.status]: { ...payload.data, items: prevData } };
    }

    case ContractorActionTypes.SET_CONTRACTOR_ACTIVE_ITEM: {
      const { payload } = action;
      return { ...state, selectedContractor: payload };
    }

    case ContractorActionTypes.SET_FILTERS: {
      const { payload } = action;
      return { ...state, contractorFilters: payload };
    }

    case ContractorActionTypes.SET_CONTRACTOR_ACTIVE_FILTER: {
      const { payload } = action;
      return { ...state, contractorActiveFilter: payload };
    }

    case ContractorActionTypes.SET_CONTRACTOR_SEARCH_STRING : {
      const { payload } = action;
      return { ...state, contractorSearchString: payload };
    }

    case ContractorActionTypes.UPDATE_CONTRACTORS_FILES: {
      const { payload } = action;
      const { contractorId, data } = payload;
      const details = Object.values(state.contractorByVersionId);
      const contractorsById = details.filter(contractor => contractor.Id.toString() === contractorId);
      const updated = contractorsById.reduce(
        (accumulator, contractor) => ({ ...accumulator, [contractor.versionId.toString()]: { ...contractor, files: data.files } }),
        {},
      );
     
      return {
        ...state,
        contractorByVersionId: {
          ...state.contractorByVersionId,
          ...updated,
        },
      };
    }


    case ContractorActionTypes.UPDATE_CONTRACTOR_BY_VERSION: {
      const { payload } = action;
      const { versionId } = payload;
      const currentItemsKey = state.contractorActiveFilter;
      const details = state.contractorByVersionId[versionId] ?? {};
      const updatedDetails = { ...details, ...payload.data };

      const items = state[currentItemsKey]?.items ?? [];
      const [page, index] = findIndexInArrayOfItemArray(items, (item) => item.versionId === Number(versionId));
      items[page] = updateItemInArrayByIndex(items[page], index, updatedDetails);
      const updatedItems = { ...state[currentItemsKey], items };

      return {
        ...state,
        [currentItemsKey]: updatedItems,
        contractorByVersionId: {
          ...state.contractorByVersionId,
          [versionId]: updatedDetails,
        },
      };
    }

    case ContractorActionTypes.SET_CONTRACTOR_BY_VERSION_ID: {
      const { payload } = action;

      return { ...state, contractorByVersionId: { ...state.contractorByContractorId, [payload.id]: payload.data } };
    }

    case ContractorActionTypes.SET_IS_CONTRACTOR_CARD_LOADING: {
      const { payload } = action;

      return { ...state, isCardLoading: payload };
    }

    default:
      return state;
  }
};
