import styled, { css, DefaultTheme } from "styled-components";
import { Loader } from "../loader";
import * as styleUtils from "./button.styles.utils";
import * as ButtonTypes from "./button.types";

type Props = Required<Omit<ButtonTypes.StyleProps, "isLoading">>;

export const getStyle = (theme: DefaultTheme) => (props: Props) => {
  const { content } = props;
  const { priority = ButtonTypes.Priority.Primary } = props;
  const { kind = ButtonTypes.Kind.Info } = props;
  const { size = ButtonTypes.Size.Medium } = props;

  const styleProps: styleUtils.StyledProps = { content, priority, kind, size };

  return css`
    position: relative;

    /* Base */
    ${styleUtils.getMarkup(theme)(styleProps)}
    ${styleUtils.getHeight(theme)(styleProps)}
    ${styleUtils.getPadding(theme)(styleProps)}
    ${styleUtils.getBackgroundColor(theme)(styleProps)}
    ${styleUtils.getBorder(theme)(styleProps)}
    ${styleUtils.getGap(theme)(styleProps)}
    ${styleUtils.getIconSize(theme)(styleProps)}

    transition: background-color ${theme.transitions.duration.shortest.str}, border-color ${theme.transitions.duration
      .shortest.str}, color ${theme.transitions.duration.shortest.str};

    /* Children */
    ${styleUtils.styleForBeforeIcon(theme)(styleProps)}
    ${styleUtils.styleForAfterIcon(theme)(styleProps)}
    ${styleUtils.styleForText(theme)(styleProps)}
    ${styleUtils.styleForCaption(theme)(styleProps)}
      
    border-radius: ${theme.borderRadiuses[2].str};

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: default;
    }
  `;
};

export const Button = styled.button<Props>`
  ${({ theme, ...props }) => getStyle(theme)(props)}
`;
