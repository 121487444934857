import { css, DefaultTheme, FlattenSimpleInterpolation } from "styled-components";
import * as Components from "./components";
import * as ButtonTypes from "./button.types";

export type StyledProps = Required<Omit<ButtonTypes.StyleProps, "isLoading">>;

type GetStyleProps = (theme: DefaultTheme) => (styleProps: StyledProps) => FlattenSimpleInterpolation;

export const getMarkup: GetStyleProps = () => (styleProps) => {
  switch (styleProps.content) {
    case ButtonTypes.Content.TextCaption:
    case ButtonTypes.Content.IconCaption:
      return css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `;
    default:
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
      `;
  }
};

export const getHeight: GetStyleProps = () => (styleProps) => {
  if (styleProps.size === ButtonTypes.Size.Small) {
    return css`
      min-height: 24px;
    `;
  }

  return css`
    min-height: 32px;
  `;
};

export const getBackgroundColor: GetStyleProps = (theme) => (styleProps) =>
  css`
    background-color: ${theme.button.default[styleProps.priority][styleProps.kind].button.backgroundColor};

    &:hover {
      background-color: ${theme.button.hover[styleProps.priority][styleProps.kind].button.backgroundColor};
    }

    &:active {
      background-color: ${theme.button.active[styleProps.priority][styleProps.kind].button.backgroundColor};
    }

    &:disabled {
      background-color: ${theme.button.disabled[styleProps.priority][styleProps.kind].button.backgroundColor};
    }
  `;

export const getBorder: GetStyleProps = (theme) => (styleProps) =>
  css`
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.button.default[styleProps.priority][styleProps.kind].button.borderColor};

    &:hover {
      border-color: ${theme.button.hover[styleProps.priority][styleProps.kind].button.borderColor};
    }

    &:active {
      border-color: ${theme.button.active[styleProps.priority][styleProps.kind].button.borderColor};
    }

    &:disabled {
      border-color: ${theme.button.disabled[styleProps.priority][styleProps.kind].button.borderColor};
    }
  `;

export const getPadding: GetStyleProps = () => (styleProps) => {
  switch (styleProps.content) {
    case ButtonTypes.Content.Icon:
      return css`
        padding: ${styleProps.size === ButtonTypes.Size.Medium ? 3 : 0}px;
      `;
    case ButtonTypes.Content.TextCaption:
    case ButtonTypes.Content.IconCaption:
      return css`
        padding: 8px;
      `;
    default:
      return css`
        padding: 4px 8px;
      `;
  }
};

export const getGap: GetStyleProps = () => (styleProps) => {
  switch (styleProps.content) {
    case ButtonTypes.Content.IconText:
    case ButtonTypes.Content.TextIcon:
      return css`
        gap: 4px;
      `;
    default:
      return css``;
  }
};

export const getIconSize: GetStyleProps = () => (styleProps) => {
  let maxWidth = 24;
  let maxHeight = 24;
  if (styleProps.size === ButtonTypes.Size.Small) {
    maxHeight = 22;
    maxWidth = 22;
  }
  return css`
    svg {
      max-width: ${maxWidth}px;
      max-height: ${maxHeight}px;
    }
  `;
};

export const styleForBeforeIcon: GetStyleProps = (theme) => (styleProps) => {
  const { priority, kind } = styleProps;

  return css`
    ${Components.BeforeIcon.Markup.Container} {
      svg {
        ${Components.BeforeIcon.Markup.getDefaultColor({ priority, kind })(theme)}
      }
    }

    &:hover {
      ${Components.BeforeIcon.Markup.Container} {
        svg {
          ${Components.BeforeIcon.Markup.getHoverColor({ priority, kind })(theme)}
        }
      }
    }

    &:active {
      ${Components.BeforeIcon.Markup.Container} {
        svg {
          ${Components.BeforeIcon.Markup.getActiveColor({ priority, kind })(theme)}
        }
      }
    }

    &:disabled {
      ${Components.BeforeIcon.Markup.Container} {
        svg {
          ${Components.BeforeIcon.Markup.getDisabledColor({ priority, kind })(theme)}
        }
      }
    }
  `;
};

export const styleForAfterIcon: GetStyleProps = (theme) => (styleProps) => {
  const { priority, kind } = styleProps;

  return css`
    ${Components.AfterIcon.Markup.Container} {
      svg {
        ${Components.AfterIcon.Markup.getDefaultColor({ priority, kind })(theme)}
      }
    }

    &:hover {
      ${Components.AfterIcon.Markup.Container} {
        svg {
          ${Components.AfterIcon.Markup.getHoverColor({ priority, kind })(theme)}
        }
      }
    }

    &:active {
      ${Components.AfterIcon.Markup.Container} {
        svg {
          ${Components.AfterIcon.Markup.getActiveColor({ priority, kind })(theme)}
        }
      }
    }

    &:disabled {
      ${Components.AfterIcon.Markup.Container} {
        svg {
          ${Components.AfterIcon.Markup.getDisabledColor({ priority, kind })(theme)}
        }
      }
    }
  `;
};

export const styleForText: GetStyleProps = (theme) => (styleProps) => {
  const { priority, kind } = styleProps;

  return css`
    ${Components.Text.Markup.Container} {
      ${Components.Text.Markup.getDefaultColor({ priority, kind })(theme)}
    }

    &:hover {
      ${Components.Text.Markup.Container} {
        ${Components.Text.Markup.getHoverColor({ priority, kind })(theme)}
      }
    }

    &:active {
      ${Components.Text.Markup.Container} {
        ${Components.Text.Markup.getActiveColor({ priority, kind })(theme)}
      }
    }

    &:disabled {
      ${Components.Text.Markup.Container} {
        ${Components.Text.Markup.getDisabledColor({ priority, kind })(theme)}
      }
    }
  `;
};

export const styleForCaption: GetStyleProps = (theme) => (styleProps) => {
  const { priority, kind } = styleProps;

  return css`
    ${Components.Caption.Markup.Container} {
      ${Components.Caption.Markup.getDefaultColor({ priority, kind })(theme)}
    }

    &:hover {
      ${Components.Caption.Markup.Container} {
        ${Components.Caption.Markup.getHoverColor({ priority, kind })(theme)}
      }
    }

    &:active {
      ${Components.Caption.Markup.Container} {
        ${Components.Caption.Markup.getActiveColor({ priority, kind })(theme)}
      }
    }

    &:disabled {
      ${Components.Caption.Markup.Container} {
        ${Components.Caption.Markup.getDisabledColor({ priority, kind })(theme)}
      }
    }
  `;
};
