/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useState } from "react";
import { Content } from "@megapolis/uikit/button/button.types";
import { Button } from "components";
import { useRouter } from "react-router5";
import { Depot24Icon, EditBlue32Icon } from "assets/icons";
import { PAGE_ROUTES } from "shared/definitions";
import { useRouteParams } from "shared/hooks";
import { VersionViewer } from "components/ui/version-card";
import styled from "styled-components";
import { VersionViewerContent } from "features/version-view";
import { depotActions } from "store/actions";
import { useDispatch } from "react-redux";
import { getDepotVersionsDates } from "api";
import { DetailsPanelHeader } from "../../components";
import { labels } from "../../../../shared/constants";
import { useGetDepotItemsByVersion } from "../depot-details-panel.data";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const DepotPanelHeader = () => {
  const { id } = useRouteParams();
  const { depotDetails } = useGetDepotItemsByVersion();
  const router = useRouter();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   fetchDepot(versionId);
  // }, [fetchDepot, versionId]);

  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => {
    setIsShown(!isShown);
  };

  const handleEditClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.DEPOT, { id: `${id}_edit` });
    },
    [router, id],
  );


  
  const handleEditVersionClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.DEPOT, { id: `${id}_version` });
    },
    [router, id],
  );


  const handleItemClick = useCallback((versionId: any) => {
    dispatch(depotActions.setActiveDepot(versionId));
    router.navigate(PAGE_ROUTES.DEPOT, { id: versionId.toString() });
    setIsShown(!isShown);
  }, [dispatch, isShown, router]);

  const handleCreateClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.ROUTE, { id: "new" });
    },
    [router],
  );
  const versionCaption = useCallback((itm: any) => {
    let versionString = "";
    if (itm?.startDate) {
      versionString =  `Версия от ${itm?.startDate}`;
    } else if (!itm?.startDate && itm?.endDate) {
      versionString =  `Версия до ${itm?.endDate}`;
    } else {
      versionString =  "Версия бессрочная";
    }
    return versionString;
  }, []);

  return (
    <DetailsPanelHeader
      title={depotDetails?.name || labels.noValue}
      caption={<Container onClick={toggle} ><span>{versionCaption(depotDetails)}</span><VersionViewer isShown={isShown} hide={toggle} modalContent={<VersionViewerContent currentVersion={depotDetails} id={depotDetails?.depotId} handleItemClick={handleItemClick} onEdit={handleEditVersionClick} fetchVersions={getDepotVersionsDates} />} headerText={"Версионность"} onCreateClick={handleCreateClick} /></Container>}
      icon={<Depot24Icon />}
      rightAction={<>
        <Button content={Content.Icon} icon={<EditBlue32Icon />} onClick={handleEditClick} priority="quaternary" />
      </>

      }
    />
  );
};
