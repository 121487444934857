import { createContext } from "react";

interface TabsContextProps {
  activeTabValue: string;
  onClick: (value: string) => void;
}

export const TabsContext = createContext<TabsContextProps>({
  activeTabValue: "",
  onClick: () => {},
});
