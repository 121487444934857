export enum ScheduleTypes {
  Sunday  = "Sunday",
  Monday  = "Monday",
  Tuesday  = "Tuesday",
  Wednesday  = "Wednesday",
  Thursday   = "Thursday",
  Friday  = "Friday",
  Saturday   = "Saturday",
}

export const ScheduleTypesCaptionMapShort = {
  [ScheduleTypes.Monday]: "пн",
  [ScheduleTypes.Tuesday]: "вт",
  [ScheduleTypes.Wednesday]: "ср",
  [ScheduleTypes.Thursday]: "чт",
  [ScheduleTypes.Friday]: "пт",
  [ScheduleTypes.Saturday]: "сб",
  [ScheduleTypes.Sunday]: "вс",
};

export const ScheduleTypesCaptionMapLong = {
  [ScheduleTypes.Monday]: "Понедельник",
  [ScheduleTypes.Tuesday]: "Вторник",
  [ScheduleTypes.Wednesday]: "Среда",
  [ScheduleTypes.Thursday]: "Четверг",
  [ScheduleTypes.Friday]: "Пятница",
  [ScheduleTypes.Saturday]: "Суббота",
  [ScheduleTypes.Sunday]: "Воскресенье",
};

export const getScheduleTypesCaptionMapShort = (type: string): string =>
  ScheduleTypesCaptionMapShort[type as keyof typeof ScheduleTypes];

export const getScheduleTypesCaptionMapLong = (type: string): string =>
  ScheduleTypesCaptionMapLong[type as keyof typeof ScheduleTypes];