import React from "react";
import { getDefaultColor, getHoverColor, getActiveColor, getDisabledColor } from "./caption.styles.utils";
import { Container } from "./caption.styles";
import { TContent, Content } from "../../button.types";

interface Props {
  /** Тип контента */
  content: TContent;
  /** Подпись */
  caption: string | null;
}

const Caption = (props: Props) => {
  if (!props.caption) return null;

  switch (props.content) {
    case Content.IconCaption:
    case Content.TextCaption:
      return <Container>{props.caption}</Container>;
    default:
      return null;
  }
};

Caption.Markup = { Container, getDefaultColor, getHoverColor, getActiveColor, getDisabledColor };

export { Caption };
