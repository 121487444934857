import * as api from "services";
import { AxiosRequestConfig } from "axios";
import { APP_ENV } from "app-env";
import {
  SearchVehicleOptions,
  VehicleCreateRequestOptions,
  VehicleEditRequestOptions,
  VehicleGetRequestOptions,
} from "./types";
import { VehicleDetails, VehicleRequestItem } from "../../../models";

const requestPath = "Vehicle";

export const getVehicleRequest = (options?: VehicleGetRequestOptions) => api.get<VehicleRequestItem>(`${requestPath}/version`, options);

export const getVehicleCapacityTypesRequest = () => api.get<{
  id: number,
  name: string,
  shortName: string
}[]>(`${requestPath}/capacityTypes`);

export const searchVehicle = (options: SearchVehicleOptions) => api.get(`${requestPath}/version`, options);

export const getVehicleVersionRequest = (versionId: string, options?: AxiosRequestConfig) => api.get<VehicleDetails>(`${requestPath}/version/${versionId}`, {}, options);

export const getVehicleVersionsDatesRequest = (vehicleId: number | string) =>
  api.get<VehicleDetails[]>(`${requestPath}/${vehicleId}/versionsDates`);

export const createVehicleRequest = (options: VehicleCreateRequestOptions) => api.post(`${requestPath}/version`, {}, options);

export const editVehicleRequest = (options: VehicleEditRequestOptions) =>
  api.put(`${requestPath}/version/${options.versionId}`, {}, options);

export const getVehicleFile = async (vehicleId: number | string, id: number | string, signal?: AbortSignal | undefined) => {
  const myHeaders = new Headers();
  const accessToken = localStorage.getItem("Authorization") ?? "";
  if (accessToken.length) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const payload = await fetch(`${APP_ENV.REACT_APP_BASE_URL}/${requestPath}/${vehicleId}/${id}`, { ...requestOptions, signal });
  return payload;
};
