import { ArrowDown16BlueIcon } from "assets/icons";
import { withNeutralColor } from "shared/utils";
import styled from "styled-components";
import { ifProp } from "styled-tools";

type WrapperProps = {
  Top?: string;
  Left?: string;
};

export const Wrapper = styled.div<WrapperProps>`
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledModal = styled.div`
  z-index: 100;
  position: fixed;
  box-shadow: 0px 0px 24px rgba(91, 104, 113, 0.12);
  border-radius: 8px;
  z-index: 700;
  background: white;
  margin: auto;
  border-radius: 8px;
  min-width: 320px;
  width: 395px;
  display:flex;
  flex-direction:column;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
`;

export const Footer = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
`;

export const HeaderText = styled.div`
  color: #fff;
  align-self: center;
  color: lightgray;
`;

export const CloseButton = styled.button`
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  margin-left: 0.5rem;
  background: none;
  :hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  padding: 10px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Heading = styled.header`
  flex-grow: 1;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: ${withNeutralColor("d4")};
`;


export const ButtonsList = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const Controls = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
`;

export const IconBlock = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
`;

export const ArrowIcon = styled(ArrowDown16BlueIcon)<{ rotated: boolean }>`
  transform: ${props => props.rotated ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s ease;
`;

export const SelectOptions = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32), 0px 12px 24px rgba(91, 104, 113, 0.24);
  background-color: #fff;
  margin: 0;
  padding: 8px;
  list-style: none;
  overflow: auto;
  min-height: 100%;
  width: inherit;
  max-height: 200px;
  overflow: auto;
  z-index: 1;
`;

export const SelectOption = styled.li<{ isActive: boolean }>`
  padding: 8px;
  line-height: 16px;
  background-color: ${ifProp("isActive", withNeutralColor("l4"))};
  border-radius: 12px;
  &:hover {
    background-color: ${withNeutralColor("l4")};
  }
`;

export const OptionButton = styled.button`
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

