import { Units } from "@turf/turf";

export type Editable = {
  editableMode: boolean;
  editableBoundingBox: boolean;
};

export type MapLayers = "BOUND_BOX" | "DIRECTIONS" | "EQUIPMENT" | "PHASE" | "CYCLES" | "ROADS";
export type LayersVisibility = { [k in MapLayers]: boolean };

export const MAP_STYLE = Object.freeze({
  light: "https://maps.megapolis-it.ru/styles/megapolis-light/style.json",
  dark: "https://maps.megapolis-it.ru/styles/megapolis-dark/style.json",
  gray: "https://maps.megapolis-it.ru/styles/megapolis-gray/style.json",
});

export const DEF_LAYERS_VISIBILITY: LayersVisibility = Object.freeze({
  BOUND_BOX: true,
  DIRECTIONS: true,
  EQUIPMENT: false,
  PHASE: false,
  CYCLES: false,
  ROADS: true,
});

export const DEF_EDITABLE: Editable = Object.freeze({
  editableMode: false,
  editableBoundingBox: false,
});

export const B_BOX_SIZES = Object.freeze({
  min: 40,
  def: 100,
  max: 600,
} as const);

export const DISTANCE_OPTIONS = { units: "metres" as Units };

export const MapLayerNames = Object.freeze({
  BOX_LAYER_ID: "box-layer",
  TRACKS_LAYER_ID: "tracks-layer",
  ADD_TRACK_LAYER: "add-track-layer",
  EQUIPMENT_ICONS: "equipment-icons",
  EQUIPMENT_LABELS: "equipment-labels",
  PHASE_DIRECTIONS: "phase-directions",
  ROADS_EDGE_VISIBLE: "traffic-light-editor-road-graph-edge-visible",
  ROADS_EDGE: "traffic-light-editor-road-graph-edge",
  GRAPH_EDGES_HIGHLIGHT_ID: "graph-edges-highlight-id",
  GRAPH_ARROW_ID: "graph-arrow-id",
  GRAPH_SOURCE_ID: "graph-source-id",
  GRAPH_EDGES_ID: "graph-edges-id",
  GRAPH_VERTICES_ID: "graph-vertices-id",
  ACTUAL_STOP_LAYER_ID: "actual_stop_Layer_id",
  ACTUAL_STOP_SELECTED_SOURCE_ID: "actual_stop_selected_source_id",
  ACTUAL_STOP_SELECTED_LAYER_ID: "actual_stop_selected_Layer_id",
  ACTUAL_STOP_SOURCE_ID: "actual_stop_source_id",
  CREATE_ROUTE_SOURCE_ID: "create_route_source_id",
  CREATE_ROUTE_LAYER_ID: "create_route_layer_id",
  TILE_SET_KEYZONE_LINE_LAYER_ID: "TILE_SET_KEYZONE_LINE_LAYER_ID",
  TILE_SET_KEYZONE_FILL_LAYER_ID: "TILE_SET_KEYZONE_FILL_LAYER_ID",
  KEYZONE_ROUTE_POLYGON_FILL: "KEYZONE_ROUTE_POLYGON_FILL",
  TILE_SET_KEYZONE_SOURCE_ID: "TILE_SET_KEYZONE_SOURCE_ID",
});
