import { TabValues } from "../../../features";
import { IInitialStopState } from "./stop.types";

export const initialState: IInitialStopState = {
  activeStop: {},
  inactiveStop: {},
  stopByVersionId: {},
  stopActiveFilter: TabValues.ACTIVE,
  stopActiveItem: "",
  searchSubstring: undefined,
  currentStopStation: {},
  stopFilters: {
    BalanceHolder: undefined,
    RouteIds: null,
    features: {
      alarmButton: false,
      forecastBoard: false,
      light: false,
      pedestrianPass: false,
      usbCharge: false,
      wasteBin: false,
      wheelChairBoarding: false,
      wiFi: false,
    },
  },
  newStopStationCoords: {},
  stopCurrentPage: 1,
  newStopStationData: {},
  [TabValues.ACTIVE]: null,
  [TabValues.INACTIVE]: null,
};
