import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRoute } from "react-router5";
import router from "router";
import { PAGE_ROUTES } from "shared/definitions/routes";
import { RouteCardListPagination } from "features/card-lists";
import { FilterWindow, Tab } from "components/ui";
import { commonActions, routeActions } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { RouteFilterContent } from "features/filters-windows";
import { useMap } from "components/common/main-map";
import { ListPanel, ListPanelFilterTabs, ListPanelHeader, ListPanelHeaderWrapper } from "../components";
import { TabValues } from "../list-panels.constants";
import { RouteFilters } from "../../../store/reducers/route";
import { useActiveRouteSelector } from "../../../store/selectors/route-selectors";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

export const RouteListPanel = () => {
  const dispatch = useDispatch();
  const activeId = useActiveRouteSelector();
  const { showDetails } = useLayoutDesignState();
  const { route: { name: routeName, params: { id: routeId } = {} } } = useRoute();
  const { routeActiveFilter, filterStatus } = useSelector((state: RootState) => ({
    routeActiveFilter: state.route.routeActiveFilter,
    filterStatus: state.common.filterStatus,
  }));
  const toggle = useCallback(() => {
    dispatch(commonActions.setFilterStatus(!filterStatus));
  }, [filterStatus]);

  useEffect(() => {
    if (!routeId && routeName === PAGE_ROUTES.ROUTES && activeId) {
      router.navigate(PAGE_ROUTES.ROUTE, { id: activeId });
    }
  }, [routeName, routeId, activeId]);

  const handleItemClick = useCallback((id: string) => {
    dispatch(routeActions.setActiveRoute(id));
    router.navigate(PAGE_ROUTES.ROUTE, { id });
  }, [dispatch]);

  const handleAddItem = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.ROUTE, {
        id: "new",
      });
      // showDetails();
    }, [showDetails]);

  const handleChangeFilter = useCallback((filter: string) => {
    dispatch(routeActions.setActiveFilter(filter as RouteFilters));
  }, [dispatch]);

  return (
    <ListPanel
      headerSlot={
        <ListPanelHeaderWrapper>
          {/* <FilterWindow headerText="Маршруты" isShown={showFilter} hide={toggle} modalContent={<RouteFilterContent hide={toggle} />} /> */}
          <ListPanelHeader requestPath="Route" title="Маршруты" onAddClick={handleAddItem} onFilterClick={toggle} />
        </ListPanelHeaderWrapper>
      }
      filterSlot={
        <ListPanelFilterTabs activeFilterTab={routeActiveFilter} onChange={handleChangeFilter}>
          <Tab value={TabValues.ALL}>Все</Tab>
          <Tab value={TabValues.ACTIVE}>Активные</Tab>
          <Tab value={TabValues.PLANNED}>Планируемые</Tab>
          <Tab value={TabValues.ARCHIVED}>Архивные</Tab>
        </ListPanelFilterTabs>
      }
      listSlot={<RouteCardListPagination activeId={activeId} onClick={handleItemClick} />}
    />
  );
};
