import { v4 } from "uuid";

const chipLabels = [
  "ГУП «Мосгортранс»",
  "ТрансАвтолиз",
  "Мострансавто",
  "Рико",
  "Трансавтолиз",
  "Автолайн",
  "ВестЛайн",
];

export const chips = chipLabels.map((label) => ({
  id: v4(),
  label,
}));

export const selectOptions = [
  {
    label: "A",
    value: v4(),
  },
  {
    label: "B",
    value: v4(),
  },
];

export const selectOptionsLisence = [
  {
    label: "M",
    value: "M",
  },
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  {
    label: "BE",
    value: "BE",
  },
  {
    label: "C",
    value: "C",
  },
  {
    label: "CE",
    value: "CE",
  },
  {
    label: "D",
    value: "D",
  },
  {
    label: "DE",
    value: "DE",
  },
  {
    label: "TM",
    value: "TM",
  },
  {
    label: "TB",
    value: "TB",
  },
];