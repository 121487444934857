import { NewRouteForm } from "features/detail-panels/route-details-panel/new-route-panel/new-route.panel.logic";
import { RouteDetails } from "models";
import { RouteFilters } from "store/reducers/route";
import { RoutesVersionResponse, RouteVersionDetails, Trip, TripStop } from "../../../api";

type Action<A extends RouteActionType, P> = { type: A, payload: P };

export enum RouteActionType {
  ADD_ROUTES = "ADD_ROUTES",
  SET_FILTERS = "SET_FILTERS",
  SET_ALL_ROUTE = "SET_ALL_ROUTE",
  SET_NEW_ROUTE_FORM = "SET_NEW_ROUTE_FORM",
  SET_ACTIVE_ROUTE = "SET_ACTIVE_ROUTE",
  SET_PLANNED_ROUTE = "SET_PLANNED_ROUTE",
  SET_ARCHIVED_ROUTE = "SET_ARCHIVED_ROUTE",
  SET_ROUTE_BY_ROUTE_ID = "SET_ROUTE_BY_ROUTE_ID",
  SET_ROUTE_BY_VERSION_ID = "SET_ROUTE_BY_VERSION_ID",
  SET_ROUTE_ACTIVE_FILTER = "SET_ROUTE_ACTIVE_FILTER",
  SET_NEW_ROUTE_STATION_COORDS = "SET_NEW_ROUTE_STATION_COORDS",
  UPDATE_ROUTE_BY_VERSION = "UPDATE_ROUTE_BY_VERSION",
  DELETE_ROUTE_BY_VERSION = "DELETE_ROUTE_BY_VERSION",
  SET_NEW_ROUTE_STATION_DATA = "SET_NEW_ROUTE_STATION_DATA",
  SET_ROUTE_SEARCH_SUBSTRING = "SET_ROUTE_SEARCH_SUBSTRING",
  SET_ACTIVE_ROUTE_ID = "SET_ACTIVE_ROUTE_ID",
  UPDATE_ROUTES_FILES = "UPDATE_ROUTES_FILES",
  SET_ROUTE_ISLOADING = "SET_ROUTE_ISLOADING",
  SET_SELECTED_ROUTE_TRIPS = "SET_SELECTED_ROUTE_TRIPS",
  SET_NEW_ROUTE_TRIPS = "SET_NEW_ROUTE_TRIPS",
  SET_EDIT_ROUTE_TRIPS = "SET_EDIT_ROUTE_TRIPS",
  DELETE_NEW_ROUTE_TRIP = "DELETE_NEW_ROUTE_TRIPS",
}

export type AddRoutesAction = Action<RouteActionType.ADD_ROUTES, { status: RouteFilters; data: RoutesVersionResponse }>;
export type SetRouteActiveFilterAction = Action<RouteActionType.SET_ROUTE_ACTIVE_FILTER, RouteFilters>;
export type SetRouteByVersionIdAction = Action<RouteActionType.SET_ROUTE_BY_VERSION_ID, { versionId: ID, data: RouteVersionDetails }>;
export type UpdateRouteByVersionIdAction = Action<RouteActionType.UPDATE_ROUTE_BY_VERSION, { versionId: string, data: Partial<RouteDetails> }>;
export type DeleteRouteByVersionIdAction = Action<RouteActionType.DELETE_ROUTE_BY_VERSION, { versionId: string }>;
export type SetRouteSearchSubstring = Action<RouteActionType.SET_ROUTE_SEARCH_SUBSTRING, string>;
export type SetActiveRouteAction = Action<RouteActionType.SET_ACTIVE_ROUTE_ID, string>;
export type SetRouteIsLoadingAction = Action<RouteActionType.SET_ROUTE_ISLOADING, boolean>;
export type SetSelectedRouteTrips = Action<RouteActionType.SET_SELECTED_ROUTE_TRIPS, TripStop[]>;
export type SetFilters = Action<RouteActionType.SET_FILTERS, {
  ContractorIds?: string | null,
  RouteKindIds?: string | null,
  TransportTypes?: string | null,
  CapacityTypes?: string | null,
}>;
export type SetNewRouteTrips = Action<RouteActionType.SET_NEW_ROUTE_TRIPS, Trip[]>;
export type SetNewRouteForm = Action<RouteActionType.SET_NEW_ROUTE_FORM, NewRouteForm | undefined>;
export type SetEditRouteTrips = Action<RouteActionType.SET_EDIT_ROUTE_TRIPS, Trip[]>;
export type DeleteNewRouteTrip = Action<RouteActionType.DELETE_NEW_ROUTE_TRIP, { type: string, direction: string }>;
export type UpdateRoutesFilesAction = Action<RouteActionType.UPDATE_ROUTES_FILES, { routeId: string, data: Partial<RouteVersionDetails> }>;

export type RoutesAction =
  | AddRoutesAction
  | SetFilters
  | SetRouteActiveFilterAction
  | SetNewRouteForm
  | UpdateRoutesFilesAction
  | SetRouteSearchSubstring
  | SetRouteIsLoadingAction
  | SetRouteByVersionIdAction
  | UpdateRouteByVersionIdAction
  | DeleteRouteByVersionIdAction
  | SetSelectedRouteTrips
  | SetNewRouteTrips
  | SetEditRouteTrips
  | DeleteNewRouteTrip
  | SetActiveRouteAction;
