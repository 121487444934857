import React, { useEffect } from "react";
import { useRoute } from "react-router5";
import { useAccessToken } from "@megapolis/react-auth";
import Routes from "./routes";
import { PAGE_ROUTES } from "./shared/definitions";

export const App: any = (props: any) => {
  const { route, router } = useRoute();
  const accessToken = useAccessToken() ?? "";

  useEffect(() => {
    localStorage.setItem("Authorization", accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (!route) {
      router.navigate(PAGE_ROUTES.ROUTES);
    }
  }, [route, router]);

  if (!route) return null;

  return <Routes router={route || {}} {...props} />;
};

export default App;
