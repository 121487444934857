import { v4 } from "uuid";

const transportTypeChipLabelData = ["Автобус", "Электробус", "Трамвай", "Троллейбус"];
const routeTypeChipLabelData = ["Магистральный",  "Районный", "Социальный", "Ночной"];
const attributeTypeChipLabelData = ["Муниципальный", "Внутригородской"];
const routeGeometryChipLabelData = ["Маятниковый", "Кольцевой"];

export const transportTypeChipLabels = transportTypeChipLabelData.map((label) => ({
  id: v4(),
  label,
}));

export const routeTypeChipLabel = routeTypeChipLabelData.map((label, id) => ({
  id: (id + 1).toString(),
  label,
}));

export const attributeTypeChipLabel = attributeTypeChipLabelData.map((label) => ({
  id: v4(),
  label,
}));

export const routeGeometryChipLabel = routeGeometryChipLabelData.map((label) => ({
  id: v4(),
  label,
}));
