import styled from "styled-components";
import { withAccentColor } from "shared/utils";
import { switchProp } from "styled-tools";
import { withContentColor } from "@megapolis/uikit/utils";

interface SwitchIconWrapperProps {
  isActive?: boolean;
}

export const Wrapper = styled.div<SwitchIconWrapperProps>`
  width: 100%;
  height: 32px;
  margin: auto;
  display: flex;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  // prettier-ignore
  svg {
    width: 100%;
    display: block;
    color: ${switchProp("isActive", {
    true: withAccentColor("blue", "basic"),
    false: withContentColor("secondary"),
  })};
  }
`;
