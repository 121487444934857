export enum TabValues {
  /** Все */
  ALL = "all",
  /** Новые */
  NEW = "new",
  /** На утверждении */
  ONAPPROVAL = "onapproval",
  /** Согласованные */
  AGREED = "agreed",
  /** Активные */
  ACTIVE = "active",
  /** Неактивные */
  INACTIVE = "inactive",
  /** На связи */
  ONLINE = "online",
  /** Нет связи */
  OFFLINE = "offline",
  /** Планируемые */
  PLANNED = "planned",
  /** архивные */
  ARCHIVED = "archived",
}

export const ItemsCountMap: { [p: string]: number } = {
  [TabValues.ALL]: 100,
  [TabValues.NEW]: 25,
  [TabValues.ONAPPROVAL]: 40,
  [TabValues.AGREED]: 10,
  [TabValues.ACTIVE]: 20,
  [TabValues.INACTIVE]: 5,
};
