import React, { useCallback, useEffect, useState } from "react";
import { FormField } from "features/detail-panels/components";
import { Chips, ChipsItem, TestButton } from "components";
import { labels } from "shared/constants";
import { VehicleTypeArray } from "models";
import { ContractorMultiSelectField } from "components/common/select-with-source";
import { Content } from "@megapolis/uikit/button/button.types";
import { Kind, Priority } from "components/ui/buttontest/button.types";
import { useDispatch } from "react-redux";
import { routeActions } from "store/actions";
import { useRouteFiltersSelector } from "store/selectors/route-selectors";
import { CapacityTypesFilter, RouteTypesFilter, VehicleTypesFilter } from "features/filter-element";
import * as Markup from "./route-filter-content.style";

export const RouteFilterContent = (props: { hide: () => void }) => {

  type Filter = {
    ContractorIds?: string | null,
    RouteKindIds?: string | null,
    TransportTypes?: string | null,
    CapacityTypes?: string | undefined,
  };

  const initialFIlter = {
    ContractorIds: null,
    RouteKindIds: null,
    TransportTypes: "",
    CapacityTypes: undefined,
  };
  const dispatch = useDispatch();
  const filters = useRouteFiltersSelector();
  const [filter, setFilter] = useState<Filter>(filters);

  const handleSubmit = useCallback(() => {
    dispatch(routeActions.setFilters(filter));
    props.hide();
  }, [filter]);

  const handleReset = useCallback(() => {
    dispatch(routeActions.setFilters(initialFIlter));
    setFilter(initialFIlter);
  }, [initialFIlter]);

  const updateValue = useCallback(<P extends keyof Filter>(name: P, value: Filter[P] | null) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  }, []);

  return (


    <><Markup.Container>
      <FormField>
        <VehicleTypesFilter TransportTypes={filter.TransportTypes || undefined} field={"TransportTypes"} updateValue={updateValue} />
      </FormField>
      <FormField>
        <RouteTypesFilter RouteTypes={filter.RouteKindIds || ""} field={"RouteKindIds"} updateValue={updateValue} />
      </FormField>
      <FormField>
        <ContractorMultiSelectField
          value={filter.ContractorIds ? filter.ContractorIds?.split(",") : null}
          onChange={el => {
            const result = el.map(({ value }) => value);
            updateValue("ContractorIds", result.toString());
          }}
          label={labels.contractor}
          cyData="route-contractor"
          errorText={undefined}
          hasError={false}
        />
      </FormField>
      <FormField>
        <CapacityTypesFilter CapacityTypesFilter={filter.CapacityTypes} field={"CapacityTypes"} updateValue={updateValue} />
      </FormField>
    </Markup.Container>
      <Markup.Footer>
        <TestButton content={Content.Text} priority={Priority.Tertiary} text="Применить фильтр" onClick={handleSubmit} cyData="Filter" />
        <TestButton kind={Kind.Danger} content={Content.Text} priority={Priority.Tertiary} text="Сброс" onClick={handleReset} cyData="Filter" />
      </Markup.Footer>
    </>);
};
