import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getVehicleType, VehicleDetails, VehicleRequestItem } from "models";
import { VehicleCard } from "features/cards";
import { vehicleActions } from "store/actions";
import { PaginationCardList } from "../components/pagination-card-list";
import {
  useVehicleFilterSelector,
  useVehicleFiltersSelector,
  useVehicleSearchSubstringSelector,
  useVehiclesItemsSelector,
} from "../../../store/selectors/vehicle-selectors";
import { TabValues } from "../../list-panels";
import { getVehicleRequest } from "../../../api";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

const DEFAULT_PAGE_SIZE = 200;

export type VehicleCardListProps = {
  activeId?: ID;
  onClick?: (id: ID) => void;
  fetcher?: typeof getVehicleRequest;
};

const VehicleCardListComponent = (props: VehicleCardListProps) => {
  const { activeId, onClick } = props;
  const activeFilter = useVehicleFilterSelector();
  const filter = useVehicleFiltersSelector();
  const { items, totalCount } = useVehiclesItemsSelector(activeFilter);
  const searchSubstring = useVehicleSearchSubstringSelector();
  const { showDetails } = useLayoutDesignState();
  const [pageNum] = useState(1);
  const dispatch = useDispatch();

  const firstItem = items[0];
  const activeIndex = useMemo(() => items.findIndex((item) => String(item.versionId) === String(activeId)), [items, activeId]);

  useEffect(() => {
    if (!activeId && firstItem?.versionId && onClick) {
      onClick(String(firstItem.versionId));
    }
  }, [activeId, firstItem?.versionId, onClick]);

  const handleClick = useCallback((id: ID) => {
    if (onClick) {
      onClick(id);
      // showDetails();
    }
  }, [onClick, showDetails]);

  const options = useMemo(() => {
    const { RouteIds, RouteFilterElements, ...rest } = filter;
    return {
      IsActive: activeFilter === TabValues.ACTIVE,
      vehiclePlateFilter: searchSubstring || undefined,
      RouteIds: RouteFilterElements?.map(el => el.routeId).join(",") || undefined,
      ...rest,
    };
  }, [activeFilter, searchSubstring, filter]);

  const params = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
  }), [pageNum]);

  const setItems = useCallback((response: VehicleRequestItem) => {
    dispatch(vehicleActions.addVehicles(activeFilter, response));
  }, [dispatch, activeFilter]);

  const itemRenderer = useCallback((item: VehicleDetails) => {
    const { plate, contractor, transportType, versionId } = item;
    return (
      <VehicleCard
        id={versionId}
        type={getVehicleType(transportType)}
        registrationNumber={plate}
        contractor={contractor.name}
        isActive={String(activeId) === String(versionId)}
        onClick={handleClick}
        isActual={TabValues.ACTIVE === activeFilter}
      />
    );
  }, [activeId, handleClick, activeFilter]);

  return (
    <PaginationCardList
      items={items}
      itemRender={itemRenderer}
      options={options}
      params={params}
      fetcher={getVehicleRequest}
      setItems={setItems}
      totalCount={totalCount}
      scrollTo={activeIndex}
    />
  );
};

export const VehicleCardListPagination = memo(VehicleCardListComponent);
