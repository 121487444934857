import { getRouteRequest, GetRouteRequestOptions, RoutesVersionResponse, RouteVersion } from "api";
import { RouteCard } from "features/cards";
import { getVehicleType, VehicleTypeCaptionMap } from "models";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { labels } from "shared/constants";
import { useRouteFilterSelector, useRouteSearchSubstringSelector, useRouteFiltersSelector } from "store/selectors/route-selectors";
import styled from "styled-components";

const DEFAULT_PAGE_SIZE = 100;


const NoDataLabel = styled.div`
  width: 100%;
  padding: 10px 0;
  background-color: #dbdbdb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;

const Text = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
  padding: 0 16px;
`;

export type RouteCardListPaginationProps = {
  VehicleIds?: string;
  DepotIds?: string;
  DriverIds?: string
  ContractorIds?: string;
  StopIds?: string;
};

const RouteTabListPaginationComponent = (props: RouteCardListPaginationProps) => {
  const { VehicleIds, DepotIds, DriverIds, ContractorIds, StopIds } = props;
  const activeFilter = useRouteFilterSelector();
  const [items, setItems] = useState<RouteVersion[]>([]);
  const searchSubstring = useRouteSearchSubstringSelector();
  const filters = useRouteFiltersSelector();
  const [pageNum] = useState<number>(1);

 const params = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
  }), [pageNum]);

  const options: GetRouteRequestOptions = useMemo(() => ({
    VehicleIds,
    DepotIds,
    DriverIds,
    ContractorIds,
    StopIds,
  }), [VehicleIds, DepotIds, DriverIds, ContractorIds, StopIds]);

  const handleSetItems = useCallback((response: RoutesVersionResponse) => {
    setItems(response.items);
  }, []);

  const fetch = useCallback(async ()=>{
    const payload = await getRouteRequest({ ...params, ...options });
    handleSetItems(payload);
  }, []);

  useEffect(()=>{
    fetch();
    return ()=> {
      setItems([]);
    };
  }, [VehicleIds, DepotIds, DriverIds, ContractorIds, StopIds]);

  

  const itemRender = useCallback((item: RouteVersion) => {
    const { versionId, transportType, number, name, kind, contractor, appearance = {
      backgroundColor: "#FFFFFF",
      borderColor: "#000000",
      foregroundColor: "#FFFFFF",
    } } = item;
    const vehicleData = {
      contractorName: contractor?.name || labels.noValue,
      routeType: kind ? `Тип трассы ${kind}` : "",
      transportType: `Тип транспорта ${VehicleTypeCaptionMap[getVehicleType(transportType)]}`,
    };

    return (
      <RouteCard
        id={String(versionId)}
        key={String(versionId)}
        routeName={name || "Трасса отстутствует"}
        routeNumber={String(number)}
        routeIconColor={appearance?.backgroundColor}
        routeNumberColor={appearance?.foregroundColor}
        routeBorderColor={appearance?.borderColor}
        isActive={false}
        vehicleData={vehicleData}
        onClick={()=>{}}
      />
    );
  }, []);

  return <Container>{
    items.length > 0 ?  items.map(item => itemRender(item)) : <NoDataLabel><Text>Нет данных</Text></NoDataLabel>
  }
  </Container>;
};

export const RouteTabListPagination =  memo(RouteTabListPaginationComponent);