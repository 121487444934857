import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "react-router5";
import { PAGE_ROUTES } from "../../../../shared/definitions";
import { stopActions } from "../../../../store/actions";
import { getStopVersionsDatesRequest } from "../../../../api";
import { getLastItem } from "../../../../shared/utils/array";
import { MapLayerNames } from "../utils/consts/map.constants";
import { useMap } from "../map.context";

export const useOnStopClick = () => {
  const { map } = useMap();
  const router = useRouter();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onStop = useCallback(async (e) => {
    if (!e.features) return;

    const stopId = JSON.parse(e.features[0]?.properties?.stopId);

    if (stopId) {
      const response = await getStopVersionsDatesRequest({ stopId });
      const actualVersion = getLastItem(response);

      if (actualVersion) {
        const { versionId } = actualVersion;
        router.navigate(PAGE_ROUTES.STOP_STATION, { id: `${stopId}_${versionId}` });
        dispatch(stopActions.setActiveItem(`${stopId}_${versionId}`));
      }
    }
  }, [dispatch, router]);

  useEffect(() => {
    if (map) {
      map.on("click", MapLayerNames.ACTUAL_STOP_LAYER_ID, onStop);
    }

    return () => {
      if (map) {
        map.off("click", MapLayerNames.ACTUAL_STOP_LAYER_ID, onStop);
      }
    };
  }, [map, onStop]);
};
