import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { driverActions, keyZoneActions, routeActions, stopActions, vehicleActions } from "store/actions";
import { ListPanelHeaderSearch, ListPanelHeaderMain } from "./components";
import * as Markup from "./list-panel-header.styles";

export type ListPanelHeaderProps = {
  title: string;
  className?: string;
  onAddClick?: () => void;
  onFilterClick?: () => void;
  requestPath?: string;
};

const ListPanelHeaderComponent = (props: ListPanelHeaderProps) => {
  const { title, className, onAddClick, requestPath, onFilterClick } = props;
  const [searchActive, setSearchActive] = useState(false);
  const dispatch = useDispatch();
  const handleSearchClick = useCallback(() => {
    setSearchActive(true);
  }, []);

  const handleSearchCancelClick = useCallback(() => {
    setSearchActive(false);
    switch (requestPath) {
      case "StopStation":
        dispatch(stopActions.setSearchSubstring(""));
        break;
      case "Vehicle":
        dispatch(vehicleActions.setSearchSubstring(""));
        break;
      case "Route":
        dispatch(routeActions.setSearchSubstring(""));
        break;
      case "Driver":
        dispatch(driverActions.setSearchSubstring(""));
        break;
      case "KeyZone":
        dispatch(keyZoneActions.setSearchSubstring(""));
        break;
      default:
        break;
    }
  }, [dispatch, requestPath]);

  return (
    <Markup.Container className={className}>
      {searchActive ? (
        <ListPanelHeaderSearch requestPath={requestPath} onCancelClick={handleSearchCancelClick} />
      ) : (
        <ListPanelHeaderMain title={title} onSearchClick={handleSearchClick} onAddClick={onAddClick} onFilterClick={onFilterClick} />
      )}
    </Markup.Container>
  );
};

export const ListPanelHeader = memo(ListPanelHeaderComponent);

