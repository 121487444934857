import { shallowEqual, useSelector } from "react-redux";
import { ContractorFilters } from "store/reducers/contractor/contractor.types";
import { RootState } from "../../types";
import { ContractorResponse } from "../../../api";

export const useContractorItemsSelector = (filter: ContractorFilters): { totalCount: number, items: ContractorResponse[] } =>
  useSelector((state: RootState) => {
    const contractors = state.contractor[filter];
    if (!contractors) return { totalCount: 0, items: [] };
    const { totalCount } = contractors;

    // return contractors.items.reduce((acc, item) => [...acc, ...item], []).slice(0, totalCount) || [];
    return { totalCount, items: contractors.items.reduce((acc, item) => [...acc, ...item], []).slice(0, totalCount) || [] };
  }, shallowEqual);


export const useContractorActiveFilter = () => useSelector((state: RootState) => state.contractor.contractorActiveFilter);
export const useSelectedContractorIdSelector = () => useSelector((state: RootState) => state.contractor.selectedContractor);
export const useIsContractorCardLoading = () => useSelector((state: RootState) => state.contractor.isCardLoading);
export const useContractorsFiltersSelector = () => useSelector((state: RootState) => state.contractor.contractorFilters);
export const useSelectedContractorVersion = (versionId: ID) => useSelector((state: RootState) => state.contractor.contractorByVersionId[versionId]);
