import React, { ChangeEvent } from "react";
import { FieldProps } from "../form.types";
import { MaskedTextField, TextField } from "../../../ui";
import { SelectField } from "../../select-field";
import { labels } from "../../../../shared/constants";
import { Option } from "../../select/select.types";
import * as Markup from "../form.styles";

export const Field = <T extends Record<keyof T, T[keyof T]>>(props: FieldProps<T> & { cyData?: string } ) => {
  const { name, values, updateValue, children, type, label, errors, cyData } = props;

  if (children) {
    return children(values, updateValue);
  }


  const renderField = () => {
    switch (type) {
      case "text": {
        const { placeholder = labels.enterValue } = props;
        const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
          const { value: newValue } = event.target;
          const clearedValue = newValue;

          updateValue(name, clearedValue as T[keyof T]);
        };

        return <TextField
          value={values[name] as string ?? ""}
          onChange={handleChangeInput}
          data-cypress={cyData}
          label={<Markup.Label>{label}</Markup.Label>}
          errorText={errors?.[name] ?? undefined}
          hasError={!!errors?.[name]}
          placeholder={placeholder}
        />;
      }

      case "masked-text": {
        const { mask, placeholder } = props;
        const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
          const { value: newValue } = event.target;
          const clearedValue = newValue.replaceAll("_", "").trim();

          updateValue(name, clearedValue as T[keyof T]);
        };

        return <MaskedTextField
          value={values[name] as string ?? ""}
          label={<Markup.Label>{label}</Markup.Label>}
          data-cypress={cyData}
          onChange={handleChangeInput}
          mask={mask}
          errorText={errors?.[name] ?? undefined}
          hasError={!!errors?.[name]}
          placeholder={placeholder}
        />;
      }

      case "select": {
        const { updateValue: handler, options } = props;
        const handleSelect = (option: Option) => {
          handler(name, option);
        };

        return <SelectField
          options={options}
          onChange={handleSelect}
          data-cypress={cyData}
          value={values[name] as Option}
          label={<Markup.Label>{label}</Markup.Label>}
          errorText={errors?.[name] ?? undefined}
          hasError={!!errors?.[name]}
        />;
      }

      default: {
        return null;
      }
    }
  };

  return <Markup.Block>{renderField()}</Markup.Block>;
};
