import React from "react";
import styled from "styled-components/macro";

const Container = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0 16px;
  overflow: auto;
`;

export const ScrollContainer = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0 16px;
  height: calc(100vh - 115px);
  overflow-y: scroll;
  overflow-x: hidden;
  content-visibility: auto;
  contain-intrinsic-size: 30px;
`;

export const Item = styled.li`
  padding: 4px 0;
`;

export type CardListProps = {
  children: React.ReactNode;
  className?: string;
};

type ListComponent = React.FunctionComponent<CardListProps> & {
  Item: typeof Item;
};

export const CardList: ListComponent = ({ className, children }: CardListProps) => (
  <Container className={className}>{children}</Container>
);

CardList.Item = Item;
