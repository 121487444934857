import { TabValues } from "../../../features";
import { IInitialDepotState } from "./depot.types";

export const initialState: IInitialDepotState = {
  depotFilters: {
    ContractorIds: "",
    TransportTypes: "",
    RouteIds: "",
  },
  depotByVersionId: {},
  activeDepotId: "",
  depotSearchSubstring: null,
  depotActiveFilter: TabValues.ACTIVE,
  currentDepotStation: undefined,
  [TabValues.ACTIVE]: null,
  [TabValues.INACTIVE]: null,
};

export type DepotFilter = {
  ContractorIds?: string | null,
  TransportTypes?: string | null,
  RouteIds?: string | null,
};
