import { useCallback, useEffect, useRef, useState } from "react";

interface Props<T, O, P> {
  callback: (response: T, params?: P) => void;
  fetcher: (props: (O & P) | O) => Promise<T>;
  options: O;
  initialParams?: P;
}

export const useFetch = <T, O, P>(props: Props<T, O, P>) => {
  const { callback, fetcher, options, initialParams } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const optionsRef = useRef(options);
  const callbackRef = useRef(callback);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const fetchHandler = useCallback(
    async (params?: P) => {
      try {
        setIsLoading(true);
        const data = await fetcher({ ...options, ...params });
        if (callbackRef.current !== callback && optionsRef.current === options) return;

        callback(data, params);
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [fetcher, options, callback, optionsRef, callbackRef],
  );

  /** Initial loading */
  useEffect(() => {
    fetchHandler(initialParams);
  }, [fetchHandler, initialParams]);

  const fetchMore = useCallback(
    (params: P) => {
      fetchHandler(params);
    },
    [fetchHandler],
  );

  return { isLoading, fetchMore, error };
};
