import React, { useCallback, useMemo, useState } from "react";
import { Content } from "@megapolis/uikit/button/button.types";
import { BusstIcon, EditBlue32Icon } from "assets/icons";
import { useRouter } from "react-router5";
import { Button } from "components";
import { PAGE_ROUTES } from "shared/definitions/routes";
import { useRouteParams } from "shared/hooks";
import styled from "styled-components";
import { VersionViewer } from "components/ui/version-card";
import { VersionViewerContent } from "features/version-view";
import { useDispatch } from "react-redux";
import { stopActions } from "store/actions";
import { getStopStationVersionsDates } from "api";
import { formatDateToString } from "../../../../../shared/utils/date";
import { labels } from "../../../../../shared/constants";
import { useGetStopItemsByVersion } from "../stop-station-details.data";
import { DetailsPanelHeader } from "../../../components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const StopStationDetailsPanelHeader = () => {
  const { id } = useRouteParams();
  const { item } = useGetStopItemsByVersion();
  const router = useRouter();
  const { id: currentPathId } = useRouteParams();
  const dispatch = useDispatch();

  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => {
    setIsShown(!isShown);
  };

  const handleEditClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.STOP_STATION, { id: `${id}_edit` });
    },
    [router, id],
  );

  const handleEditVersionClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.STOP_STATION, { id: `${id}_version` });
    },
    [router, id],
  );

  const handleItemClick = useCallback((versionId: any) => {
    const stopId = currentPathId.split("_")?.[0];
    dispatch(stopActions.setActiveItem(`${stopId}_${versionId}`));
    router.navigate(PAGE_ROUTES.STOP_STATION, { id: `${stopId}_${versionId}` });
    setIsShown(!isShown);
  }, [currentPathId, dispatch, isShown, router]);

  const handleCreateClick = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.STOP_STATION, { id: "new" });
    },
    [router],
  );

  const versionCaption = useCallback((itm: any) => {
    let versionString = "";
    if (itm?.startDate) {
      versionString =  `Версия от ${itm?.startDate}`;
    } else if (!itm?.startDate && itm?.endDate) {
      versionString =  `Версия до ${itm?.endDate}`;
    } else {
      versionString =  "Версия бессрочная";
    }
    return versionString;
  }, []);


  const date = useMemo(() => item?.startDate ? formatDateToString(new Date(item.startDate)) : labels.noValue, [item?.startDate]);

  return (
    <DetailsPanelHeader
      title={item?.name || labels.noValue}
      caption={<Container onClick={toggle} ><span >{versionCaption(item)}</span><VersionViewer isShown={isShown} hide={toggle} modalContent={<VersionViewerContent onEdit={handleEditVersionClick} currentVersion={item} id={item?.id || ""} handleItemClick={handleItemClick} fetchVersions={getStopStationVersionsDates} />} headerText={"Версионность"} onCreateClick={handleCreateClick} /></Container>}
      icon={<BusstIcon />}
      rightAction={
        <Button onClick={handleEditClick} icon={<EditBlue32Icon />} content={Content.Icon} priority="quaternary" />
      }
    />
  );
};
