import React, { memo } from "react";
import { Tab } from "components/ui";
import { DetailsPanelTabs } from "../../../components";
import { TAB_VALUES } from "../driver-details.constants";

export type TabsProps = {
  value: string;
  onChange: (val: string) => void;
};

const DriverDetailsTabsComponent = (props: TabsProps) => {
  const { value, onChange } = props;
  return (
    <DetailsPanelTabs value={value} onChange={onChange}>
      <Tab value={TAB_VALUES.info}>Справка</Tab>
      <Tab value={TAB_VALUES.routes}>Трассы</Tab>
    </DetailsPanelTabs>
  );
};

export const DriverDetailsTabs = memo(DriverDetailsTabsComponent);
