import React from "react";
import styled from "styled-components/macro";
import { Contractor } from "models";

const CaptionBlock = styled.span`
  display: flex;
  flex-flow: column nowrap;
`;

const ContractorBlock = styled.span``;

export type DepotCardCaptionProps = {
  contractor: Contractor;
};

export const DepotCardCaption = ({ contractor }: DepotCardCaptionProps) => (
  <CaptionBlock>
    <ContractorBlock>{contractor?.shortName}</ContractorBlock>
  </CaptionBlock>
);
