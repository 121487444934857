import { withNeutralColor } from "shared/utils";
import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
`;

export const Label = styled.span`
font-weight: 500;
line-height: 16px;
padding: 0 8px 4px 8px;
width: 100%;
`;

export const Header = styled.span`
  padding: 16px 16px 0 24px;
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const LabelContainer = styled.span`
  margin-bottom: 8px;
`;

export const Heading = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding-left: 8px;
`;

export const MainBlock = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 8px 16px;
  overflow: auto;
`;

export const UploadAreaCaption = styled.span`
  display: block;
  color: ${withNeutralColor("l1")};
  padding: 4px 8px;
  margin-top: 8px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  border-top: 1px solid ${withNeutralColor("l4")};
  width: 100%;
  padding: 16px;
  button:first-child {
    margin-right: 8px;
  }
`;

export const BlockLabel = styled.span`
  font-weight: 500;
  padding-left: 8px;
`;

export const Block = styled.div`
  margin-top: 8px;
`;

export const Chips = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 8px 0;
  button {
    color: ${withNeutralColor("d4")};
    margin: 0 8px 8px 0;
  }
`;

export const UploadAreaLabel = styled.span`
  display: block;
  font-weight: 500;
  padding-left: 8px;
  margin-bottom: 8px;
`;
