import * as ButtonTypes from "./button.types";

export const getIconByProps = (props: ButtonTypes.Props) => {
  switch (props.content) {
    case ButtonTypes.Content.IconText:
    case ButtonTypes.Content.TextIcon:
    case ButtonTypes.Content.IconCaption:
    case ButtonTypes.Content.Icon:
      return props.icon;
    default:
      return null;
  }
};

export const getTextByProps = (props: ButtonTypes.Props) => {
  switch (props.content) {
    case ButtonTypes.Content.Text:
    case ButtonTypes.Content.IconText:
    case ButtonTypes.Content.TextIcon:
    case ButtonTypes.Content.TextCaption:
      return props.text;
    default:
      return null;
  }
};

export const getCaptionByProps = (props: ButtonTypes.Props) => {
  switch (props.content) {
    case ButtonTypes.Content.TextCaption:
    case ButtonTypes.Content.IconCaption:
      return props.caption;
    default:
      return null;
  }
};
