import React, { memo, useCallback } from "react";
import { Card } from "components/ui";
import { Depot24Icon } from "assets/icons";
import { Contractor } from "models";
import { DepotCardCaption } from "./components";

export type DepotCardProps = {
  /** Идентификатор */
  id: number;
  /** Наименование парка */
  name: string;
  /** Перевозчик */
  contractor: Contractor;
  /** Признак активности карточки */
  isActive?: boolean;
  /** Обработчик нажатия на карточку */
  onClick?: (id: number) => void;
};

const DepotCardComponent = (props: DepotCardProps) => {
  const { id, name, isActive = false, onClick, ...captionProps } = props;

  const handleClick = useCallback(() => onClick?.(id), [onClick, id]);

  return (
    <Card
      title={name}
      caption={<DepotCardCaption {...captionProps} />}
      icon={<Depot24Icon />}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};

export const DepotCard = memo(DepotCardComponent);
