import { getPavilionHolders, getVehicleCapacityTypesRequest } from "api";
import { Chips, ChipsItem } from "components/common";
import { VehicleClassTypeArrayString } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { labels } from "shared/constants";

export type FilterProps = {
  PavilionTypesFilter: string | undefined | null;
  updateValue: any;
  field: string;
};

export const Filter = (props: FilterProps) => {

  const { PavilionTypesFilter, updateValue, field } = props;
  const [pavilionTypeArray, setPavilionTypeArray] = useState<string[]>(PavilionTypesFilter ? PavilionTypesFilter.split(",") : []);
  const [pavilionHoldersChips, setPavilionHoldersChips] = useState<{ id: string, label: string }[]>([]);
  const pavilionAllExp = useMemo(()=> PavilionTypesFilter !== undefined ? PavilionTypesFilter?.length === pavilionHoldersChips.map(e=>e.id).toString().length : false, [PavilionTypesFilter, pavilionHoldersChips]);
  const [pavilionAll, setPavilionAll] = useState(pavilionAllExp);

  const getPavilionHoldersChips = useCallback(async () => {
    const pavilionHolders = await getPavilionHolders();
    const result = pavilionHolders.map(item => ({ id: item.id.toString(), label: item.name }));
    setPavilionHoldersChips(result);
    setPavilionAll(pavilionAllExp);
  }, [pavilionAllExp]);

  useEffect(() => {
    setPavilionAll(pavilionAllExp);
  }, [pavilionAllExp]);

  useEffect(() => {
    getPavilionHoldersChips();
  }, []);

  const allClick = useCallback(() => {
    if (pavilionAll) {
      updateValue(field, "");
      setPavilionTypeArray([]);
      setPavilionAll(false);
    } else {
      updateValue(field, pavilionHoldersChips.map(e => e.id).toString());
      setPavilionTypeArray(pavilionHoldersChips.map(e => e.id));
      setPavilionAll(true);
    }
  }, [pavilionHoldersChips, pavilionAll]);

  return (
    <Chips label={labels.balanceholder}>
      <ChipsItem isActive={pavilionAll} onClick={allClick} id={"all"} label={"Все"} />
      {pavilionHoldersChips.map((chip) => (
        <ChipsItem
          key={chip.id}
          isActive={PavilionTypesFilter !== undefined ? PavilionTypesFilter?.split(",").findIndex(el => el === chip.id) !== -1 : false}
          {...chip}
          onClick={({ id }) => {
            const idx = pavilionTypeArray.indexOf(id);
            if (idx === -1) {
              setPavilionTypeArray(oldArray => {
                const arr = [...oldArray, id];
                updateValue(field, arr.toString());
                return arr;

              });
            } else {
              setPavilionTypeArray(oldArray => {
                const filtered = oldArray.filter((element) => element !== id);
                updateValue(field, filtered.toString());
                return filtered;
              });
            }
          }}
        />
      ))}
    </Chips>
  );
};

export const PavilionTypesFilter = React.memo(Filter);