import React, { useCallback, PropsWithChildren } from "react";
import { TabsContext } from "./tabs.context";
import * as Markup from "./tabs.styles";

export type TabsProps = PropsWithChildren<{
  value: string;
  onChange?: (value: string) => void;
  className?: string;
}>;

export const Tabs = ({ children, value, onChange, className }: TabsProps) => {
  const handleClick = useCallback(
    (newValue: string) => {
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );
  return (
    <TabsContext.Provider
      value={{
        activeTabValue: value,
        onClick: handleClick,
      }}>
      <Markup.Container className={className}>{children}</Markup.Container>
    </TabsContext.Provider>
  );
};
