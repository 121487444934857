import styled, { css } from "styled-components/macro";
import { theme } from "styled-tools";

const commonCss = css`
  margin: 0;
  padding: 0;
  color: ${theme("heading.color")};
`;

export const H1 = styled.h1`
  ${commonCss};
  line-height: 48px;
  font-size: 36px;
  font-weight: 700;
`;

export const H2 = styled.h2`
  ${commonCss};
  line-height: 32px;
  font-size: 24px;
  font-weight: 600;
`;

export const H3 = styled.h3`
  ${commonCss};
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
`;

export const H4 = styled.h4`
  ${commonCss};
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
`;

export const H5 = styled.h5`
  ${commonCss};
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
`;
