import { useCallback, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { keyZoneActions } from "store/actions";
import { useRouteParams } from "shared/hooks";
import { getKeyZoneVersionRequest } from "api";

export const useGetKeyZoneItemsByVersion = () => {
  const dispatch = useDispatch();
  const { id } = useRouteParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const  pageId = typeof id !== "undefined" ? id : "";
  const versionId = useMemo(() => !pageId.toString().includes("_") ? pageId : pageId.split("_")[0], [pageId]);
  // const  pageId = typeof id !== "undefined" ? id : "";
  // const versionId = useMemo(() => !pageId.toString().includes("_edit") ? pageId : pageId.split("_")[0], [pageId]);
  const stopId = "";
  const { keyZoneByVersionId } = useSelector((state: RootState) => ({
    keyZoneByVersionId: state.keyZone.keyZoneByVersionId ?? {},
  }), shallowEqual);

  // eslint-disable-next-line consistent-return
  const fetchKeyZone = useCallback(async (verId) => {
    if (!(verId in keyZoneByVersionId)) {
      setIsLoading(true);
      try {
        const payload = await getKeyZoneVersionRequest(verId);
        dispatch(keyZoneActions.getKeyZoneByVersionId(verId, payload));
        return payload;
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    } else return keyZoneByVersionId[verId];
  }, [keyZoneByVersionId, dispatch]);



  return {
    keyZoneDetails: keyZoneByVersionId[versionId] ?? null,
    fetchKeyZone,
    versionId,
    isLoading,
    stopId,
  };
};