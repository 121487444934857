import { GetScheduleByRouteIdResponce, getScheduleRequest, GetScheduleRequestOptions, ScheduleItem } from "api";
import { ScheduleCard } from "features/cards/schedule-card";
import { TabValues } from "features/list-panels";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { AutoSizer, List, ListRowRenderer } from "react-virtualized";

const DEFAULT_PAGE_SIZE = 100;

export type CardListPaginationProps = {
  activeId?: string;
  onClick?: (id: string) => void;
  routeId?: string;
  activeFilter?: string;
};

const ScheduleCardListPaginationComponent = (props: CardListPaginationProps) => {
  const { routeId, activeId, onClick, activeFilter = TabValues.ALL } = props;
  // const activeFilter = useRouteFilterSelector();
  const [items, setItems] = useState<GetScheduleByRouteIdResponce["schedules"]>([]);

  const options: GetScheduleRequestOptions = useMemo(() => ({
    routeId: routeId || "",
  }), [routeId]);

  const handleSetItems = useCallback((response: GetScheduleByRouteIdResponce) => {
    setItems(response.schedules);
  }, []);

  const handleClick = useCallback((id: string) => {
    if (onClick) {
      onClick(id);
      // showDetails();
    }
  }, [onClick]);

  const sortArray = useCallback((schedules, filter) => {
    let filterArr;
    switch (filter) {
      case "active":
        filterArr = schedules.filter((el: any) => el.status === "Active");
        break;
      case "all":
        filterArr = schedules;
        break;
      case "inactive":
        filterArr = schedules.filter((el: any) => el.status === "Inactive");
        break;
      default:
        filterArr = [];
        break;
    }
    return filterArr;
  }, []);

  const fetch = useCallback(async () => {
    const payload = await getScheduleRequest({ ...options });
    handleSetItems({ ...payload, schedules: sortArray(payload.schedules, activeFilter) });
    if (onClick) {
      onClick(payload.schedules[0].versionId.toString());
    }
  }, [activeFilter, handleSetItems, onClick, options, sortArray]);

  useEffect(() => {
    fetch();
    return () => {
      setItems([]);
    };
  }, [fetch, options, activeFilter]);



  const itemRender = useCallback((item: ScheduleItem) => {
    const { versionId } = item;

    return (
      <ScheduleCard
        key={`key_${versionId}`}
        id={String(versionId)}
        isActive={String(activeId) === String(versionId)}
        onClick={handleClick}
        {...item}
      />
    );
  }, [handleClick, activeId]);

  const rowRenderer: ListRowRenderer = useCallback(({ index, style, key }) => {
    const item = items[index];
    const updatedStyles = {
      ...style,
      height: (Number(style.height)) - 8,
      top: ((Number(style.top) || 0) + 8 / 2),
    };

    return <div style={updatedStyles} key={key} >
      {itemRender(item)}
    </div>;
  }, [items, itemRender]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {
        <AutoSizer>
          {({ width, height }) =>
            <List
              width={width}
              height={height}
              rowRenderer={rowRenderer}
              rowCount={items.length}
              rowHeight={46 + 8}
              scrollToAlignment={"center"}
              // noRowsRenderer={() => items.length !== -1 ? <Markup.NoDataLabel><Markup.Text>Нет данных</Markup.Text></Markup.NoDataLabel> : <SkeletonCard count={15} />}
              className={"pagination-card-list"}
            />
          }
        </AutoSizer>
      }

    </div>
  );
};

export const ScheduleCardListPagination = memo(ScheduleCardListPaginationComponent);
