import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DatePickerHeader, DatePickerHeaderProps } from "./date-picker-header";
import { DatePickerMonths } from "./date-picker-months";
import * as Markup from "./date-picker.styles";
import type { DatePickerProps, DateRangePickerProps } from "./date-picker.types";

type Args = Pick<DatePickerProps, "minDate" | "maxDate" | "zIndex"> & {
  startDate?: DateRangePickerProps["startDate"];
  endDate?: DateRangePickerProps["endDate"];
  value?: DatePickerProps["value"];
};

export const useDatePickerProps = (props: Args) => {
  const { startDate, endDate, value, minDate, maxDate, zIndex = 1 } = props;
  const [isMonthSelection, setMonthSelection] = useState(false);
  const [openToDate, setOpenToDate] = useState<Date>(new Date());

  const handleMonthChange = useCallback((date: Date) => {
    setOpenToDate(date);
    setMonthSelection(false);
  }, []);

  useEffect(() => {
    setOpenToDate(value || startDate || endDate || new Date());
  }, [value, startDate, endDate]);

  const calendarContainer = useMemo(
    () =>
      isMonthSelection
        ? () => (
            <Markup.Popup>
              <DatePickerMonths date={openToDate} onChange={handleMonthChange} minDate={minDate} maxDate={maxDate} />
            </Markup.Popup>
          )
        : Markup.Popup,
    [openToDate, isMonthSelection, handleMonthChange, minDate, maxDate],
  );

  const popperContainer = useCallback(
    ({ children }: React.PropsWithChildren<any>) => {
      if (!children) return null;
      return <Markup.PopupContainer zIndex={zIndex}>{children}</Markup.PopupContainer>;
    },
    [zIndex],
  );

  const renderCustomHeader = useCallback(
    (headerProps: Omit<DatePickerHeaderProps, "onMonthClick">) => (
      <DatePickerHeader onMonthClick={() => setMonthSelection(true)} {...headerProps} />
    ),
    [],
  );

  return { calendarContainer, popperContainer, openToDate, renderCustomHeader };
};
