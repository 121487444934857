import React, { memo } from "react";
import { StackHeader, IconButton } from "components/common";
import { MeatBall24BlueIcon, ArrowDown16BlueIcon } from "assets/icons";
import * as Markup from "./details-panel-header.styles";

export type DetailsPanelHeaderProps = {
  /** Заголовок */
  title: string;
  /** Подпись */
  caption: string | React.ReactNode;
  /** Иконка слева */
  icon: React.ReactNode;
  /** Контроллер справа */
  rightAction?: React.ReactNode;
};

export const DetailsPanelHeaderComponent = (props: DetailsPanelHeaderProps) => {
  const { title, icon, caption, rightAction } = props;
  return (
    <StackHeader>
      <Markup.Container>
        <Markup.Icon>{icon}</Markup.Icon>
        <Markup.Content>
          <Markup.Text>{title}</Markup.Text>
          <Markup.Caption>
            {caption}
            { caption &&  <ArrowDown16BlueIcon />}
          </Markup.Caption>
        </Markup.Content>
        <Markup.RightControl>{rightAction || <IconButton content="icon" icon={<MeatBall24BlueIcon />} />}</Markup.RightControl>
      </Markup.Container>
    </StackHeader>
  );
};

export const DetailsPanelHeader = memo(DetailsPanelHeaderComponent);
