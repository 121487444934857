import React, { memo } from "react";
import { withNeutralColor } from "shared/utils";
import styled from "styled-components/macro";

type PointProps = {
  className?: string;
  color?: string;
};

const PointBlock = styled.div<PointProps>`
  background-color: ${(props) => props.color || withNeutralColor("l4")};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;

const PointComponent = (props: PointProps) => {
  const { className, color } = props;
  return <PointBlock className={className} color={color} />;
};

export const Point = memo(PointComponent);

