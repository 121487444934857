import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { DriverGetRequestOptions, DriverResponse, DriverVersionResponse } from "api/requests/driver/types";
import { useDriverActiveFilter, useDriverFiltersSelector, useDriverItemsSelector, useDriverSearchSubstringSelector } from "store/selectors/driver-selectors";
import { getDriverRequest } from "api/requests/driver/driver.requests";
import { TabValues } from "features/list-panels";
import { PaginationCardList } from "../components/pagination-card-list";
import { DriverCard } from "../../cards";
import { driverActions } from "../../../store/actions";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

const DEFAULT_PAGE_SIZE = 200;

export type DriverCardListProps = {
  activeId?: string;
  ContractorIds?: string | null,
  RouteIds?: string | null,
  DepotIds?: string | null,
  onClick?: (id: string) => void;
};

const DriverCardListComponent = (props: DriverCardListProps) => {
  const { activeId, onClick, ContractorIds = null, RouteIds = null, DepotIds = null } = props;
  const [pageNum] = useState(1);
  const activeFilter = useDriverActiveFilter();
  const { items, totalCount } = useDriverItemsSelector(activeFilter);
  const { showDetails } = useLayoutDesignState();
  const dispatch = useDispatch();
  const filters = useDriverFiltersSelector();
  const searchSubstring = useDriverSearchSubstringSelector();

  const firstItem = items[0];
  const activeIndex = useMemo(() => items.findIndex((item) => `${item.versionId}` === String(activeId)), [items, activeId]);

  useEffect(() => {
    if (!activeId && firstItem && onClick) {
      const stopId = `${firstItem.versionId}`;
      onClick(stopId);
    }
  }, [activeId, firstItem, onClick]);

  const handleClick = useCallback((id: string) => {
    if (onClick) {
      onClick(id);
      // showDetails();
    }
  }, [onClick, showDetails]);

  const params: DriverGetRequestOptions = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
    IncludeActive: activeFilter === TabValues.ACTIVE,
  }), [pageNum, activeFilter]);

  // const options: DriverGetRequestOptions = useMemo(() => {

  // }({
  //   IncludeActive: activeFilter === TabValues.ACTIVE,
  //   searchSubstring,
  //   ...filters,
  // }), [activeFilter, searchSubstring, filters]);

  const options: any = useMemo(() => {
    const fil = {
      ContractorIds: ContractorIds || filters.ContractorIds,
      DepotIds: DepotIds || filters.DepotFilterElements?.map(el => el.depotId).join(",") || undefined,
      RouteIds: RouteIds || filters.RouteFilterElements?.map(el => el.routeId).join(",") || undefined,
    };
    return {
      IncludeActive: activeFilter === TabValues.ACTIVE,
      searchSubstring: searchSubstring || undefined,
      ...fil,
    };
  }, [filters, ContractorIds, DepotIds, RouteIds, activeFilter, searchSubstring]);

  const itemRender = useCallback((item: DriverResponse) => {
    const { driverId, firstName, middleName, lastName, versionId, depot } = item;

    return <DriverCard
      id={String(versionId)}
      firstName={firstName || "-"}
      middleName={middleName || ""}
      lastName={lastName || "-"}
      depot={depot}
      isActual={activeFilter === TabValues.ACTIVE}
      isActive={activeId === String(versionId)}
      onClick={handleClick}
    />;
  }, [activeId, handleClick, activeFilter]);

  const setItems = useCallback((response: DriverVersionResponse, pageParams?: DriverGetRequestOptions) => {
    dispatch(driverActions.setDrivers({
      data: response,
      status: activeFilter,
      pageNum: pageParams?.pageNum || -1,
    }));
  }, [dispatch, activeFilter]);

  return (
    <PaginationCardList
      fetcher={getDriverRequest}
      items={items}
      itemRender={itemRender}
      options={options}
      params={params}
      setItems={setItems}
      totalCount={totalCount}
      scrollTo={activeIndex}
    />
  );
};
export const DriverCardListPagination = memo(DriverCardListComponent);
