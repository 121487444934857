/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PanelForm } from "features/panel-form";
import { composeValidators, isCorrectEndDate, isCorrectStartDate, isRequired } from "shared/utils/validator";
import { DetailsPanelTabs, FormField } from "features/detail-panels/components";
import { labels } from "shared/constants";
import { LineEditor } from "@megapolis/utils";
import { Trip, getRouteStops, TripStop } from "api";
import { createNewDataTripsLine, createNewDataTripsStop } from "components/common/main-map/selected-route/utils";
import { MapLayerNames } from "components/common/main-map/utils/consts/map.constants";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { routeActions } from "store/actions";
import { RootState } from "store/types";
import { v4 } from "uuid";
import { FeatureCollection } from "geojson";
import { useMap } from "components/common/main-map/map.context";
import { AlternativeSelect } from "components/common/alternative-select";
import { DatePicker } from "components/common/date-picker";
import { Toggle } from "@megapolis/uikit";
import { MapUtilsForLayers } from "components/common/main-map/utils/map.utils";
import { Tab } from "components";
import { Content } from "megapolis-uikit-latest/button/button.types";
import { Button } from "megapolis-uikit-latest";
import { KeyZoneDetails } from "models";
import { Geometry } from "@turf/turf";
import * as Markup from "./new-route-panel.styles";
import { TripsStepper } from "../tab-pages/route-details-route-tab/sections/trip-stepper/trips-stepper";
import { KeyZoneStepper } from "../tab-pages/route-details-route-tab/sections/keyZone-stepper/keyzone-stepper";

type Option = { label: string, value: string };
type LineProps = { startDate: string | null, endDate: string | null, direction: { label: string, value: string }, type: { label: string, value: string } };

export const NewRouteTripLine: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const { map } = useMap();
  const [startDate, setStartDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");
  const [filter, setFilter] = useState<string>("tripStops");
  const [curStops, setCurStops] = useState<Array<any>>([]);
  const [curKeyZones, setKeyZones] = useState<Array<any>>([]);
  const [lenghtRoute, setLenghtRoute] = useState(0);
  const [valid, setValid] = useState<boolean>(false);
  const [customDate, SetCustomDate] = useState<boolean>(false);
  const [tripCoords, setTripCoords] = useState<Trip>({
    direction: "",
    path: {
      "type": "LineString",
      "coordinates": [],
    },
    tripStops: [],
    tripKeyZones: [],
    type: "",
    startDate: null,
    endDate: null,
  });

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isDrawing, setIsDrawing] = React.useState(false);
  const [features, setFeatures] = React.useState<mapboxgl.MapboxGeoJSONFeature[] | null>(null);
  const lineEditor = React.useRef<LineEditor | null>(null);

  const form: LineProps = {
    startDate: null, // Время работы трассы
    endDate: null, // Время работы трассы
    direction: {
      label: "Прямое",
      value: "Forward",
    },
    type: {
      label: "Основной вариант",
      value: "00",
    },
  };

  const dispatch = useDispatch();
  const { setVisibility } = MapUtilsForLayers();

  const { newRouteTrips } = useSelector((state: RootState) => ({
    newRouteTrips: state.route.newRouteTrips,
  }));
  const memoType: Array<Option> = [
    {
      label: "Основной вариант",
      value: "00",
    },
    {
      label: "Укороченный вариант",
      value: "У1",
    },
    {
      label: "Дополнительный вариант",
      value: "Д1",
    },
  ];

  const memoDirection: Array<Option> = [
    {
      label: "Прямой",
      value: "Forward",
    },
    {
      label: "Обратный",
      value: "Backward",
    },
  ];

  useEffect(() => {
    if (filter === "tripStops") {
      map && setVisibility(map, true, MapLayerNames.ACTUAL_STOP_LAYER_ID);
    } else {
      map &&  setVisibility(map, false, MapLayerNames.ACTUAL_STOP_LAYER_ID);

    }
  }, [filter]);

  const updateStopLayers = useCallback((data) => {
    if (!map?.getSource("StopsRoute")) {
      map?.addSource("StopsRoute", {
        type: "geojson",
        data: createNewDataTripsStop(data),
      } as mapboxgl.AnySourceData);
    } else {
      const source: mapboxgl.GeoJSONSource = map?.getSource("StopsRoute") as mapboxgl.GeoJSONSource;
      source.setData(createNewDataTripsStop(data) as FeatureCollection);
    }
    if (!map?.getLayer("StopsRoute")) {

      map?.addLayer({
        id: "StopsRoute",
        type: "symbol",
        source: "StopsRoute",
        minzoom: 0,
        maxzoom: 24,
        layout: {
          "icon-image": [
            "case",
            ["==", ["get", "isActive"], true],
            "selectedListStopIcon",
            "selectedStopIcon",
          ],
          "icon-size": ["interpolate", ["exponential", 0], ["zoom"], 13.5, 0.7, 14.01, 1, 15, 1, 16.01, 1],
          "visibility": "visible",
          "icon-anchor": "bottom",
        },
      });
    }

  }, [map]);

  const updateKeyZoneLayers = useCallback((geometry: KeyZoneDetails["geometry"]) => {
    if (!map) return;
    const data = {
      type: "Feature",
      geometry: geometry as Geometry || {
        type: "Polygon",
        // These coordinates outline Maine.
        coordinates: [],
      } as Geometry,
      properties: {},
    };

    const prevSource = map?.getSource("KeyZoneRouteSource");

    if (!prevSource) {
      map?.addSource("KeyZoneRouteSource", {
        type: "geojson",
        data,
      } as mapboxgl.AnySourceData);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      prevSource?.setData(data);
    }

    if (!map?.getLayer("KeyZoneRoutePolygon")) {
      map?.addLayer({
        "id": "KeyZoneRoutePolygon",
        "type": "fill",
        "source": "KeyZoneRouteSource", // reference the data source
        "layout": {},
        "paint": {
          "fill-color": "#e25afd", // blue color fill
          "fill-opacity": 0.7,
        },
      });
    }


    if (!map?.getLayer("KeyZoneRouteLine")) {
      map?.addLayer({
        "id": "KeyZoneRouteLine",
        "type": "line",
        "source": "KeyZoneRouteSource",
        "layout": {},
        "paint": {
          "line-color": "#5ACAFD",
          "line-width": 0.4,
        },
      });
    }
  }, [map]);

  const updateLineLayers = useCallback((data) => {
    if (!map?.getSource("LineRoute")) {
      map?.addSource("LineRoute", createNewDataTripsLine(data[0]) as mapboxgl.AnySourceData);
    } else {
      const source: mapboxgl.GeoJSONSource = map?.getSource("LineRoute") as mapboxgl.GeoJSONSource;
      source.setData(createNewDataTripsLine(data[0]).data as any);
    }
    if (!map?.getLayer("LineRoute")) {

      map?.addLayer({
        id: "LineRoute",
        type: "line",
        source: "LineRoute",
        minzoom: 0,
        maxzoom: 24,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#5297ff",
          "line-width": 6,
        },
      });
    }

  }, [map]);

  const kindSet = useCallback((arr) => {
    const stops = arr;
    stops.forEach((element: { kind: string; order: number; }, index: number, oldArr: { length: number; }) => {
      switch (index) {
        case 0:
          element.kind = "First";
          element.order = index + 1;
          break;
        case oldArr.length - 1:
          element.kind = "Last";
          element.order = index + 1;
          break;

        default:
          element.kind = "Middle";
          element.order = index + 1;
          break;
      }
    });
    return stops;

  }, []);


  const onPaintCurve = useCallback((feature: any[]) => {
    const featureArr: any[] = [];
    feature.forEach((elm: any) => featureArr.push(...elm.geometry.coordinates));
    getRouteStops(JSON.stringify({
      "type": "LineString",
      "coordinates": featureArr,
    }), { filterByStopAngle: false }).then((data: TripStop[]) => {
      const arr = data.map((item) => ({ ...item, active: item.active === undefined ? true : item.active, isControl: item.isControl ? item.isControl : false }));
      updateStopLayers(arr);
      setLenghtRoute(featureArr.length >= 3 ? featureArr.length : 0);
      const newTrip: Trip = {
        direction: "",
        path: {
          "type": "LineString",
          "coordinates": featureArr,
        },
        tripStops: kindSet(arr),
        type: "",
        startDate: null,
        endDate: null,
      };
      setTripCoords((trip) => ({ tripKeyZones: trip.tripKeyZones, ...newTrip }));
      setCurStops(kindSet(arr));
    });
    updateLineLayers(feature);

    if (map?.getLayer("LineRoute")) {
      map?.moveLayer("LineRoute", MapLayerNames.ACTUAL_STOP_LAYER_ID);
    }


  }, [kindSet, map, updateLineLayers, updateStopLayers]);

  const onPaintCurveStarted = useCallback(() => {
    if (map?.getLayer("StopsRoute")) {
      map?.removeLayer("StopsRoute");
    }
    if (map?.getSource("StopsRoute")) {
      map?.removeSource("StopsRoute");
    }
    if (map?.getLayer("LineRoute")) {
      map?.removeLayer("LineRoute");
    }
    if (map?.getSource("LineRoute")) {
      map?.removeSource("LineRoute");
    }
    if (map?.getLayer("KeyZoneRoutePolygon")) {
      map?.removeLayer("KeyZoneRoutePolygon");
    }
    if (map?.getLayer("KeyZoneRouteLine")) {
      map?.removeLayer("KeyZoneRouteLine");
    }
    if (map?.getSource("KeyZoneRouteSource")) {
      map?.removeSource("KeyZoneRouteSource");
    }
  }, [map]);

  const viewStopsAlongTrip = useCallback(async () => {
    // StopController(payload, map);
    const actualStopLayer: mapboxgl.SymbolLayer = {
      id: MapLayerNames.ACTUAL_STOP_LAYER_ID,
      type: "symbol",
      source: MapLayerNames.ACTUAL_STOP_SOURCE_ID,
      minzoom: 10,
      maxzoom: 24,
      layout: {
        "icon-image": "actualStopIcon",
        "icon-allow-overlap": true,
        "icon-size": 1,
        "visibility": "visible",
      },
      paint: {
        "icon-opacity": ["case", ["boolean", ["feature-state", "selected"], false], 0, 1],
        "icon-opacity-transition": {
          duration: 1000,
        },
      },
    };

    if (!map?.getLayer(MapLayerNames.ACTUAL_STOP_LAYER_ID) && map?.getSource(MapLayerNames.ACTUAL_STOP_SOURCE_ID)) {
      map?.addLayer(actualStopLayer);
    }
  }, [map]);

  const clickOnStop = useCallback((e: any) => {
    if (e.features[0]) {
      setCurStops((stops) => {
        const searchIndex = stops.findIndex((stop) => stop.stop.id === e.features[0].properties.stopId);
        if (searchIndex === -1) {
          const tripStop = {
            versionId: 0,
            kind: "Middle",
            mode: "Usual",
            noBoarding: false,
            active: true,
            keyPoint: false,
            stop: {
              id: e.features[0].properties.stopId,
              name: e.features[0].properties.stopName,
              lat: e.features[0].geometry.coordinates[1],
              lng: e.features[0].geometry.coordinates[0],
            },
          };
          stops = stops.concat([tripStop]);
          setTripCoords((trip) => ({ ...trip, tripStops: stops } as Trip));
          updateStopLayers(stops);
          return stops;
        }
        return stops;
      });
    }

  }, [updateStopLayers]);

  const selectStop = useCallback((e: any) => {
    setCurStops(e);
    updateStopLayers(e.filter((elm: any) => elm.active === true));
  }, []);


  const onStopClick = useCallback((tripStop: TripStop[], idx: number) => {
    const arr = tripStop.map((elm) => ({ ...elm, isActive: false }));
    arr[idx].isActive = true;
    updateStopLayers(arr.filter((elm: any) => elm.active === true));
  }, []);

  const onKeyZoneClick = useCallback((keyZone: KeyZoneDetails) => {
    keyZone.geometry && updateKeyZoneLayers(keyZone.geometry);
  }, [updateKeyZoneLayers]);


  const clickOnKeyZone = useCallback((e: any) => {
    if (e.features[0]) {
      setKeyZones((zones) => {
        const searchIndex = zones.findIndex((zone) => zone.keyZoneId === e.features[0].properties.keyZoneId);
        if (searchIndex === -1) {
          const elm = e.features[0].properties;
          const keyZone = {
            keyZoneId: elm.keyZoneId,
            name: elm.name,
            geometry: e.features[0].geometry,
            active: true,
          };
          zones = zones.concat([keyZone]);
          zones = zones.map((zone, idx) => ({ ...zone, sequenceNum: idx + 1 }));
          setTripCoords((trip) => ({ ...trip, tripKeyZones: zones } as Trip));
          return zones;
        }
        return zones;
      });

    }
  }, []);

  useEffect(() => {
    if (filter === "tripStops") {
      map && setVisibility(map, true, MapLayerNames.ACTUAL_STOP_LAYER_ID);
      map && setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
      map && setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
      map && setVisibility(map, false, MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL);
      map && setVisibility(map, false, "KeyZoneRoutePolygon");
      map && setVisibility(map, false, "KeyZoneRouteLine");

    } else {

      map &&  setVisibility(map, false, MapLayerNames.ACTUAL_STOP_LAYER_ID);
      map && setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
      map && setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
      map && setVisibility(map, true, MapLayerNames.KEYZONE_ROUTE_POLYGON_FILL);
      if (map?.getLayer("KeyZoneRoutePolygon")) {
        map?.removeLayer("KeyZoneRoutePolygon");
      }
      if (map?.getLayer("KeyZoneRouteLine")) {
        map?.removeLayer("KeyZoneRouteLine");
      }
      if (map?.getSource("KeyZoneRouteSource")) {
        map?.removeSource("KeyZoneRouteSource");
      }
    }
  }, [filter]);

  const handleDrawEnd = (_features: mapboxgl.MapboxGeoJSONFeature[]) => {
    setFeatures(_features);
    onPaintCurve(_features);
  };

  const handleStartDrawingClick = () => {
    if (!lineEditor.current) return;

    if (isDrawing) {
      onPaintCurveStarted();
      lineEditor.current.finishDrawing();
      setCurStops([]);
      setKeyZones([]);
      setTripCoords((trip) => ({ ...trip, tripKeyZones: [], tripStops: [] }));
    } else {
      onPaintCurveStarted();
      setCurStops([]);
      setKeyZones([]);
      setTripCoords((trip) => ({ ...trip, tripKeyZones: [], tripStops: [] }));


      lineEditor.current.startDrawing();
    }

    setIsDrawing((prev) => !prev);
  };

  const handleContinuePrevLineClick = () => {
    if (!Array.isArray(features)) return;
    onPaintCurveStarted();
    lineEditor.current?.continueDrawing(features);
    setIsDrawing(true);
    setFeatures(null);
  };



  const clearLayers = useCallback(() => {
    onPaintCurveStarted();

    if (map) {
      setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
      setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
      setVisibility(map, false, MapLayerNames.ACTUAL_STOP_LAYER_ID);
      setVisibility(map, false, MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID);
    }
  }, [map, onPaintCurveStarted, setVisibility]);

  const createLayersForNewRoute = useCallback(() => {
    if (!map) return;


    if (map) {
      // setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
      // setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
      setVisibility(map, true, MapLayerNames.ACTUAL_STOP_LAYER_ID);
      setVisibility(map, true, MapLayerNames.ACTUAL_STOP_SELECTED_LAYER_ID);
    }

    // map.moveLayer(layerId, MapLayerNames.ACTUAL_STOP_LAYER_ID);

  }, [map, setVisibility]);

  const onCancelPanel = useCallback(() => {
    if (map?.isStyleLoaded()) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (lineEditor.current) {
        lineEditor.current?.destroy();
        map?.removeControl(lineEditor.current);
      }
      clearLayers();
      map?.off("click", MapLayerNames.ACTUAL_STOP_LAYER_ID, clickOnStop);
      map?.off("click", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, clickOnKeyZone);
      setCurStops([]);
      setKeyZones([]);
      onCancel();
    }
  }, [clearLayers, clickOnKeyZone, clickOnStop, map, onCancel]);

  const handleLoad = () => {
    lineEditor.current = new LineEditor({
      onDrawEnd: handleDrawEnd,
    });
    map?.addControl(lineEditor.current);
    setIsLoaded(true);
  };

  


  useEffect(() => {
    viewStopsAlongTrip();
    createLayersForNewRoute();
    handleLoad();
    handleStartDrawingClick();
    // map?.on("click", MapLayerNames.ACTUAL_STOP_LAYER_ID, clickOnStop);
    // map?.on("click", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, clickOnKeyZone);
    return function cleanup() {
      onCancelPanel();
    };

  }, []);

  useEffect(() => {
    if (!map) return;
    if (isDrawing) {
      map?.off("click", MapLayerNames.ACTUAL_STOP_LAYER_ID, clickOnStop);
      map?.off("click", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, clickOnKeyZone);
    } else {
      map?.on("click", MapLayerNames.ACTUAL_STOP_LAYER_ID, clickOnStop);
      map?.on("click", MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID, clickOnKeyZone);
    }

  }, [isDrawing]);

  const scheme = useMemo(() => ({
    type: isRequired("Введите тип трассы"),
    startDate: customDate ? composeValidators(isRequired("Введите тип трассы"), isCorrectStartDate("Дата начала действия трассы не может быть больше даты окончания", endDate || "")) : isCorrectStartDate("Дата начала действия трассы не может быть больше даты окончания", endDate || ""),
    endDate: isCorrectEndDate("Дата окончания действия трассы не может быть меньше даты начала", startDate || ""),
    direction: isRequired("Укажите направление"),
  }), [endDate, startDate, customDate]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    lenghtRoute > 0 && curStops.length > 1 && (curKeyZones.length === 0 || curKeyZones.length > 1 )   ? setValid(true) : setValid(false);
  }, [lenghtRoute, curStops, curKeyZones]);


  const handleSubmit = useCallback((values: LineProps) => {
    const trip: Trip = {
      ...tripCoords,
      tripId: tripCoords.tripId ? -tripCoords.tripId : 0,
      versionId: tripCoords.versionId ? -tripCoords.versionId : 0,
      type: values.type.value,
      direction: values.direction.value,
      startDate: values.startDate,
      endDate: values.endDate,
      tripStops: kindSet(curStops.filter((e) => e.active)),
      tripKeyZones: curKeyZones ? curKeyZones.filter((e) => e.active).map((zone, i) =>  ({ ...zone, sequenceNum: i + 1 })) : curKeyZones,
      workId: v4(),
    };
    dispatch(routeActions.setNewRouteTrips([...newRouteTrips, trip]));
    onCancelPanel();
  }, [tripCoords, kindSet, curStops, curKeyZones, dispatch, newRouteTrips, onCancelPanel]);

  return (
    <PanelForm
      title={""}
      initialValues={form}
      validationScheme={scheme}
      onSubmit={handleSubmit}
      onCancel={onCancelPanel}
      isDisabled={!valid}

    >
      {
        ({ names, values, updateValue: setValue, errors }) => (
          <>
            <FormField>
              <AlternativeSelect label={"Тип трассы"} options={memoType} value={values.type}
                onChange={({ value, label }) => {
                  setValue(names.type, { value, label });
                }}
              ></AlternativeSelect>
            </FormField>
            <FormField>
              <AlternativeSelect label={"Направление"} options={memoDirection} value={values.direction}
                onChange={({ value, label }) => setValue(names.direction, { value, label })}
              ></AlternativeSelect>
            </FormField>
            <FormField>
              <Toggle value={customDate} onChange={() => {
                SetCustomDate(pos => !pos);
                setValue(names.startDate, null);
                setValue(names.endDate, null);
              }}><Markup.Label>{"Указать дату действия трассы"}</Markup.Label></Toggle>
            </FormField>
            <FormField>
              <Markup.Label>{labels.startDateRoute}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setStartDate(`${year}-${month}-${day}`);
                    setValue("startDate", `${year}-${month}-${day}`);
                  } else {
                    setStartDate(null);
                    setValue("startDate", null);
                  }
                }}
                isDisabled={customDate === false}
                errorText={errors?.startDate ?? undefined}
                hasError={!!errors?.startDate}
                value={values?.startDate ? new Date(values.startDate) : null}
              />

            </FormField>
            <FormField>
              <Markup.Label>{labels.endDateRoute}</Markup.Label>
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    const year = e?.getFullYear();
                    const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                    const day = e?.getDate().toString().padStart(2, "0");
                    setEndDate(`${year}-${month}-${day}`);
                    setValue("endDate", `${year}-${month}-${day}`);
                  } else {
                    setEndDate(null);
                    setValue("endDate", null);
                  }
                }}
                isDisabled={customDate === false}
                errorText={errors?.endDate ?? undefined}
                hasError={!!errors?.endDate}
                value={values?.endDate ? new Date(values.endDate) : null}
              />
            </FormField>
            <FormField>
              <Markup.Label>{"Контроль карты"}</Markup.Label>
              <Markup.ButtonMapControl>
                <Button
                  isDisabled={!isLoaded}
                  content={Content.Text}
                  priority="primary"
                  text={`${isDrawing ? "закончить" : "начать"} отрисовку`}
                  onClick={handleStartDrawingClick} />
                <div style={{ marginLeft: "5px" }}>
                  <Button
                    isDisabled={!features}
                    content={Content.Text}
                    className="second"
                    priority="secondary"
                    text={"продолжить предыдущую линию"}
                    onClick={handleContinuePrevLineClick} />
                </div>
              </Markup.ButtonMapControl>

            </FormField>
            <DetailsPanelTabs value={filter} onChange={setFilter}>
              <Tab value={"tripStops"}>Остановки</Tab>
              <Tab value={"tripKeyZones"}>Зоны</Tab>
            </DetailsPanelTabs>
            {filter === "tripStops" && <Markup.TripsStepperWrapper>
              <TripsStepper tripStops={curStops} isEdit onStopClick={onStopClick} onStopSelect={selectStop} onStopChangePlace={setCurStops} />
            </Markup.TripsStepperWrapper>}
            {filter === "tripKeyZones" && <Markup.TripsStepperWrapper>
              <KeyZoneStepper keyZones={curKeyZones as any} isEdit onKeyZoneClick={onKeyZoneClick} onKeyZoneSelect={setKeyZones} />
            </Markup.TripsStepperWrapper>}
          </>
        )
      }
    </PanelForm>
  );
};



