import { APP_ENV } from "app-env";
import { AxiosRequestConfig } from "axios";
import * as api from "services";
import {
  StopCreateRequestOptions,
  SearchStopStationOptions,
  StopEditRequestOptions,
  StopGetRequestOptions,
  StopGetVersionsDatesRequestOptions,
  StopVersionDateResponse,
  StopDetailsResponse,
  StopVersionResponse,
  StopResponse,
} from "./types";

const requestPath = "Stop";

export const getStopRequest = (options?: StopGetRequestOptions) => api.get<StopVersionResponse>(`${requestPath}/version`, options);

export const getStopVersionRequest = (versionId: string, options?: AxiosRequestConfig) => api.get<StopDetailsResponse>(`${requestPath}/version/${versionId}`, {}, options);

export const getStopVersionsDatesRequest = (options: StopGetVersionsDatesRequestOptions) =>
  api.get<StopVersionDateResponse[]>(`${requestPath}/${options.stopId}/versionsDates`, options);

export const createStopRequest = (options: StopCreateRequestOptions) => api.post<{ stopId: number; versionId: number }>(`${requestPath}/version`, {}, options);

export const searchStopStation = (options: SearchStopStationOptions) => api.get(`${requestPath}/version`, options);

export const getStopStationVersionsDates = (stopId: string | number) => api.get<StopResponse[]>(`${requestPath}/${stopId}/versionsDates`);

export const editStopRequest = (options: StopEditRequestOptions) =>
  api.put<{ stopId: number; versionId: number }>(`${requestPath}/version/${options.versionId}?updateCommonAttributes=true`, {}, options);

export const getStopFile = async (stopId: number | string, id: number | string, signal?: AbortSignal | undefined) => {
  const myHeaders = new Headers();
  const accessToken = localStorage.getItem("Authorization") ?? "";
  if (accessToken.length) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const payload = await fetch(`${APP_ENV.REACT_APP_BASE_URL}/${requestPath}/${stopId}/${id}`, { ...requestOptions, signal });
  return payload;
};

export const getPavilionHolders = () => api.get<{ id: number, name: string }[]>(`${requestPath}/pavilionHolders`);
