import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  padding: 16px 0 6px 0;
  width: 100%;
`;

export const List = styled.div<{ headerHeight?: string }>`
  display: flex;
  flex-grow: 1;
  padding: 8px 0;
  overflow: hidden;
  width: 100%;
  height: calc(100% - ${(props) => props.headerHeight || 0});
`;

export const Filter = styled.div`
  width: 100%;
`;
