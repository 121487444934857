import React, { memo, useCallback } from "react";
import { UserComponent } from "@megapolis/react-auth";
import { Sidebar as SidebarCore } from "megapolis-uikit-latest";
import { Card } from "megapolis-uikit-latest/card";
import { SidebarFooter as SidebarFooterCore } from "megapolis-uikit-latest/sidebar-footer";
import { useRoute } from "react-router5";
import routes from "./components/navigation/routes";


const userComponent = <UserComponent size={24} />;
const footer = <SidebarFooterCore userComponent={userComponent} />;

export const useSidebar = () => {
  const { route } = useRoute();
  const handleClick = (action: any, page: any) => action(page);
  const header = ({
    title: "Реестр ОТ",
  });

  const sidebarContent = () =>routes.map((routesProps) => {
    const { title, action, page, icon, counter, isDisabled } = routesProps;
    const isActiveRoute = page.includes(route.name);

    return (
      <Card.ListItem
        key={`page_${page[0]}`}
        size="s"
        isNoWrapTitle
        isNoWrapCaption
        title={title}
        isActive={isActiveRoute}
        icon={icon}
        isDisabled={isDisabled}
        onClick={() => handleClick(action, page[0])}
      />
    );
  });
   


  return ({
    id: "sidebar",
    content: <SidebarCore.Component header={header} body={sidebarContent()} footer={footer} />,
    defaultWidth: 230,
    minWidth: 230,
    maxWidth: 350,
  });
};
