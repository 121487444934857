import React, { memo, ReactNode } from "react";
import styled from "styled-components/macro";
import { DetailRouteIcon } from "components/common";
import { Heading } from "components/ui";
import { withNeutralColor } from "shared/utils";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  margin-left: 8px;
`;

const Caption = styled.div`
  color: ${withNeutralColor("d1")};
`;

export type RouteBlockProps = {
  iconColor: string;
  iconText: string;
  title: string;
  caption: ReactNode;
};

const RouteBlockComponent = (props: RouteBlockProps) => {
  const { iconColor, iconText, caption, title } = props;
  return (
    <Container>
      <DetailRouteIcon color={iconColor}>{iconText}</DetailRouteIcon>
      <Content>
        <Heading.H5>{title}</Heading.H5>
        <Caption>{caption}</Caption>
      </Content>
    </Container>
  );
};

export const RouteBlock = memo(RouteBlockComponent);
