import { DepotVersionResponce, getDepotRequest, GetDepotRequestOptions, getRouteRequest, GetRouteRequestOptions, getVehicleRequest, RoutesVersionResponse, RouteVersion } from "api";
import { getDriverRequest } from "api/requests/driver/driver.requests";
import { DriverDepot, DriverResponse, DriverVersionResponse } from "api/requests/driver/types";
import { DepotCard, DriverCard, RouteCard, VehicleCard } from "features/cards";
import { TabValues } from "features/list-panels";
import { DepotVersion, getVehicleType, VehicleDetails, VehicleRequestItem, VehicleTypeCaptionMap } from "models";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { labels } from "shared/constants";
import { DriverItem } from "store/reducers/driver";
import { useRouteFilterSelector, useRouteSearchSubstringSelector, useRouteFiltersSelector } from "store/selectors/route-selectors";
import styled from "styled-components";

const DEFAULT_PAGE_SIZE = 100;

const NoDataLabel = styled.div`
  width: 100%;
  padding: 10px 0;
  background-color: #dbdbdb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;

const Text = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
  padding: 0 16px;
`;


export type RouteCardListPaginationProps = {
  VehicleIds?: string;
  DepotIds?: string;
  DriverIds?: string;
  RouteIds?: string;
  ContractorIds?: string;
};

const DepotTabListPaginationComponent = (props: RouteCardListPaginationProps) => {
  const { ContractorIds, RouteIds } = props;
  const activeFilter = useRouteFilterSelector();
  const [items, setItems] = useState<DepotVersion[]>([]);
  const [pageNum] = useState<number>(1);

 const params = useMemo(() => ({
    pageNum,
    pageSize: DEFAULT_PAGE_SIZE,
  }), [pageNum]);

  const options: GetDepotRequestOptions = useMemo(() => ({
    ContractorIds,
    RouteIds,
  }), [activeFilter, RouteIds, ContractorIds]);

  const handleSetItems = useCallback((response: DepotVersionResponce) => {
    setItems(response.items);
  }, []);

  const fetch = useCallback(async ()=>{
    const payload = await getDepotRequest({ ...params, ...options });
    handleSetItems(payload);
  }, []);

  useEffect(()=>{
    fetch();
    return ()=>{
      setItems([]);
    };
  }, []);

  const itemRender = useCallback((item: DepotVersion & { depotId: number }) => {
    const { versionId, name, depotId, ...rest } = item;

    return (
      <DepotCard
        {...rest}
        name={name}
        key={versionId}
        id={versionId}
        isActive={false}
        onClick={undefined}
      />
    );
  }, []);

  return <Container>{
    items.length > 0 ?  items.map(item => itemRender(item)) : <NoDataLabel><Text>Нет данных</Text></NoDataLabel>
  }
  </Container>;
};

export const DepotTabListPagination =  memo(DepotTabListPaginationComponent);