import React from "react";
import * as Markup from "./loader.styles";

export type LoaderProps = {
  /** Кастомный className */
  className?: string;
  /** Кастомный цвет лепестков */
  color?: string;
  /** Размер спиннера, определяется как ширина и высота с сохранением пропорций */
  size?: string;
};

export const Loader = ({ className, color, size }: LoaderProps) => (
  <Markup.Loader className={className} color={color} size={size} />
);
