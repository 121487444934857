
import React from "react";
import { toastError } from "components";

export type ErrorHandlerProps = {
  errors: any,
  status: number,
  title: string,
  traceId: string,
  type: string
};

export const ErrorHandler = (props: ErrorHandlerProps) => {
  const { errors } = props;
  Object.keys(errors).map((key) => {
    const title = key;
    let message = "";
    errors[key].forEach((el: string) => {
      message += `${el} \n`;
    });
    return toastError({ title, message });
  });

};