import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider } from "components/common";
import { ContractorResponse, getContractorFile } from "api";
import { NoDataLabel, toastError, ViewFileArea } from "components";
import { DetailsBlock } from "features/detail-panels/components";
import { Loader } from "megapolis-uikit-latest/loader";
import * as Markup from "./contractor-details-info-tab.styles";
import { GeneralInfo } from "./sections";
import { useGetContractorItemsByVersion } from "../../contractor-details.data";

export const ContractorDetailsInfoTab = () => {

  const { contractorDetails } = useGetContractorItemsByVersion();

  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchFiles = useCallback(async (controller: AbortController, contractor: ContractorResponse) => {
    if (!contractor?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(contractor.files.map(async fileElem => {
        const payload = await getContractorFile(contractor.Id, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);

  useEffect(() => {
    setFiles([]);
    const controller = new AbortController();
    fetchFiles(controller, contractorDetails);
    return () => {
      setFiles([]);
      controller.abort();
    };
  }, [contractorDetails, fetchFiles]);

  return (
    <Markup.Container>
      <GeneralInfo />
      <Divider margin="8px 0" />
      <DetailsBlock header={<Box>Файлы</Box>}>
        {
          isLoading && (<Box justifyContent={"center"}><Loader /></Box>)
        }
        {files.length > 0 ?
          (<Box direction="column" margin="-24px 0 0 0">
            <ViewFileArea
              filesInput={files}
              multiple={true} />
          </Box>) : (!isLoading && <NoDataLabel/>)
        }
      </DetailsBlock>
    </Markup.Container>
  );
};
