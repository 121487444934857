import { APP_ENV } from "app-env";
import * as api from "services";
import {
  ContractorGetRequestOptions, ContractorResponse, ContractorsVersionResponse, ContractorVersionRequest,
} from "./types";

const requestPath = "Contractor";

export const getContractorRequest = (options?: ContractorGetRequestOptions) => api.get<ContractorsVersionResponse>(`${requestPath}/version`, options);

export const getContractorTypes = () => api.get<{ id: number, name: string }[]>(`${requestPath}/contractorTypes`);

export const getContractorVersionRequest = (versionId: string) => api.get<ContractorResponse>(`${requestPath}/version/${versionId}`, {});

export const addContractorVersionRequest = (options: ContractorVersionRequest) => api.post(`${requestPath}/version`, {}, options);

export const editContractorRequest = (options: ContractorVersionRequest) => api.put(`${requestPath}/version`, {}, options);

export const getContractorVersionsDates = (contractorId: string | number) => api.get<any[]>(`${requestPath}/${contractorId}/versionsDates`);

export const getContractorFile = async (contractorId: number | string, id: number | string, signal?: AbortSignal | undefined) => {
  const myHeaders = new Headers();
  const accessToken = localStorage.getItem("Authorization") ?? "";
  if (accessToken.length) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }
  
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  
  const payload = await fetch(`${APP_ENV.REACT_APP_BASE_URL}/${requestPath}/${contractorId}/${id}`, { ...requestOptions, signal });
  return payload;
};

