/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useCallback, useEffect, useState } from "react";
import { toastError, toastSuccess } from "components";
import { ErrorHandler } from "shared/helpers/error-handler";
import { createStopRequest, editStopRequest, StopFeatures, StopCreateRequestOptions, StopDetailsResponse, getStopRequest, getStopFile, getStopVersionRequest } from "api";
import { useNavigateToPreviousRoute } from "shared/hooks/use-navigate-to-previous-route";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { useStopActiveFilter, useStopCurrentPage } from "store/selectors/stop-selectors";
import { useRouter } from "react-router5";
import { stopActions } from "store/actions";
import { PAGE_ROUTES } from "shared/definitions";
import { useFileLoadLogic } from "features/file-load-logic";
import { changeDay, getDateToBack } from "models/date.utils";
import { useGetStopItemsByVersion } from "../stop-station-details/stop-station-details.data";

export type NewStopStationForm = {
  name: string,
  address: string,
  angle: number,
  regNum: string | null,
  lat: number,
  lng: number,
  street: string,
  startDate: string | null,
  endDate: string | null,
  pavilionBalanceHolderId: number | undefined,
  hasPavilion: boolean,
} & StopFeatures;

export type EditStopStationForm = {
  versionId?: number,
  id: number,
} & NewStopStationForm;

const initialForm: NewStopStationForm = {
  name: "",
  address: "",
  angle: 3.33,
  lat: 0,
  lng: 0,
  street: "",
  regNum: null,
  startDate: null,
  endDate: null,
  pavilionBalanceHolderId: undefined,
  hasPavilion: false,
  alarmButton: false,
  forecastBoard: false,
  light: false,
  pedestrianPass: false,
  usbCharge: false,
  wasteBin: false,
  wheelChairBoarding: false,
  wiFi: false,
};

const detailsToForm = (details: StopDetailsResponse): EditStopStationForm => {
  const { name, address, angle, lat, lng, street, startDate, endDate, pavilionBalanceHolderId, hasPavilion, versionId, id, features, regNum } = details || {};

  const featuresStop = {
    alarmButton: features?.alarmButton || false,
    forecastBoard: features?.forecastBoard || false,
    light: features?.light || false,
    pedestrianPass: features?.pedestrianPass || false,
    usbCharge: features?.usbCharge || false,
    wasteBin: features?.wasteBin || false,
    wheelChairBoarding: features?.wheelChairBoarding || false,
    wiFi: features?.wiFi || false,
  };

  return { 
    name,
    address, 
    angle, 
    lat, 
    lng, 
    street, 
    startDate: startDate || null, 
    endDate: endDate || null, 
    pavilionBalanceHolderId: pavilionBalanceHolderId || undefined, 
    hasPavilion, 
    versionId, 
    id, 
    regNum: regNum || null,
    ...featuresStop };
};


const formToRequest = (form: NewStopStationForm): StopCreateRequestOptions => {
  const { alarmButton, forecastBoard, light, pedestrianPass, usbCharge, wasteBin, wheelChairBoarding, wiFi, ...rest } = form;
  return {
    features: {
      alarmButton: alarmButton || false,
      forecastBoard: forecastBoard || false,
      light: light || false,
      pedestrianPass: pedestrianPass || false,
      usbCharge: usbCharge || false,
      wasteBin: wasteBin || false,
      wheelChairBoarding: wheelChairBoarding || false,
      wiFi: wiFi || false,
    },
    ...rest,
  };
};



export const useNewStopLogic = (isNew: boolean, setValue?: (name: keyof NewStopStationForm, value: NewStopStationForm[keyof NewStopStationForm]) => void) => {
  const { item } = useGetStopItemsByVersion();
  const router = useRouter();
  const [form, setForm] = useState(isNew ? initialForm : detailsToForm(item));
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const { createMetaDataArr } = useFileLoadLogic();
  const [files, setFiles] = useState<File[] | undefined>(undefined);
  const dispatch = useDispatch();
  const activeFilter = useStopActiveFilter();
  const currentPage = useStopCurrentPage();

  const { newStopStationCoords, newStopStationData } = useSelector((state: RootState) => ({
    newStopStationCoords: state.stop.newStopStationCoords,
    newStopStationData: state.stop.newStopStationData,
  }));

  const fetchFiles = useCallback(async (controller: AbortController, stop: StopDetailsResponse) => {
    if (!stop?.files) return;
    const fileArr: File[] = [];

    try {
      setIsLoading(true);
      await Promise.all(stop.files.map(async fileElem => {
        const payload = await getStopFile(stop.id, fileElem.id, controller.signal);
        const blob = await payload.blob();
        const file = new File([blob], fileElem.name, {
          type: fileElem.mimeType,
        });
        fileArr.push(file);
      }));
    } catch (error: any) {
      if (error.name !== "AbortError") {
        toastError({ title: "Ошибка", message: error });
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !controller.signal.aborted && setIsLoading(false);
    }
    setFiles(fileArr);
  }, []);


  useEffect(() => {
    const controller = new AbortController();
    if (item && !isNew) {
      fetchFiles(controller, item);
    }
    return ()=>{
      controller.abort();
    };
  }, [item, fetchFiles, isNew]);

  useEffect(() => {
    if (newStopStationCoords === undefined) return;
    setValue && setValue("lat", newStopStationCoords.lat);
    setValue && setValue("lng", newStopStationCoords.lng);
    setForm((prevState: any) => ({
      ...prevState,
      lat: newStopStationCoords.lat,
      lng: newStopStationCoords.lng,
    }));
  }, [newStopStationCoords]);

  useEffect(() => {
    if (newStopStationData === undefined) return;
    setValue && setValue("address", newStopStationData.address);
    setValue && setValue("street", newStopStationData.street);
    setForm((prevState: any) => ({
      ...prevState,
      address: newStopStationData.address,
      street: newStopStationData.street,
    }));
  }, [newStopStationData]);


  useEffect(() => {
    setForm(initialForm);
    if (!isNew && item) {
      setEndDate(item.endDate ? item.endDate : undefined);
      if (item.startDate) {
        const dateMod = new Date(item.startDate);
        dateMod.setDate(dateMod.getDate() + 1);
        setStartDate(getDateToBack(dateMod));
      } else {
        setStartDate(undefined);
      }
    }
    return () => {
      setForm(initialForm);
    };

  }, []);

  const handleExecQuery = useCallback(async (verId: number, stpId: number) => {
    // dispatch(stopActions.getStopByVersionId(verId.toString()));
    try {
      const stop = await getStopVersionRequest(verId.toString());
      dispatch(stopActions.setStopByVersionId(verId.toString(), stop));
      dispatch(stopActions.updateStopsFiles(stop.id.toString(), stop));
      const response = await getStopRequest({ pageNum: currentPage, pageSize: 200 });
      dispatch(stopActions.setStops({
        data: response,
        status: activeFilter,
        pageNum: currentPage,
      }));
      router.navigate(PAGE_ROUTES.STOP_STATION, { id: `${stpId}_${verId}` });
    } catch (error) {
      router.navigate(PAGE_ROUTES.STOP_STATIONS);
    }
  }, [dispatch, activeFilter, currentPage, router]);


  const clearUp = useCallback(() => {
    (Object.keys(initialForm) as Array<keyof typeof initialForm>).map(el => setValue && setValue(`${el}`, initialForm[`${el}`]));
    setFiles(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
  }, [setValue]);

  const createStop = useCallback(async (values: NewStopStationForm) => {
    setIsLoading(true);

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files || []);
    } catch (error) {
      return;
    }


    createStopRequest({ ...formToRequest(values), files: fileArr }).then((res) => {
      setIsLoading(false);
      toastSuccess({ title: "Успешно", message: `Добавлен ${values.name}` });
      handleExecQuery(res.versionId, res.stopId);
      // navigateToPrevPage();
    })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));

  }, [createMetaDataArr, files, handleExecQuery]);


  const editStop = useCallback(async (values: EditStopStationForm) => {
    setIsLoading(true);

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files || []);
    } catch (error) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    delete values.versionId;

    await createStopRequest({ ...formToRequest(values), files: fileArr }).then((res) => {
      setIsLoading(false);
      toastSuccess({ title: "Успешно", message: `${isNew ? "Добавлен" : "Обновлен"} ${values.name}` });
      handleExecQuery(res.versionId, res.stopId);
    })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));

  }, [item, createMetaDataArr, files, isNew, handleExecQuery]);

  const editStopVersion = useCallback(async (values: EditStopStationForm) => {
    setIsLoading(true);

    let fileArr: { id: string; name: any; sizeInBytes: any; mimeType: string }[] = [];
    try {
      fileArr = await createMetaDataArr(files || []);
    } catch (error) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    await editStopRequest({ ...formToRequest(values), files: fileArr } as StopDetailsResponse).then((res) => {
      setIsLoading(false);
      toastSuccess({ title: "Успешно", message: `${isNew ? "Добавлен" : "Обновлен"} ${values.name}` });
      handleExecQuery(item.versionId, item.id);
    })
      .catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            ErrorHandler(error.response.data);
          } else {
            toastError({ title: "Ошибка", message: error.response.data.toString() });
          }

        }
      }).finally(() => setIsLoading(false));

  }, [createMetaDataArr, files, isNew, handleExecQuery, item?.versionId, item?.id]);


  return {
    form,
    clearUp,
    createStop,
    editStop,
    editStopVersion,
    handleFiles: setFiles,
    files,
    startDate,
    endDate,
    setForm,
    isLoading,
  };
};
