import React, { useCallback, useState } from "react";
import router from "router";
import { PAGE_ROUTES } from "shared/definitions/routes";
import { FilterWindow, Tab } from "components/ui";
import { commonActions, contractorActions } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { ContractorFilterContent } from "features/filters-windows";
import { ListPanel, ListPanelFilterTabs, ListPanelHeader, ListPanelHeaderWrapper } from "../components";
import { TabValues } from "../list-panels.constants";
import { ContractorFilters } from "../../../store/reducers/contractor";
import { ContractorPaginationCardList } from "../../card-lists/contractor-card-list/contractor-pagination-card-list";
import { useSelectedContractorIdSelector } from "../../../store/selectors/contractor-selectors";
import { useLayoutDesignState } from "../../../shared/hooks/use-layout-design-state";

export const ContractorListPanel = () => {
  const dispatch = useDispatch();
  const activeId = useSelectedContractorIdSelector();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { showDetails } = useLayoutDesignState();
  const { contractorActiveFilter } = useSelector((state: RootState) => state.contractor);
  const { filterStatus } = useSelector((state: RootState) => ({
    filterStatus: state.common.filterStatus,
  }));
  const toggle = useCallback(() => {
    dispatch(commonActions.setFilterStatus(!filterStatus));
  }, [filterStatus]);

  const handleItemClick = useCallback((id: string) => {
    dispatch(contractorActions.setSelectedContractor(id));
    // showDetails();
    router.navigate(PAGE_ROUTES.CONTRACTOR, { id });
  }, [dispatch, showDetails]);

  const handleChangeFilter = useCallback((filter: string) => {
    dispatch(contractorActions.setActiveFilter(filter as ContractorFilters));
  }, [dispatch]);

  const handleAddItem = useCallback(
    () => {
      router.navigate(PAGE_ROUTES.CONTRACTOR, {
        id: "new",
      });
    },

    [],
  );

  return (
    <ListPanel
      headerSlot={
        <ListPanelHeaderWrapper>
          {/* <FilterWindow headerText="Перевозчики" isShown={showFilter} hide={toggle} modalContent={<ContractorFilterContent hide={toggle} />} /> */}
          <ListPanelHeader requestPath="Contractor" title="Перевозчики" onAddClick={handleAddItem} onFilterClick={toggle} />
        </ListPanelHeaderWrapper>
      }
      filterSlot={
        <ListPanelFilterTabs activeFilterTab={contractorActiveFilter} onChange={handleChangeFilter}>
          <Tab value={TabValues.ACTIVE}>Активные</Tab>
          <Tab value={TabValues.INACTIVE}>Неактивные</Tab>
        </ListPanelFilterTabs>
      }
      listSlot={<ContractorPaginationCardList activeId={activeId} onClick={handleItemClick} />}
    />
  );
};
