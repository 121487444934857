import { withCBColor, withContentColor } from "@megapolis/uikit/utils/styled-helpers";
import styled, { css } from "styled-components/macro";


type WrapperProps = {
  isActive: boolean;
  isDisabled: boolean;
  withoutUserEvents: boolean;
};

const CommonCss = css`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.span`
  width: 32px;
  height: 32px;
  display: block;
  margin: auto 8px auto auto;
  svg {
    width: 100%;
    display: block;
  }
`;

export const Content = styled.span`
  display: flex;
  flex-flow: column nowrap;
  line-height: 16px;
  overflow: hidden;
  text-align: left;
  flex: 1;
  margin: 7px 0;
  white-space: nowrap;
  user-select: none;
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MainControl = styled.button`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
  ${CommonCss};
`;

export const AdditionalControl = styled.button`
  display: block;
  max-width: 24px;
  margin-left: 20px;
  justify-self: flex-end;
  ${CommonCss}
  svg {
    width: 100%;
    display: block;
  }
`;

export const isActiveCss = css`
  background-color: ${withCBColor("secondary", "active")};
  border-color: ${withCBColor("secondary", "active")};
`;

export const isDisabledCss = css`
  pointer-events: none;
  filter: grayscale(100%);
  opacity: 0.4;
`;

export const hoverCss = css`
  background-color: ${withCBColor("secondary", "hover")};
  border: 1px solid ${withCBColor("secondary", "hover")};
`;

export const focusWithinCss = css`
  background-color: ${withCBColor("secondary", "active")};
  border: 1px solid ${withCBColor("secondary", "active")};
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 12px;
  padding: 0 8px;
  overflow: hidden;
  &:focus-within {
    ${(props) => !props.withoutUserEvents && focusWithinCss};
  }
  &:active {
    ${(props) => !props.withoutUserEvents && isActiveCss};
  }
  &:hover {
    ${(props) => !props.withoutUserEvents && hoverCss};
  }
  ${(props) => props.isActive && isActiveCss};
  ${(props) => props.isDisabled && isDisabledCss};
`;

export const Title = styled.span`
  display: block;
  color: ${withContentColor("primary")};
`;

export const Caption = styled.span`
  display: block;
  color: ${withContentColor("secondary")};
  * {
    font-size: 11px;
  }
`;
