import React, { memo } from "react";
import { useElementComputedStyles } from "shared/hooks/use-element-computed-styles";
import * as Markup from "./details-panel.styles";
import { useLayoutDesignState } from "../../../../shared/hooks/use-layout-design-state";


export type DetailsPanelProps = {
  headerSlot: React.ReactNode;
  tabsSlot: React.ReactNode;
  children: React.ReactNode;
};

const DetailsPanelComponent = (props: DetailsPanelProps) => {
  const { headerSlot, tabsSlot, children } = props;
  const { ref, styles } = useElementComputedStyles<HTMLHeadElement>();
  const { isCollapsed } = useLayoutDesignState();

  return (
    <Markup.Container>
      <header ref={ref}>
        <Markup.HeaderWrapper isCollapsed={isCollapsed} >{headerSlot}</Markup.HeaderWrapper>
        <Markup.TabsWrapper>{tabsSlot}</Markup.TabsWrapper>
      </header>
      <Markup.Content headerHeight={styles?.height}>{children}</Markup.Content>
    </Markup.Container>
  );
};

export const DetailsPanel = memo(DetailsPanelComponent);
