import { Dispatch } from "redux";
import {
  getStopRequest,
  getStopVersionRequest,
  searchStopStation,
  SearchStopStationOptions,
  StopDetailsResponse,
  StopGetRequestOptions,
} from "api";
import axios, { CancelTokenSource } from "axios";
import { toastError } from "components";
import { labels } from "shared/constants";
import { TYPES } from "../types";
import { StopFilters } from "../../reducers/stop/stop.types";
import {
  StopActionTypes,
  SetStopByVersionIdAction,
  SetStopSearchStringAction,
  SetStopsAction,
  SetStopActiveFilterAction,
  SetCurrentPage,
  SetStopFiltersAction,
  UpdateStopsFilesAction,
} from "./stop-actions.types";

let source: CancelTokenSource;
export const stopActions = {
  getStop: (params: StopGetRequestOptions) => async (dispatch: Dispatch<any>) => {
    const payload = await getStopRequest({ ...params, pageSize: 50 });
    dispatch({
      type: params.isActive ? TYPES.STOP.SET_ACTIVE_STOP : TYPES.STOP.SET_INACTIVE_STOP,
      payload,
    });
  },

  searchStop: (params: SearchStopStationOptions) => async (dispatch: Dispatch<any>) => {
    const payload = await searchStopStation({ ...params, pageSize: 50 });
    dispatch({
      type: params.isActive ? TYPES.STOP.SET_ACTIVE_STOP : TYPES.STOP.SET_INACTIVE_STOP,
      payload,
    });
  },

  getStopByVersionId: (versionId: string) => async (dispatch: Dispatch<SetStopByVersionIdAction>) => {
    try {
      if (source) source.cancel("Operation canceled by the user.");
      source = axios.CancelToken.source();

      const payload = await getStopVersionRequest(versionId, { cancelToken: source.token });
      dispatch({
        type: StopActionTypes.SET_STOP_BY_VERSION_ID,
        payload: {
          versionId,
          data: payload,
        },
      });
    } catch (error) {
      toastError(labels.mapNotLoad);
    }

  },

  setStopByVersionId: (versionId: string, data: Partial<StopDetailsResponse>) => ({
    type: StopActionTypes.SET_STOP_BY_VERSION_ID,
    payload: { versionId, data },
  }),

  setActiveFilter: (payload: StopFilters): SetStopActiveFilterAction => ({
    type: StopActionTypes.SET_STOP_ACTIVE_FILTER,
    payload,
  }),

  setFilters: (payload: {
    BalanceHolder?: string | undefined | null;
    RouteIds?: string | undefined | null;
  }): SetStopFiltersAction => ({
    type: StopActionTypes.SET_STOP_FILTERS,
    payload,
  }),

  setCurrentPage: (payload: number): SetCurrentPage => ({
    type: StopActionTypes.SET_CURRENT_PAGE,
    payload,
  }),


  setSearchSubstring: (payload: string): SetStopSearchStringAction => ({
    type: StopActionTypes.SET_STOP_SEARCH_STRING,
    payload,
  }),

  setActiveItem: (payload: string) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: TYPES.STOP.SET_STOP_ACTIVE_ITEM,
      payload,
    });
  },

  updateStopsFiles: (stopId: string, data: Partial<StopDetailsResponse>): UpdateStopsFilesAction => ({
    type: StopActionTypes.UPDATE_STOPS_FILES,
    payload: { stopId, data },
  }),

  setStops: (payload: SetStopsAction["payload"]): SetStopsAction => ({
    type: StopActionTypes.SET_STOPS,
    payload,
  }),
};

export default stopActions;
