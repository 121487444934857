import styled, { css } from "styled-components/macro";
import { withAccentColor } from "shared/utils";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const requiredLabelCss = css`
  &::after {
    content: "*";
    color: ${withAccentColor("red", "basic")};
  }
`;

export const ContainerCustom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 12px;
  padding: 0 8px;
  overflow: hidden;
`;

export const LabelContainer = styled.span`
  margin-bottom: 8px;
`;

export const Label = styled.span`
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 8px 8px;
`;