import styled from "styled-components/macro";
import { withNeutralColor } from "shared/utils";

export const MeatballWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;
`;

export const Meatball = styled.div<{ color?: string }>`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: ${({ color }) => color || withNeutralColor("l4")};
  flex-shrink: 0;
  margin: 1px 0;
`;
