import React, { memo } from "react";
import styled from "styled-components/macro";

export type StepProps = {
  indicator: React.ReactNode;
  children: React.ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  width: 100%;
`;

const StepIndicator = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 24px;
`;

const StepContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
`;

const StepComponent = (props: StepProps) => {
  const { children, indicator } = props;
  return (
    <Container>
      <StepIndicator>{indicator}</StepIndicator>
      <StepContent>{children}</StepContent>
    </Container>
  );
};

export const Step = memo(StepComponent);
