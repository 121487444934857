import React, { useMemo, memo } from "react";
import { SkeletonDetailCard } from "components/ui/skeleton-detail-card";
import { useRouteParams } from "shared/hooks";
import { NewDepotPanel } from "./new-depot-panel";
import { DepotDetailsPanel } from "./depot-details-panel";


const ComponentNew = () => <NewDepotPanel />;

const ComponentEdit = ( props: { isNew: boolean } ) => <NewDepotPanel isNew={props.isNew}/>;

const ComponentEditVersion = ( props: { isNew: boolean, changeVersion: boolean } ) => <NewDepotPanel isNew={props.isNew} changeVersion={props.changeVersion} />;

const DepotDetailsPanelComponent = () => {
  const parapms = useRouteParams();
  let Component = useMemo(()=> <SkeletonDetailCard />, []);
  const isNew = useMemo(() => parapms.id === "new", [parapms.id]);
  const isEdit = useMemo(() => String(parapms.id).includes("_edit"), [parapms.id]);
  const isEditVersion = useMemo(() => parapms.id.toString().indexOf("version") > -1, [parapms.id]);
  const allAttr = useMemo(()=> [isNew, isEdit, isEditVersion].every((cv)=> cv === false), [isNew, isEdit, isEditVersion]);
  if (isEditVersion) Component = <ComponentEditVersion isNew={!isEditVersion} changeVersion={isEditVersion} />;
  if (isNew) Component = <ComponentNew/>;
  if (isEdit) Component = <ComponentEdit isNew = {false} />;
  if (allAttr) Component = <DepotDetailsPanel/>;
  return Component;
};

export const DepotDetailsPanelView = memo(DepotDetailsPanelComponent);