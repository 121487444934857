export enum ContractorType {
  /** Государственный */
  State,
  /** Коммерческий */
  Commercial,
}

export const ContractorTypeCaptionMap = {
  [ContractorType.State]: "Государственный перевозчик",
  [ContractorType.Commercial]: "Коммерческие перевозчики",
};

export type Contractor = {
  /** Идентификатор */
  id: number;
  /** Идентификатор версии */
  versionId?: number;
  /** Краткое наименование */
  shortName: string;
  /** Полное наименование */
  fullName?: string;
};

export type ContractorRequestItem = {
  /** Массив перевозчиков */
  items: Contractor[];
  /** Номер страницы */
  pageIndex: number;
  /** Размер страниц */
  pageSize: number;
  /** Общее число */
  totalCount: number;
};