import { v4 } from "uuid";
import { VehicleTypeArray } from "../../../models";

export const chips = VehicleTypeArray;

const transportTypeChipLabelData = ["Автобус", "Электробус", "Трамвай", "Троллейбус"];
const routeTypeChipLabelData = [{ id: "1", label: "Магистральный" }, { id: "3", label: "Социальный" }, { id: "2", label: "Районный" }, { id: "4", label: "Ночной" }];
const routeTypeChipStringData = "1,3,2,4";
const attributeTypeChipLabelData = ["Муниципальный", "Внутригородской"];
const routeGeometryChipLabelData = ["Маятниковый", "Кольцевой"];

export const transportTypeChipLabels = transportTypeChipLabelData.map((label) => ({
    id: v4(),
    label,
}));

export const routeTypeChipLabel = routeTypeChipLabelData;

export const routeTypeChipString = routeTypeChipStringData;

export const attributeTypeChipLabel = attributeTypeChipLabelData.map((label) => ({
    id: v4(),
    label,
}));

export const routeGeometryChipLabel = routeGeometryChipLabelData.map((label) => ({
    id: v4(),
    label,
}));

