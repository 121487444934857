import { APP_ENV } from "app-env";
import { DriverDetails } from "models";
import * as api from "services";
import {
  GetDriverRequestOptions,
  DriverVersionResponse,
  DriverVersionDetails,
  DriverVersion,
  DriverVersionRequest,
} from "./types";

const requestPath = "Driver";

export const getDriverRequest = (options?: GetDriverRequestOptions) => api.get<DriverVersionResponse>(`${requestPath}/version`, options);
export const getDriverVersionRequest = (versionId: string) => api.get<DriverVersionDetails>(`${requestPath}/version/${versionId}`);
export const getDriverVersionsDates = (driverId: string | number) => api.get<DriverVersion[]>(`${requestPath}/${driverId}/versionsDates`);
export const addDriverVersionRequest = (body: DriverVersionRequest) => api.post(`${requestPath}/version`, {}, body);
export const editDriverVersionRequest = (options: DriverDetails) => api.put(`${requestPath}/version/${options.versionId}`, {}, options);
export const getDriverFile = async (driverId: number | string, id: number | string, signal?: AbortSignal | undefined) => {
  const myHeaders = new Headers();
  const accessToken = localStorage.getItem("Authorization") ?? "";
  if (accessToken.length) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const payload = await fetch(`${APP_ENV.REACT_APP_BASE_URL}/${requestPath}/${driverId}/${id}`, { ...requestOptions, signal });
  return payload;
};
