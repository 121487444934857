import { Option } from "../../../components/common/select/select.types";

export enum SourceActionType {
  setSource = "SET_SOURCE",
  setTabSource = "SET_TAB_SOURCE",
}

type Action<A extends SourceActionType, P = never> = { type: A, payload: P };

export type SetSourceAction = Action<SourceActionType.setSource, { id: string, options: Option[] | undefined }>;

export type SetTabSourceAction = Action<SourceActionType.setTabSource, { id: string, items: any[] }>;

export type SourceAction = SetSourceAction | SetTabSourceAction;
