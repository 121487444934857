import { combineReducers } from "redux";
import { mapControlsSlice, PaintBuildingSlice } from "features/route-paint/store";
import { layersSlice } from "store/slice";
import { commonReducer } from "./common";
import { stopReducer } from "./stop";
import { scheduleReducer } from "./schedule";
import { vehicleReducer } from "./vehicle";
import { routeReducer } from "./route";
import { contractorReducer } from "./contractor";
import { depotReducer } from "./depot";
import { sourceReducer } from "./source";
import { driverReducer } from "./driver";
import { keyZoneReducer } from "./key-zone";
import { tabSourceReducer } from "./tab-source";

export default combineReducers({
  common: commonReducer,
  schedule: scheduleReducer,
  stop: stopReducer,
  vehicle: vehicleReducer,
  route: routeReducer,
  driver: driverReducer,
  contractor: contractorReducer,
  depot: depotReducer,
  source: sourceReducer,
  tabSource: tabSourceReducer,
  paintBuildingSlice: PaintBuildingSlice.reducer,
  mapControls: mapControlsSlice.reducer,
  layers: layersSlice.reducer,
  keyZone: keyZoneReducer,
});
