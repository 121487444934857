/* eslint-disable no-useless-escape */

import { APP_ENV } from "app-env";

/** Кирилический, латинский символ без учета регистра */
export const cyrLatCharIsensitive = /[a-zA-Zа-яА-Я]/;

/** Кирилический, латинский символ в верхнем регистре */
export const cyrLatCharUpperCase = /[A-ZА-Я]/;

/**  Кирилический, латинский символ в нижнем регистре */
export const cyrLatCharLowerCase = /[a-zа-я]/;

/**  Номер телефона */
export const phone = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

/** Число */
export const digit = /[0-9]/;

export const universalValue = /[A-ZА-Я0-9\s]/;

/** Код региона */
export const Region = [universalValue, universalValue, universalValue];

/** Универсальный Гос.регистрационный знак без региона */
export const universalGRN = [cyrLatCharUpperCase, universalValue, universalValue, universalValue, universalValue, universalValue];

/** Простой Гос.регистрационный знак без региона */
export const defaultGRN = [cyrLatCharUpperCase, digit, digit, digit, cyrLatCharUpperCase, cyrLatCharUpperCase];

/** Простой Гос.регистрационный знак без региона Севастополь */
export const defaultGRNSevastopol = [cyrLatCharUpperCase, cyrLatCharUpperCase, digit, digit, digit];

/** Простой Гос.регистрационный знак с регионом */
export const defaultGRNRegion = [...defaultGRN, ...Region];

/** Простой Гос.регистрационный знак с регионом Sevastopol */
export const defaultGRNRegionSevastopol = [...defaultGRNSevastopol, ...Region];

/** Универсальный Гос.регистрационный знак с регионом */
export const universalGRNRegion = [...universalGRN, ...Region];

/** Простой Гос.регистрационный знак без региона */
export const licensedGRN = [cyrLatCharIsensitive, cyrLatCharIsensitive, digit, digit, digit];

/** Простой Гос.регистрационный знак с регионом */
export const licensedGRNRegion = [...licensedGRN, " ", digit, digit, digit];

/** Гос.регистрационный знак Севастополя для трамваев и троллейбусов */
export const trolleybusGRN = APP_ENV.REACT_APP_CITY === "SEVASTOPOL" ? [cyrLatCharUpperCase, cyrLatCharUpperCase, digit, digit, digit, digit, cyrLatCharUpperCase, cyrLatCharUpperCase, cyrLatCharUpperCase, digit, digit] : [digit, digit, digit, digit];

/** Мобильный телефон, с Российским кодом */
export const ruMobilePhoneNumber = [
  "+",
  "7",
  "(",
  digit,
  digit,
  digit,
  ")",
  " ",
  digit,
  digit,
  digit,
  "-",
  digit,
  digit,
  "-",
  digit,
  digit,
];


export const coordRegex = /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$/i;

export const grzNumberRegex = /[a-zA-Zа-яА-Я]\d{3}[a-zA-Zа-яА-Я]{1,2}/i;
export const grzRegionNumberRegex = /\d{2,3}/i;
export const grzRegex = /([a-zA-Zа-яА-Я]\d{3}[a-zA-Zа-яА-Я]{1,2})(\d{2,3})/i;
export const grzNumberRegexSevastopol = /([a-zA-Zа-яА-Я]{1,2}\d{3}[a-zA-Zа-яА-Я\s]{0,2})(\d{0,3})/i; // Севастополь
export const vinRegex = /^[(A-HJ-NPR-Z0-9)]{17}$/;
export const trolleybusGRNRegex = APP_ENV.REACT_APP_CITY === "SEVASTOPOL" ? /^[АВЕКМНОРСТУХ]{2}\d{4}(?<!0000)[RUS]{3}\d{2,3}$/i : /\d{4}/i;

