import { Button, DatePicker } from "@megapolis/uikit";
import { latScheme, Divider, Textarea } from "components";
import { Field } from "components/common/form/field";
import { FormField } from "features/detail-panels/components";
import { PanelForm } from "features/panel-form";
import React, { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useRoute, useRouter } from "react-router5";
import { labels } from "shared/constants";
import { PAGE_ROUTES } from "shared/definitions";
import { useLayoutDesignState } from "shared/hooks/use-layout-design-state";
import { isRequired, isCorrectStartDate, isCorrectEndDate } from "shared/utils/validator";
import { RootState } from "store/types";
import { WarningCircleSVG } from "assets/icons";
import { Content } from "megapolis-uikit-latest/button/button.types";
import { NewKeyZoneForm, useNewKeyZoneLogic } from "./new-keyzone-panel.logic";
import * as Markup from "./new-keyzone-panel.styles";
import { DrawPolygon } from "./new-keyzone-panel.map";
import { KeyZoneMap } from "../keyzone-details-panel.map";


export const NewKeyZoneStationPanel = (props: { isNew?: boolean, changeVersion?: boolean }) => {
  const { isNew = true, changeVersion = false } = props;
  const referSetValue = useRef<(name: keyof NewKeyZoneForm, value: NewKeyZoneForm[keyof NewKeyZoneForm]) => void>();
  const { form, createKeyZone, editKeyZone, editVersionKeyZone, isLoading, clearUp, startDate: oldStartDate, endDate: oldEndDate } = useNewKeyZoneLogic(isNew, referSetValue.current);
  const [strtDate, setStrtDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");
  const { activeId } = useSelector((state: RootState) => ({
    activeId: state.keyZone.keyZoneActiveItem,
  }));
  const { handleLoad, clearPolygon, polygon, isCreate, isLoaded, handleContinueCreate, handleCreateClick } = DrawPolygon(isNew, form.geometry);
  const { clearLayers } = KeyZoneMap();

  const title = isNew ? labels.createKeyZone : labels.editKeyZone;

  const router = useRouter();
  const { previousRoute } = useRoute();

  const handleClosePanel = useCallback(() => {
    if (previousRoute) {
      router.navigate(PAGE_ROUTES.KEY_ZONE, { id: activeId });
    } else {
      router.navigate(PAGE_ROUTES.KEY_ZONES);
    }
  }, [activeId, previousRoute, router]);

  const handleSubmit = useCallback((values: any) => {
    if (isNew) {
      createKeyZone({ ...values, geometry: polygon?.geometry });
    } else if (changeVersion) {
      editVersionKeyZone({ ...values, geometry: polygon?.geometry });
    } else {
      editKeyZone({ ...values, geometry: polygon?.geometry });
    }
  }, [createKeyZone, editKeyZone, isNew, polygon?.geometry]);

  const addDays = useCallback((date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }, []);

  useEffect(() => {
    if (isNew) clearUp();
    clearLayers();
    handleLoad();
    return () => {
      clearUp();
      clearPolygon();
    };
  }, []);

  const scheme = useMemo(() => ({
    name: isRequired("Введите имя"),
    // address: isRequired("Введите адрес"),
    // street: isRequired("Введите улицу"),
    // lat: composeValidators(isMatchesTo("Неправильный формат", latRegex)),
    // lng: composeValidators(isMatchesTo("Неправильный формат", latRegex)),
    startDate: isCorrectStartDate("Дата начала действия трассы не может быть больше даты окончания", endDate || ""),
    endDate: isCorrectEndDate("Дата окончания действия трассы не может быть меньше даты начала", strtDate || ""),
  }), [endDate, polygon, strtDate]);

  return (
    <PanelForm
      title={title}
      initialValues={form}
      validationScheme={scheme}
      onSubmit={handleSubmit}
      onCancel={handleClosePanel}
      isLoading={isLoading}
      isDisabled={polygon === null}
    >
      {
        ({ names, values, updateValue: setValue, errors }) => {
          referSetValue.current = setValue;
          // const modValues = {name: values.name, startDate: values.startDate, endDate: values.endDate };
          return (
            <>
              <Field
                values={values}
                updateValue={setValue}
                name={names.name}
                errors={errors}
                type="text"
                label={labels.driverName}
                cyData="stop-station-name-add" />
              <FormField>
                <Markup.Label>{"Описание"}</Markup.Label>
                <Textarea onChange={(el) => setValue("description", el)} value={values?.description ? values.description : ""} />
              </FormField>
              <FormField>
                <Markup.Label>{"Дата начала действия зоны"}</Markup.Label>
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      const year = e?.getFullYear();
                      const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                      const day = e?.getDate().toString().padStart(2, "0");
                      setStrtDate(`${year}-${month}-${day}`);
                      setValue("startDate", `${year}-${month}-${day}`);
                    } else {
                      setStrtDate(null);
                      setValue("startDate", null);
                    }
                  }}
                  minDate={oldStartDate ? new Date(oldStartDate) : undefined}
                  maxDate={oldEndDate ? addDays(oldEndDate, 1) : undefined}
                  errorText={errors?.startDate ?? undefined}
                  hasError={!!errors?.startDate}
                  value={values?.startDate ? new Date(values.startDate) : null}
                />
              </FormField>
              <FormField>
                <Markup.Label>{"Дата окончания действия зоны"}</Markup.Label>
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      const year = e?.getFullYear();
                      const month = e?.getMonth() ? (e?.getMonth() + 1).toString().padStart(2, "0") : "01";
                      const day = e?.getDate().toString().padStart(2, "0");
                      setEndDate(`${year}-${month}-${day}`);
                      setValue("endDate", `${year}-${month}-${day}`);
                    } else {
                      setEndDate(null);
                      setValue("endDate", null);
                    }
                  }}
                  errorText={errors?.endDate ?? undefined}
                  hasError={!!errors?.endDate}
                  value={values?.endDate ? new Date(values.endDate) : null}
                />
              </FormField>
              <FormField>
                <Markup.Label>{"Контроль карты"}</Markup.Label>
                <div style={{ display: "flex" }}>
                  <Button
                    isDisabled={!isLoaded}
                    content={Content.Text}
                    priority="primary"
                    text={`${isCreate ? "закончить" : "начать"} отрисовку`}
                    onClick={() => {
                      handleCreateClick();
                    }} />
                  <div style={{ marginLeft: "5px" }}>
                    <Button
                      isDisabled={!polygon}
                      content={Content.Text}
                      className="second"
                      priority="secondary"
                      text={"продолжить предыдущую линию"}
                      onClick={() => {
                        handleContinueCreate();
                      }} />
                  </div>
                </div>
                {/* <div style={{ display: "flex" }}>
                  <Button
                    isDisabled={!isLoaded}
                    content={Content.Text}
                    priority="primary"
                    text={`${isCreate ? "закончить" : "начать"} отрисовку`}
                    onClick={()=>{
                      handleCreateClick();
                    }} />
                  <div style={{ marginLeft: "5px" }}>
                    <Button
                      isDisabled={!polygon}
                      content={Content.Text}
                      className="second"
                      priority="secondary"
                      text={"продолжить предыдущую линию"}
                      onClick={()=>{
                        handleLoad();
                        handleContinueCreate();
                      }} />
                  </div>
                </div> */}

              </FormField>
              {
                polygon === null && <FormField>
                  <Markup.NoZoneBlock>
                    <WarningCircleSVG />
                    {"Нарисуйте зону на карте"}
                  </Markup.NoZoneBlock>
                </FormField>
              }


              <Divider margin="16px 0" />

            </>
          );
        }
      }
    </PanelForm>
  );
};
