type KeyAppEnv =
  | "REACT_APP_TYPE"
  | "REACT_APP_BASE_URL"
  | "REACT_APP_API_DTM"
  | "REACT_APP_CLIENT_ID"
  | "REACT_APP_AUTH_URL"
  | "REACT_APP_API_URL"
  | "REACT_APP_AUTH_CALLBACK_URL"
  | "REACT_APP_MAP_URL"
  | "REACT_APP_MAP_STYLE"
  | "REACT_APP_BUILD"
  | "REACT_APP_CITY"
  | "REACT_APP_MAP_CENTER";

type AppEnv = { [key in KeyAppEnv]: any };

const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
      throw new Error(`Env variable ${key} is required`);
  }
  return process.env[key] || "";
};

export const MAP_ACTUAL_ROUTES = getEnvVar("REACT_APP_MAP_CLIENT_ACTUAL_ROUTES");
export const MAP_ACTUAL_STAT = getEnvVar("REACT_APP_MAP_CLIENT_ACTUAL_STAT");
export const MAP_ACTUAL_STOPS = getEnvVar("REACT_APP_MAP_CLIENT_ACTUAL_STOPS");
export const MAP_LAYER = getEnvVar("REACT_APP_MAP_CLIENT_LAYERS");
export const MAP_LAYER_DTM_VALHALLA_ROUTE = getEnvVar("REACT_APP_MAP_CLIENT_LAYERS_DTM_VALHALLA_ROUTE");
export const REACT_APP_MAP_CLIENT_PROJECTS = getEnvVar("REACT_APP_MAP_CLIENT_PROJECTS");


export const APP_ENV: AppEnv =
  (window as any).config ||
  Object.keys(process.env).reduce((acc: { [key: string]: any }, key: string) => {
    if (key.indexOf("REACT_APP_") === 0) {
      let value: any = String(process.env[key]);
      if (value.replace(/[^\d.-]/gi, "") === value) {
        const num = parseFloat(value);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(num) && isFinite(num)) {
          value = num;
        }
      } else if (value === "true") {
        value = true;
      } else if (value === "false") {
        value = false;
      } else {
        try {
          value = JSON.parse(value);
        } catch (err) {
          //
        }
      }
      if (typeof value !== undefined) {
        acc[key] = value;
      }
    }
    return acc;
  }, {});

export const MAP_CENTER = `${APP_ENV.REACT_APP_MAP_CENTER ? APP_ENV.REACT_APP_MAP_CENTER : "37.618423,55.751244"}`.split(",");


