import React, { memo } from "react";
import { TextIcon, VideoIcon, DocIcon, PdfIcon, CloseIcon, ErrorIcon } from "assets/icons";
import * as Markup from "./file.styles";
import { Loader } from "../loader";

type ClickCallbackSign = (e: React.MouseEvent<HTMLButtonElement>) => void;
type ExtensionFileType =
  | "png"
  | "jpg"
  | "bmp"
  | "tif"
  | "pdf"
  | "doc"
  | "docx"
  | "txt"
  | "csv"
  | "xls"
  | "xlsx"
  | "mp4";

export interface FileComponentProps {
  title: React.ReactNode;
  extension?: ExtensionFileType;
  isActive?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  canBeCancel?: boolean;
  withoutIcon?: boolean;
  withoutUserEvents?: boolean;
  className?: string;
  caption?: React.ReactNode;
  onClick?: ClickCallbackSign;
  onControlClick?: ClickCallbackSign;
}

const fileTypeIcons = {
  png: DocIcon,
  jpg: DocIcon,
  bmp: DocIcon,
  tif: DocIcon,
  pdf: PdfIcon,
  doc: DocIcon,
  docx: DocIcon,
  txt: TextIcon,
  xls: TextIcon,
  xlsx: TextIcon,
  csv: TextIcon,
  mp4: VideoIcon,
};

const FileComponent: React.FC<FileComponentProps> = (props) => {
  const {
    title,
    caption,
    onClick,
    className,
    onControlClick,
    isActive = false,
    isDisabled = false,
    isLoading = false,
    isError = false,
    canBeCancel = false,
    withoutIcon = false,
    withoutUserEvents = false,
    extension = "doc",
  } = props;
  const FileTypeIcon = fileTypeIcons[extension];

  return (
    <Markup.Wrapper
      className={className}
      isActive={isActive}
      isDisabled={isDisabled}
      withoutUserEvents={withoutUserEvents}>
      <Markup.MainControl onClick={onClick}>
        {!withoutIcon && (
          <Markup.Icon>
            <FileTypeIcon />
          </Markup.Icon>
        )}
        <Markup.Content>
          <Markup.Title>
            {title}
          </Markup.Title>
          <Markup.Caption>{caption}</Markup.Caption>
        </Markup.Content>
      </Markup.MainControl>
      {(isLoading || canBeCancel || isError) && (
        <Markup.AdditionalControl onClick={onControlClick}>
          {isError && <ErrorIcon />}
          {!isError && (isLoading ? <Loader /> : <CloseIcon />)}
        </Markup.AdditionalControl>
      )}
    </Markup.Wrapper>
  );
};

export const File = memo(FileComponent);
