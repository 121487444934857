import React, { FC } from "react";
import * as Markup from "./tab-base.styles";

export interface TabBaseProps {
  className?: string;
}

export const TabBase: FC<TabBaseProps> = ({ children, className }) => (
  <Markup.Container className={className}>{children}</Markup.Container>
);
