import mapboxgl, { Map, MarkerOptions } from "mapbox-gl";

let marker: mapboxgl.Marker;

export const addMarker = (
  coordinates: mapboxgl.LngLat,
  map: Map,
  prop: MarkerOptions | undefined = { color: "lightblue", draggable: false },
) => {
  if (marker) marker.remove();
  marker = new mapboxgl.Marker(prop).setLngLat(coordinates).addTo(map);
  return marker;
};

export const removeMarker = () => {
  marker?.remove();
};

export const getMarker = () => marker;
