import React, { useState, useCallback, useEffect, useRef } from "react";
import { useMap } from "components/common/main-map";
import { setVisibility } from "components/common/main-map/actual-keyZones/utils";
import { MapLayerNames } from "components/common/main-map/utils/consts/map.constants";
import { useOnZoneClick } from "components/common/main-map/actual-keyZones/use-on-zone-click";
import { TAB_VALUES } from "./keyzone-details-panel.constants";
import { DetailsPanel } from "../components";
import { KeyZoneDetailsTabs } from "./components/keyzone-details-tabs";
import { KeyZoneDetailsHeader } from "./components/keyzone-details-header";
import { useGetKeyZoneItemsByVersion } from "./keyzone-details-panel.data";
import { KeyZoneMap } from "./keyzone-details-panel.map";
import { KeyZoneDetailsRoutesTab } from "./tab-pages";

export const KeyZoneDetails = () => {
  const { map } = useMap();
  const [activeTab, setActiveTab] = useState(TAB_VALUES.info);
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  const mapTimer: { current: NodeJS.Timeout | null } = useRef(null); 
  const { keyZoneDetails } = useGetKeyZoneItemsByVersion();
  const { createLayersForKeyZone, clearLayers } = KeyZoneMap();

  useEffect(() => {
    if (keyZoneDetails?.geometry) {
      // eslint-disable-next-line consistent-return
      const waiting = () => {
        if (!map?.isStyleLoaded()) {
          if (timer.current !== null) {
            clearTimeout(timer.current);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            mapTimer.current !== null && clearTimeout(mapTimer.current);
          }
          timer.current = setTimeout(waiting, 100);
        } else {
          mapTimer.current = setTimeout(()=>createLayersForKeyZone(keyZoneDetails.geometry), 200);
          setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
          setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
        }
      };
      waiting();
    }

    return ()=>{
      clearLayers();
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      mapTimer.current !== null && clearTimeout(mapTimer.current);
      if (map) {
        setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
        setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
      }
    };
  }, [keyZoneDetails, createLayersForKeyZone, map, clearLayers]);

  useEffect(() => {
    if (map) {
      setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
      setVisibility(map, true, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
    }
    return () => {
      clearLayers();
      if (map) {
        setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_FILL_LAYER_ID);
        setVisibility(map, false, MapLayerNames.TILE_SET_KEYZONE_LINE_LAYER_ID);
      }
    };
  }, []);

  useOnZoneClick();

  const handleChangeTab = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  return (
    <DetailsPanel
      headerSlot={<KeyZoneDetailsHeader />}
      tabsSlot={<KeyZoneDetailsTabs value={activeTab} onChange={handleChangeTab} />}>
      {activeTab === TAB_VALUES.info && <KeyZoneDetailsRoutesTab />}
      {/* {activeTab === TAB_VALUES.routes && <DriverDetailsRoutesTab />} */}
    </DetailsPanel>
  );
};
