/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
import { withContentColor } from "@megapolis/uikit/utils";
import { withAccentColor } from "shared/utils";
import styled from "styled-components/macro";
import { switchProp } from "styled-tools";

type IconProps = {
  isActive: boolean;
};
export const IconWrapper = styled.span<IconProps>`
  & > svg,
  path:first-child {
    fill: ${switchProp("isActive", {
      true: withAccentColor("green", "basic"),
      false: withContentColor("tertiary"),
    })};
  }
`;
