import { CircleLayer, LineLayer } from "mapbox-gl";
import {
  CREATE_POINT_END_LAYER_ID,
  CREATE_POINT_END_SOURCE_ID,
  CREATE_POINT_LAYER_ID,
  CREATE_POINT_SOURCE_ID,
} from "../paint/paint";

export const getColorPointLine = (lineId: number) => {
  switch (lineId) {
    case 1:
      return "#40c700";
    case 2:
      return "#ff4c42";
    case 3:
      return "#b4e1ff";
    case 4:
      return "#ffbadd";
    case 5:
      return "#4a52ff";
    case 6:
      return "#ff891c";
    case 7:
      return "#8e8e8e";
    default:
      return "#40c700";
  }
};

export const getPointDraw = (selectedId: string, color: string): LineLayer => ({
  id: `${CREATE_POINT_LAYER_ID  }_${  selectedId}`,
  type: "line",
  source: `${CREATE_POINT_SOURCE_ID  }_${  selectedId}`,
  layout: {
    "line-cap": "round",
    "visibility": "visible",
  },
  paint: {
    "line-color": color,
    "line-width": 9,
    "line-opacity": 0.9,
  },
});

// export const getPointFinal = (selectedId: string, color: string): LineLayer => ({
//   id: `${FINAL_POINT_LAYER_ID  }_${  selectedId}`,
//   type: "line",
//   source: `${FINAL_POINT_SOURCE_ID  }_${  selectedId}`,
//   layout: {
//     "line-cap": "round",
//     "visibility": "visible",
//   },
//   paint: {
//     "line-color": color,
//     "line-width": 9,
//     "line-opacity": 0.9,
//   },
// });

export const getPointEndDraw = (selectedId: string, color: string): CircleLayer => ({
  id: `${CREATE_POINT_END_LAYER_ID  }_${  selectedId}`,
  type: "circle",
  source: `${CREATE_POINT_END_SOURCE_ID  }_${  selectedId}`,
  layout: {
    visibility: "visible",
  },
  paint: {
    "circle-color": color,
    "circle-radius": 8,
  },
});



