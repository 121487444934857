import React, { memo } from "react";
import { Plank } from "../plank";
import { Point } from "../point";
import { Indicator } from "../indicator";
import { IndicatorPropsBase } from "../types";
import * as Markup from "./mid-indicator.styles";

export type MidIndicatorProps = IndicatorPropsBase;

const MidIndicatorComponent = (props: IndicatorPropsBase) => {
  const { pointColor, plankColor, className } = props;

  return (
    <Indicator className={className}>
      <Markup.PlankWrapper>
        <Plank color={plankColor} />
      </Markup.PlankWrapper>
      <Markup.PointWrapper>
        <Point color={pointColor} />
      </Markup.PointWrapper>
      <Markup.PlankWrapper>
        <Plank color={plankColor} />
      </Markup.PlankWrapper>
    </Indicator>
  );
};

export const MidIndicator = memo(MidIndicatorComponent);
