import { APP_ENV } from "app-env";
import { AxiosRequestConfig } from "axios";
import * as api from "services";
import {
  GetRouteRequestByIdOptions,
  GetRouteRequestOptions,
  RouteCreateRequestOptions,
  RouteEditRequestOptions,
  RoutesVersionResponse, RouteVersion, RouteVersionDetails, TripStop,
} from "./types";

const requestPath = "Route";

export const addRouteRequest = (options: RouteCreateRequestOptions) => api.post(`${requestPath}/version`, {}, options);

export const getRouteVersionRequest = (versionId: string, options: GetRouteRequestByIdOptions) => api.get<RouteVersionDetails>(`${requestPath}/version/${versionId}`, options);

export const getRouteRequest = (options?: GetRouteRequestOptions) => api.get<RoutesVersionResponse>(`${requestPath}/version`, options);

export const editRouteRequest = (body: RouteEditRequestOptions | RouteCreateRequestOptions) => api.put(`${requestPath}/version`, {}, body);

export const getRouteStops = (body: any, params?: {
  date?: string,
  distanceInMeters?: number,
  filterBySide?: boolean,
  filterByStopAngle?: boolean,
} | undefined, options?: AxiosRequestConfig ) => api.post<TripStop[]>(`${requestPath}/path/proposedTripStops`, params, body, options);

export const getRouteversionsDates = (routeId: string | number) => api.get<RouteVersion[]>(`${requestPath}/${routeId}/versionsDates`);

export const getRouteFile = async (routeId: number | string, id: number | string, signal?: AbortSignal | undefined) => {
  const myHeaders = new Headers();
  const accessToken = localStorage.getItem("Authorization") ?? "";
  if (accessToken.length) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }
  
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  
  const payload = await fetch(`${APP_ENV.REACT_APP_BASE_URL}/${requestPath}/${routeId}/${id}`, { ...requestOptions, signal });
  return payload;
};


