import { useRoute, useRouter } from "react-router5";
import { useCallback } from "react";

export const useNavigateToPreviousRoute = () => {
  const router = useRouter();
  const { previousRoute } = useRoute();

  return useCallback(() => {
    if (previousRoute) {
      const { name, params } = previousRoute;

      router.navigate(name, params);
    }
  }, [previousRoute, router]);
};
