import React from "react";
import { getDefaultColor, getHoverColor, getActiveColor, getDisabledColor } from "./after-icon.styles.utils";
import { Container } from "./after-icon.styles";
import { TContent, Content } from "../../button.types";

export interface Props {
  /** Тип контента */
  content: TContent;
  /** Иконка */
  icon: React.ReactNode;
}

const AfterIcon = (props: Props) => {
  switch (props.content) {
    case Content.TextIcon:
      return <Container>{props.icon}</Container>;
    default:
      return null;
  }
};

AfterIcon.Markup = { Container, getDefaultColor, getHoverColor, getActiveColor, getDisabledColor };

export { AfterIcon };
