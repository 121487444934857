import React from "react";
import * as Markup from "./list-panel.styles";
import { useElementComputedStyles } from "../../../../shared/hooks/use-element-computed-styles";

export type ListPanelProps = {
  headerSlot: React.ReactNode;
  filterSlot: React.ReactNode;
  listSlot: React.ReactNode;
  className?: string;
};

export const ListPanel = (props: ListPanelProps) => {
  const { className, headerSlot, listSlot, filterSlot } = props;
  const { ref, styles } = useElementComputedStyles<HTMLHeadElement>();


  return (
    <Markup.Container className={className}>
      <header ref={ref}>
        <Markup.Header>{headerSlot}</Markup.Header>
        <Markup.Filter>{filterSlot}</Markup.Filter>
      </header>
      <Markup.List headerHeight={styles?.height}>{listSlot}</Markup.List>
    </Markup.Container>
  );
};
