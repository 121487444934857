import React from "react";
import styled from "styled-components/macro";
import { Divider } from "components/common";
import { Vehicles } from "./sections";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
`;

export const ContractorDetailsVehiclesTab = () => (
  <Container>
    <Vehicles />
    <Divider margin="8px 0" />
  </Container>
);
