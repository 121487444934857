import React, { memo, useCallback } from "react";
import { Card } from "components/ui";
import { StopStationCardCaption, StopStationCardIcon } from "./components";
import { Wrapper } from "./stop-station-card.styles";

export type StopStationCardProps = {
  /** Идентификатор */
  id: string;
  /** Наименование остановочного пункта */
  stationName: string;
  /** Адрес остановочного пункта */
  address: string;
  /** Список трасс */
  routeList: string[];
  /** Признак работоспособности(актуальности) ОП */
  isActual: boolean;

  stationId?: string;
  /** Признак активности карточки */
  isActive?: boolean;
  /** Обработчик нажатия на карточку */
  onClick?: (id: string) => void;
};

const StopStationCardComponent = (props: StopStationCardProps) => {
  const { id, stationName, isActive = false, isActual, onClick, stationId, ...captionParams } = props;

  const handleClick = useCallback(() => onClick?.(id), [onClick, id]);

  return (
    <Wrapper>
      <Card
        title={stationName}
        caption={<StopStationCardCaption id={stationId} {...captionParams} />}
        icon={<StopStationCardIcon isActual={isActual} />}
        isActive={isActive}
        onClick={handleClick}
      />
    </Wrapper>
  );
};

export const StopStationCard = memo(StopStationCardComponent);
