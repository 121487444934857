import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useRoute } from "react-router5";
import { PAGE_ROUTES } from "shared/definitions";
import { useGetRouteItemsByVersion } from "features/detail-panels/route-details-panel/route-details-panel.data";
import { useRouteParams } from "shared/hooks";
import { selectedRouteController } from "../selected-route/selected-route";
import { visibility, isGrayscale, ArrowIconSelected, useTimer } from "../selected-route/utils";

export const useActualRoute = (map?: mapboxgl.Map) => {
  const { removeTimer } = useTimer();
  const { routeDetails } = useGetRouteItemsByVersion();
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  const [isBeenDrawnRoute, setIsBeenDrawnRoute] = useState<boolean>(false);
  const { route: {
    name: pageRoute,
    params: { id },
  },
  } = useRoute();
  const parapms = useRouteParams();
  const copyRoute = parapms.state?.copy || undefined;
  const isCopy = useMemo(() => parapms.id === "copy" && parapms.state, [parapms.id, parapms.state]);
  const isNew = useMemo(() => parapms.id === "new", [parapms.id]);
  const isEdit = useMemo(() => String(parapms.id).includes("_edit"), [parapms.id]);

  useEffect(() => {
    if (!map) return;
    const waiting = () => {
      if (!map?.isStyleLoaded()) {
        if (timer.current !== null) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(waiting, 100);
      } else if (!id && isBeenDrawnRoute) {
        visibility(map, false);
        // removeDraw();
        setIsBeenDrawnRoute(false);
      } else if (pageRoute === PAGE_ROUTES.ROUTE && id) {
        if (isNew) return;
        if (!routeDetails && !copyRoute) {
          visibility(map, false);
          return;
        }
        visibility(map, true);
        const imageArrow = new Image();
        const backgroundColor = "#808080";
        const foreground = isGrayscale(backgroundColor) ? "#000000" : "#ffffff";
        imageArrow.src = ArrowIconSelected(foreground);
        imageArrow.onload = () => {
          if (map.hasImage("actualRouteArrow")) map.removeImage("actualRouteArrow");
          map.addImage("actualRouteArrow", imageArrow);
        };
        removeTimer("selectedRouteController");
        if (!map) return;
        if (routeDetails) {
          selectedRouteController(routeDetails as any, map);
        }
        if (copyRoute) {
          selectedRouteController(copyRoute as any, map);
        }
        

        setIsBeenDrawnRoute(true);
        // eslint-disable-next-line consistent-return
      }
    };
    waiting();
    // eslint-disable-next-line consistent-return
    return () => {
      removeTimer("selectedRouteController");
      if (!map) return;
      if (map?.isStyleLoaded()) {
        if (map?.getLayer("selectedRoute")) {
          map?.removeLayer("selectedRoute");
        }
        if (map?.getSource("selectedRoute")) {
          map?.removeSource("selectedRoute");
        }
      }


    };
  }, [routeDetails, id, pageRoute, map, isBeenDrawnRoute, removeTimer]);

};