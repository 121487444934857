import React, { memo, ReactNode } from "react";
import styled from "styled-components/macro";
import { prop } from "styled-tools";

import { Divider } from "../divider";

export type DividedContentProps = {
  children: ReactNode;
  dividerColor?: string;
  contentMarginSize?: string;
  margin?: string;
};

const Container = styled.div<{ margin?: string }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: ${prop("margin", "0")};
`;

const DividedContentComponent = (props: DividedContentProps) => {
  const { children, dividerColor, margin, contentMarginSize = "0" } = props;
  return (
    <Container margin={margin}>
      <Divider color={dividerColor} margin={`0 ${contentMarginSize} 0 0`} />
      {children}
      <Divider color={dividerColor} margin={`0 0 0 ${contentMarginSize}`} />
    </Container>
  );
};

export const DividedContent = memo(DividedContentComponent);
